<template>
  <div class="fire-content">
    <div class="fire-message clearfix">
      <div class="fl message">
        <h4 class="title clearfix">
          <span class="icon fl" :class="originalType(fireInfo)!=='火警'?'gaojing':''"></span>
          <span class="text fl">发现{{originalType(fireInfo)}}！请迅速行动！</span>
          
        </h4>
        <div class="bottom-buttons jingyin-button">
            <div :class="(fireInfo.isVoted ? 'actived ' : '') + 'vote button'" @click="erasureNoise(fireInfo)">
              <span class="icon fl"></span>
              <span class="text fl">消音</span>
            </div>
          </div>
        <ul class="list">
          <li class="item">
            <span class="label">单位:</span>
            <span class="text">{{fireInfo?fireInfo.companyName:'未知单位'}}</span>
          </li>
          <li class="item">
            <span class="label">建筑:</span>
            <span class="text">{{fireInfo?fireInfo.buildingName:'未知建筑'}}</span>
          </li>
          
          <li class="item">
            <span class="label">地点:</span>
            <span class="text">{{fireInfo?fireInfo.alarmInfo:'未知地点'}}</span>
          </li>
          <li class="item">
            <span class="label">备注:</span>
            <span class="text">
              {{fireInfo?fireInfo.devicePointMappingVO?
                (fireInfo.buildingName + fireInfo.devicePointMappingVO.pointAddressName):
                (fireInfo.buildingName +fireInfo.equipmentName):'暂无备注'}}
            </span>
          </li>
          <li class="item">
            <span class="label">时间:</span>
            <span class="text">{{fireInfo?fireInfo.createAt:'未知时间'}}</span>
          </li>
        </ul>
        <div class="bottom_comm">
          <p @click="checkFire('check',fireInfo)">查看</p>
          <p @click="checkFire('deal',fireInfo)">处理</p>
        </div>
       
      </div>
      <div class="fr operate">
      </div>
      <div id="mp3">
        <!-- <audio id="audio1" src="../assets/audio/4645.mp3" loop="loop"></audio> -->
      </div>
    </div>
  </div>
  
</template>

<script>
import {
  createNamespacedHelpers,
  mapGetters,
  mapActions as mapOtherActions,
  mapState as mapOtherState
} from "vuex";

const { mapActions, mapState } = createNamespacedHelpers("Dashboard/");
export default {
  name: "fireAlarm",
  props: {
    isVoted: Boolean,
    videoClick: Function,
    digitizingClick: Function,
    change: Function,
    fireInfo: Object,
  },
  data() {
    return {
      time: "",
      timeval: null
    };
  },
  watch: {
    fireInfo(nowValue) {
      this.timeInterval(nowValue);
      console.log('nowValue:',nowValue)
      this.$emit("fireTabChange", nowValue);
    }
  },
  computed: {
    ...mapState(["onFireData"])
  },
  methods: {
     originalType(comm) {
          let text=""
          if((comm.originalType==2||comm.originalType==3)&&!comm.fireAlarm&&!comm.faultAlarm&&!comm.alertAlarm){
                text="报警"
            }else{
                if(comm.fireAlarm){
                    text="火警"
                }else if(comm.faultAlarm){
                    text ="故障"
                }else if(comm.alertAlarm){
                    text ="告警"
                }else{
                    text = "未知异常"
                }
          }
          return text
      },
    checkFire(type,data){
      let lisData = data;
      lisData["dealType"] = type;
      this.$emit('getReply', lisData)
    },
      closeMsg(ev){
      if(!this.$refs.msk.contains(ev.target)){
        this.isShowModal = false
      }
    },
    copyText() {
      let clipboard = new this.clipboard(".copy");
      let that = this;
      clipboard.on('success', () => {
        that.$message.success("复制火警信息成功");
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        that.$message.error("复制火警信息失败");
        clipboard.destroy();
      });
    },
    voteAlarmVoice() {
      this.change(!this.isVoted);
    },
    erasureNoise(fireInfo) {
      this.$emit("erasureNoise",fireInfo);
    },
    bacoToHome() {
      this.$router.push({ path: "/dashboard/dashboard" });   
    },
    timeInterval(nowValue) {
      let _this = this;
      clearInterval(this.timeval);
      let oldAlarmTime = new Date(nowValue.alarmTime).getTime();
      this.timeval = setInterval(function() {
        let nowAlarmTime = new Date().getTime();
        let agoAlarmTime = nowAlarmTime - oldAlarmTime;
        let day = Math.floor(agoAlarmTime / 86400000); //天
        let hours = Math.floor((agoAlarmTime % 86400000) / 3600000); //时
        let minutes =
          (Math.floor((agoAlarmTime % 3600000) / 60000)) >= 10
            ? Math.floor((agoAlarmTime % 3600000) / 60000)
            : "0" + (Math.floor((agoAlarmTime % 3600000) / 60000)); //分
        let seconds =
          (Math.floor((agoAlarmTime % 60000) / 1000)) >= 10
            ? Math.floor((agoAlarmTime % 60000) / 1000)
            : "0" + (Math.floor((agoAlarmTime % 60000) / 1000)); //秒
        let allHours =
          day * 24 + hours > 10 ? day * 24 + hours : "0" + (day * 24 + hours);
        _this.time = allHours + ":" + minutes + ":" + seconds;
      }, 1000);
    },
    formatDate(dateValue) {
      if (dateValue) {
        var nowDate = new Date(dateValue).toLocaleDateString().split("/"),
          nowTime = new Date(dateValue).toLocaleTimeString().split(":");
        nowDate[1] < 10 ? (nowDate[1] = "0" + nowDate[1]) : nowDate[1];
        nowDate[2] < 10 ? (nowDate[2] = "0" + nowDate[2]) : nowDate[2];
        let hour = nowTime[0].slice(2);
        hour < 10 ? (nowTime[0] = "0" + hour) : hour;
        nowTime[0] = hour;
        return nowDate.join("-") + " " + nowTime.join(":");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fire-content{
  
background: linear-gradient(-90deg, #FF0000 0%, #8F080C 100%);
}
.bottom_comm{
  display:flex;
  text-align: center;
  p{
    width: 0.75rem;height:0.3rem;line-height:0.28rem;text-align: center;font-size: 0.14rem;color: #fff;border: 1px solid #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
  }
  >p:first-child{
    margin-left: 1.6rem;
    margin-right:0.3rem;
  }
  >p:nth-child(2){
    background: #FFFFFF;
    color: #C41335;
  }
}
.fire-message {
  
    border-bottom: 1px solid rgba(255,255,255,.16);
    color: #fff;
    left: 1.49rem;
    top: -1.2rem;
    background: url("../assets/image/fireIcon_02.png") no-repeat center;
    background-size: contain;
    background-position: 3rem .8rem;
  .message {
    width: 100%;
  }
  .title {
    font-weight: bold;
    font-size: 0.2rem /* 24/100 */;
    line-height: 0.6rem /* 34/100 */;
    letter-spacing: 0.03em;
    color: #FFFF00 !important;
    // margin-bottom: 0.12rem /* 12/100 */;
    height: 0.6rem;
    padding-left: .5rem /* 12/100 */;
    position: relative;
    border-bottom : 1px solid rgba(255, 255, 255,1);
    .icon {
      width: 0.61rem /* 60/100 */;
      height: 0.61rem /* 60/100 */;
      background: url("../assets/image/fireIcon.png")
        no-repeat center;
      // background-size: cover;
      // margin-left: -.8rem /* 10/100 */;
      margin-top: 0rem /* 10/100 */;
      position:absolute;
      left:-0.3rem;
    }
    .gaojing{
      background: url("../assets/image/baojing_img.png")
        no-repeat center;
    }
    .text {
      margin-left: 0.12rem /* 12/100 */;
    }
  }
  .list {
    margin-left: 0.2rem /* 10/100 */;
  }
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 0.18rem /* 18/100 */;
    .label {
      font-weight: 500;
      font-size: 0.13rem /* 13/100 */;
      line-height: 0.18rem /* 18/100 */;
      letter-spacing: 0.3px;
      margin-right: 0.1rem /* 35/100 */;
    }
    .text {
      display: inline-block;
      // font-weight: bold;
      font-size: 0.13rem /* 15/100 */;
      line-height: 0.21rem /* 21/100 */;
      letter-spacing: 0.03em;
      max-width: 3.03rem /* 303/100 */;
    }
  }
  .copy {
    border: 1px solid rgba(255, 255, 255, 0.1);
    // padding: 3px 8px;
    display: inline-block;
    height: 0.36rem /* 36/100 */;
    line-height: 0.31rem /* 24/100 */;
    margin-bottom: 0.2rem /* 20/100 */;
    margin-top: 0.05rem /* 12/100 */;
    margin-left: 0.2rem /* 20/100 */;
    cursor: pointer;
    .text {
      font-weight: 500;
      font-size: 0.13rem /* 13/100 */;
      // line-height: 0.24rem /* 24/100 */;
      letter-spacing: 0.03em;
      margin-right: 0.05rem /* 12/100 */;
      margin-left: 0.05rem /* 12/100 */;
      margin-top: 0.12rem /* 12/100 */;
    }
  }
  .bottom-buttons {
    display: flex;
    justify-content: space-between;
  }
  .jingyin-button{
    position: absolute;
    top: -35px;
    right: .3rem;
  }
  .tip {
    padding: 1px 5px;
    background: #fff;
    margin-bottom: 0.17rem /* 17/100 */;
    display: inline-block;
    .text {
      font-weight: 500;
      font-size: 0.13rem /* 13/100 */;
      line-height: 0.18rem /* 18/100 */;
      letter-spacing: 0.3px;
      color: #c6543a;
    }
  }
  .time {
    font-weight: 300;
    font-size: 0.64rem /* 64/100 */;
    line-height: 0.64rem /* 64/100 */;
    margin-bottom: 0.4rem /* 40/100 */;
  }
  .vote {
    // width: 0.6rem /* 60/100 */;
    height: 0.6rem /* 60/100 */;
    background-color: transparent;
    // border-radius: 0.3rem;
    // padding: 0.15rem 0.14rem;
    box-sizing: border-box;
    // margin-left: 0.38rem /* 38/100 */;
    // margin-top: 0.05rem /* 5/100 */;
    cursor: pointer;
    .icon {
      display: block;
      // width: 0.32rem /* 32/100 */;
      // height: 0.29rem /* 29/100 */;
      background: url("../assets/image/dashboard-vote.svg")
        no-repeat center;
      background-size: contain;
    }
  }
  .vote:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .vote.actived {
    background: rgba(255,255,255,.5);
    .icon {
      background: url("../assets/image/dashboard-voted.svg")
        no-repeat center;
      background-size: contain;
    }
  }
  .button {
    // width: 1.56rem /* 156/100 */;
    height: 0.36rem /* 36/100 */;
    margin-top: 0.45rem /* 12/100 */;
    // border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    // margin-bottom: 0.3rem /* 30/100 */;
    cursor: pointer;
    .text {
      font-weight: 500;
      font-size: 0.14rem /* 14/100 */;
      line-height: 0.24rem /* 24/100 */;
      margin-right: 0.05rem /* 12/100 */;
      margin-left: 0.05rem /* 20/100 */;
      margin-top: 0.06rem /* 12/100 */;
    }
    .icon {
      width: 0.24rem /* 24/100 */;
      height: 0.24rem /* 24/100 */;
      margin-left: 0.05rem /* 12/100 */;
      margin-top: 0.06rem /* 12/100 */;
    }
  }
  .button.video {
    .icon {
      background: url("../assets/image/dashboard-video.svg")
        no-repeat center;
      background-size: contain;
    }
  }
  .button.digitizing {
    margin-left: 0.1rem /* 12/100 */;
    margin-right: 0.2rem /* 20/100 */;
    .icon {
      background: url("../assets/image/dashboard-digitizing.svg")
        no-repeat center;
      background-size: contain;
    }
  }
  .button:hover {
    background: rgba(255, 255, 255, 0.5);
  }
}
</style>