import request from "@/utils/request";
import { buildListParams } from "@/utils";

export function loginByUsername(data) {
	return request({
		url: "/api-auth/v1/auth/login",
		method: "post",
		data,
	});
}

export function loginVideo(data) {
	return request({
		url: "/api-auth/v1/auth/videoLogin",
		method: "get",
	});
}

export function logout() {
	return request({
		url: "/api-auth/v1/auth/logout",
		method: "post",
	});
}

export function getUserInfo(token, userId) {
	return request({
		url: "/api/v1/tenant/{tenant}/uo/user/profile/info",
		method: "get",
	});
}

export function updatePassword(user) {
	return request({
		url: "/api/v1/tenant/{tenant}/uo/user/profile/password",
		method: "PUT",
		data: user,
	});
}

export function checkErrorCount(userName) {
	return request({
		url: `/api/v2/tenant/{tenant}/school/account/display/${userName}`,
	});
}

export function getImgCodeInfo(userName) {
	return request({
		url: `/api/v2/tenant/{tenant}/school/account/code/${userName}`,
	});
}

export function phoneMessage(phone) {
	return request({
		url: `/api-uc/v1/uc/user/forget/sms?phone=${phone}&only=0`,
		method: "get",
	});
}

export function checkPhone(phone) {
	return request({
		url: `/api-uc/v1/uc/user/forget/check?phone=${phone}`,
		method: "get",
	});
}

export function checkMessageCode(phone, code) {
	return request({
		url: `/api-uc/v1/uc/user/forget/verifyCode?phone=${phone}&code=${code}`,
	});
}

export function resetPassword(data) {
	return request({
		url: `/api-uc/v1/uc/user/forget/reset`,
		method: "put",
		data: data,
	});
}

export function GetCasualToken() {
	return request({
		url: `/api-auth/v1/auth/provisionalpass`,
		method: "get",
	});
}