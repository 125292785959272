const AccessTokenKey = "iot-fire-accessToken";
const RefreshTokenKey = "iot-fire-refreshToken";
const UserInfoKey = "iot-fire-userinfo";
const UserIDKey = "iot-fire-user-id";
const TenantIDKey = "iot-fire-tenant-id";
const AuthCodesKey = "iot-fire-auth-codes";
const CasualTokenKey = "iot-fire-casualToken";

export function getAccessToken() {
  return localStorage.getItem(AccessTokenKey);
}

export function setAccessToken(accessToken) {
  return localStorage.setItem(AccessTokenKey, accessToken);
}

export function removeAccessToken() {
  return localStorage.removeItem(AccessTokenKey);
}

export function getRefreshToken() {
  return localStorage.getItem(RefreshTokenKey);
}

export function setRefreshToken(refreshToken) {
  return localStorage.setItem(RefreshTokenKey, refreshToken);
}

export function removeRefreshToken() {
  return localStorage.removeItem(RefreshTokenKey);
}

export function getUserId() {
  return localStorage.getItem(UserIDKey);
}

export function getTenantId() {
  return localStorage.getItem(TenantIDKey);
}

export function setUserInfoData(userInfo) {
  let newUserInfo = Object.assign({}, userInfo);
  delete newUserInfo.authorities;
  return localStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
}

export function getUserInfoData() {
  return localStorage.getItem(UserInfoKey) &&
    localStorage.getItem(UserInfoKey).startsWith("{")
    ? JSON.parse(localStorage.getItem(UserInfoKey))
    : {};
}

export function setUserId(userId) {
  return localStorage.setItem(UserIDKey, userId);
}

export function setTenantId(tenantId) {
  return localStorage.setItem(TenantIDKey, tenantId);
}

export function setAuthCodes(codes) {
  return localStorage.setItem(AuthCodesKey, JSON.stringify(codes));
}

export function getAuthCodes() {
  // console.log("localStorage.getItem(AuthCodesKey) && localStorage.getItem(AuthCodesKey).startsWith('[') ? JSON.parse(localStorage.getItem(AuthCodesKey)) : [];",localStorage.getItem(AuthCodesKey) && localStorage.getItem(AuthCodesKey).startsWith('[') ? JSON.parse(localStorage.getItem(AuthCodesKey)) : []);
  return localStorage.getItem(AuthCodesKey) &&
    localStorage.getItem(AuthCodesKey).startsWith("[")
    ? JSON.parse(localStorage.getItem(AuthCodesKey))
    : [];
}

export function getCasualToken() {
  return localStorage.getItem(CasualTokenKey);
}

export function setCasualToken(casualToken) {
  return localStorage.setItem(CasualTokenKey, casualToken);
}
