export const state = {
  listDataSource: [],
  pagination: {
    pageNo: 1,
    pageSize: 10,
    total: 0,
    size:"small",
    showQuickJumper:true,
  },
  vueXSearchForm: {
    buildingName__like:'',
    companyName__like:'',
    inspectionItemName__like:'',
    rectifyStatus__eq:'',
    reportTime__gte:'',
    reportTime__lte:'',
    areaId__eq:'',
    sort:'modifyTime-'
  },
}