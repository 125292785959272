export const state = {
	listDataSource: [],
	pagination: {
		pageNo: 1,
		pageSize: 10,
		total: 0,
		size:"small",
    	showQuickJumper:true,
	},
	vuxSearchForm: {
		partyaCompanyId__eq: "",
		partybCompanyId__eq: "",
		status__eq: "",
	},
};
