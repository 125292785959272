<template>
	<i :class="'iconfont icon-' + type" :style="styleSet"></i>
</template>

<script>
export default {
	name: "IotIcon",
	props: {
		type: {
			type: String,
			required: true,
		},
		styleSet: {
			type: String,
			default: "",
		},
	},
	computed: {},
};
</script>

<style scoped></style>
