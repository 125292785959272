import {
  loginByUsername,
  loginVideo,
  logout,
  getUserInfo,
  updatePassword,
  getImgCodeInfo,
  checkMessageCode,
  GetCasualToken,
} from "@/api/login";

import {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
  setUserId,
  getUserId,
  setTenantId,
  getTenantId,
  setUserInfoData,
  getUserInfoData,
  setLoginAccount,
  setCasualToken,
  getCasualToken,
} from "@/utils/auth";

import router from "@/router/index";
import store from "@/store/store";
// import { Message } from 'element-ui'

const user = {
  state: {
    userId: getUserId(),
    tenantId: getTenantId(),
    user: "",
    status: "",
    code: "",
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    name: "",
    avatar: "",
    introduction: "",
    roles: [],
    setting: {
      articlePlatform: [],
    },
    showImgCode: false,
    imgInfo: {},
    currentCrumbsList: [],
    userInfo: getUserInfoData(),
    casualToken: getCasualToken(),
    indexRightLoadingType : 1
  },

  mutations: {
    SET_USER_ID: (state, userId) => {
      state.userId = userId;
    },
    SET_TENANT_ID: (state, tenantId) => {
      state.tenantId = tenantId;
    },
    SET_USER_INFO: (state, data) => {
      state.userInfo = data;
    },
    SET_ACCESS_TOKEN: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_ACCOUNT: (state, account) => {
      state.account = account;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      var arry = new Array();
      for (var i = 0; i < roles.length; i++) {
        arry.push(roles[i]);
      }
      state.roles = arry;
    },
    SET_CRUMBS_DATA: (state, data) => {
      state.currentCrumbsList = data;
    },
    SET_CASUAL_TOKEN: (state, casualToken) => {
      state.casualToken = casualToken;
    },
    SET_INDEX_RIGHT_LOADING_TYPE :(state, data) => {
      state.indexRightLoadingType = state.indexRightLoadingType + 1;
      console.log("indexRightLoadingTypestate:",state.indexRightLoadingType)
		}
  },

  actions: {
    
    setLoginParams({ commit }, data) {
      const { userInfo, accessToken, refreshToken } = data.result;
      commit("SET_ACCOUNT", userInfo.username);
      commit("SET_USER_ID", userInfo.id);
      commit("SET_TENANT_ID", userInfo.tenantId);
      commit("SET_ACCESS_TOKEN", accessToken);
      commit("SET_REFRESH_TOKEN", refreshToken);
      commit("SET_USER_INFO", userInfo);
      setUserId(userInfo.id);
      setTenantId(userInfo.tenantId);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setUserInfoData(userInfo);
    },
    setIndexRightLoading({ commit }, data) {
      commit("SET_INDEX_RIGHT_LOADING_TYPE", data);
    },
    setCasualToken({ commit }, data) {
      const { casualToken } = data;
      commit("SET_CASUAL_TOKEN", casualToken);
      setCasualToken(casualToken);
    },

    // 修改密码直接登录
    LoginByPhone(_1, { mobile, captcha }) {
      return new Promise((resolve, reject) => {
        checkMessageCode(mobile, captcha)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },

    // 用户名登录
    LoginByUsername(_1, userInfo) {
      return new Promise((resolve, reject) => {
        loginByUsername(userInfo)
          .then((response) => {
            if (response.success) {
              let result = response.result.userInfo;
              if (
                !result.currentOrg ||
                !result.currentOrg.orgType ||
                ![10, 11, 12, 13, 51].includes(
                  Number(result.currentOrg.orgType)
                )
              ) {
                resolve(result);
              } else {
                store.dispatch("setLoginParams", response);
                resolve(result);
              }
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    LoginVideo(_1, userInfo) {
      return new Promise((resolve, reject) => {
        loginVideo()
          .then((response) => {
            console.log("loginVideo:", response);
            if (response.success) {
              store.dispatch("setLoginParams", response);
              resolve(response.result.userInfo);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //loginVideo

    updateAccessToken({ commit }, token) {
      commit("SET_ACCESS_TOKEN", token);
      setAccessToken(token);
    },
    updateRefreshToken({ commit }, token) {
      commit("SET_REFRESH_TOKEN", token);
      setRefreshToken(token);
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.accessToken)
          .then(() => {
            commit("SET_REFRESH_TOKEN", "");
            commit("SET_ACCESS_TOKEN", "");
            removeAccessToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //获取临时令牌
    GetCasualToken() {
      return new Promise((resolve, reject) => {
        GetCasualToken()
          .then((response) => {
            if (response.success) {
              // console.log("response==========",response.result);
              store.dispatch("setCasualToken", response.result);
              resolve(response.result);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_ACCESS_TOKEN", "");
        commit("SET_REFRESH_TOKEN", "");
        removeAccessToken();
        resolve();
      });
    },

    // 动态修改权限
    ChangeRoles({ commit }, role) {
      return new Promise((resolve) => {
        commit("SET_ACCESS_TOKEN", role);
        setToken(role);
        getUserInfo(role).then((response) => {
          const data = response.data;
          commit("SET_ROLES", data.roles);
          commit("SET_NAME", data.name);
          commit("SET_AVATAR", data.avatar);
          resolve();
        });
      });
    },
    //修改用户密码
    updatePassword({ commit }, passwordupdata) {
      return new Promise((resolve, reject) => {
        updatePassword(passwordupdata)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setCrumbsData({ commit }, data) {
      commit("SET_CRUMBS_DATA", data.reverse());
    },
  },
};

export default user;
