<template>
    <!-- <el-container class="player-container" :style="{width: '100vw', height: '100vh'}"> -->
    <el-container class="player-container">
      <el-main class="main-container">
        <!-- <el-col key="col" :style="{height: '100%'}" :span="24" class="player-box active-player"> -->
        <el-col key="col" :span="24" class="player-box active-player">
          <video-player
            :ref="`player`"
            :id="'player'"
            :rtspURL="rtspURL"
            :audioURL="audioURL"
            :isLive="isLive"
            @downloadProgressUpdate="downloadProgressUpdate"
            @playProgressUpdate="playProgressUpdate"
            @downloadComplete="downloadComplete"
            @playError="playError"
          />
        </el-col>
      </el-main>
      <el-footer height="40px">
        <el-row class="w-100">
          <el-col :span="22" class="h40 flex">
            <!-- <div :class="isPause || !isPlay ? 'play-icon' : 'pause-icon'" :title="isPause || !isPlay ? '播放' : '暂停'" @click="togglePlay"></div> -->
            <!-- <div class="screenshot-icon" title="截图" @click="screenshot"></div> -->
            <div class="recording-icon" :class="{'active': recording}" title="录像" @click="record"></div>
            <!-- <div v-show="isLive" class="talk-icon" :class="{'active': talking}" title="对讲" @click="talk"></div> -->
            <!-- <div class="sound-icon" :class="{'active': isMuting}" :title="isMuting ? '静音' : '声音'" @click="toggleMute"></div> -->
            <!-- <div v-show="!isLive" class="fast-icon" title="倍速" @click="fast"></div> -->
            <div class="config-set">
              <span class="fn-13 mg-l-5">业务类型：</span>
              <el-select v-model="isLive" size="mini" style="width:80px;">
                <el-option key="false" :value="false" label="录像"></el-option>
                <el-option key="true" :value="true" label="直播"></el-option>
              </el-select>
              <span v-show="!isLive">
                <!-- <el-select v-model="speed" size="mini" placeholder="倍速" style="width: 80px;">
                  <el-option key="0.25" :value="0.25" label="1/4"></el-option>
                  <el-option key="0.5" :value="0.5" label="1/2"></el-option>
                  <el-option key="1" :value="1" label="1"></el-option>
                  <el-option key="2" :value="2" label="2"></el-option>
                  <el-option key="4" :value="4" label="4"></el-option>
                  <el-option key="6" :value="6" label="6"></el-option>
                  <el-option key="8" :value="8" label="8"></el-option>
                </el-select> -->
                <el-date-picker
                  size="mini"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="recordTimeRange"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :clearable="false"
                  @change="getDateFun"
                  style="width: 310px;">
                </el-date-picker>
              </span>
             
            </div>
          </el-col>
          <el-col :span="2" class="text-right h40">
            <!-- <span class="video-icon closeall-normal-icon" title="关闭所有" @click="closeVideo"></span> -->
            <span class="video-icon full_video_icon" title="放大" @click="fullVideo"></span>
            <yuntai @yuntaiHandle="handleMove" v-show="isPTZ"></yuntai>
          </el-col>
        </el-row>
      </el-footer>
      
    </el-container>
  </template>
  <script>
    import VideoPlayer from '@/components/videoPlayer/VideoPlayer.vue'
    import { checkBrowser } from '@/components/videoPlayer/playerWasm/public.js'
    import request from '@/utils/request'
    import yuntai from "./index_dahua_video_yuntai.vue"
    export default {
      name: 'indexVideo',
    props: {
          visible: {
              type: Boolean,
              default : false
        },
          camareInfo: {
                type: Object,
              default: {
                  sn: '',
                  channelId : ''
                }
        }
    },

      data() {
        return {
          recording: false,
          talking: false,
          isMuting: true,
          rtspURL: '',
          audioURL: null,
          isLive: true,
          url: '',
          isPause: false,
          isPlay: false,
          isDownloading: false,
          speed: '',
          recordTimeRange: [],
          isPlayRecord : false,//是否在查看录像视频
          recordType: 'localRecord',
          // 加密模式：0 - 不加密，1-加密
          encrypt: '0',
          protoType: 'rtsp',//  'rtsv' ,'rtsp'
          deviceType: 'device',
          // 设备序列号
          deviceId: '',
          // 通道号
          channelId: '1',
          // 码流类型：0 - 主码流 1- 辅码流 默认为主码流
          streamType: "0",
          isPTZ : false, // 是否可操作云台
        }
      },
      components: {
        VideoPlayer,
        yuntai
      },
      watch: {
          /**
         * @author benben.li
         * **/
            visible(newVal, val) {
            console.log("visible:",newVal,this.camareInfo)
            if (newVal&&this.camareInfo.deviceId&&this.camareInfo.deviceId!=='') {
                this.deviceId = this.camareInfo.deviceId // 初始化复制设备序列号
                this.channelId = this.camareInfo.channelId;
                
                this.isLive = true;// 是否为直播
                this.visibleInit();
            }
            if (!newVal) { // 关闭弹窗时,关闭多有直播
                this.closeVideo()
            }
          },
         
          async isLive(newVal, val) { 
            if (newVal&&this.isPlayRecord) {
              await this.init();
              this.isPlay = true
              this.play()
              this.isPlayRecord = false
            }
          },
        
      },
    methods: {
      fullVideo(){
        this.$refs[`player`] && this.$refs[`player`].setFun()
      },
      postPTZDeital(){
        let that = this;
        let param = {
                "deviceId": this.deviceId,
                "channelId": this.channelId,
                'access_token' : this.token}
            fetch(`${process.env.VUE_APP_BASE_API_YUNRUI}/yunrui/devicePTZInfo`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(param),
            }).then(res => {
              return res.json()
            }).then(data => {
              console.log("devicePTZInfo:",data,data.data.v)
              if(data.data.v){
                that.isPTZ = true
              }else{
                that.isPTZ = false
              }
            })
          },
        handleMove(command) {
          console.log("yuntaiHandle:", command)
          if (!this.isPTZ) {
            this.$message.warning("该设备不支持云台操作！")
            return false;
          }
          const param = {
              "deviceId": this.deviceId,
              "channelId": this.channelId,
              'duration': 500,
              'operation': command,
              'access_token' : this.token
          }
            fetch(`${process.env.VUE_APP_BASE_API_YUNRUI}/yunrui/controlMovePTZ`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(param),
            }).then(res => {
              return res.json()
            }).then(data => {
                if (!data.success) {
                  this.$message.warning("操作失败")
              }
            })

          
        },
        async getDateFun() {
          if (!this.isLive && (this.recordTimeRange === null || this.recordTimeRange.length < 2)) {
              this.$message.warning("录像时间不能为空")
              return
            }
            const param = {
              "deviceId": this.deviceId,
              "channelId": this.channelId,
              'beginTime': this.recordTimeRange[0],
              'endTime':  this.recordTimeRange[1],
              "businessType": 'localRecord',
              'encryptMode': this.encrypt,
              "streamType": this.streamType,
              "protoType": this.protoType,
              'access_token' : this.token
          }
          await this.createDeviceUrlFun(param);
          this.$emit("setModalTitle", '录像播放')
          this.isPlayRecord = true;
          this.isPlay = true
          this.play()
         
        },
        /**
         * @author benben.li
         * @example 操作插件
         ***/
        visibleInit() { 
            this.togglePlay();
        },
        screenshot() {
          if (!this.isPlay || this.isPause) {
            this.$message.warning("截图前请先播放视频")
            return
          }
          console.log('-----screenshot-----')
          this.$refs[`player`] && this.$refs[`player`].screenshot()
        },
        playError(e) {
          console.error(e)
          this.isPlay = false
          this.isPause = false
        },
        recordError(){
          console.error(e)
          this.recording = false
        },
        closeVideo() {
          console.log('-----closeVideo-----')
          this.isPlay = false
          this.isPause = false
          this.talking = false
          this.isMuting = true
          this.isDownloading = false
          this.recording = false
          this.rtspURL = ''
          this.$refs[`player`] && this.$refs[`player`].close()
        },
        async record() {
          if (!this.isPlay || this.isPause) {
            this.$message.warning("录像前请先直播")
            return
          }
          if (!this.recording) {
            await this.init()
          }
          this.recording = !this.recording
          this.$refs[`player`] && this.$refs[`player`].record()
        },
        talk() {
          if (!this.talking) {
            if (!this.isPlay || this.isPause) {
              this.$message.warning("对讲前请先直播")
              return
            }
            if (!this.deviceId) {
              this.$message.warning("设备序列号不能为空")
              return
            }
            if (!this.channelId) {
              this.$message.warning("通道号不能为空")
              return
            }
            const param = {
              "deviceId": this.deviceId,
              "channelId": this.channelId,
              "businessType": "talk",
              "streamType": this.streamType,
              "encryptMode": this.encrypt,
              "protoType": this.protoType,
              // 多通道设备：channel,单通道设备：device或者空
              "deviceType": this.deviceType
            }
            param['access_token'] = this.token
            fetch(`${process.env.VUE_APP_BASE_API_YUNRUI}/yunrui/createDeviceStreamUrl`, {
              method: 'POST',
              body: JSON.stringify(param),
            }).then(res => {
              return res.json()
            }).then(data => {
              const url = data.data && data.data.url
              if (url) {
                this.audioURL = url
                this.$nextTick(() => {
                  this.talking = !this.talking
                  this.$refs[`player`] && this.$refs[`player`].talk()
                })
              } else {
                this.$message.warning(data.errMsg)
              }
            })
          } else {
            this.talking = !this.talking
            this.$refs[`player`] && this.$refs[`player`].talk()
          }
        },
        toggleMute() {
          if (this.talking) {
            this.$message.warning("对讲时不能操作声音")
            return
          }
          this.isMuting = !this.isMuting
          const voiceVal = this.isMuting ? '0' : '1'
          this.$refs[`player`] && this.$refs[`player`].setAudioVolume(voiceVal)
        },
        play() {
          this.$refs[`player`] && this.$refs[`player`].play()
        },
        pause() {
          this.$refs[`player`] && this.$refs[`player`].pause()
        },
        start() {
          this.$refs[`player`] && this.$refs[`player`].start()
        },
        async togglePlay() {
          console.log("togglePlay")
          if (!this.isPlay) {
            if (!this.deviceId) {
              this.$message.warning("设备序列号不能为空")
              return
            }
            if (!this.channelId) {
              this.$message.warning("通道号不能为空")
              return
            }
            if (!this.isLive && (this.recordTimeRange === null || this.recordTimeRange.length < 2)) {
              this.$message.warning("录像时间不能为空")
              return
            }
            await this.init()
            this.isPlay = true
            this.play()
          } else if (this.isPause) {
            this.isPause = false
            this.start()
          } else {
            this.isPause = true
            this.pause()
          }
        },
        fast() {
          if (!this.isPlay || this.isPause) {
            this.$message.warning("倍速前请先播放录像")
            return
          }
          console.log("this.speed:",this.speed)
          const speed = this.speed&&this.speed!=="" ? this.speed : 1
          this.$refs[`player`] && this.$refs[`player`].playFF(speed)
        },
        playProgressUpdate(msg) {
          // console.log('----------当前播放的时间片段------------:', msg.time)
        },
        downloadProgressUpdate(msg) {
          console.log('------当前录像下载到的时间片段--------:', msg.size, msg.time)
        },
        downloadComplete() {
          console.log('---------下载完成----------')
          this.isDownloading = false
        },
        /**
         * 将getTime的值转为yyyy-mm-dd hh:mm:ss格式
         * @param {Number} time 源时间
         */
        toAllStringByTime(timeNum) {
            const time = {}
            let date = new Date(timeNum)
            time.y = this.addZero(date.getFullYear())
            time.m = this.addZero(date.getMonth() + 1)
            time.d = this.addZero(date.getDate())
            time.h = this.addZero(date.getHours())
            time.min = this.addZero(date.getMinutes())
            time.s = this.addZero(date.getSeconds())
            return `${time.y}-${time.m}-${time.d} ${time.h}:${time.min}:${time.s}`
        },
        /**
         * add Zero 月日时分秒补0函数
         */
        addZero: (time) => {
            let newTime = time > 9 ? time : '0' + time
            return newTime
        },
        async init() {
          let param = {//直播参数
              deviceId: this.deviceId,
              channelId: this.channelId,
              streamType: this.streamType,
              protoType: this.protoType,
              encryptMode: this.encrypt,
              businessType : "real"
          }
            await this.postPTZDeital(param)
            await this.createDeviceUrlFun(param)
            this.$emit("setModalTitle", '安防监控')
            this.recordTimeRange = [new Date(), new Date()]
            this.isPlayRecord = false;
        },

        createDeviceUrlFun(param) {
          /**
         * @author benben.li
            * **/
            let that = this;
         return new Promise((resolve, reject) => {
            param['access_token'] = this.token
            fetch(`${process.env.VUE_APP_BASE_API_YUNRUI}/yunrui/createDeviceStreamUrl`, {
              method: 'POST',
              body: JSON.stringify(param),
              headers: {
                'Content-Type': 'application/json'
              },
            }).then(res => {
              return res.json()
            }).then(data => {
              console.log(data)
              const url = data.data && data.data.url
              if (url) {
               
                this.rtspURL = url
                resolve()
              } else {
                const errMsg = data.code == 'DV1019' ? '设备能力级不支持！' : data.errMsg
                this.$message.warning(errMsg)
                reject(errMsg)
              }
            })
  
          })
        },
        /**
         * 获取开放平台token
        */
        getToken() {
          fetch(process.env.VUE_APP_BASE_API_YUNRUI+'/yunrui/token', {
            method: 'GET'
          }).then(res => {
            return res.json()
          }).then(data => {
                this.token = data.access_token
                /**
                 * @author benben.li
                 * @todo 去操作插件进行播放
                */
                if (this.camareInfo&&this.camareInfo.deviceId&&this.camareInfo.deviceId!=='') {
                    this.deviceId = this.camareInfo.deviceId // 初始化复制设备序列号
                    this.channelId = this.camareInfo.channelId
                    this.visibleInit();
                } else {
                    this.$message.info("请选择正确的摄像机")
                }
          })
        },
        
        // 以下是无插件播放需要引入的部分
        cPlusVisibleDecCallBack(nPort, pBufY, pBufU, pBufV, nSize, pFrameInfo) {
          // 多窗口需要执行多个窗口对象的对应方法
          this.$refs[`player`] &&
            this.$refs[`player`].cPlusVisibleDecCallBack(nPort, pBufY, pBufU, pBufV, nSize, pFrameInfo)
          // this.$refs[`player2`] && this.$refs[`player2`].cPlusVisibleDecCallBack(nPort, pBufY, pBufU, pBufV, nSize, pFrameInfo)
        },
        cDigitalSignCallBack(nPort, nFrameID, bSuccess) {
          this.$refs[`player`] && this.$refs[`player`].cDigitalSignCallBack(nPort, nFrameID, bSuccess)
          // this.$refs[`player2`] && this.$refs[`player2`].cDigitalSignCallBack(nPort, nFrameID, bSuccess)
        },
        cExtraDrawDataCallBack(nPort, nDataType, pDrawData, nDataLen) {
          this.$refs[`player`] && this.$refs[`player`].cExtraDrawDataCallBack(nPort, nDataType, pDrawData, nDataLen)
          // this.$refs[`player2`] && this.$refs[`player2`].cExtraDrawDataCallBack(nPort, nDataType, pDrawData, nDataLen)
        },
        cExtraDrawDrawCallBack(nPort) {
          this.$refs[`player`] && this.$refs[`player`].cExtraDrawDrawCallBack(nPort)
          // this.$refs[`player2`] && this.$refs[`player2`].cExtraDrawDrawCallBack(nPort)
        },
        cRecordDataCallBack(nPort, pData, nDataLen, nOffset, pRecordFrameInfo) {
          this.$refs[`player`] &&
            this.$refs[`player`].cRecordDataCallBack(nPort, pData, nDataLen, nOffset, pRecordFrameInfo)
          // this.$refs[`player2`] && this.$refs[`player2`].cRecordDataCallBack(nPort, pData, nDataLen, nOffset)
        },
        loadScript(src) {
          const dom = document.createElement('script')
          dom.src = src
          document.head.appendChild(dom)
        },
        loadLibDHPlay(isVersionCompliance) {
          // let libPath = '/WasmLib/MultiThread/libdhplay.js'
          // if (!isVersionCompliance) {
          //   libPath = '/WasmLib/SingleThread/libdhplay.js'
          // }
          let libPath = '/WasmLib/MultiThread/libplay.js'
          if (!isVersionCompliance) {
            libPath = '/WasmLib/SingleThread/libplay.js'
          }
          this.loadScript(libPath)
        },
      },
      mounted() {
        this.recordTimeRange = [new Date(), new Date()]
        this.getToken()
        window.cPlusVisibleDecCallBack = this.cPlusVisibleDecCallBack
        window.cDigitalSignCallBack = this.cDigitalSignCallBack
        window.cExtraDrawDataCallBack = this.cExtraDrawDataCallBack
        window.cExtraDrawDrawCallBack = this.cExtraDrawDrawCallBack
        window.cRecordDataCallBack = this.cRecordDataCallBack
        const { isVersionCompliance } = checkBrowser()
        this.loadLibDHPlay(isVersionCompliance)
        
      },
    }
  </script>
  <style lang="scss">
    @import '@/components/videoPlayer/index.scss';
    .el-loading-spinner {
      margin-top: -12px;
      .circular {
        height: 21px;
        width: 21px;
      }
    }
    .full_video_icon{
      background: url('./../images/icon-full-4.png') no-repeat center;
      background-size: 100% 100%;
      width: 40px;
      height: 40px;
      margin-left: 15px;
      margin-right: 15px;
      cursor: pointer;
      
    }
    .icon_full_dahua:hover{
      color: #FF5554;
    }
  </style>
  