<template>
  <div class="layout">
    <a-layout class="page-layout">
      <a-layout class="page-layout home-dashboard">
        <homeheader></homeheader>
        <a-layout :style="{ overflow:'initial',paddingTop:'100px',maxWidth: '100%',background:'#081B3E',}">
          <a-layout-content class="page-layout">
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
	<a-modal class="mapAlarmInfoDeal" v-model="mapAlarmInfoShowDeal" :afterClose="afterClose" :forceRender="true" :destroyOnClose="true" :width="1300" :footer="null" :confirmLoading="false" :keyboard="false"
		 	:maskClosable="false" title="报警日志" ok-text="确认" cancel-text="取消" @cancel='hiddenAlarmInfoDeal'>
	<alarmInfoDeal v-if="mapAlarmInfoShowDeal" :viewType="alarmDealTyle" @hiddenAlarmInfoDeal="hiddenAlarmInfoDeal" :tabsActiveIndex="alarmDealTabsActiveIndex"
				@showMapMaskCameraid='showMapMaskCameraid'>
	</alarmInfoDeal>
	</a-modal>
    <fireAlarmBox v-if="showDialog" :fireInfoArray="fireInfoArray" :alarmDealTyle='alarmDealTyle' 
      @closeFireDialog="closeFireDialog">
	</fireAlarmBox> 
    <a-modal v-model="visibleDahua" :closable="false" :width="960" :confirmLoading="false" :maskClosable="false" 
			title="实时监控" ok-text="确认" cancel-text="取消" @ok="hideModalDahua">
        <dahuaVideo :camareInfo="camareInfo" :visible="visibleDahua"></dahuaVideo>
    </a-modal>
    <div class="alarm_bottom" :class="!isHomeview?'alarm_bottom_noindex':''" @click="clickAlarmIcon">
          {{alarmInfo['dealActiveIndex']}}
        <img src="../../assets/image/baojing_img.png">
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import fireAlarmDialog from "../../components/fireAlarmDialog.vue";
import alarmInfoIndex from "./../../components/alarmInfo/alarm_info_index.vue"
import alarmInfoDeal from "./../../components/alarmInfo/alarm_info_deal.vue"
import layoutHeader from "../components/header.vue";
import { mapState, mapActions, mapMutations ,mapGetters} from "vuex";
import menuList from "@/router/_business_router";
import homeheader from "../home/components/map_mask/home_header"
import fireAlarmBox from "./../index/components/index_alerm_box.vue"
import dahuaVideo from "@/views/index/components/index_dahua_Video.vue"

export default {
  name: "layout",
  components: {
    layoutHeader,
    fireAlarmDialog,
    homeheader,
    alarmInfoIndex,
    alarmInfoDeal,
    fireAlarmBox,
    dahuaVideo
  },
  data() {
    return {
      collapsed: false,
      openKeys: [],
      selectedKeys: [],
      menuList,
      websocketHeartbeatTimer: null,
      reconnectionTimer: null,
      isReconnection: false,
      webSocket: null,
      casualToken: "",
      showDialog: false,
      fireInfoArray: [],
      isHomeview : false,
      alarmCount : 0,
      mapAlarmInfoShow: false,//报警详情弹窗
      alarmInfo: {},
      detailInfo : "",//火警的详情信息
      mapAlarmInfoShowDeal : false,//点击处理按钮弹出
      alarmDealTyle : "",//报警弹窗点击处理或者查看视频按钮
      camareInfo : "",
      visibleDahua  :false,
      alarmDealTabsActiveIndex : "1",
    };
  },
  
  computed: {
    ...mapState({
      currentCrumbsList: (store) => store.user.currentCrumbsList,
      authCodes: (store) => store.permission.authCodes,
    }),
    ...mapState("Dashboard", ["aroundPower", "onFireData"]),
	
  },
  provide() {
    return {  
      layoutGetFireData : this.getFireData,
      loyoutSetBoxType : this.setBoxType,//(alarmInfo,显示弹窗,deal显示tabs值,info显示tabs值)
    }
  },
  created() {
    if (JSON.stringify(this.$route.meta) != "{}") {
      const { id, parentId } = this.$route.meta;
      this.selectedKeys = [parentId];
      localStorage.setItem("currentKey", this.selectedKeys);
      this.isHomeview = this.$route.meta.title === "homeview";
    }
    this.GetCasualToken();
    
  },
  methods: {
    ...mapActions("Dashboard", ["getOnFireData", "localCache","getOnAlarmData"]),
    ...mapActions("company_social_fire", { 'getCompantDetail': "detail" }),
    ...mapActions("day_work_alarm", { 'getAlermDetail': "detail" }),
    
    ...mapMutations("Dashboard", ["SET_ONFIRE_DATA",]),
	...mapMutations("Index",['SET_ALARM_INFODEAL_TYPE']),
    afterClose(){
      this.detailInfo = "";
    },
    hiddenAlarmInfoDeal(){
      this.showDialog = false;
      this.getFireData();
      this.setBoxType({},false,"1","deal")
      this.$store.dispatch("setIndexRightLoading",)
    },
    clickAlarmIcon(){
      this.closeFireDialog();
      this.setBoxType({},true,"1","deal")
    },
    setBoxType(alarmInfo,type,tabsActive,dealType){
       this.alarmInfo = alarmInfo
       this.alarmDealTyle = dealType
       this.alarmDealTabsActiveIndex = tabsActive
      this.mapAlarmInfoShowDeal = type
    },
    getReply(param) {
      console.log("getReply:",param)
      let comm = param
      this.alarmDealTyle = param.dealType
      comm['dealActiveIndex'] = param['dealActiveIndex']?param['dealActiveIndex'] : '1'
      this.alarmInfo = comm;
      console.log("dealActiveIndex:loyout:setAlarmInfoDealState:",this.alarmInfo["dealActiveIndex"],this.alarmInfo)
	    this.setAlarmInfoDealState(true)
    },
    getAlermInfo() {
      const MSG = this.$message.loading("数据请求中...", 0);
        this.getAlermDetail({ id: this.alarmInfo.alarmIds.split(',')[0] })
				.then((res) => {
					MSG();
          this.detailInfo = ""
          this.detailInfo = res;
          this.detailInfo.companyAddressName = res.alarmAddressName + res.alarmAptSuite;
          this.detailInfo.alarmAddrName =  res.companyName + "-" + res.buildingName
				})
				.catch((e) => {
					MSG();
					this.$message.error("请求失败" + `${e.message ? ":" + e.message : ""}`);
				});
    },
	setAlarmInfoDealState(type){
		this.SET_ALARM_INFODEAL_TYPE(type)
	},
    showMapMaskCameraid(info){//视频信息
      this.camareInfo = {}
      this.camareInfo =info;
      this.visibleDahua = true;
    },
    hideModalDahua() {
      this.visibleDahua = false;
    },
   
    GetCasualToken() {
      clearInterval(this.websocketHeartbeatTimer);
      this.$store
        .dispatch("GetCasualToken")
        .then((result) => {
          // console.log("GetCasualToken====success======", result);
          this.casualToken = result;
          this.initWebSocket();
        }).catch((e) => {
          console.log("GetCasualToken===fail=======", e);
        });
    },

    backToHome() {
      this.showDialog = false;
    },
    initWebSocket() {
      let self = this;
      let wsurl =
        process.env.VUE_APP_WS_BASE_API +
        "/api-websocket/v1/websocket/alarm?token=" +
        self.casualToken;
      // console.log("tag-WebSocket: wsurl=======", wsurl);
      this.webSocket = new WebSocket(wsurl);
      this.webSocket.onopen = function() {
        // self.isReconnection = false;
        self.sendMessageToServer();
      };
      this.webSocket.onmessage = function(e) {
        // console.log("websocket传来信息：", e);
        // console.log(e.data == '"fire"');
        if (e.data == '"fire"') {
          self.getFireData();
        }
      };
      this.webSocket.onclose = function(e) {
        console.error("websocket链接已关闭", e);
        setTimeout(() => {
          self.GetCasualToken();  
        }, 5000);
      };
      this.setWebsocketHeartbeatTimer();
    },
    getFireData() {
      
      let self = this;
      // self.getOnFireData().then((res) => {
      self.getOnAlarmData().then((res) => {
        let userinfo = JSON.parse(localStorage.getItem("iot-fire-userinfo"));
        let userId = userinfo.id;
        this.userId = userinfo.id;
        console.log("websocket中拿到的火警列表", res);
        let fireList = [];
        if (localStorage.getItem("fireStatus")) {
          let result = JSON.parse(localStorage.getItem("fireStatus"));
          let okey = null;
          Object.keys(result).forEach((key) => {
            if (key == userId) {
              okey = key;
            }
          });
          if (okey != null) {
            res.forEach((item) => {
              let cacheArray = result[okey];
              let index = cacheArray.findIndex((_item) => _item.id == item.key);
              if (index == -1) {
                fireList.push({
                  ...item,
                  isVoted: false,
                });
              } else {
                fireList.push({
                  ...item,
                  isVoted: cacheArray[index].isSilencing,
                });
              }
            });
          } else {
            fireList = res.map((item) => {
              return {
                ...item,
                isVoted: false,
              };
            });
          }
        } else {
          fireList = res.map((item) => {
            return {
              ...item,
              isVoted: false,
            };
          });
        }
        if (fireList.length > 0) {
          self.showDialog = true;
          self.fireInfoArray = fireList;
        } else { 
          self.showDialog = false;
          self.fireInfoArray = [];
        }
        self.SET_ONFIRE_DATA(fireList);
        fireList.forEach((item) => {
          self
            .localCache({
              storageKey: "fireStatus",
              user_id: userId,
              id: item.key,
              isSilencing: item.isVoted,
              fireList
            })
            .then((res) => {
              // console.log("存储之后的火警状态",res);
            });
        });
        self.onFireDataList = _.cloneDeep(fireList);
        self.fireInfo = fireList[0];
        if (res) {
          let fireAlarmList = res.map((item) => {
            return {
              ...item,
              resourceType: "fireAlarm",
            };
          });
          console.log("fireAlarmList", fireAlarmList);
        }
      });
    },
    setWebsocketHeartbeatTimer() {
      let self = this;
      this.websocketHeartbeatTimer = setInterval(function() {
        // console.log('定时器==========');
        self.sendMessageToServer();
      }, 1000 * 5 * 1);
    },
    sendMessageToServer() {
      let message = "MESSAGE::HEARTBEAT";
      this.webSocket.send(message);
    },
    disconnect() {
      this.webSocket.close();
    },

    hasMenuChildren(data) {
      if (!data.children) return;
      if (!data.children.length) return;

      let hasMenu = false;
      data.children.map(function(item) {
        if (item.meta && item.meta.ismenu) {
          hasMenu = true;
        }
      });

      return hasMenu;
    },
    authValidate(item) {
      return this.authCodes.includes(item.meta.code);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload();
      });
    },
    itemClick(v) {
      this.selectedKeys = [v.key];
      localStorage.setItem("currentKey", this.selectedKeys);
    },
    openChange(v) {
      this.openKeys = v;
    },
    closeFireDialog() {
      this.showDialog = false;
    },
    fireTabChange(item) {
     
    },
  },
  watch: {
   
    $route(to,from) {
      setTimeout(() => {
        console.log("selectedKeys", this.selectedKeys);
        this.selectedKeys = [localStorage.getItem("currentKey")];
      }, 200);

      this.isHomeview = this.$route.meta.title === "homeview";
      if(this.isHomeview) {
      	localStorage.setItem("currentKey", "homeview");
      }
    },
  },

  mounted() {
    this.getFireData();
    
  },

  beforeDestroy: function() {
    var parent = document.querySelector("body");
    var child = document.querySelector("audio");
    if (child) {
      parent.removeChild(child);
    }
    // 页面离开时断开连接,清除定时器
    this.disconnect();

    clearInterval(this.websocketHeartbeatTimer);
  },
};
</script>

<style lang="scss" scoped>
.alarm_bottom{
  position:fixed;
  right: 4.5rem;
  bottom: 0rem;
  cursor: pointer;
  z-index: 1000;
  >img{
    width:1rem;
    height:1rem;
  }
}
.alarm_bottom_noindex{
  right: .2rem;
  bottom: 1rem;
}
.page-layout-sider {
  overflow-y: auto;
  height: calc(100vh - 100px);
  position: fixed;
  left: 0.3rem;
  top: 100px;
  background: #081B3E;
  z-index: 10;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.page-layout-menu {
  padding: 16px 0px;
  margin-top: 1px;
  border: none;
  .external-icon {
    font-size: 16px;
    position: relative;
    vertical-align: middle;
    margin-right: 8px;
  }
  .menu-text {
    vertical-align: middle;
  }
}
.page-layout-header {
  position: fixed;
  z-index: 2;
  height: 50px;
  line-height: 50px;
  right: 0;
  top: 0;
  background: #081B3E;
  padding: 0;
  display: flex;
  // justify-content: space-between;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding-left: 0.3rem;
  .title {
    color: #caf1e7;
  }
}
.page-breadcrumb-header {
  position: fixed;
  z-index: 20;
  width: 100%;
  top: 50px;
  color: rgba(0, 0, 0, 0.65);
  padding: 0 0.3rem;
  background: #081B3E;
  .ant-breadcrumb {
    margin: 16px 0;
  }
}
.page-layout {
  overflow: initial;
  position: relative;
  // min-height: 100vh;
  // min-height: calc(100vh - 1rem);
}
.page-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.layout {
  min-height: 100vh;
  height: 100%;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.page-layout .trigger:hover {
  color: #1890ff;
}

.page-header-title {
  margin: 0;
  padding: 11px 0;
  background: #1890ffd6;
  color: #fff;
  white-space: nowrap;
  font-size: 18px;
  text-align: center;
}

.ant-layout-sider-collapsed .page-header-title {
  display: none;
}
</style>
