import { state } from "./state";
import { ceil } from "lodash";
import _ from "lodash";

export const SET_MODEL_ALARM_DETAIL = (state, data) => {
  console.log("SET_MODEL_ALARM_DETAIL");
  state.modelAlarmDetail = "";
  state.modelAlarmDetail = data;
};
export const SET_HOME_BUILDING_STAT = (state, data) => {
  state.buildingStat = data;
};
export const SET_HOME_UNREAD_NUMNER = (state, data) => {
  state.homeUnreadNumber = data;
};
export const SET_HOME_INCLUDE_INNER = (state, data) => {
  state.homeInnerIncde = data;
};
export const SET_HOME_INCLUDE_INNER_NUM = (state, data) => {
  let buildingListNum = data["buildingList"].length;
  let companyListNum = 0;
  for (let key of Object.keys(data.companyMap)) {
    companyListNum += data.companyMap[key].length;
  }
  let brigadeSquadronFulltimeMapNum = 0;
  for (let key of Object.keys(data.brigadeSquadronFulltimeMap)) {
    brigadeSquadronFulltimeMapNum +=
      data.brigadeSquadronFulltimeMap[key].length;
  }
  let industryNumListNum = 0;
  for (let i = 0; i < data.industryNumList.length; i++) {
    industryNumListNum += data.industryNumList[i].num;
  }
  let minFireStationListNum = data["minFireStationList"].length;
  let fireHydrantListNum = data["fireHydrantList"].length;
  let chargingPileListNum = data["chargingPileList"].length;
  state.homeInnerIncdeNum = {
    buildingList: buildingListNum,
    companyList: companyListNum, //
    brigadeSquadronFulltimeMap: brigadeSquadronFulltimeMapNum,
    industryNumList: industryNumListNum,
    minFireStationList: minFireStationListNum,
    fireHydrantList: fireHydrantListNum,
    chargingPileList: chargingPileListNum,
  };
};
//
export const SET_HOME_TODAY_ALARM_LIST = (state, data) => {
  state.homeTodayAlarmList = data;
};
//SET_HOME_FIRE_ORG_LISTNEW
export const SET_HOME_FIRE_ORG_LISTNEW = (state, data) => {
  state.homeFireOrgListNew = data;
};
//SET_HOME_BUILDING_SCORE
export const SET_HOME_BUILDING_SCORE = (state, data) => {
  state.homeBuildingScoreList = data;
};
export const SET_MAP_HEADER_NAME = (state, data) => {
  state.mapHeaderName = data;
};

export const SET_TABLE_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data };
};
export const SET_STATUS_BAR_UNFOLD_STATUS = (state, status) => {
  state.statusBarUnFold = status;
  state.fireAlarmUnFold = false;
  state.mapUnFold = false;
};

export const SET_MAP_UNFOLD_STATUS = (state, status) => {
  state.mapUnFold = status;
  state.statusBarUnFold = status ? false : true;
};

export const SET_FIRE_ALARM_UN_FOLD_STATUS = (state, status) => {
  state.fireAlarmUnFold = status;
  state.statusBarUnFold = status ? false : true;
  state.mapUnFold = status ? true : false;
};

export const SET_ALARM_TODAY_DATA = (state, data) => {
  state.alarmTodayData = data;
};
export const SET_ALARM_TODAY_INFO_DATA = (state, data) => {
  state.alarmTodayInfoData = data;
};
export const SET_INSPECTION_STATISTICS_DATA = (state, data) => {
  state.inspectionStatisticsData = data;
};

export const SET_FAULT_TODAY_DATA = (state, data) => {
  state.faultTodayData = data;
};

export const SET_DANGER_TODAY_DATA = (state, data) => {
  state.dangerTodayData = data;
  state.untreatedDanger = data.filter((item) => item.handlerStatus == 0);
};

export const SET_ALARM_LEVEL_DATA = (state, data) => {
  let num = 0;
  for (var i in data) {
    num += data[i];
  }
  state.probability = (
    data["level3"] == 0 ? 0 : (data["level3"] / num) * 100
  ).toFixed(1);
};

export const SET_ALARM_LATEST_DATA = (state, data) => {
  state.alarmLatestList = data;
  let alarmList = [];
  for (let i = 0; i < 40; i++) {
    if (!data[i]) {
      alarmList.push(0);
    } else {
      alarmList.push(data[i].level);
    }
  }
  // let num, level0, level1, level2, level3;
  // level0 = data.filter(item => item.level == 0).length;
  // level1 = data.filter(item => item.level == 1).length;
  // level2 = data.filter(item => item.level == 2).length;
  // level3 = data.filter(item => item.level == 3).length;

  // num = level0 + level1 + level2 + level3;
  // if(num < 40){
  // 	num = 40;
  // }
  // for (let i = 0; i < num; i++) {
  // 	if (i < ceil(level3)) {
  // 		alarmList.push(3);
  // 	} else if (i < ceil(level2) + ceil(level3)) {
  // 		alarmList.push(2);
  // 	} else if (i < ceil(level1) + ceil(level2) + ceil(level3)) {
  // 		alarmList.push(1);
  // 	} else if (i < num) {
  // 		alarmList.push(0);
  // 	}
  // }
  console.log("alarmList", alarmList);
  state.levelList = alarmList;
};

export const SET_ALARM_TREND_DATA = (state, data) => {
  let timeList = [],
    numList = [];
  data.forEach((item) => {
    timeList.push(
      item.dateStr.split("-")[1] + "-" + item.dateStr.split("-")[2]
    );
  }),
    data.forEach((item) => {
      numList.push(item.num);
    });
  state.alarmTrend = {
    timeList,
    numList,
  };
};

export const SET_AROUND_POWER_DATA = (state, data) => {
  //保存首页查询建筑等数据
  console.log("首页查询建筑等数据:", data);
  state.aroundPower = data;
};
export const SET_AROUND_THE_FIRE_DATA = (state, data) => {
  state.aroundTheFire = data;
};
export const SET_FIRE_ALARM_DATA = (state, data) => {
  state.fireAlarmPoints = data;
};

export const SET_BUILD_UNIT_DATA = (state, data) => {
  let firePowerList = _.cloneDeep(state.firePowerList);

  console.log("firePowerList", firePowerList);
  console.log("data", data);
  let resourceCountData = data;
  // 给消防队赋值
  resourceCountData["brigade"] =
    resourceCountData["fireBrigade"] +
    resourceCountData["fireSquadron"] +
    resourceCountData["fullTimeFireBrigade"];
  resourceCountData["industry"] = resourceCountData["company"]; // 行业
  firePowerList.forEach((item) => {
    for (let key in resourceCountData) {
      if (item.key === key) {
        item.num = resourceCountData[key];
      }
      if (item.children.length) {
        item.children.forEach((child) => {
          if (child.key === key) {
            child.num = resourceCountData[key];
          }
        });
      }
    }
  });
  state.buildUnitData = firePowerList;
  state.firePowerList = firePowerList;
  console.log("state.buildUnitData:", state.buildUnitData);
};

export const SET_JURISDICTION_DATA = (state, data) => {
  state.jurisdictionData = data.map((item) => item);
};
export const SET_JURISDICTION = (state, data) => {
  state.jurisdiction = data;
};
export const SET_MAP_AREA = (state, data) => {
  state.selectArea = data;
};
export const SET_PER_FORCES_DATA = (state, data) => {
  state.perForces = data;
};
export const SET_ALARM_SWITCH_STATUS = (state, status) => {
  state.isSwitchAlarm = status;
};

export const SET_HIDDEN_DANGER_SWITCH_STATUS = (state, status) => {
  state.isSwitchHiddenDanger = status;
};

export const SET_ERROR_SWITCH_STATUS = (state, status) => {
  state.isSwitchError = status;
};

export const SET_INDUSTRY_UNITS_DATA = (state, data) => {
  state.industryUnitsData = data;
};

export const SET_FILTER_AROUND_POWER = (state, data) => {
  state.filterAroundPower = data.map((item) => item);
  console.log("state.filter.filterAroundPower:", state.filterAroundPower);
};
export const SET_ONFIRE_DATA = (state, data) => {
  state.onFireData = data;
};
export const SET_POINTS_LIST = (state, data) => {
  state.pointsList = data;
};
export const SET_FIRE_LOCATION = (state, data) => {
  state.fireLocation = data;
};
export const SET_MAP_CENTER = (state, data) => {
  state.mapCenter = data;
};
export const SET_CURRENT_REGION = (state, data) => {
  state.currentRegion = data;
};
export const SET_INDEX_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data };
};

export const SET_FIRE_ALARM_LIST = (state, data) => {
  state.fireAlarmList = data;
};
export const SET_IS_QUERY_UNITS_OR_BUILDINGS = (state, data) => {
  state.isQueryUnitsOrBuildings = data;
};

export const SET_SEARCH_FORM = (state, data) => {
  state.vueXSearchForm = { ...state.vueXSearchForm, ...data };
};
