<template>
    <div class="fire_box">
        <div class="alarm_box">
            <div class="box">
                <div class="cha" @click="clickMove">
                    <a-icon type="close-circle" theme="filled" :style="{ color: '#ffffff',fontSize:'24px' }"/>
                </div>
                <div class="comm b-f">
                    <div class="comm-l ">
                        <img src="./../images/home_view_fireIcon1.png" alt="" srcset="">
                        <div class="b-c-f0 b-s-18">{{originalType(fireInfoArray[activeNum])}}</div>
                    </div>
                    <div class="comm-r">
                        <template v-if="fireInfoArray[activeNum].devicePointMappingVO">
                            <div class="r-t b-s-24 b-c-f0">
                                {{fireInfoArray[activeNum].devicePointMappingVO.systemAddress}}主机-
                                {{fireInfoArray[activeNum].devicePointMappingVO.loopCode}}回路-
                                {{fireInfoArray[activeNum].devicePointMappingVO.pointCode}}点位
                                发现{{originalType(fireInfoArray[activeNum])}}
                            </div>
                        </template>
                        <template v-else>
                            <div class="r-t b-s-24 b-c-f0">{{fireInfoArray[activeNum].equipmentName}}发现{{originalType(fireInfoArray[activeNum])}}</div>
                        </template>
                        <div class="b-c-f r-c">{{fireInfoArray[activeNum].buildingName}}-{{fireInfoArray[activeNum].roomName}}
                            <template v-if="fireInfoArray[activeNum].devicePointMappingVO">-{{fireInfoArray[activeNum].devicePointMappingVO.pointAddressName}}</template>
                        </div>
                        <div class="b-c-f">{{fireInfoArray[activeNum]?fireInfoArray[activeNum].createAt:'未知时间'}}</div>
                    </div>
                    <div class="btn-list">
                        <div class="audio_btn" @click="clickIconAudio" v-if="!isPlay"><img src="../images/icon-audio-1.png" alt="" srcset=""></div>
                        <div class="audio_btn" @click="clickIconAudio" v-if="isPlay"><img src="../images/icon-audio-2.png" alt="" srcset=""></div>
                        <div class="btn-l"  @click="checkFire('deal',fireInfoArray[activeNum])">处理</div>
                        <div class="btn-r"  @click="checkFire('check',fireInfoArray[activeNum])"><a-icon type="caret-right" theme="filled" :style="{ color: '#ffffff',fontSize:'24px' }" />查看视频</div>
                    </div>
                </div>
                <div class="num-list">
                    <div class="num" :class="activeNum==index?'num-active':''" v-for="(item,index) in fireInfoArray" v-if="index<7" :key="index" @click="changeActive(index)">{{index + 1}}</div>
                    <div class="num more" v-if="fireInfoArray.length>=7"   @click="checkFire('deal',fireInfoArray[0])">更多</div>
                </div>
            </div>
        </div>
        <audio ref="audio" class="aud" autoplay loop muted  @pause="onPause" @play="onPlay" >
            <source src="@/assets/audio/fire-alarm.mp3" />
      </audio>
        
    </div>
</template>
<script>
import {setFireAlarmAudio,erasureNoise } from "@/utils/index"
export default {
    name:'indexAlarmBox',
    props : {
        fireInfoArray: Array,
    },
    components: {
    },
    data () {
        return {
            activeNum : 0,
            alarmType : 0,
            isPlay : false,
            isClickFrist : false
        }
    },
    created () {
        
    },

    inject : ['loyoutSetBoxType'],
    watch :{
        fireInfoArray(value,oldValue){
            this.audioFun()
            
        }
    },
    methods: {
        
        onPlay(){
            console.log("this.isPlay")
            this.isPlay = true
        },
        onPause(){
            this.isPlay = false
        },
        clickAll(e){
        if(!this.isPlay&&!this.isClickFrist){
            this.isClickFrist = true
               this.audioFun() 
        }
        },
        clickIconAudio(){
            if(this.isPlay){
                this.$refs.audio.pause();
            }else{
                this.$refs.audio.play();
            }
        },
        audioFun(){
           if(this.fireInfoArray&&this.fireInfoArray.length>0){
            this.$refs.audio.play();
           }else{
            this.$refs.audio.pause();
           }
        },
        changeActive(index){
            this.activeNum = index;
        },
        clickMove(){
            this.$emit("closeFireDialog");
        },
        
        checkFire(type,data){
            let lisData = data;
            lisData["dealType"] = type;
            this.loyoutSetBoxType(lisData,true,(this.alarmType==1?'2':'1'),type)
            // this.$emit('getReply', lisData)
        },
        originalType(comm) {
          let text=""
          if((comm.originalType==2||comm.originalType==3)&&!comm.fireAlarm&&!comm.faultAlarm&&!comm.alertAlarm){
                text="火警";
                this.alarmType = 0
            }else{
                if(comm.fireAlarm){
                    text="真实火警"
                    this.alarmType = 1
                }else if(comm.faultAlarm){
                    this.alarmType = 2
                    text ="故障"
                }else if(comm.alertAlarm){
                    this.alarmType = 3
                    text ="异常"
                }else{
                    text = "未知异常"
                    this.alarmType = 4
                }
          }
          return text
        },
        timeInterval(nowValue) {
            let _this = this;
            clearInterval(this.timeval);
            let oldAlarmTime = new Date(nowValue.alarmTime).getTime();
            this.timeval = setInterval(function() {
                let nowAlarmTime = new Date().getTime();
                let agoAlarmTime = nowAlarmTime - oldAlarmTime;
                let day = Math.floor(agoAlarmTime / 86400000); //天
                let hours = Math.floor((agoAlarmTime % 86400000) / 3600000); //时
                let minutes =
                (Math.floor((agoAlarmTime % 3600000) / 60000)) >= 10
                    ? Math.floor((agoAlarmTime % 3600000) / 60000)
                    : "0" + (Math.floor((agoAlarmTime % 3600000) / 60000)); //分
                let seconds =
                (Math.floor((agoAlarmTime % 60000) / 1000)) >= 10
                    ? Math.floor((agoAlarmTime % 60000) / 1000)
                    : "0" + (Math.floor((agoAlarmTime % 60000) / 1000)); //秒
                let allHours =
                day * 24 + hours > 10 ? day * 24 + hours : "0" + (day * 24 + hours);
                _this.time = allHours + ":" + minutes + ":" + seconds;
            }, 1000);
            },
            formatDate(dateValue) {
            if (dateValue) {
                var nowDate = new Date(dateValue).toLocaleDateString().split("/"),
                nowTime = new Date(dateValue).toLocaleTimeString().split(":");
                nowDate[1] < 10 ? (nowDate[1] = "0" + nowDate[1]) : nowDate[1];
                nowDate[2] < 10 ? (nowDate[2] = "0" + nowDate[2]) : nowDate[2];
                let hour = nowTime[0].slice(2);
                hour < 10 ? (nowTime[0] = "0" + hour) : hour;
                nowTime[0] = hour;
                return nowDate.join("-") + " " + nowTime.join(":");
            }
        }
    },
    mounted () {

        this.audioFun() 
        window.addEventListener('mouseup', this.clickAll)
    },
}

</script>


<style lang='scss' scoped>
.fire_box{
    position: absolute;
    z-index: 100;
    top: 120px;
   display: flex;
   justify-content: center;
   width: 100%;
}
.alarm_box{
    display: flex;
    justify-content: center;
    position: fixed;
   
    .box{
        width: 741px;
        // height: 214px;
        background: rgba(0, 0, 0, 0.7);
        padding: 20px;
        .num-list{
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            .num{
                width: 24px;
                height: 24px;
                background: #B10508;
                font-size: 14px;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                cursor: pointer;
            }
            .num-active {
                background: #F80805;
            }
            .more{
                width: 50px;
            }
        }
        .comm{
            width: 100%;
            height: 136px;
            background: linear-gradient(-90deg, #FF0000 0%, #8F080C 100%);
            position: relative;
            .comm-l{
                text-align: center;
                padding: 0px 40px 0 34px;
                >img{
                    width: 70px;
                }
            }
            .comm-r{
                margin-top: 25px;
                .r-c{
                    margin: 5px 0;
                }
                .r-t{
                    font-size: 24px;
                    font-weight: bold;
                    color: #FFFF00;
                }
            }
            .btn-list{
                display: flex;
                position: absolute;
                align-items: center;
                bottom: 20px;
                right: 20px;
                .btn-l,.btn-r{
                    width: 76px;
                    height: 31px;
                    background: #FFFFFF;
                    border-radius: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 17px;
                    color: #94080B;
                    cursor: pointer;
                }
                .btn-r{
                    margin-left: 10px;
                    width: 119px;
                    background: #B10508;
                    color: #FFFFFF;
                }
                .audio_btn{
                    cursor: pointer;
                    >img{
                        width: 30px;
                        margin-right: 20px;
                    }
                }
            }
        }
        .cha{
            position: absolute;
            right: 27px;
            top: 25px;
            z-index: 10;
            cursor: pointer;
        }
        .comm{
            display: flex;
        }
    }
}
</style>