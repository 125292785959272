export const state = {
  modelAlarmDetail: "", //
  mapHeaderName: "architecture", //首页地图的header的选项
  buildingStat: {}, //首页建筑统计信息
  homeUnreadNumber: 0, //首页消息数量
  homeInnerIncde: {}, //首页地图导航信息
  homeTodayAlarmList: {}, //首页今日报警数据信息
  homeInnerIncdeNum: {
    buildingList: 0, // "建筑",
    companyList: 0, //"单位",
    brigadeSquadronFulltimeMap: 0, //"消防队",
    industryNumList: 0, //"行业",
    minFireStationList: 0, //"微型消防站",
    fireHydrantList: 0, // "室外消防栓",
    chargingPileList: 0, // "充电桩"
  },
  homeFireOrgListNew: {},
  homeBuildingScoreList: [],
  statusBarUnFold: true,
  mapUnFold: false,
  fireAlarmUnFold: false,
  alarmTodayData: {},
  alarmTodayInfoData: {}, //联网信息统计  今日信息统计
  inspectionStatisticsData: {}, // 英汇巡查巡检统计接口：
  faultTodayData: {},
  dangerTodayData: [],
  probability: 0,
  alarmLatestList: [],
  alarmTrend: {},
  pagination: {
    pageNo: 1,
    pageSize: 8,
    total: 0,
  },
  aroundPower: [],
  fireAlarmPoints: [],
  buildUnitData: [],
  jurisdictionData: [],
  jurisdiction: {},
  selectArea: {},
  isSwitchAlarm: true,
  isSwitchHiddenDanger: true,
  isSwitchError: true,
  industryUnitsData: [],
  filterAroundPower: [
    // 初始化选择的类型  单位类型
    // "company",
    // "companyNine",
    // "companyEmphasis",
    // "companyNormal",
    // "companyUnderground",
    // "companyChemical",
    // "companySuperComplex",
    // "companyHighBuilding",
    // "companyHighRisk",
    // "companyOther",

    //建筑类型
    "building",
  ],
  onFireData: [],
  levelList: [],
  pointsList: [],
  fireLocation: {},
  mapCenter: {},
  currentRegion: {}, // 设备管理区域信息
  untreatedDanger: [],
  aroundTheFire: [],
  firePowerList: [
    {
      name: "建筑",
      key: "building",
      num: 0,
      category: "build",
      isChecked: true,
      children: [],
      queryKeysList: ["building"],
    },
    {
      name: "单位",
      key: "company",
      num: 0,
      category: "build",
      isChecked: false,
      queryKeysList: [
        "company",
        "companyNine",
        "companyEmphasis",
        "companyNormal",
        "companyUnderground",
        "companyChemical",
        "companySuperComplex",
        "companyHighBuilding",
        "companyHighRisk",
        "companyOther",
      ],
      children: [
        {
          name: "九小单位",
          key: "companyNine",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "重点单位",
          key: "companyEmphasis",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "一般单位",
          key: "companyNormal",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "地下建筑",
          key: "companyUnderground",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "化工企业",
          key: "companyChemical",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "超大综合体",
          key: "companySuperComplex",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "高层建筑",
          key: "companyHighBuilding",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "高危单位",
          key: "companyHighRisk",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "其他单位",
          key: "companyOther",
          num: 0,
          category: "build",
          isChecked: false,
        },
      ],
    },
    {
      name: "消防队",
      key: "brigade",
      num: 0,
      category: "brigade",
      isChecked: false,
      children: [
        {
          name: "专职消防队",
          key: "fullTimeFireBrigade",
          num: 0,
          category: "brigade",
          isChecked: false,
        },
        {
          name: "消防大队",
          key: "fireBrigade",
          num: 0,
          category: "brigade",
          isChecked: false,
        },
        {
          name: "消防中队",
          key: "fireSquadron",
          num: 0,
          category: "brigade",
          isChecked: false,
        },
      ],
      queryKeysList: ["fullTimeFireBrigade", "fireBrigade", "fireSquadron"],
    },
    {
      name: "行业",
      key: "industry",
      num: 0,
      category: "industry",
      isChecked: false,
      children: [],
      queryKeysList: [],
    },

    {
      name: "微型消防站",
      key: "minFireStation",
      num: 0,
      category: "fire",
      isChecked: false,
      children: [],
      queryKeysList: ["minFireStation"],
    },
    {
      name: "室外消防栓",
      key: "outdoorFireHydrant",
      num: 0,
      category: "fire",
      isChecked: false,
      children: [
        {
          name: "室外消防栓",
          key: "outdoorFireHydrant",
          num: 0,
          category: "fire",
          isChecked: false,
        },
        {
          name: "市政消防栓",
          key: "municipalFireHydrant",
          num: 82,
          category: "fire",
          isChecked: false,
        },
      ],
      queryKeysList: ["outdoorFireHydrant", "municipalFireHydrant"],
    },
    {
      name: "充电桩",
      key: "chargingPile",
      num: 0,
      category: "chargingPile",
      isChecked: false,
      children: [],
      queryKeysList: ["chargingPile"],
    },
  ],
  fireAlarmList: [],
  isQueryUnitsOrBuildings: false,
  vueXSearchForm: {},
};
