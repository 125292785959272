export const state = {
  statusBarUnFold: true,
  alarmCount: 0, //接受报警此处
  mapUnFold: false,
  fireAlarmUnFold: false,
  alarmTodayData: {},
  faultTodayData: {},
  dangerTodayData: [],
  probability: 0,
  alarmLatestList: [],
  alarmTrend: {},
  pagination: {
    pageNo: 1,
    pageSize: 8,
    total: 0,
  },
  aroundPower: [],
  fireAlarmPoints: [],
  buildUnitData: [],
  jurisdictionData: [],
  jurisdiction: {},
  selectArea: {},
  isSwitchAlarm: true,
  isSwitchHiddenDanger: true,
  isSwitchError: true,
  industryUnitsData: [],
  filterAroundPower: [
    "building",
    // 'company',
    // 'companyNine',
    // 'companyEmphasis',
    // 'companyNormal',
    "fireAlarm",
  ],
  onFireData: [],
  onAlarmData: [], //查询报警和火警信息
  levelList: [],
  pointsList: [],
  fireLocation: {},
  mapCenter: {},
  currentRegion: {},
  untreatedDanger: [],
  aroundTheFire: [],
  firePowerList: [
    {
      name: "建筑",
      key: "building",
      num: 0,
      category: "build",
      isChecked: true,
      children: [],
    },
    {
      name: "单位",
      key: "company",
      num: 0,
      category: "build",
      isChecked: false,
      children: [
        {
          name: "九小单位",
          key: "companyNine",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "重点单位",
          key: "companyEmphasis",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "一般单位",
          key: "companyNormal",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "地下建筑",
          key: "companyUnderground",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "化工企业",
          key: "companyChemical",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "超大综合体",
          key: "companySuperComplex",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "高层建筑",
          key: "companyHighBuilding",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "高危单位",
          key: "companyHighRisk",
          num: 0,
          category: "build",
          isChecked: false,
        },
        {
          name: "其他单位",
          key: "companyOther",
          num: 0,
          category: "build",
          isChecked: false,
        },
      ],
    },
    {
      name: "微型消防站",
      key: "minFireStation",
      num: 15,
      category: "fire",
      isChecked: false,
      children: [],
    },
    {
      name: "室外消防栓",
      key: "outdoorFireHydrant",
      num: 127,
      category: "fire",
      isChecked: false,
      children: [],
    },
    {
      name: "市政消防栓",
      key: "municipalFireHydrant",
      num: 82,
      category: "fire",
      isChecked: false,
      children: [],
    },
    {
      name: "消防专职队",
      key: "fullTimeFireBrigade",
      num: 39,
      category: "brigade",
      isChecked: false,
      children: [],
    },
    {
      name: "消防大队",
      key: "fireBrigade",
      num: 104,
      category: "brigade",
      isChecked: false,
      children: [
        // {
        // 	name: '灭火大队',
        // 	key: 'fireBrigade',
        // 	num: 76,
        // 	category: 'brigade',
        // 	isChecked: false,
        // },
        // {
        // 	name: '救援大队',
        // 	key: 'fireBrigade',
        // 	num: 28,
        // 	category: 'brigade',
        // 	isChecked: false,
        // }
      ],
    },
    {
      name: "消防中队",
      key: "fireSquadron",
      num: 193,
      category: "brigade",
      isChecked: false,
      children: [],
    },
  ],
  fireAlarmList: [],
  isQueryUnitsOrBuildings: false,
  vueXSearchForm: {},
};
