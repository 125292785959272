export const SET_TABLE_DATA = (state, data) => {
	state.listDataSource = data;
};

export const SET_TABLE_PAGE_INFO = (state, data) => {
	state.pagination = { ...state.pagination, ...data };
};

export const SET_COMPLETED_TABLE_DATA = (state, data) => {
	state.completedListDataSource = data;
};

export const SET_COMPLETED_TABLE_PAGE_INFO = (state, data) => {
	state.completedPagination = { ...state.completedPagination, ...data };
};
