import router from "@/router/index";
import store from "@/store/store";
import request from "@/utils/request";
// import { Message } from 'element-ui'

const otherdata = {
  state: {
    manageTroopList: [],
    nowViewData: "",
  },
  mutations: {
    SET_MANAGE_TROOPLIST: (state, list) => {
      state.manageTroopList = list;
    },
    SET_NOW_VIEW_DATE: (state, viewData) => {
      state.nowViewData = viewData;
      // console.log("state.nowViewData:",state.nowViewData)
    },
  },
  actions: {
    getManageTroopList({ commit }, params) {
      return new Promise((resolve, reject) => {
        request({
          url: `/api-uc/v1/uc/org/fireOrgs`,
          method: "get",
        })
          .then((res) => {
            if (res.success) {
              commit("SET_MANAGE_TROOPLIST", res.result);
              resolve(res.result);
            } else {
              reject(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setNowViewData({ commit }, data) {
      commit("SET_NOW_VIEW_DATE", data);
    },
  },
};

export default otherdata;
