import request from "@/utils/request";

export const getStateDistribution = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-asset/v1/equipment/equipment/stateDistribution",
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getArea = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-base/v1/base/area/${id}/children`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getBuilding = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-asset/v1/building",
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result.data);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getCurrent = ({ commit }) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-uc/v1/uc/org/current",
			method: "get"
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};
