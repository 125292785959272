import { state } from './state'

import * as actions from './action'

import * as mutations from './mutation'

export const safe_edu_article = {
  namespaced: true,
  state,
  mutations,
  actions
}
