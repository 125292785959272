import { getUserPermission } from '@/api/permission'
import {
	setAuthCodes,
	getAuthCodes
} from "@/utils/auth";

const permission = {
	state: {
		permissionRouter: [],
		menuList: [],
		authCodes: getAuthCodes()
	},
	mutations: {
		SET_AUTH_CODES: (state, authList) => {
			state.authCodes = (authList || []).map(i => i.resourceCode);
			setAuthCodes(state.authCodes);
		}
	},
	actions: {
		GetUserPermission({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getUserPermission().then(response => {
					commit('SET_AUTH_CODES', response.result);
					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		}
	}
}

export default permission