/* Layout */
import Layout from "@/views/layout/layout";

export default [
	{
		path: "/dashboard/dashboard",
		name: "首页",
		meta: {
			title: "dashboard",
			name: "首页",
			id: "dashboard",
			icon: "dashboard",
			ismenu: true,
			code: "home",
		},
		component: () => import("@/views/dashboard/dashboard.vue"),
	},
	{
		path: "/home/home",
		name: "首页",
		meta: {
			title: "homeview",
			name: "首页",
			id: "homeview",
			icon: "dashboard",
			ismenu: true,
			code: "homeview",
		},
		component: () => import("@/views/home/home.vue"),
	},
	{
		path: '/message',
		name: '消息管理',
		meta: { 
			title: "消息管理",
			name: "消息管理",
			id: "/message",
			icon: "message",
			ismenu: true,
			code: "message_management"
		},
		component: resolve => {
			require(['@/views/message/base_message/page/layout.vue'], resolve)
		},
		redirect: "/message/base_message",
		children: [{
			path: '/message/base_message',
			name: '消息中心',
			meta: { 
				title: '消息中心',
				name:'消息中心',
				id: '/message/base_message', 
				icon: "message",
				ismenu: true,
				parentId: '/message',  //parentId需与父级路由meta.id保持一致
				code: "message.baseMessage",
			},
			component: resolve => {
				require(['@/views/message/base_message/page/list.vue'], resolve)
			}
		},
		]
	},
	{
		path: "/alarm_center",
		name: "报警中心",
		meta: {
			id: "/alarm_center",
			icon: "reconciliation",
			ismenu: true,
			code: "alarmCenter",
		},
		redirect: "/alarm_center/overview",
		component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
		children: [
			{
				path: "/alarm_center/overview",
				name: "报警总览",
				meta: {
					title: "报警总览",
					name: "报警总览",
					id: "/alarm_center/overview",
					icon: "alarm-center",
					isExternalIcon: true,
					ismenu: true,
					code: "alarmCenter.overview",
				},
				component: (resolve) => {
					require([
						"@/views/alarm_center/overview/page/index.vue",
					], resolve);
				},
			},
			{
				path: "/alarm_center/alarm_log",
				name: "报警日志",
				meta: {
					title: "报警日志",
					name: "报警日志",
					id: "/alarm_center/alarm_log",
					icon: "log",
					isExternalIcon: true,
					ismenu: true,
					code: "alarmCenter.log",
				},
				redirect: "/alarm_center/alarm_log/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/alarm_center/alarm_log/list",
						name: "报警日志列表",
						meta: {
							title: "报警日志列表",
							name: "报警日志列表",
							id: "/alarm_center/alarm_log/list",
							icon: "",
							ismenu: false,
							parentId: "/alarm_center/alarm_log",
							code: "alarmCenter.log.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/alarm_center/alarm_log/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/alarm_center/alarm_log/detail",
						name: "报警日志详情",
						meta: {
							title: "报警日志详情",
							name: "报警日志详情",
							id: "/alarm_center/alarm_log/detail",
							icon: "",
							ismenu: false,
							parentId: "/alarm_center/alarm_log",
							code: "alarmCenter.log.detail",
						},
						component: (resolve) => {
							require([
								"@/views/alarm_center/alarm_log/page_newroute/detail.vue",
							], resolve);
						},
					},
					{
						path: "/alarm_center/alarm_log/alarm_floor",
						name: "楼层图",
						meta: {
							title: "楼层图",
							name: "报警楼层图",
							id: "/alarm_center/alarm_log/alarm_floor",
							icon: "",
							ismenu: false,
							parentId: "/alarm_center/alarm_log",
							code: "alarmCenter.log.floorDrawing",
						},
						component: (resolve) => {
							require([
								"@/views/alarm_center/alarm_log/page_newroute/alarm_floor.vue",
							], resolve);
						},
					},
				],
			},
		],
	},
	{
		path: "/monitor",
		name: "设备检测",
		meta: {
			title: "设备检测",
			name: "设备检测",
			id: "/monitor",
			icon: "stock",
			ismenu: true,
			code: "realTimeMonitoring",
		},
		component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
		redirect: "/monitor/water",
		children: [
			// {
			// 	path: "/monitor/alarm",
			// 	name: "火灾报警系统",
			// 	meta: {
			// 		title: "火灾报警系统",
			// 		name: "火灾报警系统",
			// 		id: "/monitor/alarm",
			// 		icon: "fire-system",
			// 		isExternalIcon: true,
			// 		ismenu: true,
			// 		parentId: "/monitor",
			// 		code: "realTimeMonitoring.fireAlarmSystem",
			// 	},
			// 	component: (resolve) => {
			// 		require(["@/views/monitor/alarm/page/index.vue"], resolve);
			// 	},
			// },
			// {
			// 	path: "/monitor/electrical_fire",
			// 	name: "电气火灾系统",
			// 	meta: {
			// 		title: "电气火灾系统",
			// 		name: "电气火灾系统",
			// 		id: "/monitor/electrical_fire",
			// 		icon: "fire-system",
			// 		isExternalIcon: true,
			// 		ismenu: true,
			// 		parentId: "/monitor",
			// 		code: "realTimeMonitoring.electricalFireSystem",
			// 	},
			// 	component: (resolve) => {
			// 		require([
			// 			"@/views/monitor/electrical_fire/page/index.vue",
			// 		], resolve);
			// 	},
			// },
			// {
			// 	path: "/monitor/indoor",
			// 	name: "室内建筑水系统",
			// 	meta: {
			// 		title: "室内建筑水系统",
			// 		name: "室内建筑水系统",
			// 		id: "/monitor/indoor",
			// 		icon: "fire-system",
			// 		isExternalIcon: true,
			// 		ismenu: true,
			// 		parentId: "/monitor",
			// 		code: "realTimeMonitoring.indoorSystem",
			// 	},
			// 	component: (resolve) => {
			// 		require(["@/views/monitor/indoor/page/index.vue"], resolve);
			// 	},
			// },
			// {
			// 	path: "/monitor/gas",
			// 	name: "可燃气系统",
			// 	meta: {
			// 		title: "可燃气系统",
			// 		name: "可燃气系统",
			// 		id: "/monitor/gas",
			// 		icon: "fire-system",
			// 		isExternalIcon: true,
			// 		ismenu: true,
			// 		parentId: "/monitor",
			// 		code: "realTimeMonitoring.gasSystem",
			// 	},
			// 	component: (resolve) => {
			// 		require(["@/views/monitor/gas/page/index.vue"], resolve);
			// 	},
			// },
			{
				path: "/monitor/water",
				name: "智慧用水系统",
				meta: {
					title: "智慧用水系统",
					name: "智慧用水系统",
					id: "/monitor/water",
					icon: "shuixitong",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/monitor",
					code: "realTimeMonitoring.waterSystem",
				},
				component: (resolve) => {
					require(["@/views/monitor/water/page/index.vue"], resolve);
				},
			},
			{
				path: "/monitor/smoke",
				name: "烟感报警系统",
				meta: {
					title: "烟感报警系统",
					name: "烟感报警系统",
					id: "/monitor/smoke",
					icon: "yangan",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/monitor",
					code: "realTimeMonitoring.smokeSystem",
				},
				component: (resolve) => {
					require(["@/views/monitor/smoke/page/index.vue"], resolve);
				},
			},
			{
				path: "/monitor/gas",
				name: "可燃气体报警系统",
				meta: {
					title: "可燃气体报警系统",
					name: "可燃气体报警系统",
					id: "/monitor/gas",
					icon: "keranqiti",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/monitor",
					code: "realTimeMonitoring.gasSystem",
				},
				component: (resolve) => {
					require(["@/views/monitor/gas/page/index.vue"], resolve);
				},
			},
			{
				path: "/monitor/video",
				name: "视频监控系统",
				meta: {
					title: "视频监控系统",
					name: "视频监控系统",
					id: "/monitor/video",
					icon: "fire-system",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/monitor",
					code: "realTimeMonitoring.videoSystem",
				},
				component: (resolve) => {
					require(["@/views/monitor/video/page/index.vue"], resolve);
				},
			},
			{
				path: "/monitor/electrical",
				name: "智慧用电系统",
				meta: {
					title: "智慧用电系统",
					name: "智慧用电系统",
					id: "/monitor/electrical",
					icon: "yongdian",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/monitor",
					code: "realTimeMonitoring.electricalSystem",
				},
				component: (resolve) => {
					require(["@/views/monitor/electrical/page/index.vue"], resolve);
				},
			},
			{
				path: "/monitor/municipal_hydrant",
				name: "市政消火栓系统",
				meta: {
					title: "市政消火栓系统",
					name: "市政消火栓系统",
					id: "/monitor/municipal_hydrant",
					icon: "shizhengxiaohuoshuan",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/monitor",
					code: "realTimeMonitoring.municipalHydrantSystem",
				},
				component: (resolve) => {
					require(["@/views/monitor/municipal_hydrant/page/index.vue"], resolve);
				},
			},
			{
				path: "/monitor/outdoor_hydrant",
				name: "室外消火栓系统",
				meta: {
					title: "室外消火栓系统",
					name: "室外消火栓系统",
					id: "/monitor/outdoor_hydrant",
					icon: "shiwaixiaohuoshuan",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/monitor",
					code: "realTimeMonitoring.outdoorFireHydrantSystem",
				},
				component: (resolve) => {
					require(["@/views/monitor/outdoor_hydrant/page/index.vue"], resolve);
				},
			},
			{
				path: "/monitor/info_transmission",
				name: "用户信息传输装置",
				meta: {
					title: "用户信息传输装置",
					name: "用户信息传输装置",
					id: "/monitor/info_transmission",
					icon: "yonghuxinxichuanshuzhuangzhi",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/monitor",
					code: "realTimeMonitoring.infoTransmission",
				},
				component: (resolve) => {
					require(["@/views/monitor/info_transmission/page/index.vue"], resolve);
				},
			},
			{
				path: "/monitor/equipment",
				name: "设备列表",
				meta: {
					title: "设备列表",
					name: "设备列表",
					id: "/monitor/equipment",
					icon: "equipment",
					isExternalIcon: true,
					ismenu: false,
					parentId: "/monitor/alarmt",
					code: "realTimeMonitoring.deviceList",
					//keepAlive: true // 需要被缓存
				},
				component: (resolve) => {
					require([
						"@/views/monitor/equipment/page/list.vue",
					], resolve);
				},
			},
			{
				path: "/monitor/hydrant",
				name: "消火栓详情",
				meta: {
					title: "消火栓详情",
					name: "消火栓详情",
					id: "/monitor/hydrant",
					icon: "equipment",
					isExternalIcon: true,
					ismenu: false,
					// parentId: "/monitor/alarmt",
					// code: "realTimeMonitoring.deviceList",
					//keepAlive: true // 需要被缓存
				},
				component: (resolve) => {
					require([
						"@/views/monitor/hydrant/detail.vue",
					], resolve);
				},
			},
			{
				path: "/monitor/equipment/detail",
				name: "设备详情",
				meta: {
					title: "设备详情",
					name: "设备详情",
					id: "/monitor/equipmentDetail",
					icon: "",
					ismenu: false,
					parentId: "/monitor/alarm",
					code: "realTimeMonitoring.deviceList.detail",
				},
				component: (resolve) => {
					require([
						"@/views/monitor/equipment/detail/page/wrap.vue",
					], resolve);
				},
			},
		],
	},
	{
		path: '/remoteVideo',
		name: '远程可视',
		meta: { 
			title: "远程可视",
			name: "远程可视",
			id: "/remoteVideo",
			icon: "video-camera",
			ismenu: true,
			code: "remoteVideo"
		},
		component: resolve => {
			require(['@/views/remote_video/video_menu/page/layout.vue'], resolve)
		},
		redirect: "/remoteVideo/videoMenu",
		children: [{
			path: '/remoteVideo/videoMenu',
			name: '远程可视',
			meta: { 
				title: '远程可视',
				name:'远程可视',
				id: '/remoteVideo/videoMenu', 
				icon: "video-camera",
				ismenu: false,
				parentId: '/remoteVideo',  //parentId需与父级路由meta.id保持一致
				code: "remoteVideo.videoMenu",
			},
			component: resolve => {
				require(['@/views/remote_video/video_menu/page/list.vue'], resolve)
			}
		},{
			path: '/remoteVideo/videoDetail',
			name: '远程可视详情',
			meta: { 
				title: '远程可视详情',
				name:'远程可视详情',
				id: '/remoteVideo/videoDetail', 
				icon: "video-camera",
				ismenu: false,
				parentId: '/remoteVideo',  //parentId需与父级路由meta.id保持一致
				code: "remoteVideo.videoDetail",
			},
			component: resolve => {
				require(['@/views/remote_video/video_menu/page/videoDetail.vue'], resolve)
			}
		},
		]
	},
	{
		path: "/monitor_center",
		name: "监测中心",
		meta: {
			id: "/monitor_center",
			icon: "reconciliation",
			code: "monitoringCenter",
			ismenu: true,
		},
		component: (resolve) => {
			require(["@/views/monitor_center/layout.vue"], resolve);
		},
		redirect: "/monitor_center/data",
		children: [
			{
				path: "/monitor_center/data",
				name: "数据监测",
				meta: {
					title: "数据监测",
					name: "数据监测",
					id: "/monitor_center/data",
					code: "monitoringCenter.dataMonitoring",
					icon: "monitor",
					isExternalIcon: true,
					ismenu: true,
				},
				component: (resolve) => {
					require([
						"@/views/monitor_center/data/page/index.vue",
					], resolve);
				},
			},
			{
				path: "/monitor_center/data/equipment_diagram",
				name: "设备监测图",
				meta: {
					title: "设备监测图",
					name: "数据监测",
					id: "/monitor_center/data/equipment_diagram",
					icon: "",
					ismenu: false,
				},
				component: (resolve) => {
					require([
						"@/views/monitor_center/equipment_diagram/page/index.vue",
					], resolve);
				},
			},
		],
	},
	{
		path: "/statistics",
		name: "数据统计",
		meta: {
			title: "数据统计",
			name: "数据统计",
			id: "/statistics",
			icon: "line-chart",
			ismenu: true,
			code: "statistics",
		},
		component: (resolve) => {
			require(["@/views/statistics/page/wrap.vue"], resolve);
		},
	},
	{
		path: "/company",
		name: "单位管理",
		meta: {
			id: "/company",
			icon: "read",
			ismenu: true,
			code: "company",
		},
		component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
		children: [
			{
				path: "/company/social_fire",
				name: "单位信息",
				meta: {
					title: "单位信息",
					name: "单位信息",
					id: "/company/social_fire",
					icon: "social",
					isExternalIcon: true,
					ismenu: true,
					code: "company.socialUnit",
				},
				redirect: "/company/social_fire/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/company/social_fire/list",
						name: "单位列表",
						meta: {
							title: "单位列表",
							name: "单位列表",
							id: "/company/social_fire/list",
							icon: "",
							ismenu: false,
							parentId: "/company/social_fire",
							code: "company.socialUnit",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/company/social_fire/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/company/social_fire/create",
						name: "单位创建",
						meta: {
							title: "单位创建",
							name: "单位创建",
							id: "/company/social_fire/create",
							icon: "",
							ismenu: false,
							parentId: "/company/social_fire",
							code: "company.socialUnit.create",
						},
						component: (resolve) => {
							require([
								"@/views/company/social_fire/page_newroute/create.vue",
							], resolve);
						},
					},
					{
						path: "/company/social_fire/edit",
						name: "单位编辑",
						meta: {
							title: "单位编辑",
							name: "单位编辑",
							id: "/company/social_fire/edit",
							icon: "",
							ismenu: false,
							parentId: "/company/social_fire",
							code: "company.socialUnit.edit",
						},
						component: (resolve) => {
							require([
								"@/views/company/social_fire/page_newroute/edit.vue",
							], resolve);
						},
					},
					{
						path: "/company/social_fire/detail",
						name: "单位详情",
						meta: {
							title: "单位详情",
							name: "单位详情",
							id: "/company/social_fire/detail",
							icon: "",
							ismenu: false,
							parentId: "/company/social_fire",
							code: "company.socialUnit.detail",
						},
						component: (resolve) => {
							require([
								"@/views/company/social_fire/social_info/info.vue",
							], resolve);
						},
					},
				],
			},
			{
				path: "/day_work/maintenance_contract",
				name: "维保合同",
				meta: {
					title: "维保合同",
					name: "维保合同",
					id: "/day_work/maintenance_contract",
					icon: "maintenance-contract",
					isExternalIcon: true,
					ismenu: true,
					code: "dailyWork.maintenanceContractManagement",
				},
				redirect: "/day_work/maintenance_contract/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/day_work/maintenance_contract/list",
						name: "维保合同列表",
						meta: {
							title: "维保合同列表",
							name: "维保合同列表",
							id: "/day_work/maintenance_contract/list",
							icon: "",
							ismenu: false,
							parentId: "/day_work/maintenance_contract",
							code:
								"dailyWork.maintenanceContractManagement.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/day_work/maintenance_contract/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/day_work/maintenance_contract/detail",
						name: "维保合同详情",
						meta: {
							title: "维保合同详情",
							name: "维保合同详情",
							id: "/day_work/maintenance_contract/detail",
							icon: "",
							ismenu: false,
							parentId: "/day_work/maintenance_contract",
							code:
								"dailyWork.maintenanceContractManagement.detail",
						},
						component: (resolve) => {
							require([
								"@/views/day_work/maintenance_contract/page_newroute/detail.vue",
							], resolve);
						},
					},
				],
			},
		],
	},
	{
		path: "/construct",
		name: "建筑管理",
		meta: {
			id: "/construct",
			icon: "home",
			ismenu: true,
			code: "buildingManagement",
		},
		component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
		children: [
			{
				path: "/construct/building",
				name: "建筑信息",
				meta: {
					title: "建筑信息",
					name: "建筑信息",
					id: "/construct/building",
					icon: "building",
					isExternalIcon: true,
					ismenu: true,
					code: "building.buildingManagement",
				},
				redirect: "/construct/building/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/construct/building/list",
						name: "建筑管理列表",
						meta: {
							title: "建筑管理列表",
							name: "建筑管理列表",
							id: "/construct/building/list",
							icon: "",
							ismenu: false,
							parentId: "/construct/building",
							code: "building.buildingManagement.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/construct/building/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/construct/building/detail",
						name: "建筑详情",
						meta: {
							title: "建筑详情",
							name: "建筑详情",
							id: "/construct/building/detail",
							icon: "",
							ismenu: false,
							parentId: "/construct/building",
							code: "building.buildingManagement.view",
						},
						component: (resolve) => {
							require([
								"@/views/construct/building/page_newroute/detail.vue",
							], resolve);
						},
					},
					{
						path: "/construct/building/drawing",
						name: "建筑图纸",
						meta: {
							title: "建筑图纸",
							name: "建筑图纸",
							id: "/construct/building/drawing",
							icon: "",
							ismenu: false,
							auth: false,
							parentId: "/construct/building",
							code: "building.buildingManagement.view",
						},
						component: (resolve) => {
							require([
								"@/views/construct/building/page_newroute/drawing.vue",
							], resolve);
						},
					},
				],
			},
			{
				path: "/construct/construction_area",
				name: "建筑群",
				meta: {
					title: "建筑群",
					name: "建筑群",
					id: "/construct/construction_area",
					icon: "building-area2",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/construct", //parentId需与父级路由meta.id保持一致
					code: "building.buildingArea",
				},
				component: (resolve) => {
					require([
						"@/views/construct/construction_area/page/list.vue",
					], resolve);
				},
			},
			{
				path: "/basic_configuration/fire_control_room",
				name: "消控室",
				meta: {
					title: "消控室",
					name: "消控室",
					id: "/basic_configuration/fire_control_room",
					icon: "control",
					isExternalIcon: true,
					ismenu: true,
					code: "building.controlroomManagement",
				},
				redirect: "/basic_configuration/fire_control_room/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/basic_configuration/fire_control_room/list",
						name: "消防控制室列表",
						meta: {
							title: "消防控制室列表",
							name: "消防控制室列表",
							id: "/basic_configuration/fire_control_room/list",
							icon: "",
							ismenu: false,
							parentId: "/basic_configuration/fire_control_room",
							code: "building.controlroomManagement.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/basic_configuration/fire_control_room/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/basic_configuration/fire_control_room/detail",
						name: "消防控制室详情",
						meta: {
							title: "消防控制室详情",
							name: "消防控制室详情",
							id: "/basic_configuration/fire_control_room/detail",
							icon: "",
							ismenu: false,
							parentId: "/basic_configuration/fire_control_room",
							code: "building.controlroomManagement.detail",
						},
						component: (resolve) => {
							require([
								"@/views/basic_configuration/fire_control_room/page_newroute/detail.vue",
							], resolve);
						},
					},
					{
						path:
							"/basic_configuration/fire_control_room/staff_roster",
						name: "人员排班管理",
						meta: {
							title: "人员排班管理",
							name: "人员排班管理",
							id:
								"/basic_configuration/fire_control_room/staff_roster",
							icon: "",
							ismenu: false,
							parentId: "/basic_configuration/fire_control_room",
							code: "building.controlroomManagement.staffRoster",
						},
						component: (resolve) => {
							require([
								"@/views/basic_configuration/fire_control_room/page_newroute/staff_roster.vue",
							], resolve);
						},
					},
				],
			},
		],
	},
	{
		path: "/firecontrol_joint",
		name: "防消联勤",
		meta: {
			id: "/firecontrol_joint",
			icon: "box-plot",
			ismenu: true,
			code: "fireFighting",
		},
		component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
		children: [
			{
				path: "/firecontrol_joint/drill_overview",
				name: "熟悉演练情况记录表",
				meta: {
					title: "熟悉演练情况记录表",
					name: "熟悉演练情况记录表",
					id: "/firecontrol_joint/drill_overview",
					icon: "drill",
					ismenu: true,
					isExternalIcon: true,
					code: "fireFighting.drillRecord",
				},
				redirect: "/firecontrol_joint/drill_overview/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/firecontrol_joint/drill_overview/list",
						name: "熟悉演练情况记录表列表",
						meta: {
							title: "熟悉演练情况记录表列表",
							name: "熟悉演练情况记录表列表",
							id: "/firecontrol_joint/drill_overview/list",
							icon: "",
							ismenu: false,
							parentId: "/firecontrol_joint/drill_overview",
							code: "fireFighting.drillRecord.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/firecontrol_joint/drill_overview/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/firecontrol_joint/drill_overview/create",
						name: "熟悉演练情况记录表创建",
						meta: {
							title: "熟悉演练情况记录表创建",
							name: "熟悉演练情况记录表创建",
							id: "/firecontrol_joint/drill_overview/create",
							icon: "",
							ismenu: false,
							parentId: "/firecontrol_joint/drill_overview",
							code: "fireFighting.drillRecord.create",
						},
						component: (resolve) => {
							require([
								"@/views/firecontrol_joint/drill_overview/page_newroute/create.vue",
							], resolve);
						},
					},
					{
						path: "/firecontrol_joint/drill_overview/edit",
						name: "熟悉演练情况记录表编辑",
						meta: {
							title: "熟悉演练情况记录表编辑",
							name: "熟悉演练情况记录表编辑",
							id: "/firecontrol_joint/drill_overview/edit",
							icon: "",
							ismenu: false,
							parentId: "/firecontrol_joint/drill_overview",
							code: "fireFighting.drillRecord.edit",
						},
						component: (resolve) => {
							require([
								"@/views/firecontrol_joint/drill_overview/page_newroute/edit.vue",
							], resolve);
						},
					},
					{
						path: "/firecontrol_joint/drill_overview/detail",
						name: "熟悉演练情况记录表详情",
						meta: {
							title: "熟悉演练情况记录表详情",
							name: "熟悉演练情况记录表详情",
							id: "/firecontrol_joint/drill_overview/detail",
							icon: "",
							ismenu: false,
							parentId: "/firecontrol_joint/drill_overview",
							code: "fireFighting.drillRecord.detail",
						},
						component: (resolve) => {
							require([
								"@/views/firecontrol_joint/drill_overview/page_newroute/detail.vue",
							], resolve);
						},
					},
				],
			},
			{
				path: "/firecontrol_joint/fire_hazard",
				name: "重大火灾隐患登记表",
				meta: {
					title: "重大火灾隐患登记表",
					name: "重大火灾隐患登记表",
					id: "/firecontrol_joint/fire_hazard",
					icon: "fire-system",
					isExternalIcon: true,
					ismenu: true,
					code: "fireFighting.majorFire",
				},
				redirect: "/firecontrol_joint/fire_hazard/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/firecontrol_joint/fire_hazard/list",
						name: "重大火灾隐患登记列表",
						meta: {
							title: "重大火灾隐患登记列表",
							name: "重大火灾隐患登记列表",
							id: "/firecontrol_joint/fire_hazard/list",
							icon: "",
							ismenu: false,
							parentId: "/firecontrol_joint/fire_hazard",
							code: "fireFighting.majorFire.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/firecontrol_joint/fire_hazard/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/firecontrol_joint/fire_hazard/create",
						name: "重大火灾隐患登记列表创建",
						meta: {
							title: "重大火灾隐患登记列表创建",
							name: "重大火灾隐患登记列表创建",
							id: "/firecontrol_joint/fire_hazard/create",
							icon: "",
							ismenu: false,
							parentId: "/firecontrol_joint/fire_hazard",
							code: "fireFighting.majorFire.create",
						},
						component: (resolve) => {
							require([
								"@/views/firecontrol_joint/fire_hazard/page_newroute/create.vue",
							], resolve);
						},
					},
					{
						path: "/firecontrol_joint/fire_hazard/edit",
						name: "重大火灾隐患登记列表编辑",
						meta: {
							title: "重大火灾隐患登记列表编辑",
							name: "重大火灾隐患登记列表编辑",
							id: "/firecontrol_joint/fire_hazard/edit",
							icon: "",
							ismenu: false,
							parentId: "/firecontrol_joint/fire_hazard",
							code: "fireFighting.majorFire.edit",
						},
						component: (resolve) => {
							require([
								"@/views/firecontrol_joint/fire_hazard/page_newroute/edit.vue",
							], resolve);
						},
					},
					{
						path: "/firecontrol_joint/fire_hazard/detail",
						name: "重大火灾隐患登记列表详情",
						meta: {
							title: "重大火灾隐患登记列表详情",
							name: "重大火灾隐患登记列表详情",
							id: "/firecontrol_joint/fire_hazard/detail",
							icon: "",
							ismenu: false,
							parentId: "/firecontrol_joint/fire_hazard",
							code: "fireFighting.majorFire.view",
						},
						component: (resolve) => {
							require([
								"@/views/firecontrol_joint/fire_hazard/page_newroute/detail.vue",
							], resolve);
						},
					},
				],
			},
		],
	},
	{
		path: "/supervision",
		name: "监督检查",
		meta: {
			title: "监督检查",
			name: "监督检查",
			id: "/supervision",
			icon: "book",
			ismenu: true,
			code: "supervisedCheck",
		},
		component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
		children: [
			{
				path: "/supervision/report",
				name: "举报核查",
				meta: {
					title: "举报核查",
					name: "举报核查",
					id: "/supervision/report",
					icon: "supervision-report",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/supervision", //parentId需与父级路由meta.id保持一致
					code: "supervisedCheck.reportVerification",
				},
				component: (resolve) => {
					require([
						"@/views/supervision/report/page/list.vue",
					], resolve);
				},
			},
			{
				path: "/supervision/f3Danger",
				name: "隐患处理",
				meta: {
					title: "隐患处理",
					name: "隐患处理",
					id: "/supervision/f3Danger",
					icon: "f3Danger",
					isExternalIcon: true,
					ismenu: true,
					code: "supervisedCheck.hiddenDangerTreatment",
				},
				redirect: "/supervision/f3Danger/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/supervision/f3Danger/list",
						name: "隐患处理列表",
						meta: {
							title: "隐患处理列表",
							name: "隐患处理列表",
							id: "/supervision/f3Danger/list",
							icon: "",
							ismenu: false,
							parentId: "/supervision/f3Danger",
							code: "supervisedCheck.hiddenDangerTreatment.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/supervision/f3Danger/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/supervision/f3Danger/detail",
						name: "隐患处理详情",
						meta: {
							title: "隐患处理详情",
							name: "隐患处理详情",
							id: "/supervision/f3Danger/detail",
							icon: "",
							ismenu: false,
							parentId: "/supervision/f3Danger",
							code: "supervisedCheck.hiddenDangerTreatment.list",
						},
						component: (resolve) => {
							require([
								"@/views/supervision/f3Danger/page_newroute/detail.vue",
							], resolve);
						},
					},
				],
			},
		],
	},
	{
		path: "/safe_edu",
		name: "安全教育",
		meta: {
			title: "安全教育",
			name: "安全教育",
			id: "/safe_edu",
			icon: "safety",
			ismenu: true,
			code: "safeEducation",
		},
		component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
		redirect: "/safe_edu/article",
		children: [
			{
				path: "/safe_edu/article",
				name: "文章管理",
				meta: {
					title: "文章管理",
					name: "文章管理",
					id: "/safe_edu/article",
					icon: "article",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/safe_edu",
					code: "safeEducation.articleManagement",
				},
				redirect: "/safe_edu/article/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/safe_edu/article/list",
						name: "文章管理列表",
						meta: {
							title: "文章管理列表",
							name: "文章管理列表",
							id: "/safe_edu/article/list",
							icon: "",
							ismenu: false,
							parentId: "/safe_edu/article/list",
							code: "safeEducation.articleManagement.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/safe_edu/article/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/safe_edu/article/create",
						name: "文章管理创建",
						meta: {
							title: "文章管理创建",
							name: "文章管理创建",
							id: "/safe_edu/article/create",
							icon: "",
							ismenu: false,
							parentId: "/safe_edu/article",
							code: "safeEducation.articleManagement.create",
						},
						component: (resolve) => {
							require([
								"@/views/safe_edu/article/page_newroute/create.vue",
							], resolve);
						},
					},
					{
						path: "/safe_edu/article/edit",
						name: "文章管理编辑",
						meta: {
							title: "文章管理编辑",
							name: "文章管理编辑",
							id: "/safe_edu/article/edit",
							icon: "",
							ismenu: false,
							parentId: "/safe_edu/article",
							code: "safeEducation.articleManagement.edit",
						},
						component: (resolve) => {
							require([
								"@/views/safe_edu/article/page_newroute/edit.vue",
							], resolve);
						},
					},
					{
						path: "/safe_edu/article/detail",
						name: "文章管理详情",
						meta: {
							title: "文章管理详情",
							name: "文章管理详情",
							id: "/safe_edu/article/detail",
							icon: "",
							ismenu: false,
							parentId: "/safe_edu/article",
							code: "safeEducation.articleManagement.detail",
						},
						component: (resolve) => {
							require([
								"@/views/safe_edu/article/page_newroute/detail.vue",
							], resolve);
						},
					},
				],
			},
			{
				path: "/safe_edu/video",
				name: "视频管理",
				meta: {
					title: "视频管理",
					name: "视频管理",
					id: "/safe_edu/video",
					icon: "video",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/safe_edu", //parentId需与父级路由meta.id保持一致
					code: "safeEducation.videoManagement",
				},
				redirect: "/safe_edu/video/list",
				component: (resolve) => {
					require(["@/layout.vue"], resolve);
				},
				children: [
					{
						path: "/safe_edu/video/list",
						name: "视频管理列表",
						meta: {
							title: "视频管理列表",
							name: "视频管理列表",
							id: "/safe_edu/video/list",
							icon: "",
							ismenu: false,
							parentId: "/safe_edu/video/list",
							code: "safeEducation.videoManagement.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/safe_edu/video/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/safe_edu/video/create",
						name: "视频管理创建",
						meta: {
							title: "视频管理创建",
							name: "视频管理创建",
							id: "/safe_edu/video/create",
							icon: "",
							ismenu: false,
							parentId: "/safe_edu/video",
							code: "safeEducation.videoManagement​.create",
						},
						component: (resolve) => {
							require([
								"@/views/safe_edu/video/page_newroute/create.vue",
							], resolve);
						},
					},
					{
						path: "/safe_edu/video/edit",
						name: "视频管理编辑",
						meta: {
							title: "视频管理编辑",
							name: "视频管理编辑",
							id: "/safe_edu/video/edit",
							icon: "",
							ismenu: false,
							parentId: "/safe_edu/video",
						},
						component: (resolve) => {
							require([
								"@/views/safe_edu/video/page_newroute/edit.vue",
							], resolve);
						},
					},
					{
						path: "/safe_edu/video/detail",
						name: "视频管理详情",
						meta: {
							title: "视频管理详情",
							name: "视频管理详情",
							id: "/safe_edu/video/detail",
							icon: "",
							ismenu: false,
							parentId: "/safe_edu/video",
						},
						component: (resolve) => {
							require([
								"@/views/safe_edu/video/page_newroute/detail.vue",
							], resolve);
						},
					},
				],
			},
		],
	},
	// {
	// 	path: "/day_work",
	// 	name: "日常工作",
	// 	meta: {
	// 		id: "/day_work",
	// 		title: "日常工作",
	// 		name: "日常工作",
	// 		icon: "calendar",
	// 		ismenu: true,
	// 		code: "dailyWork",
	// 	},
	// 	component: (resolve) => {
	// 		require(["@/layout.vue"], resolve);
	// 	},

	// 	children: [
	// 		{
	// 			path: "/day_work/maintenance_contract",
	// 			name: "维保合同",
	// 			meta: {
	// 				title: "维保合同",
	// 				name: "维保合同",
	// 				id: "/day_work/maintenance_contract",
	// 				icon: "maintenance-contract",
	// 				isExternalIcon: true,
	// 				ismenu: true,
	// 				code: "dailyWork.maintenanceContractManagement",
	// 			},
	// 			redirect: "/day_work/maintenance_contract/list",
	// 			component: (resolve) => {
	// 				require(["@/layout.vue"], resolve);
	// 			},
	// 			children: [
	// 				{
	// 					path: "/day_work/maintenance_contract/list",
	// 					name: "维保合同列表",
	// 					meta: {
	// 						title: "维保合同列表",
	// 						name: "维保合同列表",
	// 						id: "/day_work/maintenance_contract/list",
	// 						icon: "",
	// 						ismenu: false,
	// 						parentId: "/day_work/maintenance_contract",
	// 						code:
	// 							"dailyWork.maintenanceContractManagement.list",
	// 					},
	// 					component: (resolve) => {
	// 						require([
	// 							"@/views/day_work/maintenance_contract/page_newroute/list.vue",
	// 						], resolve);
	// 					},
	// 				},
	// 				{
	// 					path: "/day_work/maintenance_contract/detail",
	// 					name: "维保合同详情",
	// 					meta: {
	// 						title: "维保合同详情",
	// 						name: "维保合同详情",
	// 						id: "/day_work/maintenance_contract/detail",
	// 						icon: "",
	// 						ismenu: false,
	// 						parentId: "/day_work/maintenance_contract",
	// 						code:
	// 							"dailyWork.maintenanceContractManagement.detail",
	// 					},
	// 					component: (resolve) => {
	// 						require([
	// 							"@/views/day_work/maintenance_contract/page_newroute/detail.vue",
	// 						], resolve);
	// 					},
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	{
		path: "/h5_report",
		name: "GIS信息上报",
		meta: {
			id: "/h5_report",
			icon: "cloud-upload",
			ismenu: true,
			name: "GIS信息上报",
			code: "h5Report",
		},
		component: (resolve) => {
			require([
				"@/views/h5_report/fire_brigade/page_newroute/layout.vue",
			], resolve);
		},
		children: [
			{
				path: "/h5_report/fire_hydrant",
				name: "消火栓信息上报",
				meta: {
					title: "消火栓信息上报",
					name: "消火栓信息上报",
					id: "/h5_report/fire_hydrant",
					icon: "",
					code: "h5Report.fireHydrant",
					ismenu: true,
				},
				redirect: "/h5_report/fire_hydrant/list",
				component: (resolve) => {
					require([
						"@/views/h5_report/fire_hydrant/page_newroute/layout.vue",
					], resolve);
				},
				children: [
					{
						path: "/h5_report/fire_hydrant/list",
						name: "消火栓上报列表",
						meta: {
							title: "消火栓上报列表",
							name: "消火栓上报列表",
							id: "/h5_report/fire_hydrant/list",
							icon: "",
							ismenu: false,
							parentId: "/h5_report/fire_hydrant",
							code: "h5Report.fireHydrant.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/h5_report/fire_hydrant/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/h5_report/fire_hydrant/create",
						name: "消火栓上报创建",
						meta: {
							title: "消火栓上报创建",
							name: "消火栓上报创建",
							id: "/h5_report/fire_hydrant/create",
							icon: "",
							ismenu: false,
							parentId: "/h5_report/fire_hydrant",
							code: "h5Report.fireHydrant.create",
						},
						component: (resolve) => {
							require([
								"@/views/h5_report/fire_hydrant/page_newroute/create.vue",
							], resolve);
						},
					},
					{
						path: "/h5_report/fire_hydrant/edit",
						name: "消火栓上报编辑",
						meta: {
							title: "消火栓上报编辑",
							name: "消火栓上报编辑",
							id: "/h5_report/fire_hydrant/edit",
							icon: "",
							ismenu: false,
							parentId: "/h5_report/fire_hydrant",
							code: "h5Report.fireHydrant.edit",
						},
						component: (resolve) => {
							require([
								"@/views/h5_report/fire_hydrant/page_newroute/edit.vue",
							], resolve);
						},
					},
					{
						path: "/h5_report/fire_hydrant/detail",
						name: "消火栓上报详情",
						meta: {
							title: "消火栓上报详情",
							name: "消火栓上报详情",
							id: "/h5_report/fire_hydrant/detail",
							icon: "",
							ismenu: false,
							parentId: "/h5_report/fire_hydrant",
							code: "h5Report.fireHydrant.detail",
						},
						component: (resolve) => {
							require([
								"@/views/h5_report/fire_hydrant/page_newroute/detail.vue",
							], resolve);
						},
					},
				],
			},
			{
				path: "/h5_report/fire_brigade",
				name: "专职消防队信息上报",
				meta: {
					title: "专职消防队信息上报",
					name: "专职消防队信息上报",
					id: "/h5_report/fire_brigade",
					icon: "",
					code: "h5Report.fireBrigade",
					ismenu: true,
				},
				redirect: "/h5_report/fire_brigade/list",
				component: (resolve) => {
					require([
						"@/views/h5_report/fire_brigade/page_newroute/layout.vue",
					], resolve);
				},
				children: [
					{
						path: "/h5_report/fire_brigade/list",
						name: "专职消防队上报列表",
						meta: {
							title: "专职消防队上报列表",
							name: "专职消防队上报列表",
							id: "/h5_report/fire_brigade/list",
							icon: "",
							ismenu: false,
							parentId: "/h5_report/fire_brigade",
							code: "h5Report.fireBrigade.list",
							//keepAlive: true // 需要被缓存
						},
						component: (resolve) => {
							require([
								"@/views/h5_report/fire_brigade/page_newroute/list.vue",
							], resolve);
						},
					},
					{
						path: "/h5_report/fire_brigade/create",
						name: "专职消防队上报创建",
						meta: {
							title: "专职消防队上报创建",
							name: "专职消防队上报创建",
							id: "/h5_report/fire_brigade/create",
							icon: "",
							ismenu: false,
							parentId: "/h5_report/fire_brigade",
							code: "h5Report.fireBrigade.create",
						},
						component: (resolve) => {
							require([
								"@/views/h5_report/fire_brigade/page_newroute/create.vue",
							], resolve);
						},
					},
					{
						path: "/h5_report/fire_brigade/edit",
						name: "专职消防队上报编辑",
						meta: {
							title: "专职消防队上报编辑",
							name: "专职消防队上报编辑",
							id: "/h5_report/fire_brigade/edit",
							icon: "",
							ismenu: false,
							parentId: "/h5_report/fire_brigade",
							code: "h5Report.fireBrigade.edit",
						},
						component: (resolve) => {
							require([
								"@/views/h5_report/fire_brigade/page_newroute/edit.vue",
							], resolve);
						},
					},
					{
						path: "/h5_report/fire_brigade/detail",
						name: "专职消防队上报详情",
						meta: {
							title: "专职消防队上报详情",
							name: "专职消防队上报详情",
							id: "/h5_report/fire_brigade/detail",
							icon: "",
							ismenu: false,
							parentId: "/h5_report/fire_brigade",
							code: "h5Report.fireBrigade.detail",
						},
						component: (resolve) => {
							require([
								"@/views/h5_report/fire_brigade/page_newroute/detail.vue",
							], resolve);
						},
					},
				],
			},
		],
	},
	// {
	// 	path: "/system_config",
	// 	name: "系统设置",
	// 	meta: {
	// 		id: "/system_config",
	// 		icon: "",
	// 		ismenu: true,
	// 		name: "系统设置",
	// 	},
	// 	component: (resolve) => {
	// 		require([
	// 			"@/views/system_config/op_config/page/layout.vue",
	// 		], resolve);
	// 	},
	// 	redirect: "/system_config/op_config",
	// 	children: [
	// 		{
	// 			path: "/system_config/op_config",
	// 			name: "基础数值配置",
	// 			meta: {
	// 				title: "基础数值配置",
	// 				name: "基础数值配置",
	// 				id: "/system_config/op_config",
	// 				icon: "",
	// 				ismenu: true,
	// 				parentId: "/system_config", //parentId需与父级路由meta.id保持一致
	// 			},
	// 			component: (resolve) => {
	// 				require([
	// 					"@/views/system_config/op_config/page/list.vue",
	// 				], resolve);
	// 			},
	// 		},
	// 	],
	// },
	
	{
		path: "/system",
		name: "系统管理",
		meta: {
			title: "系统管理",
			name: "系统管理",
			id: "/system",
			icon: "setting",
			ismenu: true,
			code: "system",
		},
		component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
		redirect: "/system/user",
		children: [
			{
				path: "/system/org",
				name: "组织机构管理",
				meta: {
					title: "消防组织机构管理",
					name: "消防组织机构管理",
					id: "/system/org",
					icon: "org",
					isExternalIcon: true,
					ismenu: true,
					parentId: "/system", //parentId需与父级路由meta.id保持一致
					code: "system.fireOrg",
				},
				component: (resolve) => {
					require(["@/views/system/org/page/list.vue"], resolve);
				},
			},
			{
				path: "/system/user",
				name: "用户管理",
				meta: {
					title: "用户管理",
					name: "用户管理",
					id: "/system/user",
					icon: "user",
					ismenu: true,
					parentId: "/system",
					code: "system.user",
				},
				component: (resolve) => {
					require(["@/views/system/user/page/list.vue"], resolve);
				},
			},
			// {
			// 	path: '/system/td_log',
			// 	name: '系统日志',
			// 	meta: { 
			// 		title: '系统日志',
			// 		name:'系统日志',
			// 		id: '/system/td_log', 
			// 		icon: "message",
			// 		ismenu: true,
			// 		parentId: '/system',  //parentId需与父级路由meta.id保持一致
			// 		code: "system.tdLog",
			// 	},
			// 	component: resolve => {
			// 		require(['@/views/system/td_log/page/list.vue'], resolve)
			// 	}
			// },
		],
	},
];
