export const downLoadFile = (url) => {
	const iframe = document.createElement("iframe");
	iframe.src = url;
	iframe.style.display = "none";
	document.body.appendChild(iframe);
	const timer = setInterval(() => {
		const iframeDoc =
			iframe.contentDocument || iframe.contentWindow.document;
		if (
			iframeDoc.readyState === "complete" ||
			iframe.readyState === "interaction"
		) {
			clearInterval(timer);
			document.body.removeChild(iframe);
			return;
		}
	}, 1000);
};

export const downLoadBlob = (blob, filename) => {
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob, filename);
		return;
	}
	const a = document.createElement("a");
	a.style.display = "none";
	document.body.appendChild(a);
	const href = window.URL.createObjectURL(blob);
	a.href = href;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL(href);
	document.body.removeChild(a);
};
