export const state = {
  listDataSource: [],
  pagination: {
    pageNo: 1,
    pageSize: 100,
    totalCount: 0
  },
  dict: {},
  sexDictList: [],
  resourceTypeDictList: [],
  roleTypeDictList: [],
}