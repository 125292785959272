<template>
    <div class="PTZ-container">
      <div class="PTZ-control-circle" :class="!this.showCircle ? 'no-circle' : ''">
        
          <!-- 向上  //0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-停止-->
        <div class="PTZ-control">
          <div class="PTZ-meidiv">
          <div class="control-btn" @mousedown="handleMove('4')">
            <i class="el-icon-caret-left control-icon control-icon-1"></i>
          </div>
          <div class="control-btn" @mousedown="handleMove('0')">
            <i class="el-icon-caret-left control-icon control-icon-2"></i>
          </div>
          <div class="control-btn" @mousedown="handleMove('6')">
            <i class="el-icon-caret-left control-icon control-icon-3"></i>
          </div>
          <div class="control-btn" @mousedown="handleMove('2')">
            <i class="el-icon-caret-left control-icon control-icon-4"></i>
          </div>
          <div class="control-btn" @mousedown="handleMove('11')">
            <i class="el-icon-caret-left control-icon control-icon-5"></i>
          </div>
          <div class="control-btn" @mousedown="handleMove('3')">
            <i class="el-icon-caret-left control-icon control-icon-6"></i>
          </div>
          <div class="control-btn" @mousedown="handleMove('5')">
            <i class="el-icon-caret-left control-icon control-icon-7"></i>
          </div>
          <div class="control-btn" @mousedown="handleMove('1')">
            <i class="el-icon-caret-left control-icon control-icon-8"></i>
          </div>
          <div class="control-btn" @mousedown="handleMove('7')">
            <i class="el-icon-caret-left control-icon control-icon-9"></i>
          </div>
          <div class="control-round"></div>
        </div>
      </div>
      </div>
      <div class="control-zoom">
          <!-- 右侧缩小 -->
        <div class="control-zoom-circle" :class="!this.showCircle ? 'no-circle' : ''">
          <div
            class="zoom-circle"
            @mousedown="handleMove('9')"
          >
            <i class="el-icon-zoom-out"></i>
          </div>
        </div>
        <!-- 右侧放大 -->
        <div class="control-zoom-circle" :class="!this.showCircle ? 'no-circle' : ''">
          <div
            class="zoom-circle"
            @mousedown="handleMove('8')"
          >
            <i class="el-icon-zoom-in"></i>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'PTZ-controller',
    props: {
      showCircle: {
        type: Boolean,
        default: true
      }
    },
    computed: {
        // 是否需要浅透明底色
      // circleClass() {
      //   return !this.showCircle ? 'no-circle' : ''
      // }
    },
    data() {
      return {

      }
    },
    mounted(){
      console.log("window.addEventListener")
      window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeUnmount() {
      window.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        ////0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-停止
        handleKeyDown(event) {
			let keyName = ""
			if(event.key === 'ArrowUp'||event.key === '8') {
				keyName = "0"
			}else if(event.key === 'ArrowDown'||event.key === '2'){
				keyName = "1"
			}else if(event.key === 'ArrowLeft'||event.key === '4'){
				keyName = "2"
			}else if(event.key === 'ArrowRight'||event.key === '6'){
				keyName = "3"
			}else if(event.key === '7'){
				keyName = "4"
			}else if(event.key === '1'){
				keyName = "5"
			}else if(event.key === '9'){
				keyName = "6"
			}else if(event.key === '3'){
				keyName = "7"
			}else if(event.key === '+'){
				keyName = "8"
			}else if(event.key === '-'){
				keyName = "9"
			}

			if(keyName!=""){
				this.handleMove(keyName)
			}
        },
       handleMove(command) {
        this.$emit("yuntaiHandle",command)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  $color-control-btn: #edf0f7;
  .PTZ-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    position: fixed;
    top: 100px;
    right: 30px;
    .no-circle {
      background: transparent !important;
      width: 140px !important;
    }
    .PTZ-control-circle {
      width: 180px;
      height: 180px;
      border-radius: 90px;
      background: rgb(221 221 221 / 0.1);
      .PTZ-control {
        // 超出位置换行
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        width: 179px;
        height: 179px;
        border-radius: 90px;
        // transform: rotate(45deg);
        .PTZ-meidiv{
          width: 135px;
          height: 135px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          border-radius: 80px;
          overflow: hidden;
        }
        .control-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          // border-top-left-radius: 60px; // 把上左两个方向变圆角
          // border: 1px solid #fff;
          background-color: $color-control-btn;
          cursor: pointer;
          // &:hover {
          //   background-color: aliceblue;
          // }
          .control-icon {
            // transform: rotate(45deg);
            color: #1e90ff;
          }
          .control-icon-1{
            transform: rotate(45deg);
            margin-top: 10px;
            margin-left: 10px;
          }
          .control-icon-2{
            transform: rotate(90deg);
            margin-top: -10px;
            // margin-left: 10px;
          }
          .control-icon-3{
            transform: rotate(135deg);
            margin-top: 10px;
            margin-left: -10px;
          }
          .control-icon-4{
            margin-left: -10px;
          }
          .control-icon-6{
            transform: rotate(180deg);
            margin-left: 10px;
          }
          .control-icon-7{
            transform: rotate(-45deg);
            margin-top: -10px;
            margin-left: 10px;
          }
          .control-icon-8{
            transform: rotate(-90deg);
            margin-top: 10px;
          }
          .control-icon-9{
            transform: rotate(-135deg);
            margin-top: -10px;
            margin-left: -20px;
          }
        }
        .control-round {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          position: absolute; // 绝对定位找到圆心
          top: 70px;
          // left: 34px;
          background-color: #fff;
        }
      }
    }
  
    .control-zoom {
      font-size: 25px;
      color: #1e90ff;
      cursor: pointer;
      .control-zoom-circle {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        background: rgb(221 221 221 / 0.1); 
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
      }
      .no-circle {
        background: transparent !important;
      }
      .zoom-circle {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: $color-control-btn;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: aliceblue;
        }
      }
    }
  }
  </style>
  
  