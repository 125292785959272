<template>
    <div class="confirmed">
        <div class="confirmed_list">
            <div class="con_info" @click="clickItem(item,index)" :class="activeBadgeIndex==index?'con_info_active':''" v-for="(item,index) in alarmDataList" :key="index">
                <div class="list_top" :class="(fireType(item)=='故障'||fireType(item)=='异常')?'list_top1':''">
                    <template v-if="fireType(item)=='火警'">
                        <img src="../../assets/image/fireIcon_huozai.png" style="width:0.3rem;">
                    </template>
                    <template v-if="fireType(item)=='故障'">
                        <img src="../../assets/image/fireIcon_wubao.png">
                    </template>
                    <template v-if="fireType(item)=='异常'">
                        <img src="../../assets/image/fireIcon_gaojing.png">
                    </template>
                    <template v-if="fireType(item)=='报警'">
                        <img src="../../assets/image/fireIcon_huojing.png">
                    </template>
                    {{fireType(item)}}<a-badge :dot="!item.dataStatus"/></div>
                <p class="list_time">{{ item.alarmLatestTime }}</p>
                    <!-- 如果有报警原因就是显示原因, -->
                    <template v-if="item.alarmRemake"> 
                        <p class="list_comment">{{item.alarmRemake}}</p>
                    </template>
                    <template v-else-if="item.devicePointMappingVO">
                        <p class="list_comment">
                            {{item.devicePointMappingVO.systemAddress}}主机
                            {{item.devicePointMappingVO.loopCode}}回路
                            {{item.devicePointMappingVO.pointCode}}点位 
                            {{ item.devicePointMappingVO.pointAddressName }}
                            发生{{originalType(item.fireTypeName)}}
                        </p>
                    </template>
                    <template v-else>
                        <p class="list_comment">
                            {{item.equipmentName}}
                        发生{{originalType(item.fireTypeName)}}</p>
                    </template>
                    <p class="list_comment">
                        {{item.buildingName}}-{{item.buildingFloorName+"楼"}}-{{item.roomName}}
                    </p>
               
            </div>
            
            
        </div>
    </div>
</template>
<script>
export default {
     name: "alarm_confirmed",
     props:["alarmDataList","activeBadgeIndex"],
  data() {
    return {
        showBadge : true,
    };
  },
  methods: {
      clickItem(item,index){
          this.$emit("localItemData", item)
          this.$emit("checkActiveBadge", index)
      },
      fireType(comm) {
          let text=""
          if((comm.type==2||comm.type==3)&&!comm.fireAlarm&&!comm.faultAlarm&&!comm.alertAlarm){
                text="报警"
            }else{
                if(comm.fireAlarm){
                    text="火警"
                }else if(comm.faultAlarm){
                    text ="故障"
                }else if(comm.alertAlarm){
                    text ="异常"
                }else{
                    text = "未知异常"
                }
          }
          return text
      },
    originalType(item) {
      let text = "";
      switch (item) {
        case '0':
          text = "报警";
          break;
        case '1':
          text = "火警";
          break;
        case '2':
          text = "故障";
          break;
        case '3':
          text = "异常";
          break;
        default:
          text = "报警";
          break;
      }
      return text;
    },
  },
};
</script>
<style lang="less">
.confirmed{
    .ant-badge{
        float: right;
        top: 0.17rem;
    }
    
}

</style>
<style lang="less" scoped>
p{
    margin:0;
}

    .con_info{
        width: 2.92rem;
        background: #173158;
        padding : 0.2rem 0.2rem 0.1rem 0.2rem;
        margin-bottom: 0.2rem;
        cursor: pointer;
        .list_top{
            width:100%;
            color:#EA5449;
            font-size: 0.18rem /* 18/100 */;
            font-weight: bold;
            margin-top: -0.15rem;
            margin-left: -0.05rem;
            >img{
                width:0.24rem;
                margin-left: 0.05rem;
                margin-right: 0.05rem;
            }
            
        }
        .list_top1{
            color: #52cfff;
        }
        .list_time{
            color:#9DB5D3;
            font-size:0.14rem /* 14/100 */;
            margin-bottom: 0.05rem;
        }
        .list_comment{
            font-size:0.14rem /* 14/100 */;
            color:#fff;
            word-break:break-all;
            display:-webkit-box;
            -webkit-line-clamp:2; //这里写的数字是几就是超过几行剩余的显示省略号
            -webkit-box-orient:vertical;
            overflow:hidden;
        }
    }
    .con_info_active{
        background: #1E3F72;
    }
</style>