import { state } from "./state";

import * as actions from "./action";

import * as mutations from "./mutation";

export const system_org = {
	namespaced: true,
	state,
	mutations,
	actions,
};
