import request from '@/utils/request'
import { message } from 'ant-design-vue'
import { buildListParams } from '@/utils';

//获取当前用户单位电子文档提交情况(已提交，未提交) 社会单位
export const getOwnDocumentList = ({ commit }) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api-fire/v1/form/documentSpotCheck/own',
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}
//社会单位获取文档内容
export const getOwnDocumentDetail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/form/documentSpotCheck/own/${params}/document`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}

//催一下 （催填填文档）
export const urge = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/form/documentSpotCheck/${params}/urge`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}

//保存或提交文档（action save 保存 commit 提交）
export const updateDocumentDetail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/form/documentSpotCheck/${params.documentType}/document/${params.action}`,
      method: 'post',
      data: params.data
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}

//消防平台获取文档内容
export const getDocumentDetail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/form/documentSpotCheck/${params.id}/${params.type}/document`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}

//获取单位电子文档提交情况(已提交，未提交)，消防物联平台
export const getDocumentList = ({ commit }, params) => {
  console.log(params)
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/form/documentSpotCheck/${params}/company`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}


//文档填写反馈
export const feedBack = ({ commit }, params) => {
  console.log(params)
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/form/documentSpotCheck/${params.id}/feedBack`,
      method: 'post',
      data: params.form
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}

//查询消防大队
export const fireOrgs = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/org/fireOrgs`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}

//查询业主单位详情
export const getCompanyDetail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/company/${params}`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}

//查询单位微型消防站可以属于 楼栋 单独建筑 列表
export const getMinFireStation = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-asset/v1/station/minFireStation/${params.id}/company`,
      method: 'get',
      data: params.data
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}

//查询单位微型消防站可以属于 楼栋 单独建筑
export const getMinFireStationById = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-asset/v1/station/minFireStation/${id}`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject(e);
    })
  })
}


