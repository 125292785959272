import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

export const getTableList = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-fire/v1/inspection/inspectionTask",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					commit("SET_TABLE_DATA", res.result.data);
					commit("SET_TABLE_PAGE_INFO", {
						pageNo: result.pageNo,
						pageSize: result.pageSize,
						total: Number(result.totalCount),
					});
					resolve(res.result.data);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getCompletedTableList = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-fire/v1/inspection/inspectionTask",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					commit("SET_COMPLETED_TABLE_DATA", res.result.data);
					commit("SET_COMPLETED_TABLE_PAGE_INFO", {
						pageNo: result.pageNo,
						pageSize: result.pageSize,
						total: Number(result.totalCount),
					});
					resolve(res.result.data);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const detail = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/inspection/inspectionTask/${params.id}`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const create = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-fire/v1/inspection/inspectionTask",
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const edit = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/inspection/inspectionTask/${params.id}`,
			method: "put",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteData = ({ commit }, keys) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/inspection/inspectionTask`,
			method: "delete",
			data: keys,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const deleteSingleData = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/inspection/inspectionTask/${id}`,
			method: "delete",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getInspectionType = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/inspection/inspectionType`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getBuilding = ({ commit }) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-asset/v1/building`,
			method: "get",
			params: {
				pageNo: 1,
				pageSize: 500,
			},
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getInspectionPointItems = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/inspection/inspectionPoint/${
				params.buildingId
			}/${params.companyId}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getInspectionItem = ({ commit }) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/inspection/inspectionItem`,
			method: "get",
			params: {
				pageNo: 1,
				pageSize: 500,
			},
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getAttachInfo = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-base/v1/base/attach/${params.id}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getFileAddress = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-base/v1/base/attach/sign/${id}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};
