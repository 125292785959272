<template>
  <div v-if="showDialog" class="shade-view">
    <div class="dialog-view clearfix">
      <div :class="(fireAlarmUnFold ? 'unfold' : '') + ' fire-alarm-operate clearfix'">
        <ul class="tabs clearfix" v-if="onFireData.length > 1">
          <li :class=" (activeIndex === index ? ' active' : '') +
           ( index !== fireInfoArray.length - 1 ? ' other' : '')+ 
                (fireInfoArray.length>=7?' other':'') + 
                 ' tab fl'" v-for="(item, index) in fireInfoArray"
              v-if="index<7"
            :key="index" @click="() => {changeFireTabIndex(item, index);}">
            <span class="text">{{ index + 1 }}</span>
          </li>
          <li class=" tab fl" style="width:.7rem;" v-if="fireInfoArray.length>=7" @click="clickMove"><span class="text">more</span></li>
        </ul>
        <ul class="tabs clearfix" v-else>
          <li :class="'active tab fl'" style="border-top:0;border-right:0">
            <span class="text">{{ 1 }}</span>
          </li>
        </ul>
      </div>
      <div
        :class="
          (fireAlarmUnFold ? 'unfold' : '') +
            ' gloable-fire-alarm-information box'
        "
        v-show="fireAlarmUnFold"
      >
        <!-- <div class="border-decorate">
          <div class="left-top">
            <span class="row"></span>
            <span class="col"></span>
          </div>
          <div class="right-top">
            <span class="row"></span>
            <span class="col"></span>
          </div>
          <div class="left-bottom">
            <span class="row"></span>
            <span class="col"></span>
          </div>
          <div class="right-bottom">
            <span class="row"></span>
            <span class="col"></span>
          </div>
        </div> -->
        <div class="substance" v-if="!showVideoList">
          <!-- <span class="fire-icon"></span> -->
          <fire-alarm :isVoted="isVoted" :digitizingClick="digitizingClick" v-bind="$attrs"  v-on="$listeners"
            :timeValue="timeValue" :fireInfo="fireInfoArray[activeIndex]" @fireTabChange="fireTabChange" 
            @erasureNoise="erasureNoise"></fire-alarm>
        </div>
      </div>
      <div class="bottom-line"></div>
      <div class="bottom-close" @click="closeDialog"></div>
    </div>
  </div>
</template>

<script>
import fireAlarm from "./fireAlarm.vue";
import { List } from "ant-design-vue";
import {
  createNamespacedHelpers,
  mapGetters,
  mapActions as mapOtherActions,
  mapState as mapOtherState,
} from "vuex";
export default {
  name: "fireAlarmInformation",
  props: {
    statusBarUnFold: Boolean,
    mapUnFold: Boolean,
    fireAlarmUnFold: Boolean,
    callback: Function,
    fireInfoArray: Array,
    fireInfo: Object,
    showDialog: Boolean,
    onFireData: Array,
    SET_ONFIRE_DATA: Function,
  },
  data() {
    return {
      isVoted: false,
      showVideoList: false,
      timeValue: "00:00",
      timeoutNum: null,
      tiemr: null,
      activeIndex: 0,
    };
  },
  mounted() {},
  watch: {
    fireAlarmUnFold(value) {
      if (value) {
        this.timeValueStart();
        this.switchAlarmVoted();
      }
    },
    onFireData(value) {
      console.log("火警数据", value);
      if (value.findIndex((item) => item.isVoted == false) == -1) {
        var parent = document.querySelector("body");
        var child = document.querySelector("audio");
        if (child) {
          parent.removeChild(child);
        }
      } else {
        this.setFireAlarmAudio();
      }
    },
  },
  methods: {
    ...mapOtherActions("Dashboard", ["localCache"]),
    clickMove(){
      // this.$emit("showAlarmInfoDealShow")
      this.$emit("closeFireDialog");
      localStorage.setItem("currentKey", "/alarm_center/alarm_log");
      this.$router.push({path:'/alarm_center/alarm_log/list'});
    },
    
    //设置火警音频
    setFireAlarmAudio() {
      var parent = document.querySelector("body");
      var child = document.querySelector("audio");
      if (child) {
        parent.removeChild(child);
      }
      let audio = document.createElement("audio");
      audio.setAttribute("src", require("@/assets/audio/fire-alarm.mp3"));
      audio.setAttribute("muted", "muted");
      audio.setAttribute("autoplay", "true");
      audio.setAttribute("loop", "loop");
      audio.setAttribute("muted", false);
      audio.play();
      document.body.appendChild(audio);
      audio.addEventListener(
        "ended",
        function() {
          var parent = document.querySelector("body");
          var child = document.querySelector("audio");
          parent.removeChild(child);
        },
        false
      );
    },
    //静音
    erasureNoise(data) {
      let userinfo = JSON.parse(localStorage.getItem("iot-fire-op-userinfo"));
      let userId = userinfo.id;
      if (this.onFireData.findIndex((item) => item.isVoted == false) != -1) {
        this.onFireData.forEach((item) => {
          if (item.key == data.key) {
            item.isVoted = !data.isVoted;
            this.localCache({
              storageKey: "fireStatus",
              user_id: userId,
              id: data.key,
              isSilencing: data.isVoted,
            }).then((res) => {
              console.log("存储火警后的返回值", res);
              if (
                res[userId].findIndex((item) => item.isSilencing == false) == -1
              ) {
                var parent = document.querySelector("body");
                var child = document.querySelector("audio");
                if (child) {
                  parent.removeChild(child);
                }
                // this.audio.pause();
              }
            });
          }
        });
      } else {
        var parent = document.querySelector("body");
        var child = document.querySelector("audio");
        if (child) {
          parent.removeChild(child);
        }
      }
      this.SET_ONFIRE_DATA(this.onFireData);
    },
    digitizingClick() {
      this.$emit("closeFireDialog");
      //   this.$message.info("数字化预案");
    },
    comeBack() {
      this.showVideoList = false;
    },
    timeValueStart() {
      clearInterval(this.timeoutNum);
      let secondNum = 0,
        minuteNum = 0;
      this.timeoutNum = setInterval(() => {
        if (secondNum < 59) {
          ++secondNum;
        } else {
          ++minuteNum;
          secondNum = 0;
        }
        let a = secondNum < 10 ? "0" + secondNum : secondNum;
        let b = minuteNum < 10 ? "0" + minuteNum : minuteNum;
        this.timeValue = b + ":" + a;
      }, 1000);
    },
    changeFireTabIndex(value, index) {
      this.activeIndex = index;
      this.$emit("fireTabItem", value);
    },
    fireTabChange(value) {
      this.$emit("fireTabChange", value);
    },
    closeDialog() {
      this.$emit("closeFireDialog");
    },
  },
  components: {
    fireAlarm,
  },
};
</script>

<style lang="scss" scoped>
.shade-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.dialog-view {
  position: absolute;
  z-index: 1000;
  top: 30%;
  left: 50%;
  margin-left: -2.05rem;
}

.gloable-fire-alarm-information {
  z-index: 1000;
  position: relative;
  .substance {
    // background: linear-gradient(-180deg, #f34d28 0%, #f34d28 100%);
    background: linear-gradient(-90deg, #FF0000 0%, #8F080C 100%);
    background-size: cover;
    // overflow: hidden;
    position: relative;
  }
  .fire-icon {
    width: 4rem /* 403/100 */;
    height: 4rem /* 400/100 */;
    position: absolute;
    left: 1.49rem /* 249/100 */;
    top: -1.2rem /* 80/100 */;
    background: url("../assets/image/fireIcon_02.png") no-repeat center;
    background-size: contain;
    // opacity: 0.1;
  }
}

.fire-alarm-operate {
  width: 5.1rem;
  transition: all ease-in-out 0.7s;
  display: none;
  margin-bottom: 10px;
  .fold-fire {
    background: rgba(243, 77, 40, 0.3);
    box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.3);
    padding: 0.03rem /* 3/100 */ 0.09rem /* 39/100 */ 0.03rem /* 3/100 */
      0.14rem /* 39/100 */;
    cursor: pointer;

    .icon {
      width: 0.12rem /* 12/100 */;
      height: 0.08rem /* 8/100 */;
      background: url("../assets/image/dashboard-fire-fold.svg") no-repeat
        center;
      background-size: contain;
      margin-top: 0.05rem /* 5/100 */;
      margin-right: 0.13rem /* 13/100 */;
    }

    .text {
      font-weight: 500;
      color: #fff;
      line-height: 0.18rem /* 18/100 */;
      letter-spacing: 0.03em;
    }
  }

  .tabs {
    padding-left: 0;
    margin-bottom: 0;
    .tab {
      // background: rgba(243, 77, 40, 0.3);
      list-style-type: none;
      font-weight: bold;
      color: #fff;
      font-size: 0.14rem /* 14/100 */;
      line-height: 0.17rem /* 17/100 */;
      position: relative;
      cursor: pointer;

      .text {
        position: absolute;
        top: -0.17rem;
        left: 0.2rem /* 20/100 */;
      }
    }

    .tab.other {
      width: 0.55rem /* 55/100 */;
      height: 0.18rem /* 18/100 */;
      background: rgba(243, 77, 40, 0.3);
      transform: skewX(-27deg);
      margin-right: 0.05rem;

      .text {
        top: 0.02rem /* 3/100 */;
        left: 0.23rem /* 23/100 */;
        transform: skewX(27deg);
      }
    }

    .tab.other.active {
      background: rgba(243, 77, 40, 1);
    }

    .tab.other.m-r-0 {
      margin-right: 0;
    }

    .tab:nth-child(1) {
      // height: 0;
      // width: 0.5rem;
      /* 50/100 */
      // border-top: 0.18rem /* 50/100 */ solid rgba(243, 77, 40, 0.3);
      // border-right: 0.09rem /* 9/100 */ solid transparent;
    }

    
    .tab:nth-last-child(1) {
      height: 0;
      width: 0.5rem;
      /* 50/100 */
      border-bottom: 0.18rem /* 50/100 */ solid rgba(243, 77, 40, 0.3);
      border-left: 0.09rem /* 9/100 */ solid transparent;

      .text {
        top: 0.02rem;
        left: 0.16rem /* 16/100 */;
      }
    }

    .tab:nth-last-child(1).active {
      border-bottom: 0.18rem /* 50/100 */ solid rgba(243, 77, 40, 1);
    }
  }
}

.fire-alarm-operate.unfold {
  display: block;
}

.build-color {
  color: #67d9fd;
}

.fire-color {
  color: #65deab;
}

.brigade-color {
  color: #6da5f8;
}

.bottom-line {
  width: 2px;
  height: 60px;
  background: white;
  z-index: 900;
  // top: 65%;
  // left: 50%;
  // margin-left: -1px;
  margin: 0 auto;
}

.bottom-close {
  width: 30px;
  height: 30px;
  background: url("../assets/image/fire-alarm-close.svg") no-repeat center;
  background-size: contain;
  z-index: 901;
  // top: 75%;
  // left: 50%;
  margin: 0 auto;
  cursor: pointer;
}
</style>
