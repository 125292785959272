import request from '@/utils/request'
import { message } from 'ant-design-vue'
import { buildListParams } from '@/utils';
import { downLoadFile } from "@/utils/download";

export const getTableList = ({ commit }, params) => {
  console.log('buildListParams(params.searchForm, params.pagination)',buildListParams(params.searchForm, params.pagination));
  return new Promise((resolve, reject) => {
    request({
      url: '/api-alarm/v1/alarm/data',
      method: 'get',
      params: buildListParams(params.searchForm, params.pagination),
    }).then(res => {
      if(res.success) {
        let result = res.result;
        commit('SET_TABLE_DATA', res.result.data)
        commit('SET_TABLE_PAGE_INFO', {
          pageNo: result.pageNo, 
          pageSize: result.pageSize, 
          total: Number(result.totalCount)})
        resolve(res);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const detail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/${params.id}`,
      method: 'get',
      params
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const create = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api-alarm/v1/alarm/dataDetail',
      method: 'post',
      data: params
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
export const edit = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/dataDetail/${params.id}`,
      method: 'put',
      data: params
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
export const deleteData = ({ commit }, keys) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-alarm/v1/alarm/dataDetail`,
      method: 'delete',
      data: {ids: keys}
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const deleteSingleData = ({ commit }, id) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-alarm/v1/alarm/dataDetail/${id}`,
      method: 'delete',
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}
//导出报警日志
export const exportExcelData = ({ commit }, params) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-alarm/v1/alarm/data/exportExcel`,
      method: 'get',
      params: buildListParams(params.searchForm, params.pagination),
    }).then(res => {
      if(res.success) {
        resolve(res);
        downLoadFile(res.result);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const updatePagination = ({commit}, params) => {
	return new Promise((resolve, reject) => {
		commit("SET_TABLE_PAGE_INFO", params);
		resolve();
	});
};

export const getAlarmHistory = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/dataDetail`,
			method: "get",
			params: params
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};