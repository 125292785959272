import Vue from 'vue'

export const SET_TABLE_DATA = (state, data) => {
  state.listDataSource = data;
}

export const SET_TABLE_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data }
}

export const SET_DICT_TYPE_DATA = (state, data) => {
  let currentData = Object.assign(state.listDataSource[data.index], {children: data.data, loadedChildren: true});
  state.listDataSource.splice(data.index, 1, currentData)
  
  state.listDataSource = state.listDataSource;
}

export const SET_DICT_DATA = (state, data) => {
  Vue.set(state.dict, data.dictCode, data.dictItems)
}



//注意：字典类型的名称中间必须是连起来的单词，例如roleType字典类型的必须是CLEAR_DICT_ROLETYPE_DATA这样的
// 因为此方法将会在字典管理页面使用for循环进行清除操作
export const SET_DICT_SEX_DATA = (state, data) => {
  state.sexDictList = data;
}

export const CLEAR_DICT_SEX_DATA = (state) => {
  state.sexDictList = [];
}

export const SET_DICT_RESOURCETYPE_DATA = (state, data) => {
  state.resourceTypeDictList = data;
}

export const CLEAR_DICT_RESOURCETYPE_DATA = (state) => {
  state.resourceTypeDictList = [];
}

export const SET_DICT_ROLETYPE_DATA = (state, data) => {
  state.roleTypeDictList = data;
}

export const CLEAR_DICT_ROLETYPE_DATA = (state) => {
  state.roleTypeDictList = [];
}
