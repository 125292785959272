import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

export const getTableList = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: "/api-uc/v1/uc/role",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					commit("SET_TABLE_DATA", res.result.data);
					commit("SET_TABLE_PAGE_INFO", {
						pageNo: result.pageNo,
						pageSize: result.pageSize,
						total: Number(result.totalCount),
					});
					reslove(res.result.data);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const detail = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/role/${params.id}`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const create = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: "/api-uc/v1/uc/role",
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const edit = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/role/${params.id}`,
			method: "put",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteData = ({ commit }, keys) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/role`,
			method: "delete",
			data: keys,
		})
			.then((res) => {
				if (res.success) {
					reslove(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const deleteSingleData = ({ commit }, id) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/role/${id}`,
			method: "delete",
		})
			.then((res) => {
				if (res.success) {
					reslove(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getRoleResourceData = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/role/res/${params.id}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					reslove(result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const setRoleResourceData = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/role/${params.id}/res`,
			method: "post",
			data: { action: "grant", resId: params.resId },
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					reslove(result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteRoleResourceData = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/role/${params.id}/res`,
			method: "post",
			data: { action: "revoke", resId: params.resId },
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					reslove(result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};
export const updatePagination = ({commit}, params) => {
	  return new Promise((reslove, reject) => {
	    commit("SET_TABLE_PAGE_INFO", params);
	    reslove();
	  });
	};
