/*
 * @Author: your name
 * @Date: 2020-07-13 16:07:24
 * @LastEditTime: 2020-07-24 14:09:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /iot-fire-front/src/views/company/property/store/state.js
 */ 
export const state = {
  listDataSource: [],
  allListDataSource: [],
  pagination: {
    pageNo: 1,
    pageSize: 10,
    total: 0
  }
}