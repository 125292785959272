import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/store";
import Antd from "ant-design-vue";
import viserVue from "viser-vue";
import iotIcon from "@/components/iotIcon/index.js";
import borderDecorate from "@/components/borderDecorate/index.js";

// import BaiduMap from 'vue-baidu-map'
import "./assets/style/index.scss";
import "./assets/style/template.scss";
import "ant-design-vue/dist/antd.less";
import "./assets/style/custom_ant_design_style.scss";
import "./utils/rem";
import "./permission";
import _ from "lodash";
import "./utils/filter";
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/candlestick";
import "echarts/lib/chart/bar";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import clipboard from 'clipboard';
import Moment from 'moment'
import scroll from 'vue-seamless-scroll'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 转移Element全局实例，跟antd分开
Vue.prototype.$eleMessage = Vue.prototype.$message;
Vue.prototype.$eleConfirm = Vue.prototype.$confirm;

// 定义全局时间戳过滤器
Vue.filter('formatDate', function(value) {
  return Moment(value).format('YYYY-MM-DD HH:mm:ss')
})
// require("./mock/index.js");

Vue.prototype._ = _;

Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(viserVue);
Vue.use(iotIcon);
Vue.use(borderDecorate);
Vue.use(scroll)
Vue.prototype.clipboard = clipboard;

Vue.component("chart", ECharts);
// Vue.use(BaiduMap, {
// 	ak: 'r5ESyfm2F37fbYUgdcVrZOVjazBHvbGR'
// })
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
