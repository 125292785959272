import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/layout'
import businessRouter from './_business_router_hs';

export const constantRouterMap = [
    {
        path: '/exception/403',
        name: 'Exception403',
        component: () => import('@/views/exception/403.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/login.vue'),
    },
    {
        path: '/loginVideo',
        name: 'loginVideo',
        component: () => import('@/views/login/loginVideo.vue'),
      },
    {
        path: '/reset',
        name: 'Reset',
        component: () => import('@/views/login/reset.vue'),
    },
    {
        path: '/',
        redirect: '/index/index',
        component: (resolve) => {
			require(["@/layout.vue"], resolve);
		},
    }
]

let routeList = businessRouter.concat(constantRouterMap);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routeList
});