import request from '@/utils/request'
import { message } from 'ant-design-vue'
import { buildListParams } from '@/utils';

export const getTableList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api-uc/v1/uc/company',
      method: 'get',
      params: buildListParams(params.searchForm, params.pagination),
    }).then(res => {
      if(res.success) {
        let result = res.result;
        commit('SET_TABLE_DATA', res.result.data)
        commit('SET_TABLE_PAGE_INFO', {
          pageNo: result.pageNo, 
          pageSize: result.pageSize, 
          total: Number(result.totalCount)})
        resolve(res.result.data);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const detail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/company/${params.id}`,
      method: 'get',
      params
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const create = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/company?action=${params.action}`,
      method: 'post',
      data: params.data,
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
export const edit = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/company/${params.data.id}?action=${params.action}`,
      method: 'put',
      data: params.data
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
export const deleteData = ({ commit }, keys) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-uc/v1/uc/company`,
      method: 'delete',
      data: keys
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const deleteSingleData = ({ commit }, id) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-uc/v1/uc/company/${id}`,
      method: 'delete',
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const updatePagination = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_TABLE_PAGE_INFO", params);
		reslove();
	});
};

//详情页生成账号
export const genUser = ({ commit }, params) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-uc/v1/uc/company/genUser`,
      method: 'post',
      data: params
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

//根据列管级别获取列管队伍 1-支队 2-大队 3-派出所
export const getTroop = ({ commit }, type) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-uc/v1/uc/company/troop/${type}`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

//向社会单位下发整改通知
export const sendText = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/company/sendAllUserMessage/${params.orgId}`,
      method: 'post',
      params: {message:params.message},
    })
      .then((res) => {
        if (res.success) {
          let result = res.result?res.result:'';
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

//社会单位批量导入
export const importExcel = ({ commit }, file) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-uc/v1/uc/company/import/excel`,
      method: 'post',
      data: file
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

//无权限控制的物业公司查询接口
export const getPropertyTableList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api-uc/v1/uc/propertyCompany/selectList',
      method: 'get',
      params: buildListParams(params.searchForm, params.pagination),
    }).then(res => {
      if(res.success) {
        let result = res.result;
       
        resolve(res.result.data);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};