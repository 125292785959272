export const SET_TABLE_DATA = (state, data) => {
  state.listDataSource = data;
}

export const SET_TABLE_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data }
}

export const SET_ALARM_SEARCH_FORM = (state, data) => {
  state.vueXAlarmSearchForm = { ...state.vueXAlarmSearchForm, ...data }
}
export const SET_OPERATE_SEARCH_FORM = (state, data) => {
  state.vueXOperateSearchForm = { ...state.vueXOperateSearchForm, ...data }
}
export const SET_RUN_SEARCH_FORM = (state, data) => {
  state.vueXRunSearchForm = { ...state.vueXRunSearchForm, ...data }
}
export const SET_SHIELD_SEARCH_FORM = (state, data) => {
  state.vueXShieldSearchForm = { ...state.vueXShieldSearchForm, ...data }
}