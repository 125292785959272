import { state } from './state'

import * as action from './action'

import * as formActions from './form_action'

import * as mutations from './mutation'

const actions = Object.assign(action,formActions)

export const company_social_fire = {
  namespaced: true,
  state,
  mutations,
  actions
}
