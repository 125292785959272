import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

export const getDetail = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-asset/v1/equipment/equipment/${id}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getRunLogDatas = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-thing/v1/thing/monitorDataDetail`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getOperateShieldLogDatas = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-thing/v1/thing/deviceOperationLog`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const equipmentStatistical = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-asset/v1/equipment/equipment/equipmentStatistical",
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const statisticsCompanyFault = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/statisticsCompanyFault/${
				params.pageSize
			}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const statisticsFault = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/statisticsFault/${
				params.startDate
			}/${params.endDate}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const inspectionStatistics = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/inspection/inspectionTask/selectTaskCount`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const alarmTypeStatistics = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/alarmTypeStatistics/${
				params.strDate
			}/${params.endDate}`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const statisticsOverview = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/rectification/reportVerification/statisticsOverview/${
				params.year
			}/${params.month}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};
