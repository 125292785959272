import request from '@/utils/request'
import { message } from 'ant-design-vue'
import { buildListParams } from '@/utils';

export const getTableList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api-uc/v1/uc/policeStation',
      method: 'get',
      params: buildListParams(params.searchForm, params.pagination),
    }).then(res => {
      if (res.success) {
        let result = res.result;
        commit('SET_TABLE_DATA', res.result.data)
        commit('SET_TABLE_PAGE_INFO', {
          pageNo: result.pageNo,
          pageSize: result.pageSize,
          total: Number(result.totalCount)
        })
        resolve(res.result.data);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const detail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/policeStation/${params.id}`,
      method: 'get',
      params
    }).then(res => {
      if (res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const create = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api-uc/v1/uc/policeStation',
      method: 'post',
      data: params
    }).then(res => {
      if (res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
export const edit = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/policeStation/${params.id}`,
      method: 'put',
      data: params
    }).then(res => {
      if (res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
export const deleteData = ({ commit }, keys) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/policeStation`,
      method: 'delete',
      data: { ids: keys }
    }).then(res => {
      if (res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const deleteSingleData = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/policeStation/${id}`,
      method: 'delete',
    }).then(res => {
      if (res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}
export const updatePagination = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    commit("SET_TABLE_PAGE_INFO", params);
    reslove();
  });
};
