<template>
	<div style="position: relative; ;" :class="classSet">
		<div
			class="border-decorate"
			style="border: 2px solid rgba(202,241,231,.3);"
		>
			<div class="left-top">
				<span class="row"></span>
				<span class="col"></span>
			</div>
			<div class="right-top">
				<span class="row"></span>
				<span class="col"></span>
			</div>
			<div class="left-bottom">
				<span class="row"></span>
				<span class="col"></span>
			</div>
			<div class="right-bottom">
				<span class="row"></span>
				<span class="col"></span>
			</div>
		</div>

		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "borderDecorate",
	props: {
		classSet: {
			type: String,
			default: "",
		},
	},
	computed: {},
};
</script>

<style scoped></style>
