export const state = {
  listDataSource: [],
  pagination: {
    pageNo: 1,
    pageSize: 10,
    totalCount: 0,
  },
  vueXAlarmSearchForm: {},
  vueXOperateSearchForm: {},
  vueXRunSearchForm: {},
  vueXShieldSearchForm: {}
}