import request from "@/utils/request";
import _ from "lodash";
import { buildListParams } from "@/utils";

//查询报警对应的摄像头
export const getByDeviceListOfDevice = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/api-asset/v1/equipment/equipment/getByDeviceId/"+params.deviceId,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//报警统计
export const getStatAlarm = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/api-alarm/v1/alarm/home/getStatAlarm",
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
///getStatDev  设备运行状态/撞他明细
export const getStatDev = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/api-asset/v1/alarm/home/getStatDev",
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//安防监控
export const getMonitorDev = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/api-asset/v1/alarm/home/getMonitorDev",
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//报警趋势
export const getAlarmTrend = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/api-alarm/v1/alarm/home/getAlarmTrend",
      method: "get",
      params : params
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//实时报警
export const getRealtimeAlarm = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/api-alarm/v1/alarm/home/getRealtimeAlarm",
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};





//报警总览
export const getAlarm = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/api-alarm/v1/alarm/data/stat/today",
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
