import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

const buildListData = function(data) {
	data.map(function(item) {
		if (item.parentId != "0") {
			let parent = data.find((i) => i.id == item.parentId);
			if (parent) {
				parent.children = parent.children
					? parent.children.concat(item)
					: [item];
			}
		}
	});

	console.log(data);
	return data;
};

export const getTableList = ({ commit, state }, params) => {
	
	if (state.listDataSource && state.listDataSource.length) {
		return state.listDataSource;
	}
	return new Promise((reslove, reject) => {
		request({
			url: "/api-uc/v1/uc/res",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = buildListData(res.result);
					console.log(
						"data",
						result.filter((i) => i.parentId == "0")
					);
					commit(
						"SET_TABLE_DATA",
						result.filter((i) => i.parentId == "0")
					);
					// commit('SET_TABLE_PAGE_INFO', {pageNo: result.pageNo, pageSize: result.pageSize, total: result.totalCount})
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const detail = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/res/${params.id}`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const create = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: "/api-uc/v1/uc/res",
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const edit = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/res/${params.id}`,
			method: "put",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteData = ({ commit }, keys) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/res`,
			method: "delete",
			data: keys,
		})
			.then((res) => {
				if (res.success) {
					reslove(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const deleteSingleData = ({ commit }, id) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/res/${id}`,
			method: "delete",
		})
			.then((res) => {
				if (res.success) {
					reslove(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};
export const updatePagination = ({commit}, params) => {
  return new Promise((reslove, reject) => {
    commit("SET_TABLE_PAGE_INFO", params);
    reslove();
  });
};
