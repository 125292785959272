import request from "@/utils/request";

export const getMonitorStatsCharts = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-thing/v1/thing/monitorData/monitorStatsCharts",
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};
