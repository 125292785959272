import Vue from 'vue';


export const SET_TABLE_DATA = (state, data) => {
  state.listDataSource = data;

  // Vue.set(state.listDataSource, data)
}

export const SET_TABLE_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data }
}