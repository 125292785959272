import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

export const getTableList = ({ commit }, params) => {
	console.log('----', params)
	return new Promise((reslove, reject) => {
		request({
			url: params.url ? params.url : "/api-uc/v1/uc/user/extend",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					commit("SET_TABLE_DATA", res.result.data);
					commit("SET_TABLE_PAGE_INFO", {
						pageNo: result.pageNo,
						pageSize: result.pageSize,
						total: Number(result.totalCount),
					});
					reslove(res.result.data);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const detail = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/user/${params.id}/extend`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const create = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: params.isExtend ? "/api-uc/v1/uc/user" : "/api-uc/v1/uc/user/extend",
			method: "post",
			data: params.data,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};


// 非消防组织机构创建用户时，需调用extend接口
export const edit = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: params.isExtend ? `/api-uc/v1/uc/user/${params.data.id}` : `/api-uc/v1/uc/user/extend/${params.data.id}`,
			method: "put",
			data: params.data,
		})
			.then((res) => {
				if (res.success) {
					reslove(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteData = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: params.isExtend ? '/api-uc/v1/uc/user' : `/api-uc/v1/uc/user/extend`,
			method: "delete",
			data: params.keys,
		})
			.then((res) => {
				if (res.success) {
					reslove(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const deleteSingleData = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: params.isExtend ? `/api-uc/v1/uc/user/${params.id}` : `/api-uc/v1/uc/user/extend/${params.id}`,
			method: "delete",
		})
			.then((res) => {
				if (res.success) {
					reslove(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getUserRole = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/user/${params.id}/roles`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					reslove(result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const setUserRole = ({ commit }, params) => {
	console.log("setUserRole:", params);
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/object/${params.id}`,
			method: "post",
			data: {
				action: "grant",
				roleId: params.roleId,
				objType: "sys_user",
			},
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					reslove(result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteUserRole = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		request({
			url: `/api-uc/v1/uc/object/${params.id}`,
			method: "post",
			data: {
				action: "revoke",
				roleId: params.roleId,
				objType: "sys_user",
			},
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					reslove(result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const updatePagination = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_TABLE_PAGE_INFO", params);
		reslove();
	});
};

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};