<template>
	<a-config-provider :locale="zh_CN">
		<div>
			<router-view v-if="_.includes(anAuth, $route.name)"></router-view>
			<Layout v-else></Layout>
		</div>
	</a-config-provider>
</template>

<script>
	import Layout from '@/views/layout/layout'
	import { getAccessToken, getRefreshToken, getUserId } from '@/utils/auth'
	import { mapGetters } from 'vuex';
	import request from '@/utils/request'
	import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
	import { setTimeout } from 'timers';
	import routeList from '@/router/_business_router';

	export default {
		name: 'App',
		data() {
			return {
				anAuth: ['login', 'Reset','loginVideo'],
				zh_CN
			}
		},
		components: {
			Layout
		},
		created() {
			// if(getAccessToken() && getUserId()) {
			// 	this.initUserInfo();
			// }
			this.setTimer();
		},
		computed: {
			...mapGetters([
				'roles',
				'addRouters'
			])
		},
		watch: {
			$route(to, from) {
				let crumbsList = this.buildRoute(to, routeList, []);
				this.$store.dispatch('setCrumbsData', crumbsList);
				this.$store.dispatch("setNowViewData",to)
			}
		},
		methods: {
			buildRoute(currentRoute, data, result) {
				let len = result.length;
				
				for(let i = 0; i < data.length; i++) {
					let item = data[i];
					if(item.path == currentRoute.path) {
						result.push(item);
						break;
					} else if(item.children) {
						let res = this.buildRoute(currentRoute, item.children, result);
						if(res.length > len) {
							result.push(item);
							break;
						}
					}
				}

				return result;
			},
			setTimer() {
				let self = this;

				setInterval(function() {
					if(getAccessToken() && getUserId()) {
						request({
							url: '/api-auth/v1/auth/reflashToken',
							method: 'post',
							data: {
								"accessToken": getAccessToken(),
								"refreshToken": getRefreshToken()
							}
						}).then(response => {
							if(response.success) {
								self.$store.dispatch('updateAccessToken', response.result.accessToken)
								self.$store.dispatch('updateRefreshToken', response.result.refreshToken)
							}
						});
					}
				}, 1000 * 60 * 10);
			},
		}
	}
</script>