export const state = {
  listDataSource: [],
  pagination: {
    pageNo: 1,
    pageSize: 10,
    total: 0,
    size:"small",
    showQuickJumper:true,
  },
  documentDetail: [], //电子化档案下表单详细数据
  documentType: '',
  companyId: '',
  vueXSearchForm: {}
}