import iotIcon from "./iotIcon.vue";


const iotIconComponent = {

    install: function(Vue){

        Vue.component('iotIcon',iotIcon)

    }
}

export default iotIconComponent