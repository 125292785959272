export const getters = {
	accessToken: state => state.user.accessToken,
	refreshToken: state => state.user.refreshToken,
	userInfo: state => state.user.userInfo,
	avatar: state => state.userInfo.avatar,
	name: state => state.userInfo.username,
	loginaccount: state => state.userInfo.account,
	userId: state => state.user.userId,
	introduction: state => state.user.introduction,
	status: state => state.user.status,
	roles: state => state.user.roles,
	setting: state => state.user.setting,
	permission_routers: state => state.permission.permissionRouter,
	menu_list: state => state.permission.menuList,
	errorLogs: state => state.errorLog.logs,
	tenantId: state => state.user.tenantId,
	permission: state => state.permission,
	manageTroopList: state => state.otherdata.manageTroopList,
	nowViewData: state => state.otherdata.nowViewData,
	

	alarmInfoDealViewType: state => state.Index.alarmInfoDealViewType, // 处理报警弹窗状态
}