import axios from 'axios'
import store from '@/store/store'
import { getAccessToken,getTenantId, removeAccessToken,removeRefreshToken } from '@/utils/auth'
import router from '@/router/index'
import { notification, message } from 'ant-design-vue'
import { getQueryVariable } from '@/utils'
let timer, timeStamp=0;
let messageFlag = true;
let run = ()=>{
    timer= setTimeout(()=>{
        if(messageFlag) {
            messageFlag = false;
            message.error('账号信息已过期，请重新登录',closeMessage);
        }
    }, 1000);
}
let clean = () => {
    clearTimeout(timer);
}

let closeMessage = () => {
    messageFlag = true;
}

// create an axios instance
console.warn('!!!!!!!!!!!!!!!', process.env.VUE_APP_BASE_API)

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // api的base_url
    timeout: 1000 * 60 * 30 // request timeout
});

// request interceptor
service.interceptors.request.use((config, e) => {
    var token = getAccessToken();
    if (token) {
        if(!config.headers['Authorization']) config.headers['Authorization'] = 'Bearer ' + getAccessToken() // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    }
    let tenantId = getTenantId();
    if(tenantId) {
        config.headers['x-tenant-header'] = tenantId;
    }
   
    return config
}, response => {
    // Do something with request error
    notification.error({
        message: response && response.data && response.data.error && response.data.error.errorMessage ? response.data.error.errorMessage : '系统错误',
    })
    console.error(JSON.stringify(config)+JSON.stringify(response))
    Promise.reject(response)
}, error => {
    // Do something with request error
    Promise.reject(response)
})

// respone interceptor
service.interceptors.response.use(
    response => {
        if(!response.data.success) {
            message.error(response && response.data && response.data.error && response.data.error.errorMessage ? response.data.error.errorMessage : '系统错误')
        }
        return response.data;
    },
    error => {
        if((error && error.response && error.response.status == 401) || (error && error.response && error.response.data.error.errorCode == 401)) {
            removeAccessToken();
            removeRefreshToken();
            
            let now = (new Date()).getTime();

            if(now-timeStamp < 1000){
                console.log('reset',now);
                clean();  // clear running timer 
                run();    // reset new timer from current time
            }else{
                console.log('set',now);
                run();    // last timer alreay executed, set a new timer
            }
            timeStamp=now;

            // message.error('账号信息已过期，请重新登录');
            // router.beforeEach((to, from, next) => {
            // })
                router.push({ path: '/login' });
            
            return Promise.reject(error)
        } else {
            message.error(error && error.data && error.data.error && error.data.error.errorMessage ? error.data.error.errorMessage : '系统错误')
        }

        return Promise.reject(error)
    })

export default service