/*
 * @Author: your name
 * @Date: 2020-07-13 16:07:24
 * @LastEditTime: 2020-07-16 18:55:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /iot-fire-front/src/views/company/property/store/index.js
 */ 
import { state } from './state'

import * as actions from './action'

import * as mutations from './mutation'

export const company_property= {
  namespaced: true,
  state,
  mutations,
  actions
}
