import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

var buildTreeData = function(data) {
	data.forEach((i) => {
		i.scopedSlots = { title: "custom" };
		if (i.children && i.children.length) {
			buildTreeData(i.children);
		}
	});
};

var buildDisableData = function(data, type, id) {
	let result = data.map((i) => {
		if (type == "enable") {
			i.disableCheckbox = false;
		} else {
			i.disableCheckbox = i.id == id ? false : true;
		}
		if (i.children) {
			i.children = [...buildDisableData(i.children, type, id)];
		}
		let current = Object.assign({}, i, {
			disableCheckbox: i.disableCheckbox,
			children: i.children,
		});
		return current;
	});
	return result;
};

export const getTableList = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-uc/v1/uc/org/tree",
			method: "get",
			// params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = [res.result];
					buildTreeData(result);
					commit("SET_TABLE_DATA", result);
					resolve(result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const buildDisabledOrg = ({ commit, state }, params) => {
	return new Promise((resolve, reject) => {
		let data = state.listDataSource;
		let result = buildDisableData(data, params.type, params.id);
		commit("SET_TABLE_DATA", result);
		resolve(result);
	});
};

export const detail = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-uc/v1/uc/org/${params.id}`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const create = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-uc/v1/uc/org",
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const edit = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-uc/v1/uc/org/${params.id}`,
			method: "put",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteData = ({ commit }, keys) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-uc/v1/uc/org`,
			method: "delete",
			data: keys,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const deleteSingleData = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-uc/v1/uc/org/${id}`,
			method: "delete",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};
