import request from "@/utils/request";
import _ from "lodash";
import { buildListParams } from "@/utils";

// 改变告警状态栏的折叠状态
export const changeStatusBarUnFoldStatus = ({ commit }, params) => {
  return commit("SET_STATUS_BAR_UNFOLD_STATUS", params);
};

// 改变告警地图的折叠状态
export const changeMapUnFoldStatus = ({ commit }, params) => {
  return commit("SET_MAP_UNFOLD_STATUS", params);
};

// 改变火警信息的折叠状态
export const changeFireAlarmUnFoldStatus = ({ commit }, params) => {
  return commit("SET_FIRE_ALARM_UN_FOLD_STATUS", params);
};
//查询报警和火警信息
export const getOnAlarmData = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/onAlarm`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          console.log("火警", res);
          commit("SET_ONALARM_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//报警总览
export const getAlarm = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "/api-alarm/v1/alarm/data/stat/today",
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//查询今日报警
export const getAlarmToday = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/todayAlarm/${params.startDate}/${params.endDate}/${params.addressId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          ``;
          commit("SET_ALARM_TODAY_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//查询今日故障
export const getFaultToday = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/todayFault/${params.startDate}/${params.endDate}/${params.addressId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          commit("SET_FAULT_TODAY_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//查询今日隐患
export const getDangerToday = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/danger/today/${params.addressId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          commit("SET_DANGER_TODAY_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//查询用于统计火警三级占比的数据
export const getAlarmLevel = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/level/${params.addressId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          commit("SET_ALARM_LEVEL_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//查询最近的报警数据
export const getAlarmLatestList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/latest/${params.startDate}/${params.endDate}/${params.addressId}?includeTypes=${params.includeTypes}&name=${params.name}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          commit("SET_ALARM_LATEST_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//查询30天报警趋势
export const getAlarmTrend = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/trend/${params.startDate}/${params.endDate}/${params.addressId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          commit("SET_ALARM_TREND_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//查询火警周边力量
export const getAroundPower = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/resource/${params.addressId}/${params.longitude}/${params.latitude}/${params.size}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          commit("SET_AROUND_THE_FIRE_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//查询周边力量
/*http://sc.zx-iot.highlands.ltd/api-alarm/v1/alarm/data/resource/0?includeTypes=building,fireBrigade
其中size=0表示不限制条数，都返回
includeTypes指需要查询哪些类型的周边力量，不传值表示查询所有的，
includeTypes的值见 swagger的api说明*/
export const getPeripheralForces = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/resource/${params.size}`,
      method: "get",
      params: _.omit(params, ["size"]),
    })
      .then((res) => {
        if (res.success) {
          commit("SET_AROUND_POWER_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updatePagination = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    commit("SET_TABLE_PAGE_INFO", params);
    resolve();
  });
};

// 查询火警点
export const fetchFireAlarmPoints = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/points/${params.startDate}/${params.endDate}/${params.addressId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          commit("SET_FIRE_ALARM_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// 查询建筑、单位等统计信息
export const fetchBuildUnitCount = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/resourceCount/${params.addressId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          console.log("建筑单位的res", res);
          commit("SET_BUILD_UNIT_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// 查询当前账号的管辖区域
export const fetchJurisdiction = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/org/current`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          commit("SET_JURISDICTION", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// 查询当前账号下的详细区域
export const fetchJurisdictionDetail = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-base/v1/base/area/${id ? id : "0"}/children`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          // console.log("前账号下的详细区域res", res);
          commit("SET_JURISDICTION_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//设置地图区域
export const setMapArea = ({ commit }, params) => {
  console.log("地图区域", params);
  return commit("SET_MAP_AREA", params);
};

// 改变今日报警的开关状态
export const changeAlarmSwitchStatus = ({ commit }, params) => {
  return commit("SET_ALARM_SWITCH_STATUS", params);
};

// 改变今日银黄的开关状态
export const changeHiddenDangerwitchStatus = ({ commit }, params) => {
  return commit("SET_HIDDEN_DANGER_SWITCH_STATUS", params);
};

// 改变今日故障的开关状态
export const changeErrorSwitchStatus = ({ commit }, params) => {
  return commit("SET_ERROR_SWITCH_STATUS", params);
};

// 按照行业统计单位数
export const fetchIndustryUnits = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/industry/${params.addressId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          // console.log("res", res);
          commit("SET_INDUSTRY_UNITS_DATA", res.result);
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// 地图周边力量过滤
export const filterAroundPower = ({ commit }, params) => {
  console.log("filterAroundPower", params);
  return commit("SET_FILTER_AROUND_POWER", params);
};
//查询火警
export const getOnFireData = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/onFire`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          console.log("火警", res);
          // commit("SET_ONFIRE_DATA", res.result.map(item=>{
          // 	return {
          // 		...item,
          // 		isVoted:false
          // 	}
          // }))
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//查询选定地址的公司
export const getCompanyBuildingByNameAndAddressId = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/getCompanyBuildingByNameAndAddressId/${params.addressId}?name=${params.name}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          console.log("地址的公司", res);

          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// 搜索周边力量
export const searchPeripheralForces = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/resource/${params.size}`,
      method: "get",
      params: _.omit(params, ["size"]),
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// 模拟登录
export const getMockLoginData = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-auth/v1/auth/mockLogin/${params.userId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          let result = res.result;
          reslove(result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// 派遣周边力量
export const sendPowerCall = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-alarm/v1/alarm/data/resource/dispatch/${params.alarmId}`,
      method: "post",
      data: params.data,
    })
      .then((res) => {
        if (res.success) {
          let result = res.result;
          reslove(result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//用于本地的local缓存
export const localCache = ({ commit }, value) => {
  let result = JSON.parse(localStorage.getItem(value.storageKey));
  if (result) {
    if (result.hasOwnProperty(value.user_id)) {
      let user = result[value.user_id];
      if (value.fireList) {
        for (var i = 0; i < user.length; i++) {
          if (
            value.fireList.findIndex((data) => data.key == user[i].id) == -1
          ) {
            user.splice(i, 1);
            i--;
          }
        }
      }
      let userIndex = user.findIndex((item) => item.id == value.id);
      if (userIndex != -1) {
        user[userIndex].isSilencing = value.isSilencing;
      } else {
        user.push({
          id: value.id,
          isSilencing: false,
        });
      }
    } else {
      result[value.user_id] = [];
      result[value.user_id].push({
        id: value.id,
        isSilencing: false,
      });
    }
  } else {
    result = {};
    result[value.user_id] = [];
    result[value.user_id].push({
      id: value.id,
      isSilencing: false,
    });
  }
  localStorage.setItem("fireStatus", JSON.stringify(result));
  return result;
};

export const updateSearchForm = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    commit("SET_SEARCH_FORM", params);
    reslove();
  });
};

//根据楼层id查询视频的设备
export const getFloorsEquipmentList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `api-asset/v1/equipment/equipment/equipmentListByFloor/${params.floorIds}`,
      method: "get",
      params: buildListParams({}, params.pagination),
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
