import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

export const getTableList = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-fire/v1/firebrigade/fireBrigade",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					commit("SET_TABLE_DATA", res.result.data);
					commit("SET_TABLE_PAGE_INFO", {
						pageNo: result.pageNo,
						pageSize: result.pageSize,
						total: Number(result.totalCount || 0),
					});
					resolve(res.result.data);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const detail = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/firebrigade/fireBrigade/${params.id}`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const create = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-fire/v1/firebrigade/fireBrigade",
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};
export const edit = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/firebrigade/fireBrigade/${params.id}`,
			method: "put",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};
export const deleteData = ({ commit }, keys) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/firebrigade/fireBrigade`,
			method: "delete",
			data: keys,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const deleteSingleData = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/firebrigade/fireBrigade/${id}`,
			method: "delete",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const updatePagination = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_TABLE_PAGE_INFO", params);
		reslove();
	});
};

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};
