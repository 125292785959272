import { state } from './state'

import * as actions from './action'

import * as mutations from './mutation'

export const property_team_member_manage= {
  namespaced: true,
  state,
  mutations,
  actions
}
