import request from '@/utils/request'
import { message } from 'ant-design-vue'
import { buildListParams } from '@/utils';
import { resolve } from 'core-js/fn/promise';

let getParent = function (data, id) {
  let parent = null;

  for(let i = 0; i < data.length; i++) {
    let item = data[i];
    if(item.id == id) {
      parent = item;
      break;
    } 
    if(item.children) {
      let parentData = getParent(item.children, id);
      if(parentData) parent = parentData;
    }
  }

  return parent;
}

export const getTableList = ({ commit, state }, id) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/area/${id ? id : '0'}/children`,
      method: 'get',
    }).then(res => {
      if(res.success) {
        let result = res.result.filter(i => {
          i.expand = false;
          i.children = [];
          // i.isLeaf = i.leaf;
          return true;
        });
        if(id) {
          let parent = getParent(state.listDataSource, id);
          if(parent) {
            parent.expand = !parent.expand;
            parent.children = result;
            parent.childNodes = result;
            commit('SET_TABLE_DATA', [...state.listDataSource]);
          } else {
            commit('SET_TABLE_DATA', result)
          }
          reslove(result);
        } else {
          commit('SET_TABLE_DATA', result)
          reslove(result);
        }
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const changeExpandAttr = ({ commit, state }, id) => {
  return new Promise((resolve, reject) => {
    let parent = getParent(state.listDataSource, id);
    parent.expand = !parent.expand;
    commit('SET_TABLE_DATA', state.listDataSource);
    resolve(parent);
  })
};
 
export const detail = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/area/${params.id}`,
      method: 'get',
      params
    }).then(res => {
      if(res.success) {
        reslove(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
 

 
export const create = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: '/api-base/v1/base/area',
      method: 'post',
      data: params
    }).then(res => {
      if(res.success) {
        reslove(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
 
 
export const edit = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/area/${params.id}`,
      method: 'put',
      data: params
    }).then(res => {
      if(res.success) {
        reslove(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
 
export const deleteData = ({ commit }, keys) => {
  return new Promise((reslove, reject) => {request({
      url: `/api-base/v1/base/area/4058069977868148888/children`,
      method: 'delete',
      data: {ids: keys}
    }).then(res => {
      if(res.success) {
        reslove(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const deleteSingleData = ({ commit }, id) => {
  return new Promise((reslove, reject) => {request({
      url: `/api-base/v1/base/area/${id}`,
      method: 'delete',
    }).then(res => {
      if(res.success) {
        reslove(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}
