<template>
<div>
    <div class="alarmDeal">
        <div class="alarmDeal-left">
            <div class="badge_list">
                <a-badge class="badge_list_one" :dot="showBadgeOne"/>
                <a-badge class="badge_list_two" :dot="showBadgeTwo"/>
            </div>
            <a-tabs :defaultActiveKey="showBadgeIndex" @change="callback">
                <a-tab-pane key="1" tab="未确认">
                   <div class="panescroll" :style="{overflowY:panescrollStye}" @scroll="scrollEvent" v-loading="loadingOne"
                        element-loading-text="玩命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(23, 49, 88, 0.8)">
                        <alarmInfoConfirmed @localItemData="localItemData" @checkActiveBadge='checkActiveBadge' 
                            :alarmDataList="alarmDataListOne" :activeBadgeIndex="activeBadgeOneIndex"></alarmInfoConfirmed>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="已确认" force-render>
                     <div class="panescroll" :style="{overflowY:panescrollStyeTwo}" @scroll="scrollEventTwo" v-loading="loadingTwo"
                        element-loading-text="玩命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(23, 49, 88, 0.8)">
                        <alarmInfoConfirmed @localItemData="localItemData" @checkActiveBadge='checkActiveBadge' 
                            :alarmDataList="alarmDataListTwo" :activeBadgeIndex="activeBadgeTwoIndex"></alarmInfoConfirmed>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div class="alarmDeal-right">
            <alarmInfoIndex ref="alarmInfoIndex" :showBadgeIndex="showBadgeIndex"  @getAlarmListBack='getAlarmListBack'
                :viewType="viewType" :detailInfo="detailInfo" :queryDeviceId="queryDeviceId" @clickDahuaShow='clickDahuaShow'></alarmInfoIndex>
        </div>
    </div>
        <div class="alertInfoBottom">
            <div class="infoBottomLeft">
                <p v-if="detailInfo&&detailInfo.erasure=='1'">已消音</p> 
                <!-- <p v-else @click="silenceAlarm">消音</p>  -->

                <span @click="gotoFire">查看更多></span>
            </div>
            <!-- <div class="infoBottomRight" v-if="viewType=='deal'">
                <p @click="handleClick">提交</p>
            </div> -->
        </div>
    </div>
</template>
<script>
import {createNamespacedHelpers,mapGetters,mapActions as mapOtherActions,mapState as mapOtherState,} from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("day_work_alarm");
import alarmInfoIndex from "./alarm_info_index.vue"
import alarmInfoConfirmed from "./alarm_info_confirmed.vue"
import request from "@/utils/request";
import { buildListParams ,getDictName} from "@/utils";
export default {
  name: "mapAlarmDeal",
  props : {
    viewType : {
        Type : String,
        default : ""
    },
    tabsActiveIndex : {
        Type : String,
        default : "1"
    },
  },
  components: {
      alarmInfoIndex,
      alarmInfoConfirmed
  },
  inject : ['loyoutSetBoxType'],
  data() {
    return {
     showBadgeOne : true,
     showBadgeTwo: true,
     loadingOne : false,
     loadingTwo : false,
     panescrollStye : "auto",
     panescrollStyeTwo : "auto",
     showBadgeIndex : "1",// tabs的显示未处理还是已确认   1 : 未确认tabs  2: 已确认tabs 
     activeBadgeOneIndex:0,
     activeBadgeTwoIndex:0,
     searchFormOne : {
        fireType: "",
        handlerStatus: "0",
        originalType : "",
        sort: "modifyTime-",
        alarmLatestTime : '2021-06-23 02:30:54'
     },
     paginationOne:{
        pageNo: 1,
		pageSize: 10,
		total: 0,
     },
     searchFormTwo : {
        fireType: "",
        handlerStatus: "1",
        originalType : "",
        sort: "modifyTime-",
        alarmLatestTime : "2021-06-23 02:30:54"
     },
     paginationTwo:{
        pageNo: 1,
		pageSize: 10,
		total: 0,
     },
     alarmDataListOne: [],
     alarmDataListTwo: [],
    queryId:"",
    detailInfo : {},//报警的具体操作,位置信息
    queryDeviceId :"",
    alarmInfo : "",//报警的设备信息
    };
  },
  created() {

        this.showBadgeIndex = this.tabsActiveIndex
        this.getLoadingOneList()
        this.getLoadingTwoList()
  },
  async mounted() {
		await this.getDict("alarmType");
		//跟进状态
		await this.getDict("alarmOpStatus");
		//确认状态
		await this.getDict("alarmDataStatus");
		// 报警类型 new
		await this.getDict("alarmTypeName");

	},
  methods: {
        ...mapActions(["detail", "dealAlarm"]),//day_work_alarm
	    ...mapOtherActions("base_dict", ["getDict"]),
        clickDahuaShow(item){
            this.$emit("showMapMaskCameraid",item)
        },
        gotoFire(){
            localStorage.setItem("currentKey", '/day_work/alarm/list');
            this.$emit("hiddenAlarmInfoDeal")
            this.$router.push("/alarm_center/alarm_log/list");
        },
        handleClick(){
            this.$refs.alarmInfoIndex.putin();
        },
        silenceAlarm(){
            request({
                url: `/api-thing/v1/thing/device/aepDown`,
                method: "post",
                data: {id: this.alarmInfo.deviceId}
            }).then((res) => {
                    if (res.success) {
                        resolve(res);
                        this.$message.success(res.result);
                    } else {
                        reject(res);
                    }
                }).catch((e) => {
                    reject(e);
                });
        },
        
        getDetail() {
            if(this.queryId){
                const MSG = this.$message.loading("数据请求中...", 0);
                this.detail({ id: this.queryId })
				.then((data) => {
                    MSG();
                    this.detailInfo = data;
                    this.detailInfo.companyAddressName = data.alarmAddressName + data.alarmAptSuite;
                    this.detailInfo.alarmAddrName =  data.companyName + "-" + data.buildingName
                    this.queryDeviceId = this.detailInfo.deviceId
				})
				.catch((e) => {
					MSG();
					this.$message.error("请求失败" + `${e.message ? ":" + e.message : ""}`);
				});
            }else{
                this.detailInfo = null,
                this.queryDeviceId = null
            }
			
		},
      localItemData(item){
          if(!item.dataStatus){
              this.updateDataStatus(item).then(res=>{}) //设置已读
          }
          
          if(this.showBadgeIndex=="1"){
                for(var i=0;i<this.alarmDataListOne.length;i++){
                    if(this.alarmDataListOne[i].id==item.id){
                        this.alarmDataListOne[i].dataStatus = true
                    }
                }
            }else{    
                for(var i=0;i<this.alarmDataListTwo.length;i++){
                    if(this.alarmDataListTwo[i].id==item.id){
                        this.alarmDataListTwo[i].dataStatus = true
                    }
                }
            }
            this.fitlerShowBadge()
          
      },
      fitlerShowBadge(){
              this.showBadgeOne = this.alarmDataListOne.some((item,index,array) => {
                  return !item.dataStatus
              })
              this.showBadgeTwo = this.alarmDataListTwo.some((item,index,array) => {
                  return !item.dataStatus
              })
      },
      checkActiveBadge(index){
          if(this.showBadgeIndex=="1"){
              this.activeBadgeOneIndex = index;
              if(this.queryId == this.alarmDataListOne[this.activeBadgeOneIndex].id){
                  return
              }
              this.queryId = this.alarmDataListOne[this.activeBadgeOneIndex].id
          }else{
              this.activeBadgeTwoIndex = index;
              if(this.queryId == this.alarmDataListTwo[this.activeBadgeTwoIndex].id){
                  return
              }
              this.queryId = this.alarmDataListTwo[this.activeBadgeTwoIndex].id
          }
        this.getDetail()
      },
      updateDataStatus(item){
          return new Promise((resolve, reject) => {
            request({
                url: `/api-alarm/v1/alarm/data/updateDataStatus/${item.id}`,
                method: "PUT"
            }).then((res) => {
                    if (res.success) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch((e) => {
                    reject(e);
                });
        });
      },
      getLoadingOneList(){
          this.getAlarmDataList(this.searchFormOne, this.paginationOne).then(res =>{
              if(this.paginationOne['pageNo']==1){
                this.alarmDataListOne = res.data
              }else{
                   this.alarmDataListOne = this.alarmDataListOne.concat(res.data);
              }
                this.paginationOne = {
                    pageNo: res.pageNo,
                    pageSize: res.pageSize,
                    total: Number(res.totalCount),
                };
                this.fitlerShowBadge();
                if(this.showBadgeIndex=="1"){
                    // this.activeBadgeOneIndex = 0
                    this.queryId =this.alarmDataListOne.length<1?null : this.alarmDataListOne[this.activeBadgeOneIndex].id;
                    this.getDetail()
                }
                
          })
      },
      getLoadingTwoList(){
          this.getAlarmDataList(this.searchFormTwo, this.paginationTwo).then(res =>{
              if(this.paginationTwo['pageNo']==1){
                this.alarmDataListTwo = res.data
              }else{
                this.alarmDataListTwo = this.alarmDataListTwo.concat(res.data);
              }
                this.paginationTwo = {
                    pageNo: res.pageNo,
                    pageSize: res.pageSize,
                    total: Number(res.totalCount),
                };
                this.fitlerShowBadge()
                if(this.showBadgeIndex=="2"){
                    this.queryId = this.alarmDataListTwo.length<1?null : this.alarmDataListTwo[this.activeBadgeOneIndex].id;
                    this.getDetail()
                }
                
          })

      },
      getAlarmDataList(searchForm,pagination){
        return new Promise((resolve, reject) => {
            request({
                url: "/api-alarm/v1/alarm/data",
                method: "get",
                params: buildListParams(searchForm, pagination),
            }).then((res) => {
                    if (res.success) {
                        let result = res.result;
                        resolve(res.result);
                    } else {
                        reject(res);
                    }
                }).catch((e) => {
                    reject(e);
                });
        });
      },
    scrollEvent(e) {
        if (e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight ) {
            this.paginationOne["pageNo"] += 1;
            this.getLoadingOneList()

        }
    },

    scrollEventTwo(e) {
        if (e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight ) {
            this.paginationTwo["pageNo"] += 1;
            this.getLoadingTwoList()
        }
    },
    getAlarmListBack(){
        this.callback(this.showBadgeIndex)
    },
    callback(key) {
        this.paginationOne.pageNo = 1;
        this.paginationTwo.pageNo = 1;
        this.showBadgeIndex = key;
        if(this.showBadgeIndex=="1"){
            this.viewType = "deal"
            this.alarmDataListOne = [];
            this.activeBadgeOneIndex = 0
            this.getLoadingOneList()
        }else{    
            this.viewType = "deal"
            this.alarmDataListTwo = [];
            this.activeBadgeTwoIndex = 0;
            this.getLoadingTwoList()
        }
    },
  },
 
};
</script>

<style lang="less">
.alertInfoBottom{
        height:0.66rem;
        background: #122644;
        display:flex;
        .infoBottomLeft{
             width: 3.35rem;
            height:100%;
            border-right: 1px solid #1A3E5D;
            >p{
                width: 1.2rem;
                height: .4rem;
                background: #52CFFF;
                border-radius: .20rem;
                line-height:0.4rem;
                text-align:center;
                color: #000;
                float:left;
                margin-top:0.15rem;
                margin-left: 0.21rem;
                cursor: pointer;
            }
            >p:nth-child(0){
                background: #e2e2e2;
            }
            >span{
                font-size: 0.14rem;
                color: #9DB5D3;
                line-height:0.66rem;
                float:right;
                cursor: pointer;
                margin-right: 0.21rem;
            }
        }
        .infoBottomRight{
            width:9.65rem;
            height:100%;
            text-align: center;
        >p{
                width: 1.2rem;
                height: .4rem;
                background: #52CFFF;
                border-radius: .20rem;
                line-height:0.4rem;
                text-align:center;
                color: #000;
                margin: 0.15rem auto;
                cursor: pointer;
        }
        }
    }
.alarmDeal{
    display:flex;
    height:7rem;
    border-bottom: 1px solid #1A3E5D;
    .el-loading-mask{
        right: 0.15rem;
    }
    .ant-badge-dot{
        width: 0.1rem;
        height: 0.1rem;
    }
    .alarmDeal-left{
        width: 3.35rem;
        height:100%;
        border-right: 1px solid #1A3E5D;
    }
    .alarmDeal-right{
        width:9.65rem;
        height:100%;
    }
}
.alarmDeal-left{
    position: relative;
    .badge_list{
        .badge_list_one{
            position: absolute;
            top: 0.2rem;
            left: 1.4rem;
            
        }
        .badge_list_two{
            position: absolute;
            top: 0.2rem;
            right: 0.8rem;
        }
    }
  .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane, .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane{
    padding: 0 0rem 0 0.21rem;
    min-height: 6rem;
   
    .panescroll{
        height: 6.2rem;
        overflow: hidden auto;
        overflow-x: hidden;
        padding-right: 0.21rem;
    }
  }
    .ant-tabs-ink-bar{
        background-color:#52CFFF;
        height:1px;
    }
    .ant-modal-content,.ant-modal-header{
        background-color:#122644;
    }
    .ant-modal-header{
         padding : 0.14rem .24rem;
         border-bottom: 0px solid #122644;
         .ant-modal-title{
             text-align : left;
         }
    }
   .ant-modal-body{
       padding :0;
       .ant-tabs-nav-wrap{
           text-align:center;
           background-color:#0E1D34;
           .ant-tabs-nav {
               .ant-tabs-tab{
                    padding: .21rem .16rem .13rem .16rem;
                }
                .ant-tabs-tab{
                    color: #0479AD;
                    font-size: 0.14rem;
                }
                .ant-tabs-tab-active{
                    color: #52CFFF;
                    font-size: 0.18rem;
                }
           }
       }
       
   }
}
</style>
<style lang="less" scoped>
    
</style>