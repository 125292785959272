/*
 * @Author: your name
 * @Date: 2020-07-21 09:34:27
 * @LastEditTime: 2020-07-22 09:55:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /iot-fire-front/src/store/store.js
 */

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { baseModule } from "./index";
import { getters } from "./getters";
import { Dashboard } from "@/views/dashboard/module";
import { system_user } from "@/views/system/user/store";
import { firecontrol_joint_drill_overview } from "@/views/firecontrol_joint/drill_overview/store";
import { firecontrol_joint_fire_hazard } from "@/views/firecontrol_joint/fire_hazard/store";
import { safe_edu_article } from "@/views/safe_edu/article/store";
import { safe_edu_video } from "@/views/safe_edu/video/store";
import { system_role } from "@/views/system/role/store";
import { system_resource } from "@/views/system/resource/store";
import { base_dict } from "@/views/base/dict/store";
import { base_area } from "@/views/base/area/store";
import { system_org } from "@/views/system/org/store";
import { supervision_report } from "@/views/supervision/report/store";
import { base_police_station } from "@/views/base/police_station/store";
import { company_property } from "@/views/company/property/store";
import { company_maintenance } from "@/views/company/maintenance/store";
import { construct_building } from "@/views/construct/building/store";
import { construct_construction_area } from "@/views/construct/construction_area/store";
import { inspection_configuration_inspection_item } from "@/views/inspection_configuration/inspection_item/store";
import { inspection_configuration_inspection_type } from "@/views/inspection_configuration/inspection_type/store";
import { monitor_equipment } from "@/views/monitor/equipment/store";
import { monitor_equipment_detail } from "@/views/monitor/equipment/detail/store";
import { alarm_center_alarm_log } from "@/views/alarm_center/alarm_log/store";
import { basic_configuration_fire_control_room } from "@/views/basic_configuration/fire_control_room/store";
import { inspection_point } from "@/views/day_work/inspection/point/store";
import { inspection_plan } from "@/views/day_work/inspection/plan/store";
import { inspection_task } from "@/views/day_work/inspection/task/store";
import { day_work_maintenance_contract } from "@/views/day_work/maintenance_contract/store";
import { day_work_alarm } from "@/views/day_work/alarm/store";
import { alarm_center_overview } from "@/views/alarm_center/overview/store";
import { basic_configuration_property_team } from "@/views/basic_configuration/property_team/store";
import { supervision_f3Danger } from "@/views/supervision/f3Danger/store";
import { company_social_fire } from "@/views/company/social_fire/store";
import { property_team_member_manage } from "@/views/basic_configuration/property_team/member_manage/store";
import { system_config_op_config } from "@/views/system_config/op_config/store";
import { monitor_alarm } from "@/views/monitor/alarm/store";
import { monitor_electrical_fire } from "@/views/monitor/electrical_fire/store";
import { monitor_gas } from "@/views/monitor/gas/store";
import { monitor_indoor } from "@/views/monitor/indoor/store";
import { monitor_center_data } from "@/views/monitor_center/data/store";
import { monitor_equipment_diagram } from "@/views/monitor_center/equipment_diagram/store";
import { monitor_water } from "@/views/monitor/water/store";
import { monitor_video } from "@/views/monitor/video/store";
import { monitor_electrical } from "@/views/monitor/electrical/store";
import { monitor_info_transmission } from "@/views/monitor/info_transmission/store";
import { monitor_municipal_hydrant } from "@/views/monitor/municipal_hydrant/store";
import { monitor_outdoor_hydrant } from "@/views/monitor/outdoor_hydrant/store";
import { monitor_smoke } from "@/views/monitor/smoke/store";
import { statistics } from "@/views/statistics/store";

import { h5_report_fire_brigade } from "@/views/h5_report/fire_brigade/store";
import { h5_report_fire_hydrant } from "@/views/h5_report/fire_hydrant/store";
import { system_td_log } from "@/views/system/td_log/store";
import { message_base_message } from "@/views/message/base_message/store";
import { remoteVideo_video_menu } from "@/views/remote_video/video_menu/store";
import { Home } from "@/views/home/module";
import { Index } from "@/views/index/module";

export default new Vuex.Store({
	modules: {
		...baseModule,
		Dashboard,
		system_user,
		system_role,
		system_resource,
		base_dict,
		base_area,
		firecontrol_joint_drill_overview,
		firecontrol_joint_fire_hazard,
		safe_edu_article,
		safe_edu_video,
		system_org,
		supervision_report,
		base_police_station,
		company_property,
		company_maintenance,
		construct_building,
		construct_construction_area,
		inspection_configuration_inspection_item,
		inspection_configuration_inspection_type,
		monitor_equipment,
		monitor_equipment_detail,
		alarm_center_alarm_log,
		basic_configuration_fire_control_room,
		inspection_point,
		inspection_plan,
		inspection_task,
		day_work_maintenance_contract,
		day_work_alarm,
		alarm_center_overview,
		basic_configuration_property_team,
		supervision_f3Danger,
		company_social_fire,
		property_team_member_manage,
		system_config_op_config,
		monitor_alarm,
		monitor_electrical_fire,
		monitor_gas,
		monitor_indoor,
		monitor_center_data,
		monitor_equipment_diagram,
		monitor_water,
		monitor_video,
		monitor_electrical,
		monitor_info_transmission,
		monitor_municipal_hydrant,
		monitor_outdoor_hydrant,
		monitor_smoke,
		statistics,
		h5_report_fire_brigade,
		h5_report_fire_hydrant,
		system_td_log,
		message_base_message,
		remoteVideo_video_menu,
		Home,Index
	},
	getters,
});
