export const SET_TABLE_DATA = (state, data) => {
	state.listDataSource = data;
};

export const CLEAR_TABLE_DATA = (state, data) => {
	state.listDataSource = [];
};

export const SET_TABLE_PAGE_INFO = (state, data) => {
	state.pagination = { ...state.pagination, ...data };
};
