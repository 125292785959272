import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";
import { downLoadFile } from "@/utils/download";
import qs from "qs";

export const getTableList = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-alarm/v1/alarm/data",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					commit("SET_TABLE_DATA", res.result.data);
					commit("SET_TABLE_PAGE_INFO", {
						pageNo: result.pageNo,
						pageSize: result.pageSize,
						total: Number(result.totalCount),
					});
					resolve(res.result.data);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const detail = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/${params.id}`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const create = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-alarm/v1/alarm/data",
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const edit = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/${params.id}`,
			method: "put",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteData = ({ commit }, keys) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data`,
			method: "delete",
			data: keys,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const deleteSingleData = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/${id}`,
			method: "delete",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getAttachInfo = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-base/v1/base/attach/${params.id}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getFileAddress = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-base/v1/base/attach/sign/${id}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const exportFile = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/exportExcel`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
					downLoadFile(res.result);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const dealAlarm = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/${params.id}/${params.fireAlarm}`,
			method: "put",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};

export const updateFaultSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_FAULT_SEARCH_FORM", params);
		reslove();
	});
};

export const updateTellSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_TELL_SEARCH_FORM", params);
		reslove();
	});
};

export const updatePagination = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_TABLE_PAGE_INFO", params);
		reslove();
	});
};