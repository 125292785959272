import errorLog from './modules/errorLog'
import permission from './modules/permission'
import user from './modules/user'
import otherdata from './modules/otherdata'
export * from './getters'

export const baseModule = {
	errorLog,
	permission,
	user,
	otherdata
}