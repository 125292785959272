import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

export const getTableList = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-fire/v1/duty/jointHazard",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					let result = res.result;
					commit("SET_TABLE_DATA", res.result.data);
					commit("SET_TABLE_PAGE_INFO", {
						pageNo: result.pageNo,
						pageSize: result.pageSize,
						total: Number(result.totalCount),
					});
					resolve(res.result.data);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const detail = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard/${params.id}`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const create = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-fire/v1/duty/jointHazard",
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const edit = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard/${params.id}`,
			method: "put",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const deleteData = ({ commit }, keys) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard`,
			method: "delete",
			data: keys,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const deleteSingleData = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard/${id}`,
			method: "delete",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getCompanys = ({ commit }) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-uc/v1/uc/company/searchWithFireOrg`,
			method: "get",
			params: {
				pageNo: 1,
				pageSize: 500,
			},
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getStatusTotalList = ({ commit }) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard/totalList`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getCurrentOrg = ({ commit }) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-uc/v1/uc/org/current`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const updateBatchStatus = ({ commit }, data) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard/updateByNoSumbit`,
			method: "put",
			data: data.keys,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const updateDealWithStatus = ({ commit }, data) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard/updateByDealWith`,
			method: "put",
			data: data.keys,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const reviewHazard = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard/commitReviewInfo/${params.id}`,
			method: "post",
			data: params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};
export const updatePagination = ({ commit }, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_TABLE_PAGE_INFO", params);
		reslove();
	});
};

//导出
export const exportExcel = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/duty/jointHazard/exportExcel`,
			method: "get",
			params
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};
