import router from "./router/index";
import store from "./store/store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getAccessToken } from "@/utils/auth"; // getToken from cookie
import routeList from "@/router/_business_router";

NProgress.configure({
  showSpinner: true,
}); // NProgress Configuration

// permissiom judge function
// function hasPermission(roles, permissionRoles) {
// 	if(roles.indexOf('admin') >= 0) return true // admin permission passed directly
// 	if(!permissionRoles) return true
// 	return roles.some(role => permissionRoles.indexOf(role) >= 0)
// }

const whiteList = ["login", "/authredirect", "Reset", "loginVideo"]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  NProgress.start(); // start progress bar
  next();
  if (getAccessToken()) {
    // determine if there has token
    /* has token*/
    // console.log("有getAccessToken信息")
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      // console.log("没有getAccessToken信息")
      next();
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.name) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
        next("/login"); // 否则全部重定向到登录页
        NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});

router.afterEach((to, from, next) => {
  NProgress.done(); // finish progress bar
});
