import request from "@/utils/request";
import { buildListParams } from "@/utils";

export const getMonitorData = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-thing/v1/thing/monitorData",
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getMonitorStats = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-thing/v1/thing/monitorData/monitorStats",
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getMonitorStatsCharts = ({ commit }, params) => {
	console.log(buildListParams(params.searchForm, params.pagination))
	return new Promise((resolve, reject) => {
		request({
			url: "/api-thing/v1/thing/monitorData/monitorStatsCharts",
			method: "get",
			params
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getBuilding = ({ commit }, name) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-asset/v1/building`,
			method: "get",
			params: {
				pageNo: 1,
				pageSize: 500,
				buildingName: name,
			},
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const getCompanys = ({ commit }, name) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-uc/v1/uc/company`,
			method: "get",
			params: {
				pageNo: 1,
				pageSize: 500,
				companyName__like: name,
			},
		})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject();
				}
			})
			.catch((e) => {
				reject();
			});
	});
};

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};