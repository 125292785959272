import { state } from './state'

import * as actions from './action'

import * as mutations from './mutation'

export const remoteVideo_video_menu= {
  namespaced: true,
  state,
  mutations,
  actions
}
