import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

export const getDetail = ({ commit }, id) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-asset/v1/equipment/equipment/${id}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getRunLogDatas = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-thing/v1/thing/monitorDataDetail`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getOperateShieldLogDatas = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-thing/v1/thing/deviceOperationLog`,
			method: "get",
			params,
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getIndicatorsType = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-thing/v1/thing/monitorData/getIndicatorsType",
			method: "get",
			params: buildListParams(params.searchForm, params.pagination),
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const updateAlarmSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_ALARM_SEARCH_FORM", params);
		reslove();
	});
};
export const updatOperateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_OPERATE_SEARCH_FORM", params);
		reslove();
	});
};
export const updateRunSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_RUN_SEARCH_FORM", params);
		reslove();
	});
};
export const updateShieldSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SHIELD_SEARCH_FORM", params);
		reslove();
	});
};