/*
 * @Author: your name
 * @Date: 2020-07-13 16:07:24
 * @LastEditTime: 2020-07-24 14:10:05
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /iot-fire-front/src/views/company/property/store/mutation.js
 */ 
export const SET_TABLE_DATA = (state, data) => {
  state.listDataSource = data;
}

export const SET_ALL_TABLE_DATA = (state, data) => {
  state.allListDataSource = data;
}

export const SET_TABLE_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data }
}