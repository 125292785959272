export const SET_TABLE_DATA = (state, data) => {
  state.listDataSource = data;
}

export const SET_TABLE_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data }
}

export const SET_FIRE_FIGHTING_FACILITIE = (state, data) => {
  state.fireFightingFacilitie = data;
}

export const SET_SEARCH_FORM = (state, data) => {
  state.vueXSearchForm = { ...state.vueXSearchForm, ...data }
}