export const state = {
  vueXSearchForm: {
    systemType__eq: "",
    buildingId__eq: "",
    companyId__eq: "",
    floorId__eq: "",
    equipmentTypeId__eq: "",
    equipmentName__like: "",
    overThreshold__eq: "",
    status__eq: "",
    createTime: "7",
    sort: "createTime-",
    dateRange: [],
  },
};
