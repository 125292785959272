import moment from "moment";


//两个时间差   计算时间差(天、小时、分钟、秒)
/**
 *  第一种："2023/08/28 04:56:38"//这种格式不用再进行处理
    第二种："2023-08-01 18:56:38"//这种格式就要进行处理
 * ***/
export function leadTime(d1, d2) {
	//如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
    let dateBegin = new Date(d1.replace(/-/g, "/"));//将-转化为/，使用new Date
    let dateEnd = d2;//
    let dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差的毫秒数
    let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
    let leave1=dateDiff%(24*3600*1000)    //计算天数后剩余的毫秒数
    let hours=Math.floor(leave1/(3600*1000))//计算出小时数
    //计算相差分钟数
    let leave2=leave1%(3600*1000)    //计算小时数后剩余的毫秒数
    let minutes=Math.floor(leave2/(60*1000))//计算相差分钟数
    //计算相差秒数
    let leave3=leave2%(60*1000)      //计算分钟数后剩余的毫秒数
	let seconds = Math.round(leave3 / 1000)
	
	let leadText = dayDiff+"天"+hours+"小时"+minutes+"分钟"+seconds+"秒"

    // console.log(" 相差 "+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒")
    // console.log(dateDiff+"时间差的毫秒数",dayDiff+"计算出相差天数",leave1+"计算天数后剩余的毫秒数"
    //     ,hours+"计算出小时数",minutes+"计算相差分钟数",seconds+"计算相差秒数");
	return leadText;

}
/**
 * Created by jiachenpan on 16/11/18.
 */

export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null;
	}
	const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
	let date;
	if (typeof time === "object") {
		date = time;
	} else {
		if (("" + time).length === 10) time = parseInt(time) * 1000;
		date = new Date(time);
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
	};
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key];
		if (key === "a")
			return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
		if (result.length > 0 && value < 10) {
			value = "0" + value;
		}
		return value || 0;
	});
	return time_str;
}

export function formatTime(time, option) {
	time = +time * 1000;
	const d = new Date(time);
	const now = Date.now();

	const diff = (now - d) / 1000;

	if (diff < 30) {
		return "刚刚";
	} else if (diff < 3600) {
		// less 1 hour
		return Math.ceil(diff / 60) + "分钟前";
	} else if (diff < 3600 * 24) {
		return Math.ceil(diff / 3600) + "小时前";
	} else if (diff < 3600 * 24 * 2) {
		return "1天前";
	}
	if (option) {
		return parseTime(time, option);
	} else {
		return (
			d.getMonth() +
			1 +
			"月" +
			d.getDate() +
			"日" +
			d.getHours() +
			"时" +
			d.getMinutes() +
			"分"
		);
	}
}

// 格式化时间
export function getQueryObject(url) {
	url = url == null ? window.location.href : url;
	const search = url.substring(url.lastIndexOf("?") + 1);
	const obj = {};
	const reg = /([^?&=]+)=([^?&=]*)/g;
	search.replace(reg, (rs, $1, $2) => {
		const name = decodeURIComponent($1);
		let val = decodeURIComponent($2);
		val = String(val);
		obj[name] = val;
		return rs;
	});
	return obj;
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getByteLen(val) {
	let len = 0;
	for (let i = 0; i < val.length; i++) {
		if (val[i].match(/[^\x00-\xff]/gi) != null) {
			len += 1;
		} else {
			len += 0.5;
		}
	}
	return Math.floor(len);
}

export function cleanArray(actual) {
	const newArray = [];
	for (let i = 0; i < actual.length; i++) {
		if (actual[i]) {
			newArray.push(actual[i]);
		}
	}
	return newArray;
}

export function param(json) {
	if (!json) return "";
	return cleanArray(
		Object.keys(json).map((key) => {
			if (json[key] === undefined) return "";
			return (
				encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
			);
		})
	).join("&");
}

export function param2Obj(url) {
	const search = url.split("?")[1];
	if (!search) {
		return {};
	}
	return JSON.parse(
		'{"' +
			decodeURIComponent(search)
				.replace(/"/g, '\\"')
				.replace(/&/g, '","')
				.replace(/=/g, '":"') +
			'"}'
	);
}

export function html2Text(val) {
	const div = document.createElement("div");
	div.innerHTML = val;
	return div.textContent || div.innerText;
}

export function objectMerge(target, source) {
	/* Merges two  objects,
	   giving the last one precedence */

	if (typeof target !== "object") {
		target = {};
	}
	if (Array.isArray(source)) {
		return source.slice();
	}
	Object.keys(source).forEach((property) => {
		const sourceProperty = source[property];
		if (typeof sourceProperty === "object") {
			target[property] = objectMerge(target[property], sourceProperty);
		} else {
			target[property] = sourceProperty;
		}
	});
	return target;
}

export function scrollTo(element, to, duration) {
	if (duration <= 0) return;
	const difference = to - element.scrollTop;
	const perTick = (difference / duration) * 10;
	setTimeout(() => {
		// console.log(new Date())
		element.scrollTop = element.scrollTop + perTick;
		if (element.scrollTop === to) return;
		scrollTo(element, to, duration - 10);
	}, 10);
}

export function toggleClass(element, className) {
	if (!element || !className) {
		return;
	}
	let classString = element.className;
	const nameIndex = classString.indexOf(className);
	if (nameIndex === -1) {
		classString += "" + className;
	} else {
		classString =
			classString.substr(0, nameIndex) +
			classString.substr(nameIndex + className.length);
	}
	element.className = classString;
}

export const pickerOptions = [
	{
		text: "今天",
		onClick(picker) {
			const end = new Date();
			const start = new Date(new Date().toDateString());
			end.setTime(start.getTime());
			picker.$emit("pick", [start, end]);
		},
	},
	{
		text: "最近一周",
		onClick(picker) {
			const end = new Date(new Date().toDateString());
			const start = new Date();
			start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
			picker.$emit("pick", [start, end]);
		},
	},
	{
		text: "最近一个月",
		onClick(picker) {
			const end = new Date(new Date().toDateString());
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			picker.$emit("pick", [start, end]);
		},
	},
	{
		text: "最近三个月",
		onClick(picker) {
			const end = new Date(new Date().toDateString());
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
			picker.$emit("pick", [start, end]);
		},
	},
];

export function getTime(type) {
	if (type === "start") {
		return new Date().getTime() - 3600 * 1000 * 24 * 90;
	} else {
		return new Date(new Date().toDateString());
	}
}

export function debounce(func, wait, immediate) {
	let timeout, args, context, timestamp, result;

	const later = function() {
		// 据上一次触发时间间隔
		const last = +new Date() - timestamp;

		// 上次被包装函数被调用时间间隔last小于设定时间间隔wait
		if (last < wait && last > 0) {
			timeout = setTimeout(later, wait - last);
		} else {
			timeout = null;
			// 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
			if (!immediate) {
				result = func.apply(context, args);
				if (!timeout) context = args = null;
			}
		}
	};

	return function(...args) {
		context = this;
		timestamp = +new Date();
		const callNow = immediate && !timeout;
		// 如果延时不存在，重新设定延时
		if (!timeout) timeout = setTimeout(later, wait);
		if (callNow) {
			result = func.apply(context, args);
			context = args = null;
		}

		return result;
	};
}

export function deepClone(source) {
	if (!source && typeof source !== "object") {
		throw new Error("error arguments", "shallowClone");
	}
	const targetObj = source.constructor === Array ? [] : {};
	Object.keys(source).forEach((keys) => {
		if (source[keys] && typeof source[keys] === "object") {
			targetObj[keys] = source[keys].constructor === Array ? [] : {};
			targetObj[keys] = deepClone(source[keys]);
		} else {
			targetObj[keys] = source[keys];
		}
	});
	return targetObj;
}

export function buildListParams(searchForm = {}, page = {}) {
	searchForm.pageSize = page.pageSize || 10;
	searchForm.pageNo = page.pageNo || 1;
	return searchForm;
}

export function getDictName(id, datas) {
	const item = datas.filter((data) => {
		return data.dictValue == id;
	})[0];
	if (item) return item.dictShowName;
	return "";
}

/**
 * 查询表单，日期区间条件的转换
 * @param {Object} formData 原始查询参数
 * @param {String} rangeKey 日期字段的key
 * @param {String} format 日期转换标准
 */
export function convertFormRangeDate(
	formData,
	rangeKey,
	format = "YYYY-MM-DD"
) {
	let newFormData = { ...formData };
	if (newFormData[rangeKey]) {
		const rangeDate = newFormData[rangeKey];
		newFormData[rangeKey + "__gte"] = moment(rangeDate[0]).format(format);
		newFormData[rangeKey + "__lte"] = moment(rangeDate[1]).format(format);
	} else {
		newFormData[rangeKey + "__gte"] = "";
		newFormData[rangeKey + "__lte"] = "";
	}

	delete newFormData[rangeKey];

	return newFormData;
}

/**
 * 字典表转换
 * @param {Object} list 字典表
 * @param {String} item key值
 */
export function buildType(list, item) {
	if (list && list.length != 0) {
		let resource = list.find((i) => i.dictValue == item);
		return resource ? resource.dictShowName : "";
	} else {
		return item;
	}
}

/**
 * 千分位转换
 * @param {Number} num 要转的数值
 * @param {Number} fixed 保留的小数位
 */
export function formatThousand(num, fixed = 0) {
	return (num.toFixed(0) + "").replace(
		/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,
		"$&,"
	);
}


export function buildGridWidth (columns) {
	let width = 0;

	columns.forEach((item) => {width = item.width || 150})

	return width;
}
//原生获取当前连接参数
export function getQueryVariable(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    if (r != null)return unescape(r[2]);
    return null;
}

//设置火警音频
export function setFireAlarmAudio() {
	var parent = document.querySelector("body");
	var child = document.querySelector("audio");
	if (child) {
	  parent.removeChild(child);
	}
	let audio = document.createElement("audio");
	audio.setAttribute("src", require("@/assets/audio/fire-alarm.mp3"));
	audio.setAttribute("muted", "muted");
	audio.setAttribute("autoplay", "true");
	audio.setAttribute("loop", "loop");
	audio.setAttribute("muted", false);
	audio.play();
	document.body.appendChild(audio);
	audio.addEventListener(
	  "ended",
	  function() {
		var parent = document.querySelector("body");
		var child = document.querySelector("audio");
		parent.removeChild(child);
	  },
	  false
	);
};
  //静音
export function erasureNoise(){
	var parent = document.querySelector("body");
	var child = document.querySelector("audio");
	if (child) {
		parent.removeChild(child);
	}
};

export function alermType(comm) {
	let text = ""
	let type = 0
	if((comm.originalType==2||comm.originalType==3)&&!comm.fireAlarm&&!comm.faultAlarm&&!comm.alertAlarm){
		text="火警";
		type = 0
	}else{
		if(comm.fireAlarm){
			text="真实火警"
			type = 1
		}else if(comm.faultAlarm){
			type = 2
			text ="故障"
		}else if(comm.alertAlarm){
			type = 3
			text ="异常"
		}else{
			text = "未知异常"
			type = 4
		}
	}
	return {value : type,name : text}
  }



