import request from "@/utils/request";
import { message } from "ant-design-vue";
import { buildListParams } from "@/utils";

export const getTableList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: "api-base/v1/base/message/ii",
      method: "get",
      params: buildListParams(params.searchForm, params.pagination),
    })
      .then((res) => {
        if (res.success) {
          if (params.searchForm.status__eq == 1) {
            resolve(res.result);
          } else {
            let result = res.result;
            commit("SET_TABLE_DATA", res.result.data);
            commit("SET_TABLE_PAGE_INFO", {
              pageNo: result.pageNo,
              pageSize: result.pageSize,
              total: Number(result.totalCount || 0),
            });
            resolve(res.result);
          }
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const detail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `api-base/v1/base/message/${params.id}`,
      method: "get",
      params,
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//根据区域信息 获取摄像头信息
export const cameraInfo = ({ commit }, parentId) => {
  return new Promise((resolve, reject) => {
    request({
      url: `api-thing/v1/thing/device/areaInfo/cameraInfo/${parentId}`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//根据区域path查询该区域下的设备
export const getEquipmentList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `api-asset/v1/equipment/equipment/equipmentList/${params.searchForm.addressPaths}`,
      method: "get",
      params: buildListParams(params.searchForm, params.pagination),
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//根据楼层id查询视频的设备
export const getFloorsEquipmentList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `api-asset/v1/equipment/equipment/equipmentListByFloor/${params.floorIds}`,
      method: "get",
      params: buildListParams(params.searchForm, params.pagination),
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//获取当前账号下建筑所在区域
export const getChildrenByPermissions = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `api-base/v1/base/area/childrenByPermissions`,
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
