<template>
  <div class="mapalarmInfo">
    <a-tabs :defaultActiveKey="viewType" @change="callback" class="mapalarmInfo_tabs">
      <a-tab-pane key="deal" tab="报警信息">
        <alarmInfo ref="alarmInfo" v-if="alarmInfoShow" :showBadgeIndex="showBadgeIndex" :viewType="viewType" 
          :detailInfo="detailInfo" :dataRecord="dataRecord"  v-bind="$attrs" v-on="$listeners"></alarmInfo>
      </a-tab-pane>
      <a-tab-pane key="check" tab="关联视频">
          <div id="id_test_video" class="none_camare" v-if="deviceList.length>0">
            <el-carousel  height="600px"  :autoplay="false"  ref="carouselTwo" :initialIndex="carIndexTwo" @change="carChangeTwo">
                <el-carousel-item v-for="(item,index) in deviceList" :key="index">
                  <div class="video_mian_list">
                      <div class="video_mian" :class="item.length==1?'video_mian_one':''" v-for="(its,inds) in item" :key="inds" @click="clickVideo(its)">
                        <div class="title">
                          <div class="name">{{its.channelName}}</div>
                          <div class="time">{{getNowTime()}}</div>
                        </div>
                        <div class="m-img">
                            <img src="@/views/index/images/logo.png" alt="" srcset="">
                            <div class="no-bg b-c-f b-s-24">暂时无法获取封面图</div>
                        </div>
                        <img class="video_img" :src="its.url&&its.url!=''?its.url:''" alt="封面" srcset="">
                      </div>
                  </div>
                </el-carousel-item>
            </el-carousel>
          </div>
          <div class="none_camare none_camare_nodata" v-else>
            <a-empty description='暂无关联视频' image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"/>
          </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import alarmInfo from "./alarm_info"
import mapMaskCameraidDetail from "./map_mask_cameraid_detail"
import { parseTime } from "@/utils";
import { createNamespacedHelpers, mapGetters, mapActions as mapOtherActions, mapState as mapOtherState,} from "vuex";
import { getDataRecord } from "@/api/alarmMask";
export default {
  name: "mapAlarmInfo",
  props:["detailInfo","viewType","visible","showBadgeIndex","queryDeviceId"],
  //viewType :deal check
  data() {
    return {
      camareShow : true,
      imgDivShow  : false,
      isLoadingCamare : false,
      camareList : [],
      alarmInfoShow : false,
      carIndexTwo : 0, 
      token : "",
      resDeviceList : [],
      deviceList : [],
      demoIamgeUrl : require("../../views/index/images/demo-video.png"),
      dataRecord : []
    };
  },
  watch: {
    queryDeviceId(value){
      if(value){
        this.getDeviceList()
        console.log("queryDeviceIddetailInfo:",this.detailInfo)
        this.getDataRecordOfId();
      }else{
        this.dataRecord = []
        this.deviceList = []
      }
      
    }
  },
  mounted (){
    this.init();
    this.alarmInfoShow = true
  },
  created() {
    this.alarmInfoShow = false
  },
  methods: {
    ...mapOtherActions("Index",['getByDeviceListOfDevice']),
    getDeviceList(){
      this.getByDeviceListOfDevice({deviceId:this.detailInfo.deviceId}).then(res=>{
        this.resDeviceList = res;
        let commList = res['channelInfoResVOS'];
        this.deviceList = []
        for (var i = 0; i < commList.length; i += 4) {
              this.deviceList.push(commList.slice(i, i + 4));
          }
      })

    },
    getDataRecordOfId(){
      this.dataRecord = []
      return new Promise((resolve, reject) => {
        getDataRecord(this.detailInfo.id).then((res) => {
          if(res.success){
              this.dataRecord = res.result.reverse();
              resolve(res.result)
          }
        }).catch((err) => {
            reject(err)
        })
          
      })
    },
    clickVideo(item){
      this.$emit("clickDahuaShow",{
        deviceId : item.channelDeviceId,
        channelId : item.channelNo
      })
    },
    getNowTime(){
            let myDate = new Date();
            return parseTime(myDate)
        },
    yunruiFun(){
      fetch(process.env.VUE_APP_BASE_API_YUNRUI+'/yunrui/token', {
                    method: 'GET'
                }).then(res => {
                return res.json()
                }).then(data => {
                    this.token = data.access_token
                    this.postDviceList()
                })
    },
    postDviceList(){

    },
    carChangeTwo(index) {
            this.carIndexTwo = index;
        },
    // getDataRecordOfId(){
    //   this.$refs.alarmInfo.getDataRecordOfId();
    // },
    init(){
      
      // this.getDeviceList();
    },
    putin(){
      this.$refs.alarmInfo.grandSon();
    },
    detail(id){
      console.log(id)
    },

    callback(key) {
      console.log("key:",key)
      
    },
  },
  components: {
    alarmInfo,mapMaskCameraidDetail
  }
};
</script>
<style lang="less">
.mapAlarmInfoDeal,.mapAlarmInfoModal{
  .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane, .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane{
    padding: 0 0.21rem;
    min-height: 5.5rem;
  }
  .ant-tabs-bar {
    border-bottom: 3px solid #1A3E5D;
  }
    .ant-tabs-ink-bar{
        background-color:#52CFFF;
        height:1px;
    }
    .ant-modal-content,.ant-modal-header{
        background-color:#122644;
    }
    .ant-modal-header{
         padding : 0.14rem .24rem;
         border-bottom: 0px solid #122644;
         .ant-modal-title{
             text-align : left;
         }
    }
   .ant-modal-body{
       padding :0;
       .ant-tabs-nav-wrap{
           text-align:center;
           background-color:#0E1D34;
           .ant-tabs-nav {
               .ant-tabs-tab{
                    padding: 0.15rem 0.16rem 0.12rem 0.16rem
                }
                .ant-tabs-tab{
                    color: #0479AD;
                    font-size: 0.14rem;
                }
                .ant-tabs-tab-active{
                    color: #52CFFF;
                    font-size: 0.18rem;
                }
           }
       }
       
   }
}
</style>
<style lang="less" scoped>

.none_camare{
  height:6rem;
  .video_mian_list{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    cursor: pointer;
    .video_mian{
      width: 46%;
      height: 250px;
      margin: 2%;
      position: relative;
      // background: url('./../../views/index/images/demo-video.png') no-repeat;
      // background-size: 100% 100%;
      .m-img{
                background: url('./../../views/index/images/video-bg.jpg') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                text-align: center;
                >img{
                    width: 100px;
                    margin: 50px 0 30px 0;
                }
            }
      .video_img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
      }
      .title{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 11;
        height: 28px;
        display: flex;
        justify-content: space-between;
       align-items: center;
        color: #ffffff;
        background: #347EA5;
        padding: 0 10px ;
        .name{
          width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .video_mian_one{
      width: 100%;
      height: 500px;
      .m-img{
        >img{
          margin: 150px 0 20px 0px;
        }
      }
    }
  }
 
}
.none_camare_nodata{
  padding-top: 2rem;
}
.mapalarmInfo_tabs{
  padding-bottom: 0.2rem;

}
</style>
