import request from '@/utils/request'

//获取导航标签
export function getUserPermission() {
    return request({
        url: '/api-uc/v1/uc/res/user',
        method: 'GET',
        headers: {parentCode: 'fireService'}
    })
}
