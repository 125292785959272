import request from "@/utils/request";


export const getAlarmStatNum = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-asset/v1/alarm/home/getAlarmStat`,
			method: "get",
		}).then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getAlarmStat = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/home/getAlarmStat?start=${params.startDate}&end=${params.endDate}`,
			method: "get",
		}).then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};


export const getTodayAlarm = ({ commit }) => {
	return new Promise((resolve, reject) => {
		request({
			url: "/api-alarm/v1/alarm/data/stat/today",
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getStatType = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-alarm/v1/alarm/data/stat/${params.startDate}/${
				params.endDate
			}/${params.statType}`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};


export const getDangerStatistical = ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		request({
			url: `/api-fire/v1/danger/statistical`,
			method: "get",
		})
			.then((res) => {
				if (res.success) {
					resolve(res.result);
				} else {
					reject(res);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};


