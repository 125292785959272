<template>
  <div>
    <div class="avatar-wrapper clearfix">
      <span class="border fl"></span>
      <a-dropdown class="fl">
        <div class="avatar-container">
          <span class="icon"></span>
          <span class="text">{{ userInfo.currentOrg?userInfo.currentOrg.orgName+'-':'' }}</span>
          <span class="text">{{ userInfo.fullName || userInfo.username }}</span>
        </div>
        <a-menu slot="overlay" style="z-index: 21;background: #1b283b;">
          <a-menu-item key="3" @click="logout()">
            <a-icon type="logout" />
            退出登录
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <span v-if="unReadNum&&unReadNum!=0" class="border-vertical fl"></span>
      <div v-if="unReadNum&&unReadNum!=0" class="letter fl clearfix" @click="gotoLetter">
        <span class="icon fl"></span>
        <span class="num fl">{{unReadNum}}</span>
        <span class="point fl"></span>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters,mapActions } from 'vuex'

export default {
  name: 'layoutHeader',
  data() {
    return {
      isLogin: '',
      unReadNum: 0
    }
  },
  computed:{
    ...mapGetters(['avatar', 'name', 'userInfo'])
  },
  methods: {
    ...mapActions('message_base_message', ['getTableList']),
    logout() {
      this.$store.dispatch('LogOut')
      .then(() => {
        localStorage.removeItem('isLogin');
        location.reload();
      })
    },
    gotoLetter() {
      // this.$message.info("点击了站内信");
      this.$router.push('/message/base_message');
      localStorage.setItem('currentKey','/message/base_message');
    },
    getUnReadNum() {
      this.getTableList({
        searchForm: { status__eq: 1 }
      }).then(res => {
        this.unReadNum = res.totalCount;
      })
    }
  },
  mounted() {
    this.isLogin = localStorage.getItem("isLogin");
    // if(localStorage.getItem("isLogin")) {
    //   this.getUnReadNum();
    //   let self = this;
    //   setInterval(() => {
    //     self.getUnReadNum();
    //   }, 30*1000)
    // }
  },
  watch: {
    isLogin: {
      handler() {
        if(this.isLogin) {
          this.getUnReadNum();
          let self = this;
          this.flag = setInterval(() => {
            self.getUnReadNum();
          }, 30*1000)
        }else {
          clearInterval(this.flag);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-dropdown-menu-item:hover{
  background: rgba(202, 241, 231, 0.12);
}
.border {
  width: 32px;
  height: 1px;
  background: #CAF1E7;
  opacity: 0.3;
  margin-right: 12px;
  margin-top: 25px;
}
.avatar-wrapper {
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 12px;
  line-height: 50px;
  font-size: 13px;
  .avatar-container {
    display: flex;
    align-items: center;
    font-weight: 500;
    .icon {
      width: 16px;
      height: 16px;
      background: url('../../assets/image/header-avatar.svg') no-repeat center;
      background-size: cover;
      margin-right: 8px;
    }
  }

  .ant-avatar {
    margin-right: 8px;
  }

  &:hover{
    background-color: rgba(0, 0, 0, 0.025);
  }
  .border-vertical {
    width: 1px;
    height: 16px;
    background: #CAF1E7;
    opacity: 0.3;
    margin-left: 28px;
    margin-top: 18px;
  }
  .letter {
    margin-left: 28px;
    .icon {
      width: 16px;
      height: 12px;
      background: url('../../assets/image/header-letter.svg') no-repeat center;
      background-size: cover;
      margin-right: 8px;
      margin-top: 19px;
    }
    .num {
      font-weight: 500;
    }
    .point {
      width: 6px;
      height: 6px;
      background: #F34C28;
      border-radius: 50%;
      margin-top: 17px;
    }
  }
}
</style>


