import { state } from "./state";
import { ceil } from "lodash";
import _ from "lodash";

export const SET_ADD_ALARM_COUNT = (state, data) => {
  state.alarmCount += 1;
};

export const SET_TABLE_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data };
};
export const SET_STATUS_BAR_UNFOLD_STATUS = (state, status) => {
  state.statusBarUnFold = status;
  state.fireAlarmUnFold = false;
  state.mapUnFold = false;
};

export const SET_MAP_UNFOLD_STATUS = (state, status) => {
  state.mapUnFold = status;
  state.statusBarUnFold = status ? false : true;
};

export const SET_FIRE_ALARM_UN_FOLD_STATUS = (state, status) => {
  state.fireAlarmUnFold = status;
  state.statusBarUnFold = status ? false : true;
  state.mapUnFold = status ? true : false;
};

export const SET_ALARM_TODAY_DATA = (state, data) => {
  state.alarmTodayData = data;
};

export const SET_FAULT_TODAY_DATA = (state, data) => {
  state.faultTodayData = data;
};

export const SET_DANGER_TODAY_DATA = (state, data) => {
  state.dangerTodayData = data;
  state.untreatedDanger = data.filter((item) => item.handlerStatus == 0);
};

export const SET_ALARM_LEVEL_DATA = (state, data) => {
  let num = 0;
  for (var i in data) {
    num += data[i];
  }
  state.probability = (
    data["level3"] == 0 ? 0 : (data["level3"] / num) * 100
  ).toFixed(1);
};

export const SET_ALARM_LATEST_DATA = (state, data) => {
  state.alarmLatestList = data;
  let alarmList = [];
  for (let i = 0; i < 40; i++) {
    if (!data[i]) {
      alarmList.push(0);
    } else {
      alarmList.push(data[i].level);
    }
  }
  // let num, level0, level1, level2, level3;
  // level0 = data.filter(item => item.level == 0).length;
  // level1 = data.filter(item => item.level == 1).length;
  // level2 = data.filter(item => item.level == 2).length;
  // level3 = data.filter(item => item.level == 3).length;

  // num = level0 + level1 + level2 + level3;
  // if(num < 40){
  // 	num = 40;
  // }
  // for (let i = 0; i < num; i++) {
  // 	if (i < ceil(level3)) {
  // 		alarmList.push(3);
  // 	} else if (i < ceil(level2) + ceil(level3)) {
  // 		alarmList.push(2);
  // 	} else if (i < ceil(level1) + ceil(level2) + ceil(level3)) {
  // 		alarmList.push(1);
  // 	} else if (i < num) {
  // 		alarmList.push(0);
  // 	}
  // }
  console.log("alarmList", alarmList);
  state.levelList = alarmList;
};

export const SET_ALARM_TREND_DATA = (state, data) => {
  let timeList = [],
    numList = [];
  data.forEach((item) => {
    timeList.push(
      item.dateStr.split("-")[1] + "-" + item.dateStr.split("-")[2]
    );
  }),
    data.forEach((item) => {
      numList.push(item.num);
    });
  state.alarmTrend = {
    timeList,
    numList,
  };
};

export const SET_AROUND_POWER_DATA = (state, data) => {
  state.aroundPower = data;
};
export const SET_AROUND_THE_FIRE_DATA = (state, data) => {
  state.aroundTheFire = data;
};
export const SET_FIRE_ALARM_DATA = (state, data) => {
  state.fireAlarmPoints = data;
};

export const SET_BUILD_UNIT_DATA = (state, data) => {
  let firePowerList = _.cloneDeep(state.firePowerList);

  // console.log('firePowerList', firePowerList);
  // console.log('data', data);

  firePowerList.forEach((item) => {
    for (let key in data) {
      if (item.key === key) {
        item.num = data[key];
      }
      if (item.children.length) {
        item.children.forEach((child) => {
          if (child.key === key) {
            child.num = data[key];
          }
        });
      }
    }
  });

  console.log("firePowerList_", firePowerList);

  state.buildUnitData = firePowerList;
  state.firePowerList = firePowerList;
};

export const SET_JURISDICTION_DATA = (state, data) => {
  state.jurisdictionData = data.map((item) => item);
};
export const SET_JURISDICTION = (state, data) => {
  state.jurisdiction = data;
};
export const SET_MAP_AREA = (state, data) => {
  state.selectArea = data;
};
export const SET_PER_FORCES_DATA = (state, data) => {
  state.perForces = data;
};
export const SET_ALARM_SWITCH_STATUS = (state, status) => {
  state.isSwitchAlarm = status;
};

export const SET_HIDDEN_DANGER_SWITCH_STATUS = (state, status) => {
  state.isSwitchHiddenDanger = status;
};

export const SET_ERROR_SWITCH_STATUS = (state, status) => {
  state.isSwitchError = status;
};

export const SET_INDUSTRY_UNITS_DATA = (state, data) => {
  state.industryUnitsData = data;
};

export const SET_FILTER_AROUND_POWER = (state, data) => {
  state.filterAroundPower = data.map((item) => item);
};
export const SET_ONFIRE_DATA = (state, data) => {
  state.onFireData = data;
};
export const SET_POINTS_LIST = (state, data) => {
  state.pointsList = data;
};
export const SET_FIRE_LOCATION = (state, data) => {
  state.fireLocation = data;
};
export const SET_MAP_CENTER = (state, data) => {
  state.mapCenter = data;
};
export const SET_CURRENT_REGION = (state, data) => {
  state.currentRegion = data;
};
export const SET_INDEX_PAGE_INFO = (state, data) => {
  state.pagination = { ...state.pagination, ...data };
};

export const SET_FIRE_ALARM_LIST = (state, data) => {
  state.fireAlarmList = data;
};
export const SET_IS_QUERY_UNITS_OR_BUILDINGS = (state, data) => {
  state.isQueryUnitsOrBuildings = data;
};

export const SET_SEARCH_FORM = (state, data) => {
  state.vueXSearchForm = { ...state.vueXSearchForm, ...data };
};

export const SET_ONALARM_DATA = (state, data) => {
  state.onAlarmData = data;
};
