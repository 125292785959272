import request from '@/utils/request'
import { message } from 'ant-design-vue'
import { buildListParams } from '@/utils';
import { reject } from 'core-js/fn/promise';

export const getTableList = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: '/api-base/v1/base/dict',
      method: 'get',
      params: buildListParams(params.searchForm, params.pagination),
    }).then(res => {
      if(res.success) {
        let result = res.result;
        let data = (result.data||[]).filter(i => {
          i.children = [];
          i.loading = false;
          i.loadedChildren = false;
          i.pagination = {pageNo: 1, pageSize: 10};
          i.searchForm = {};
          return true;
        });
        commit('SET_TABLE_DATA', data)
        commit('SET_TABLE_PAGE_INFO', {
          pageNo: result.pageNo, 
          pageSize: result.pageSize, 
          totalCount: result.totalCount})
        reslove(data);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const create = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: '/api-base/v1/base/dict',
      method: 'post',
      data: params
    }).then(res => {
      if(res.success) {
        reslove(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
 
 
export const edit = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/dict/${params.id}`,
      method: 'put',
      data: params
    }).then(res => {
      if(res.success) {
        reslove(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
 
export const deleteData = ({ commit }, keys) => {
  return new Promise((reslove, reject) => {request({
      url: `/api-base/v1/base/dict`,
      method: 'delete',
      data: {ids: keys}
    }).then(res => {
      if(res.success) {
        reslove(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const deleteSingleData = ({ commit }, id) => {
  return new Promise((reslove, reject) => {request({
      url: `/api-base/v1/base/dict/${id}`,
      method: 'delete',
    }).then(res => {
      if(res.success) {
        reslove(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const getDictDetailList = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/dict/${params.id}/data`,
      method: 'get',
      params: buildListParams(params.searchForm, params.pagination),
    }).then(res => {
      if(res.success) {
        let result = res.result;
        console.log('---getDictDetailList---', res.result, params);
        commit('SET_DICT_TYPE_DATA', {data: res.result, index: params.index})//{data: res.result.data, index: params.index})
        reslove(res.result.data);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}


 
export const createDictValue = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/dict/${params.dictId}/data`,
      method: 'post',
      data: params
    }).then(res => {
      if(res.success) {
        reslove(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
 
 
export const editDictValue = ({ commit }, params) => {
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/dict/${params.dictId}/data/${params.id}`,
      method: 'put',
      data: params
    }).then(res => {
      if(res.success) {
        reslove(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const deleteSingleDictValueData = ({ commit }, params) => {
  return new Promise((reslove, reject) => {request({
    url: `/api-base/v1/base/dict/${params.dictId}/data/${params.id}`,
      method: 'delete',
    }).then(res => {
      if(res.success) {
        reslove(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const sexDictGet = ({ commit, state }) => {
  if(state.sexDictList && state.sexDictList.length) {
    return(state.sexDictList);
  }
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/dict/sex/data/fromCache`,
      method: 'get',
    }).then(res => {
      if(res.success) {
        commit('SET_DICT_SEX_DATA', res.result)
        reslove(res.result);
      } else {
        reject(res.result);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const resourceTypeDictGet = ({ commit, state  }) => {
  if(state.resourceTypeDictList && state.resourceTypeDictList.length) {
    return(state.resourceTypeDictList);
  }
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/dict/resourceType/data/fromCache`,
      method: 'get',
    }).then(res => {
      if(res.success) {
        console.log('----data', res);
        commit('SET_DICT_RESOURCETYPE_DATA', res.result)
        reslove(res.result);
      } else {
        reject(res.result);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const roleTypeDictGet = ({ commit, state  }) => {
  if(state.roleTypeDictList && state.roleTypeDictList.length) {
    return(state.roleTypeDictList);
  }
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/dict/roleType/data/fromCache`,
      method: 'get',
    }).then(res => {
      if(res.success) {
        commit('SET_DICT_ROLETYPE_DATA', res.result)
        reslove(res.result);
      } else {
        reject(res.result);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const getDict = ({ commit, state  }, dictCode) => {
  if(state.dict[dictCode]) {
    return state.dict[dictCode];
  }
  return new Promise((reslove, reject) => {
    request({
      url: `/api-base/v1/base/dict/${dictCode}/data/fromCache`,
      method: 'get',
    }).then(res => {
      if(res.success) {
        commit('SET_DICT_DATA', {dictCode, dictItems: res.result})
        reslove(res.result);
      } else {
        reject(res.result);
      }
    }).catch(e => {
      reject(e);
    })
  })
}