import { state } from './state'

import * as actions from './action'

import * as mutations from './mutation'

export const Home = {
	namespaced: true,
	state,
	mutations,
	actions
}