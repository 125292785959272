<template>
	<a-card :bordered="false" class="camare-info">
		<a-card :bordered="false" class="camare-video-info">
			<template slot="title">
				{{ cameraDetail.equipmentName }}
				<span
					class="circle"
					:style="{
						background: cameraDetail.status == 0 ? 'red' : 'green',
					}"
				></span>
			</template>
			<div class="video-area">
				<div id="id_test_video"></div>
			</div>
			<div style="text-align:right">
				<a-icon
					type="video-camera"
					:style="{ fontSize: '32px', margin: '5px 0' }"
				/>
			</div>
			<div style="margin-bottom:5px">
				建筑名称：{{ cameraDetail.buildingName }}
			</div>
			<div>
				拍摄位置：{{
					cameraDetail.addressName +
						(cameraDetail.aptSuite ? cameraDetail.aptSuite : "") +
						(cameraDetail.room ? cameraDetail.room + "房间" : "") +
						(cameraDetail.pointAddress
							? "点位" + cameraDetail.pointAddress
							: "")
				}}
			</div>
		</a-card>
		<!-- <div
			class="swiper-container gallery-thumbs"
			:style="
				$props.parentInfo.length > 4 ? 'padding: 0 35px;' : 'padding:0'
			"
		>
			<div
				v-if="$props.parentInfo.length > 4"
				class="swiper-button-next swiper-button-white"
				@click="next"
			></div>
			<div
				v-if="$props.parentInfo.length > 4"
				class="swiper-button-prev swiper-button-white"
				@click="prev"
			></div>
			<div class="swiper-wrapper" style="height:100px">
				<div
					v-for="(item, index) in $props.parentInfo"
					:key="item.id"
					class="swiper-slide"
					:class="[
						item.status == 0
							? 'camare-fault-bg'
							: 'camare-normal-bg',
						activeIndex == index ? 'swiper-slide-active' : '',
					]"
				>
					<a-tooltip placement="top">
						<template slot="title">
							<span>双击查看视频</span>
						</template>
						<div>
							<img
								:src="
									item.status == 0
										? faultVideoImg
										: normalVideoImg
								"
								class="img-class"
							/>
							<div class="video-status">
								{{
									item.status == 0
										? "设备连接异常"
										: "播放视频"
								}}
								<p style="font-size: 10px;opacity: 0.5;">
									{{ item.equipmentName }}
								</p>
							</div>
						</div>
					</a-tooltip>
				</div>
			</div>
		</div> -->
	</a-card>
</template>
<script>
import {
	createNamespacedHelpers,
	mapGetters,
	mapActions as mapOtherActions,
	mapState as mapOtherState,
} from "vuex";
const { mapActions, mapState } = createNamespacedHelpers(
	"remoteVideo_video_menu"
);
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

export default {
	props: ["camareInfo", "activeVideoIndex"],
	data() {
		return {
			normalVideoImg: require("@/assets/image/normal_video.png"),
			faultVideoImg: require("@/assets/image/fault_video.png"),
			cameraDetail: {},
			activeIndex: 0,
			player: null,
			swiper: null,
		};
	},
	components: {},
	computed: {
		...mapOtherState("base_dict", ["dict"]),
	},
	destroyed() {
		this.destroyPlayer();
		this.swiper = null;
	},
	mounted() {
		let self = this;

		this.cameraDetail = this.camareInfo;
		this.initPlayer();
		this.initSwiper();
		this.activeIndex = this.activeVideoIndex;
	},
	methods: {
		initPlayer() {
			let self = this,
			m3u8Url = `${this.cameraDetail.cameraIp}/live/cameraid/${this.cameraDetail.cameraId}/substream/${this.cameraDetail.cameraStreamType}.m3u8`;
			this.player = new TcPlayer("id_test_video", {
				m3u8: m3u8Url,
				autoplay: true, //iOS下safari浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
				width: "100%", //视频的显示宽度，请尽量使用视频分辨率宽度
				height: "100%", //视频的显示高度，请尽量使用视频分辨率高度
			});
		},
		initSwiper() {
			let self = this;

			this.swiper = new Swiper(".gallery-thumbs", {
				spaceBetween: 10,
				slidesPerView: 4,
				slideActiveClass: "my-slide-active",
				// watchSlidesVisibility: true,
				// watchSlidesProgress: true,
				noSwiping: true,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				on: {
					click: function(Swiper) {
						if (
							(this.clickedIndex !== 0 && !this.clickedIndex) ||
							self.activeIndex == this.clickedIndex
						)
							return;
						self.cameraDetail = self.parentInfo[this.clickedIndex];
						self.activeIndex = this.clickedIndex;
						self.destroyPlayer();
						self.initPlayer();
					},
					slideChange: function(Swiper) {
						return;
					},
				},
			});
		},
		destroyPlayer() {
			this.player.destroy();
		},
		prev() {
			this.swiper.slidePrev();
		},
		next(e) {
			this.swiper.slideNext();
		},
		clickImg(item, index) {
		},
		loadVideo(source) {
			// if (Hls.isSupported()) {
			//     this.hls = new Hls();
			//     this.hls.loadSource(source);
			//     this.hls.attachMedia(this.$refs.video);
			//     this.hls.on(Hls.Events.MANIFEST_PARSED, (e) => {
			//         this.$refs.video.play();
			//     });
			//     this.hls.on(Hls.Events.ERROR, (event, data) => {
			//         // 监听出错事件
			//     });
			// }
		},
	},
};
</script>

<style lang="scss">
.camare-info {
	height: 100%;
	.ant-card-body {
		height: 100%;
	}
	.slide1 {
		background-image: url("../../assets/image/normal_video.png");
	}
	.slide2 {
		background-image: url("../../assets/image/fault_video.png");
	}
	.swiper-container {
		width: 100%;
		height: 200px;
		margin-left: auto;
		margin-right: auto;
	}
	.swiper-slide {
		background-size: cover;
		background-position: center;
	}
	.gallery-thumbs {
		height: 100px;
		box-sizing: border-box;
		margin: 25px 0;
	}
	.gallery-thumbs .swiper-slide {
		width: 100px;
		height: 100%;
		opacity: 0.4;
	}
	.gallery-thumbs .swiper-slide-active {
		opacity: 1;
	}

	@mixin public {
		.img-class {
			position: absolute;
			left: calc(50% - 16px);
			top: 20px;
			cursor: pointer;
		}
		.video-status {
			width: 100%;
			text-align: center;
			position: absolute;
			top: 60px;
		}
	}
	.swiper-button-next {
		right: 0;
	}
	.swiper-button-prev {
		left: 0;
	}
	.camare-normal-bg {
		background-image: url("../../assets/image/normal_video_bg.png");
		@include public;
	}
	.camare-fault-bg {
		background-image: url("../../assets/image/fault_video_bg.png");
		@include public;
	}
	.camare-video-info {
		height: calc(100% - 125px);
	}
	.video-area {
		height: calc(100% - 120px);
		#id_test_video {
			height: 100%;
		}
	}
}
</style>
