export const state = {
	listDataSource: [],
	pagination: {
		pageNo: 1,
		pageSize: 10,
		total: 0,
	},
	completedListDataSource: [],
	completedPagination: {
		pageNo: 1,
		pageSize: 10,
		total: 0,
	},
};
