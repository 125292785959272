<template>
    <div class="">
        <div class="alarm_info">
          <a-timeline  v-if="detailInfo&&dataRecord.length>0">
              <a-timeline-item v-for="(comm,index) in dataRecord" :key="index" :class="index==0?'timeline_last':''">{{comm.createTime}} - {{comm.operatingNote}}</a-timeline-item>
          </a-timeline>
          <a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original" v-else/>
      </div>
    <div class="info_comm">
        <template v-if="detailInfo">
      <a-descriptions title="报警详情" :column="2">
          <a-descriptions-item label="设备名称">
          {{detailInfo.devicePointMappingVO?detailInfo.devicePointMappingVO.pointAddressName : detailInfo.equipmentName}}
          </a-descriptions-item>
          <a-descriptions-item label="">
          </a-descriptions-item>
          <a-descriptions-item label="单位名称">
          {{detailInfo.companyName}}
          </a-descriptions-item>
          <a-descriptions-item label="单位地址">
          {{detailInfo.companyAddressName}}
          </a-descriptions-item>
          <a-descriptions-item label="建筑地址">
          {{detailInfo.companyAddressName}}
          </a-descriptions-item>
          <a-descriptions-item label="报警地址">
          {{detailInfo.alarmAddressName? detailInfo.alarmAddressName +" " +(detailInfo.alarmAptSuite?detailInfo.alarmAptSuite:''): ""}}
          </a-descriptions-item>
      </a-descriptions>
      <a-descriptions :column="2" v-if="detailInfo.safetyRespUserName">
          <a-descriptions-item label="消防安全责任人">
          {{detailInfo.safetyRespUserName
          ? detailInfo.safetyRespUserName +
          " " +
          detailInfo.safetyRespPhone
          : ""}}
          </a-descriptions-item>
          <a-descriptions-item label="消防安全管理人">
          {{ detailInfo.safetyManagerUserName? detailInfo.safetyManagerUserName + " " + detailInfo.safetyManagerPhone: ""}}
          </a-descriptions-item>
      </a-descriptions>
      <a-descriptions :column="2">
          <a-descriptions-item label="报警类型">
          {{ originalType(detailInfo) }}
          <template v-if="detailInfo.alarmTails">   {{detailInfo.alarmTails.alarmName}}</template>
          </a-descriptions-item>
          <a-descriptions-item label="报警位置">
          {{detailInfo.alarmAddrName}}
          </a-descriptions-item>
          <a-descriptions-item label="首次报警时间">
          {{detailInfo.createTime}}
          </a-descriptions-item>
          <a-descriptions-item label="最新报警时间">
          {{ detailInfo.alarmLatestTime }}
          </a-descriptions-item>
          <a-descriptions-item label="报警原因" v-if="detailInfo.alarmRemake">
          {{ detailInfo.alarmRemake }}
          </a-descriptions-item>
         
      </a-descriptions>
      <a-descriptions title="报警图片" :column="2" v-if="detailInfo.alarmImages">
        <a-descriptions-item label="">
            <div class="alermImg">
                
                <img class="img-comm" v-for="(item,index) in detailInfo.alarmImages" :key="index" @click="clickShow(item,index)" :src="item">
            </div>
        </a-descriptions-item>
      </a-descriptions>
      <div v-if="viewType=='check'">
          <a-descriptions :column="2">
              <a-descriptions-item label="是否确认">
               {{detailInfo.confirmUserId?"已确认":"未确认"}}
              </a-descriptions-item>
              <a-descriptions-item label="确认人">
              {{ detailInfo.confirmUserName? detailInfo.confirmUserName + " " + detailInfo.confirmUserPhone : "" }}
              </a-descriptions-item>
              <a-descriptions-item label="确认结果">
              确认为{{ originalType(detailInfo) }}
              </a-descriptions-item>
              <a-descriptions-item label="是否处理">
              <!-- {{detailInfo.handlerStatus!=="0"?"已处理":"未处理"}} -->
              {{ getStatus(detailInfo) }}
              </a-descriptions-item>
              <a-descriptions-item label="处理时间">
              {{detailInfo.closeTime}}
              </a-descriptions-item>
              <a-descriptions-item label="处理意见">
              {{detailInfo.closeFireNote}}
              </a-descriptions-item>
          </a-descriptions>
      </div>
      <!-- <div v-if="(originalTypeName(detailInfo)=='huozai'||originalTypeName(detailInfo)=='baojing')&&(detailInfo.handlerStatus==0||detailInfo.handlerStatus==1)"> -->
      <div>
          <div style="padding-left:0.21rem;padding-top:0.21rem;margin-bottom:0.21rem">
              <a-form :form="form" v-bind="formItemLayout">
                  <div v-if="originalTypeName(detailInfo)=='baojing'">
                  <a-form-item  label="确认结果">
                      <a-radio-group @change="onFieldsChange" v-decorator="['fireAlarm', {
                              rules: [
                              {required: true,message: '请选择确认结果',},
                              { pattern: /^[^\s]*$/, message: '禁止空格' },],},]">
                          <a-radio :value="1">确认火警</a-radio>
                          <a-radio :value="0">误报火警</a-radio>
                          <a-radio :value="4">测试</a-radio>
                      </a-radio-group>
                  </a-form-item>
                  <div v-if="!isAlarm">
                  <a-form-item  label="是否设备故障">
                      <a-radio-group v-decorator="['fireIsFault', { initialValue: '' }]">
                          <a-radio :value="1">是</a-radio>
                          <a-radio :value="0">否</a-radio>
                      </a-radio-group>
                  </a-form-item>
                  <a-form-item  label="误报内容">
                      <a-radio-group v-decorator="['misinformationContent', { initialValue: '' }]">
                          <a-radio :value="item.dictValue" v-for="item in dict['misinformationContent']" :key="item.dictValue">{{item["dictShowName"]}}</a-radio>
                      </a-radio-group>
                  </a-form-item>
                  </div>
                  </div>
                  <div v-if="originalTypeName(detailInfo)=='huozai'" >
                      <a-form-item label="处理意见">
                      <a-textarea placeholder="请输入处理意见" maxLength=100  v-decorator="['closeFireNote', { initialValue: '' }]"></a-textarea>
                      </a-form-item>
                  </div>
                <div v-if="(originalTypeName(detailInfo)=='guzhang')">
                    <a-form-item  label="确认结果">
                        <a-radio-group v-decorator="['faultAlarm', {
                                rules: [
                                {required: true,message: '请选择确认结果',},
                                { pattern: /^[^\s]*$/, message: '禁止空格' },],},]">
                            <template  v-if="detailInfo.handlerStatus==0">
                                <a-radio :value="1">确认故障</a-radio>
                                <a-radio :value="0">误报故障</a-radio>
                            </template>
                            <template  v-if="detailInfo.handlerStatus==1">
                                <a-radio :value="3">自主处理</a-radio>
                            </template>
                        </a-radio-group>
                    </a-form-item>
                </div>
                <div v-if="(originalTypeName(detailInfo)=='gaojing')">
                    <a-form-item  label="确认结果">
                      <a-radio-group v-decorator="['alertAlarm', {
                              rules: [
                                {required: true,message: '请选择确认结果',},
                                {pattern: /^[^\s]*$/, message: '禁止空格' },],},]">
                          <a-radio :value="1">已确认</a-radio>
                      </a-radio-group>
                  </a-form-item>
                </div>

              </a-form>
          </div>
      </div>
    </template>
      <template v-else>
          <a-empty style="margin-top:2rem;" image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"/>
      </template>
      </div>
      <div class="infoBottomRight" v-if="detailInfo">
          <div v-if="(originalTypeName(detailInfo)=='huozai'||originalTypeName(detailInfo)=='baojing')&&(detailInfo.handlerStatus==0||detailInfo.handlerStatus==1)">
              <p class="queren" @click="handleClick" v-if="originalTypeName(detailInfo)!=='huozai'">提交</p>
              <p class="chuli" v-else @click="chuliHuojing">确认处理</p>
          </div>
          <div v-if="(originalTypeName(detailInfo)=='guzhang')&&(detailInfo.handlerStatus==0||detailInfo.handlerStatus==1)">
              <p class="queren" @click="handleClickGuzhang">提交</p>
          </div>
          <div v-if="(originalTypeName(detailInfo)=='gaojing')&&(detailInfo.handlerStatus==0||detailInfo.handlerStatus==1)">
              <p class="queren" @click="handleClickGaojing">提交</p>
          </div>
      </div>
      <!--  -->
        <div class="show-img-div" v-if="showImg" @click="showImg=false">
            <!-- <div class="icon_left icon_j">
                <a-icon type="left-circle" @click.stop="chengeImg(1)" :style="{fontSize:'60px'}" />
            </div>
            <div class="icon_right icon_j">
                <a-icon type="right-circle" @click.stop="chengeImg(2)" :style="{fontSize:'60px'}"/>
            </div>
            <div class="icon_cha icon_j">
                <a-icon type="close-circle"   @click="showImg=false" :style="{fontSize:'30px',color:'#eee'}"/>
            </div> -->
            
            <img class="show-img" :src="showImgUrl ">
        </div>
    </div>
  </template>
  <script>
  import {
      createNamespacedHelpers,
      mapGetters,
      mapActions as mapOtherActions,
      mapState as mapOtherState,
  } from "vuex";
  import moment from "moment";
  import request from "@/utils/request";
  import { getDataRecord } from "@/api/alarmMask";
  export default {
       name: "alarm_info",
    //    props:["viewType","detailInfo","showBadgeIndex"],
    props : {
        viewType : {
            type : String,
            default : "deal"
        },
        detailInfo : {

        }
    },
    data() {
      return {
          formItemLayout: {
          labelCol: { span: 3 },
          wrapperCol: { span: 14 },
          form : "",
          formGuzhang : '',
          fromGaojing  : '',
          
         
      },
       moment,
      getReplyIsLoading : false,
      isAlarm : false,
      dataRecord : [],
      showImg : false,
      showIndex : 0,
        showImgUrl : ''
      };
    },
    beforeCreate() {
      this.form=this.$form.createForm(this, { name: 'normal_login' })
    },
    async mounted() {
          await this.getDict("misinformationContent");
          
      },
      
    computed:{
        ...mapOtherState("base_dict", ["dict"]),
    },
    created(){
      //   this.getDataRecordOfId()
    },
    watch: {
        detailInfo(value){
          console.log("detailInfo:",value)
            if(value &&value!==""){
              this.getDataRecordOfId()
            }
        }
    },
    destroyed(){
      },
    inject : ['layoutGetFireData'],
    methods: {
      ...mapOtherActions("base_dict", ["getDict"]),
      clickShow(url,index){
        this.showImg = true
        this.showImgUrl = url
        this.showIndex = index;

        },
        chengeImg(f){
            if(f==1){
                if(this.showIndex==0){
                    this.$message.warn("最前面一张了")
                }else{
                    this.showIndex = this.showIndex - 1
                    this.showImgUrl = this.detailInfo.alarmImages[this.showIndex]
                }
            }else{
                if(this.showIndex==this.detailInfo.alarmImages.length-1){
                    this.$message.warn("最后面一张了")
                }else{
                    this.showIndex = this.showIndex + 1
                    this.showImgUrl = this.detailInfo.alarmImages[this.showIndex]
                }
            }
        },
      getDataRecordOfId(){
          this.form.setFieldsValue({ closeFireNote: '',});
          let alarmId = "";
          if(this.detailInfo.id&&this.detailInfo.id!==""){
              alarmId = this.detailInfo.id
          }else if(this.detailInfo.alarmIds&&this.detailInfo.alarmIds!==""){
              alarmId = this.detailInfo.alarmIds.split(",")[0]
          }else{
              this.$message.error("没有找到对应的火警")
              return false
          }
        return new Promise((resolve, reject) => {
          getDataRecord(alarmId).then((res) => {
            if(res.success){
                this.dataRecord = res.result.reverse();
                resolve(res.result)
            }
          }).catch((err) => {
              reject(err)
          })
            
        })
      },
      onFieldsChange(e){
          if(e.target.value==1||e.target.value==4){
              this.isAlarm = true
          }else{
              this.isAlarm = false
          }
      },
      chuliHuojing(){
          let that = this;
          if(that.getReplyIsLoading){
              return false
          }
          that.getReplyIsLoading = true;
          let alarmId = "";
          if(that.detailInfo.id&&that.detailInfo.id!==""){
              alarmId = that.detailInfo.id
          }else if(that.detailInfo.alarmIds&&that.detailInfo.alarmIds!==""){
              alarmId = that.detailInfo.alarmIds.split(",")[0]
          }else{
              that.$message.error("没有找到对应的火警")
              return false
          }
          that.form.validateFields(['closeFireNote'],
                      {focus: true}, (err, values) => {
                          if(err !== null) return;
                          let newValue = values
                          that.$confirm({
                              title: '提示',
                              content: '是否确认提交处理?',
                              okText : "确认",
                              cancelText : "关闭",
                              onOk(){
                          const MSG = that.$message.loading("数据请求中...", 0);
                          request({
                              url: `/api-alarm/v1/alarm/data/closeFire/${alarmId}`,
                              method: "PUT",
                              data: values
                          }).then((res) => {
                              MSG();
                              that.getReplyIsLoading = false;
                                  if (res.success) {
                                      that.$emit("callback",'2')
                                      that.$emit("hiddenAlarmInfoShowDeal")
                                      that.$message.success("提交成功")
                                      that.layoutGetFireData();// 首页调用火警/报警弹窗
                                      that.form.setFieldsValue({
                                          closeFireNote: '',
                                      });
                                     
                                  } else {
                                     that.$message.error("提交失败")
                                  }
                              }).catch((e) => {
                                  that.getReplyIsLoading = false;
                                  MSG();
                              });
                               },
                                      onCancel() {that.getReplyIsLoading = false;}
                                  });
                              
                      })
                      
      },
      
      handleClick(){
          let alarmId = "";
          let that = this;
          if(that.detailInfo.id&&that.detailInfo.id!==""){
              alarmId = that.detailInfo.id
          }else if(that.detailInfo.alarmIds&&that.detailInfo.alarmIds!==""){
              alarmId = that.detailInfo.alarmIds.split(",")[0]
          }else{
              that.$message.error("没有找到对应的火警")
              return false
          }
          if(that.getReplyIsLoading){
              return false
          }
          that.getReplyIsLoading = true;
          that.form.validateFields(['fireAlarm','fireIsFault','misinformationContent'],
              {focus: true}, (err, values) => {
                  if(err !== null) return;
                  this.$confirm({
                      title: '提示',
                      content: '是否确认提交处理?',
                      onOk() {
                          
                          const MSG = that.$message.loading("数据请求中...", 0);
                          request({
                              url: `/api-alarm/v1/alarm/data/${alarmId}/${values.fireAlarm}`,
                              method: "PUT",
                              data: values
                          }).then((res) => {
                              MSG();
                              that.getReplyIsLoading = false;
                                  if (res.success) {
                                      that.$emit("callback",'1')
                                      that.$emit("hiddenAlarmInfoShowDeal")
                                      that.$message.success("提交成功")
                                      that.layoutGetFireData();// 首页调用火警/报警弹窗
                                      that.form.setFieldsValue({
                                          fireAlarm: '',
                                          fireIsFault: '',
                                          misinformationContent: ''
                                      });
                                      
                                  } else {
                                      that.$message.error("提交失败")
                                  }
                              }).catch((e) => {
                                  that.getReplyIsLoading = false;
                                  MSG();
                              });
                              },
                              onCancel() {that.getReplyIsLoading = false;}
                          });
                          
              })
      },
      
      handleClickGuzhang(){
          let alarmId = "";
          let that = this;
          if(that.detailInfo.id&&that.detailInfo.id!==""){
              alarmId = that.detailInfo.id
          }else if(that.detailInfo.alarmIds&&that.detailInfo.alarmIds!==""){
              alarmId = that.detailInfo.alarmIds.split(",")[0]
          }else{
              that.$message.error("没有找到对应的故障")
              return false
          }
          if(that.getReplyIsLoading){
              return false
          }
          that.getReplyIsLoading = true;
          that.form.validateFields(['faultAlarm'],
              {focus: true}, (err, values) => {
                  if(err !== null) return;
                  this.$confirm({
                      title: '提示',
                      content: '是否确认提交处理?',
                      onOk() {
                          const MSG = that.$message.loading("数据请求中...", 0);
                          request({
                              url: `/api-alarm/v1/alarm/data/fault/${alarmId}/${values.faultAlarm}`,
                              method: "PUT",
                              data: values
                          }).then((res) => {
                              MSG();
                              that.getReplyIsLoading = false;
                                  if (res.success) {
                                      that.$emit("callback",'1')
                                      that.$emit("callback",'2')
                                      that.$emit("hiddenAlarmInfoShowDeal")
                                      that.$message.success("提交成功")
                                      that.layoutGetFireData();// 首页调用火警/报警弹窗
                                      that.form.setFieldsValue({faultAlarm: ''});
                                      
                                  } else {
                                      that.$message.error("提交失败")
                                  }
                              }).catch((e) => {
                                  that.getReplyIsLoading = false;
                                  MSG();
                              });
                              },
                              onCancel() {that.getReplyIsLoading = false;}
                          });
                          
              })
      },
      handleClickGaojing(){
          let alarmId = "";
          let that = this;
          if(that.detailInfo.id&&that.detailInfo.id!==""){
              alarmId = that.detailInfo.id
          }else if(that.detailInfo.alarmIds&&that.detailInfo.alarmIds!==""){
              alarmId = that.detailInfo.alarmIds.split(",")[0]
          }else{
              that.$message.error("没有找到对应的异常")
              return false
          }
          if(that.getReplyIsLoading){
              return false
          }
          that.getReplyIsLoading = true;
          that.form.validateFields(['alertAlarm'],
              {focus: true}, (err, values) => {
                  if(err !== null) return;
                  this.$confirm({
                      title: '提示',
                      content: '是否确认提交处理?',
                      onOk() {
                          const MSG = that.$message.loading("数据请求中...", 0);
                          request({
                              url: `/api-alarm/v1/alarm/data/alert/${alarmId}`,
                              method: "PUT",
                              data: values
                          }).then((res) => {
                              MSG();
                              that.getReplyIsLoading = false;
                                  if (res.success) {
                                      that.$emit("callback",'1')
                                      that.$emit("hiddenAlarmInfoShowDeal")
                                      that.$message.success("提交成功")
                                      that.layoutGetFireData();// 首页调用火警/报警弹窗
                                      that.form.setFieldsValue({
                                          alertAlarm: ''
                                      });
                                      
                                  } else {
                                      that.$message.error("提交失败")
                                  }
                              }).catch((e) => {
                                  that.getReplyIsLoading = false;
                                  MSG();
                              });
                              },
                              onCancel() {that.getReplyIsLoading = false;}
                          });
                          
              })
      },
      grandSon(){
          
      },
       originalType(comm) {
            let text=""
            if((comm.originalType==2||comm.originalType==3)&&!comm.fireAlarm&&!comm.faultAlarm&&!comm.alertAlarm){
                  text="报警"
              }else{
                  if(comm.fireAlarm){
                      text="火警"
                  }else if(comm.faultAlarm){
                      text ="故障"
                  }else if(comm.alertAlarm){
                      text ="告警"
                  }else{
                      text = "未知异常"
                  }
            }
            return text
        },
        originalTypeName(comm) {
            let text=""
            if((comm.originalType==2||comm.originalType==3)&&!comm.fireAlarm&&!comm.faultAlarm&&!comm.alertAlarm){
                  text="baojing"
              }else{
                  if(comm.fireAlarm){
                      text="huozai"
                  }else if(comm.faultAlarm){
                      text ="guzhang"
                  }else if(comm.alertAlarm){
                      text ="gaojing"
                  }else{
                      text = "weizhiyichang"
                  }
            }
            return text
        },
        getStatus(comm){
          let statusText = "";
          if(
            this.originalTypeName(comm) =="baojing"
            //报警的时候
          ){
            if(comm.handlerStatus==0){
            //就待确认
            statusText = "待确认"
          }else{
            //里面只有handlerStatus=2或者4
            if(comm.handlerStatus==2){
              //已误报
              statusText = "已处理"
            }else{
              //测试
              statusText = "已处理"
            }
          }
          }else if(
            //火警的时候
            this.originalTypeName(comm) =="huozai"
          ){
            if(comm.handlerStatus==1){
              //带处理
              statusText = "待处理"
            }else if(comm.handlerStatus==2){
              //已关闭
              statusText = "已处理"
            }else if(comm.handlerStatus==3){
              //已处理
              statusText = "已处理"
            }else if(comm.handlerStatus==4){
              //测试
              statusText = "已处理"
            }else{
              //其他
              statusText = "已处理"
            }
          }
          return statusText
        },
    },
  };
  </script>
  <style lang="less">
  .info_comm{
      height: 4.5rem;
      background: #173158;
      overflow-y: auto;
      .ant-form-item{
              margin-bottom: 0;
              label{
                  float: left;
              }
          }
      .ant-descriptions-title{
              height: 0.55rem;
      line-height: 0.55rem;
      margin-bottom: 0;
      padding: 0 0.2rem;
      border-bottom: 1px solid #22477F;
      }
      .ant-descriptions-view{
          margin: 0 0.2rem;
          padding: 0.2rem 0;
          border-bottom: 1px solid #22477F;
          width : auto;
      }
      .ant-descriptions-row > td{
          padding: 0.05rem 0rem;
      }
      .ant-descriptions-row > td:first-child{
          padding-right:0.1rem;
      }
      .ant-descriptions-row > td:last-child{
          padding-left:0.1rem;
      }
  }
      .alarm_info{
          
          .ant-timeline-item-last > .ant-timeline-item-content{
              min-height:0;
          }
          .ant-timeline-item{
              padding:0 0 0.08rem;
              font-size:0.14rem;
              color:#9DB5D3;
              .ant-timeline-item-tail{
                  border-left: 2px solid #204D73;
              }
          }
          .ant-timeline-item-head-blue{
              border-color: #3F9CC3;
          }
          .timeline_last.ant-timeline-item{
              color: #EA5449;
              font-size: 0.16rem;
              .ant-timeline-item-head{
                  background-color : #A60028;
                  width: 0.14rem;
                  height: 0.14rem;
              }
              .ant-timeline-item-head-blue{
                  border-color: #FE9797;
                  margin-left: -2px;
              }
              
          }
      }
  </style>
  <style lang="less" scoped>
  .alermImg{
    // width: 40%;
    
  .img-comm{
    width: .8rem;
    height: 0.8rem;
    margin-right: 0.1rem;
    margin-bottom: 0.1rem;
  }
}
.show-img-div{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon_j{
        width: 100px;
    }
    .icon_left{
        position: absolute;
        left: 1rem;
    }
    .icon_right{
        position: absolute;
        right: 1rem;
    }
    .icon_cha{
        position: absolute;
        right: .51rem;
        top: .51rem;
    }
  .show-img{
    width: 50%;
    max-height: 80%;
    margin: auto;
    position: relative;
  }
}
  .infoBottomRight{
              height:0.4rem;
              text-align: center;
          p{
                  width: 1.2rem;
                  height: .4rem;
                  background: #52CFFF;
                  border-radius: .20rem;
                  line-height:0.4rem;
                  text-align:center;
                  color: #000;
                  margin: 0.2rem auto;
                  cursor: pointer;
          }
          }
      .alarm_info{
          background: #173158;
          height:1.5rem;
          padding:0.2rem 0.24rem 0rem 0.24rem;
          overflow-y: auto;
          margin-bottom: 0.2rem;
      }
  </style>
  