export const state = {
  listDataSource: [],
  pagination: {
    pageNo: 1,
    pageSize: 10,
    total: 0,
    size:"small",
    showQuickJumper:true,
  },
  vueXSearchForm: {
    status__eq:'',
    unread__eq:'',
    reportUserName__like:'',
    reportPhone__like:'',
    reportTime__gte:'',
    reportTime__lte:'',
    provinceId__eq:'',
    cityId__eq:'',
    areaId__eq:'', 
    streetId__eq:'',
    sort:'modifyTime-'
  },
}