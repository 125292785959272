import { state } from './state'

import * as actions from './action'

import * as mutations from './mutation'

export const alarm_center_alarm_log= {
  namespaced: true,
  state,
  mutations,
  actions
}
