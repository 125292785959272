var PlayerControl = (function (e) {
  var t = {};
  function n(r) {
    if (t[r]) return t[r].exports;
    var i = (t[r] = { i: r, l: !1, exports: {} });
    return e[r].call(i.exports, i, i.exports, n), (i.l = !0), i.exports;
  }
  return (
    (n.m = e),
    (n.c = t),
    (n.d = function (e, t, r) {
      n.o(e, t) || Object.defineProperty(e, t, { enumerable: !0, get: r });
    }),
    (n.r = function (e) {
      "undefined" !== typeof Symbol &&
        Symbol.toStringTag &&
        Object.defineProperty(e, Symbol.toStringTag, { value: "Module" }),
        Object.defineProperty(e, "__esModule", { value: !0 });
    }),
    (n.t = function (e, t) {
      if ((1 & t && (e = n(e)), 8 & t)) return e;
      if (4 & t && "object" === typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (
        (n.r(r),
        Object.defineProperty(r, "default", { enumerable: !0, value: e }),
        2 & t && "string" != typeof e)
      )
        for (var i in e)
          n.d(
            r,
            i,
            function (t) {
              return e[t];
            }.bind(null, i)
          );
      return r;
    }),
    (n.n = function (e) {
      var t =
        e && e.__esModule
          ? function () {
              return e.default;
            }
          : function () {
              return e;
            };
      return n.d(t, "a", t), t;
    }),
    (n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }),
    (n.p = ""),
    n((n.s = 2))
  );
})([
  function (e, t, n) {
    "use strict";
    n.d(t, "h", function () {
      return l;
    }),
      n.d(t, "a", function () {
        return u;
      }),
      n.d(t, "f", function () {
        return a;
      }),
      n.d(t, "g", function () {
        return s;
      }),
      n.d(t, "e", function () {
        return d;
      }),
      n.d(t, "c", function () {
        return c;
      }),
      n.d(t, "b", function () {
        return h;
      }),
      n.d(t, "d", function () {
        return f;
      });
    var r = {
      Opera: "Opera",
      Chrome: "Chrome",
      Firefox: "Firefox",
      Edge: "Edge",
      IE: "IE",
      Safari: "Safari",
    };
    function i() {
      var e = navigator.userAgent;
      return e.includes("Edge")
        ? r.Edge
        : e.includes("Firefox")
        ? r.Firefox
        : e.includes("Chrome")
        ? r.Chrome
        : e.includes("Safari")
        ? r.Safari
        : e.includes("compatible") && e.includes("MSIE") && e.includes("Opera")
        ? r.IE
        : e.includes("Opera")
        ? r.Opera
        : "";
    }
    function o(e) {
      return navigator.userAgent.split(e)[1].split(".")[0].slice(1);
    }
    function a() {
      var e = i(),
        t = o(e),
        n = !1,
        a = 0;
      switch (e) {
        case r.Chrome:
          (n = t >= 91), (a = 701);
          break;
        case r.Firefox:
          (n = t >= 97), (a = 702);
          break;
        case r.Edge:
          (n = t >= 91), (a = 703);
          break;
        default:
          n = 0;
      }
      return { bSupportMultiThread: n, browserType: e, errorCode: a };
    }
    function s() {
      var e = i(),
        t = o(e),
        n = !1;
      switch (e) {
        case r.Chrome:
          n = t >= 104;
          break;
        default:
          n = 0;
      }
      return n;
    }
    var l = {
      log: function () {},
      error: function () {},
      count: function () {},
      info: function () {},
    };
    function u() {
      var e = navigator.userAgent.toLowerCase(),
        t = navigator.appName,
        n = null;
      return (
        "Microsoft Internet Explorer" === t ||
        e.indexOf("trident") > -1 ||
        e.indexOf("edge/") > -1
          ? ((n = "ie"),
            "Microsoft Internet Explorer" === t
              ? ((e = /msie ([0-9]{1,}[\.0-9]{0,})/.exec(e)),
                (n += parseInt(e[1])))
              : e.indexOf("trident") > -1
              ? (n += 11)
              : e.indexOf("edge/") > -1 && (n = "edge"))
          : e.indexOf("safari") > -1
          ? (n = e.indexOf("chrome") > -1 ? "chrome" : "safari")
          : e.indexOf("firefox") > -1 && (n = "firefox"),
        n
      );
    }
    var c = (function () {
        function e() {}
        return (
          (e.createFromElementId = function (t) {
            for (
              var n = document.getElementById(t), r = "", i = n.firstChild;
              i;

            )
              3 === i.nodeType && (r += i.textContent), (i = i.nextSibling);
            var o = new e();
            return (o.type = n.type), (o.source = r), o;
          }),
          (e.createFromSource = function (t, n) {
            var r = new e();
            return (r.type = t), (r.source = n), r;
          }),
          e
        );
      })(),
      f = (function () {
        return function (e, t) {
          if ("x-shader/x-fragment" === t.type)
            this.shader = e.createShader(e.FRAGMENT_SHADER);
          else {
            if ("x-shader/x-vertex" !== t.type)
              return void error("Unknown shader type: " + t.type);
            this.shader = e.createShader(e.VERTEX_SHADER);
          }
          e.shaderSource(this.shader, t.source),
            e.compileShader(this.shader),
            e.getShaderParameter(this.shader, e.COMPILE_STATUS) ||
              error(
                "An error occurred compiling the shaders: " +
                  e.getShaderInfoLog(this.shader)
              );
        };
      })(),
      h = (function () {
        function e(e) {
          (this.gl = e), (this.program = this.gl.createProgram());
        }
        return (
          (e.prototype = {
            attach: function (e) {
              this.gl.attachShader(this.program, e.shader);
            },
            link: function () {
              this.gl.linkProgram(this.program);
            },
            use: function () {
              this.gl.useProgram(this.program);
            },
            getAttributeLocation: function (e) {
              return this.gl.getAttribLocation(this.program, e);
            },
            setMatrixUniform: function (e, t) {
              var n = this.gl.getUniformLocation(this.program, e);
              this.gl.uniformMatrix4fv(n, !1, t);
            },
          }),
          e
        );
      })(),
      d = (function () {
        var e = null;
        function t(e, t, n) {
          (this.gl = e),
            (this.size = t),
            (this.texture = e.createTexture()),
            e.bindTexture(e.TEXTURE_2D, this.texture),
            (this.format = n || e.LUMINANCE),
            e.texImage2D(
              e.TEXTURE_2D,
              0,
              this.format,
              t.w,
              t.h,
              0,
              this.format,
              e.UNSIGNED_BYTE,
              null
            ),
            e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MAG_FILTER, e.NEAREST),
            e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MIN_FILTER, e.NEAREST),
            e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_S, e.CLAMP_TO_EDGE),
            e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_T, e.CLAMP_TO_EDGE);
        }
        return (
          (t.prototype = {
            fill: function (e, t) {
              var n = this.gl;
              n.bindTexture(n.TEXTURE_2D, this.texture),
                t
                  ? n.texSubImage2D(
                      n.TEXTURE_2D,
                      0,
                      0,
                      0,
                      this.size.w,
                      this.size.h,
                      this.format,
                      n.UNSIGNED_BYTE,
                      e
                    )
                  : n.texImage2D(
                      n.TEXTURE_2D,
                      0,
                      this.format,
                      this.size.w,
                      this.size.h,
                      0,
                      this.format,
                      n.UNSIGNED_BYTE,
                      e
                    );
            },
            bind: function (t, n, r) {
              var i = this.gl;
              e || (e = [i.TEXTURE0, i.TEXTURE1, i.TEXTURE2]),
                i.activeTexture(e[t]),
                i.bindTexture(i.TEXTURE_2D, this.texture),
                i.uniform1i(i.getUniformLocation(n.program, r), t);
            },
          }),
          t
        );
      })();
  },
  function (e, t) {
    e.exports = function () {
      return new Worker("/playerWasm/audioTalkWorker.worker.js");
    };
  },
  function (e, t, n) {
    "use strict";
    n.r(t);
    var r = n(0),
      i = 1e-6;
    function o() {}
    function a() {}
    function s() {}
    function l() {}
    (o.prototype = {
      e: function (e) {
        return e < 1 || e > this.elements.length ? null : this.elements[e - 1];
      },
      dimensions: function () {
        return this.elements.length;
      },
      modulus: function () {
        return Math.sqrt(this.dot(this));
      },
      eql: function (e) {
        var t = this.elements.length,
          n = e.elements || e;
        if (t != n.length) return !1;
        do {
          if (Math.abs(this.elements[t - 1] - n[t - 1]) > i) return !1;
        } while (--t);
        return !0;
      },
      dup: function () {
        return o.create(this.elements);
      },
      map: function (e) {
        var t = [];
        return (
          this.each(function (n, r) {
            t.push(e(n, r));
          }),
          o.create(t)
        );
      },
      each: function (e) {
        var t,
          n = this.elements.length,
          r = n;
        do {
          (t = r - n), e(this.elements[t], t + 1);
        } while (--n);
      },
      toUnitVector: function () {
        var e = this.modulus();
        return 0 === e
          ? this.dup()
          : this.map(function (t) {
              return t / e;
            });
      },
      angleFrom: function (e) {
        var t = e.elements || e,
          n = this.elements.length;
        if (n != t.length) return null;
        var r = 0,
          i = 0,
          o = 0;
        if (
          (this.each(function (e, n) {
            (r += e * t[n - 1]), (i += e * e), (o += t[n - 1] * t[n - 1]);
          }),
          (i = Math.sqrt(i)),
          (o = Math.sqrt(o)),
          i * o === 0)
        )
          return null;
        var a = r / (i * o);
        return a < -1 && (a = -1), a > 1 && (a = 1), Math.acos(a);
      },
      isParallelTo: function (e) {
        var t = this.angleFrom(e);
        return null === t ? null : t <= i;
      },
      isAntiparallelTo: function (e) {
        var t = this.angleFrom(e);
        return null === t ? null : Math.abs(t - Math.PI) <= i;
      },
      isPerpendicularTo: function (e) {
        var t = this.dot(e);
        return null === t ? null : Math.abs(t) <= i;
      },
      add: function (e) {
        var t = e.elements || e;
        return this.elements.length != t.length
          ? null
          : this.map(function (e, n) {
              return e + t[n - 1];
            });
      },
      subtract: function (e) {
        var t = e.elements || e;
        return this.elements.length != t.length
          ? null
          : this.map(function (e, n) {
              return e - t[n - 1];
            });
      },
      multiply: function (e) {
        return this.map(function (t) {
          return t * e;
        });
      },
      x: function (e) {
        return this.multiply(e);
      },
      dot: function (e) {
        var t = e.elements || e,
          n = 0,
          r = this.elements.length;
        if (r != t.length) return null;
        do {
          n += this.elements[r - 1] * t[r - 1];
        } while (--r);
        return n;
      },
      cross: function (e) {
        var t = e.elements || e;
        if (3 != this.elements.length || 3 != t.length) return null;
        var n = this.elements;
        return o.create([
          n[1] * t[2] - n[2] * t[1],
          n[2] * t[0] - n[0] * t[2],
          n[0] * t[1] - n[1] * t[0],
        ]);
      },
      max: function () {
        var e,
          t = 0,
          n = this.elements.length,
          r = n;
        do {
          (e = r - n),
            Math.abs(this.elements[e]) > Math.abs(t) && (t = this.elements[e]);
        } while (--n);
        return t;
      },
      indexOf: function (e) {
        var t,
          n = null,
          r = this.elements.length,
          i = r;
        do {
          (t = i - r), null === n && this.elements[t] == e && (n = t + 1);
        } while (--r);
        return n;
      },
      toDiagonalMatrix: function () {
        return a.Diagonal(this.elements);
      },
      round: function () {
        return this.map(function (e) {
          return Math.round(e);
        });
      },
      snapTo: function (e) {
        return this.map(function (t) {
          return Math.abs(t - e) <= i ? e : t;
        });
      },
      distanceFrom: function (e) {
        if (e.anchor) return e.distanceFrom(this);
        var t = e.elements || e;
        if (t.length != this.elements.length) return null;
        var n,
          r = 0;
        return (
          this.each(function (e, i) {
            (n = e - t[i - 1]), (r += n * n);
          }),
          Math.sqrt(r)
        );
      },
      liesOn: function (e) {
        return e.contains(this);
      },
      liesIn: function (e) {
        return e.contains(this);
      },
      rotate: function (e, t) {
        var n, r, i, s, l;
        switch (this.elements.length) {
          case 2:
            return 2 != (n = t.elements || t).length
              ? null
              : ((r = a.Rotation(e).elements),
                (i = this.elements[0] - n[0]),
                (s = this.elements[1] - n[1]),
                o.create([
                  n[0] + r[0][0] * i + r[0][1] * s,
                  n[1] + r[1][0] * i + r[1][1] * s,
                ]));
          case 3:
            if (!t.direction) return null;
            var u = t.pointClosestTo(this).elements;
            return (
              (r = a.Rotation(e, t.direction).elements),
              (i = this.elements[0] - u[0]),
              (s = this.elements[1] - u[1]),
              (l = this.elements[2] - u[2]),
              o.create([
                u[0] + r[0][0] * i + r[0][1] * s + r[0][2] * l,
                u[1] + r[1][0] * i + r[1][1] * s + r[1][2] * l,
                u[2] + r[2][0] * i + r[2][1] * s + r[2][2] * l,
              ])
            );
          default:
            return null;
        }
      },
      reflectionIn: function (e) {
        if (e.anchor) {
          var t = this.elements.slice(),
            n = e.pointClosestTo(t).elements;
          return o.create([
            n[0] + (n[0] - t[0]),
            n[1] + (n[1] - t[1]),
            n[2] + (n[2] - (t[2] || 0)),
          ]);
        }
        var r = e.elements || e;
        return this.elements.length != r.length
          ? null
          : this.map(function (e, t) {
              return r[t - 1] + (r[t - 1] - e);
            });
      },
      to3D: function () {
        var e = this.dup();
        switch (e.elements.length) {
          case 3:
            break;
          case 2:
            e.elements.push(0);
            break;
          default:
            return null;
        }
        return e;
      },
      inspect: function () {
        return "[" + this.elements.join(", ") + "]";
      },
      setElements: function (e) {
        return (this.elements = (e.elements || e).slice()), this;
      },
    }),
      (o.create = function (e) {
        return new o().setElements(e);
      }),
      (o.i = o.create([1, 0, 0])),
      (o.j = o.create([0, 1, 0])),
      (o.k = o.create([0, 0, 1])),
      (o.Random = function (e) {
        var t = [];
        do {
          t.push(Math.random());
        } while (--e);
        return o.create(t);
      }),
      (o.Zero = function (e) {
        var t = [];
        do {
          t.push(0);
        } while (--e);
        return o.create(t);
      }),
      (a.prototype = {
        e: function (e, t) {
          return e < 1 ||
            e > this.elements.length ||
            t < 1 ||
            t > this.elements[0].length
            ? null
            : this.elements[e - 1][t - 1];
        },
        row: function (e) {
          return e > this.elements.length
            ? null
            : o.create(this.elements[e - 1]);
        },
        col: function (e) {
          if (e > this.elements[0].length) return null;
          var t,
            n = [],
            r = this.elements.length,
            i = r;
          do {
            (t = i - r), n.push(this.elements[t][e - 1]);
          } while (--r);
          return o.create(n);
        },
        dimensions: function () {
          return { rows: this.elements.length, cols: this.elements[0].length };
        },
        rows: function () {
          return this.elements.length;
        },
        cols: function () {
          return this.elements[0].length;
        },
        eql: function (e) {
          var t = e.elements || e;
          if (
            ("undefined" == typeof t[0][0] && (t = a.create(t).elements),
            this.elements.length != t.length ||
              this.elements[0].length != t[0].length)
          )
            return !1;
          var n,
            r,
            o,
            s = this.elements.length,
            l = s,
            u = this.elements[0].length;
          do {
            (n = l - s), (r = u);
            do {
              if (((o = u - r), Math.abs(this.elements[n][o] - t[n][o]) > i))
                return !1;
            } while (--r);
          } while (--s);
          return !0;
        },
        dup: function () {
          return a.create(this.elements);
        },
        map: function (e) {
          var t,
            n,
            r,
            i = [],
            o = this.elements.length,
            s = o,
            l = this.elements[0].length;
          do {
            (n = l), (i[(t = s - o)] = []);
            do {
              (r = l - n), (i[t][r] = e(this.elements[t][r], t + 1, r + 1));
            } while (--n);
          } while (--o);
          return a.create(i);
        },
        isSameSizeAs: function (e) {
          var t = e.elements || e;
          return (
            "undefined" == typeof t[0][0] && (t = a.create(t).elements),
            this.elements.length == t.length &&
              this.elements[0].length == t[0].length
          );
        },
        add: function (e) {
          var t = e.elements || e;
          return (
            "undefined" == typeof t[0][0] && (t = a.create(t).elements),
            this.isSameSizeAs(t)
              ? this.map(function (e, n, r) {
                  return e + t[n - 1][r - 1];
                })
              : null
          );
        },
        subtract: function (e) {
          var t = e.elements || e;
          return (
            "undefined" == typeof t[0][0] && (t = a.create(t).elements),
            this.isSameSizeAs(t)
              ? this.map(function (e, n, r) {
                  return e - t[n - 1][r - 1];
                })
              : null
          );
        },
        canMultiplyFromLeft: function (e) {
          var t = e.elements || e;
          return (
            "undefined" == typeof t[0][0] && (t = a.create(t).elements),
            this.elements[0].length == t.length
          );
        },
        multiply: function (e) {
          if (!e.elements)
            return this.map(function (t) {
              return t * e;
            });
          var t = !!e.modulus;
          if (
            ("undefined" == typeof (p = e.elements || e)[0][0] &&
              (p = a.create(p).elements),
            !this.canMultiplyFromLeft(p))
          )
            return null;
          var n,
            r,
            i,
            o,
            s,
            l,
            u = this.elements.length,
            c = u,
            f = p[0].length,
            h = this.elements[0].length,
            d = [];
          do {
            (d[(n = c - u)] = []), (r = f);
            do {
              (i = f - r), (o = 0), (s = h);
              do {
                (l = h - s), (o += this.elements[n][l] * p[l][i]);
              } while (--s);
              d[n][i] = o;
            } while (--r);
          } while (--u);
          var p = a.create(d);
          return t ? p.col(1) : p;
        },
        x: function (e) {
          return this.multiply(e);
        },
        minor: function (e, t, n, r) {
          var i,
            o,
            s,
            l = [],
            u = n,
            c = this.elements.length,
            f = this.elements[0].length;
          do {
            (l[(i = n - u)] = []), (o = r);
            do {
              (s = r - o),
                (l[i][s] = this.elements[(e + i - 1) % c][(t + s - 1) % f]);
            } while (--o);
          } while (--u);
          return a.create(l);
        },
        transpose: function () {
          var e,
            t,
            n,
            r = this.elements.length,
            i = this.elements[0].length,
            o = [],
            s = i;
          do {
            (o[(e = i - s)] = []), (t = r);
            do {
              (n = r - t), (o[e][n] = this.elements[n][e]);
            } while (--t);
          } while (--s);
          return a.create(o);
        },
        isSquare: function () {
          return this.elements.length == this.elements[0].length;
        },
        max: function () {
          var e,
            t,
            n,
            r = 0,
            i = this.elements.length,
            o = i,
            a = this.elements[0].length;
          do {
            (e = o - i), (t = a);
            do {
              (n = a - t),
                Math.abs(this.elements[e][n]) > Math.abs(r) &&
                  (r = this.elements[e][n]);
            } while (--t);
          } while (--i);
          return r;
        },
        indexOf: function (e) {
          var t,
            n,
            r,
            i = this.elements.length,
            o = i,
            a = this.elements[0].length;
          do {
            (t = o - i), (n = a);
            do {
              if (((r = a - n), this.elements[t][r] == e))
                return { i: t + 1, j: r + 1 };
            } while (--n);
          } while (--i);
          return null;
        },
        diagonal: function () {
          if (!this.isSquare) return null;
          var e,
            t = [],
            n = this.elements.length,
            r = n;
          do {
            (e = r - n), t.push(this.elements[e][e]);
          } while (--n);
          return o.create(t);
        },
        toRightTriangular: function () {
          var e,
            t,
            n,
            r,
            i = this.dup(),
            o = this.elements.length,
            a = o,
            s = this.elements[0].length;
          do {
            if (((t = a - o), 0 == i.elements[t][t]))
              for (j = t + 1; j < a; j++)
                if (0 != i.elements[j][t]) {
                  (e = []), (n = s);
                  do {
                    (r = s - n), e.push(i.elements[t][r] + i.elements[j][r]);
                  } while (--n);
                  i.elements[t] = e;
                  break;
                }
            if (0 != i.elements[t][t])
              for (j = t + 1; j < a; j++) {
                var l = i.elements[j][t] / i.elements[t][t];
                (e = []), (n = s);
                do {
                  (r = s - n),
                    e.push(
                      r <= t ? 0 : i.elements[j][r] - i.elements[t][r] * l
                    );
                } while (--n);
                i.elements[j] = e;
              }
          } while (--o);
          return i;
        },
        toUpperTriangular: function () {
          return this.toRightTriangular();
        },
        determinant: function () {
          if (!this.isSquare()) return null;
          var e,
            t = this.toRightTriangular(),
            n = t.elements[0][0],
            r = t.elements.length - 1,
            i = r;
          do {
            (e = i - r + 1), (n *= t.elements[e][e]);
          } while (--r);
          return n;
        },
        det: function () {
          return this.determinant();
        },
        isSingular: function () {
          return this.isSquare() && 0 === this.determinant();
        },
        trace: function () {
          if (!this.isSquare()) return null;
          var e,
            t = this.elements[0][0],
            n = this.elements.length - 1,
            r = n;
          do {
            (e = r - n + 1), (t += this.elements[e][e]);
          } while (--n);
          return t;
        },
        tr: function () {
          return this.trace();
        },
        rank: function () {
          var e,
            t,
            n,
            r = this.toRightTriangular(),
            o = 0,
            a = this.elements.length,
            s = a,
            l = this.elements[0].length;
          do {
            (e = s - a), (t = l);
            do {
              if (((n = l - t), Math.abs(r.elements[e][n]) > i)) {
                o++;
                break;
              }
            } while (--t);
          } while (--a);
          return o;
        },
        rk: function () {
          return this.rank();
        },
        augment: function (e) {
          var t = e.elements || e;
          "undefined" == typeof t[0][0] && (t = a.create(t).elements);
          var n,
            r,
            i,
            o = this.dup(),
            s = o.elements[0].length,
            l = o.elements.length,
            u = l,
            c = t[0].length;
          if (l != t.length) return null;
          do {
            (n = u - l), (r = c);
            do {
              (i = c - r), (o.elements[n][s + i] = t[n][i]);
            } while (--r);
          } while (--l);
          return o;
        },
        inverse: function () {
          if (!this.isSquare() || this.isSingular()) return null;
          var e,
            t,
            n,
            r,
            i,
            o,
            s,
            l = this.elements.length,
            u = l,
            c = this.augment(a.I(l)).toRightTriangular(),
            f = c.elements[0].length,
            h = [];
          do {
            (i = []), (n = f), (h[(e = l - 1)] = []), (o = c.elements[e][e]);
            do {
              (r = f - n),
                (s = c.elements[e][r] / o),
                i.push(s),
                r >= u && h[e].push(s);
            } while (--n);
            for (c.elements[e] = i, t = 0; t < e; t++) {
              (i = []), (n = f);
              do {
                (r = f - n),
                  i.push(
                    c.elements[t][r] - c.elements[e][r] * c.elements[t][e]
                  );
              } while (--n);
              c.elements[t] = i;
            }
          } while (--l);
          return a.create(h);
        },
        inv: function () {
          return this.inverse();
        },
        round: function () {
          return this.map(function (e) {
            return Math.round(e);
          });
        },
        snapTo: function (e) {
          return this.map(function (t) {
            return Math.abs(t - e) <= i ? e : t;
          });
        },
        inspect: function () {
          var e,
            t = [],
            n = this.elements.length,
            r = n;
          do {
            (e = r - n), t.push(o.create(this.elements[e]).inspect());
          } while (--n);
          return t.join("\n");
        },
        setElements: function (e) {
          var t,
            n = e.elements || e;
          if ("undefined" != typeof n[0][0]) {
            var r,
              i,
              o,
              a = n.length,
              s = a;
            this.elements = [];
            do {
              (i = r = n[(t = s - a)].length), (this.elements[t] = []);
              do {
                (o = i - r), (this.elements[t][o] = n[t][o]);
              } while (--r);
            } while (--a);
            return this;
          }
          var l = n.length,
            u = l;
          this.elements = [];
          do {
            (t = u - l), this.elements.push([n[t]]);
          } while (--l);
          return this;
        },
      }),
      (a.create = function (e) {
        return new a().setElements(e);
      }),
      (a.I = function (e) {
        var t,
          n,
          r,
          i = [],
          o = e;
        do {
          (i[(t = o - e)] = []), (n = o);
          do {
            (r = o - n), (i[t][r] = t == r ? 1 : 0);
          } while (--n);
        } while (--e);
        return a.create(i);
      }),
      (a.Diagonal = function (e) {
        var t,
          n = e.length,
          r = n,
          i = a.I(n);
        do {
          (t = r - n), (i.elements[t][t] = e[t]);
        } while (--n);
        return i;
      }),
      (a.Rotation = function (e, t) {
        if (!t)
          return a.create([
            [Math.cos(e), -Math.sin(e)],
            [Math.sin(e), Math.cos(e)],
          ]);
        var n = t.dup();
        if (3 != n.elements.length) return null;
        var r = n.modulus(),
          i = n.elements[0] / r,
          o = n.elements[1] / r,
          s = n.elements[2] / r,
          l = Math.sin(e),
          u = Math.cos(e),
          c = 1 - u;
        return a.create([
          [c * i * i + u, c * i * o - l * s, c * i * s + l * o],
          [c * i * o + l * s, c * o * o + u, c * o * s - l * i],
          [c * i * s - l * o, c * o * s + l * i, c * s * s + u],
        ]);
      }),
      (a.RotationX = function (e) {
        var t = Math.cos(e),
          n = Math.sin(e);
        return a.create([
          [1, 0, 0],
          [0, t, -n],
          [0, n, t],
        ]);
      }),
      (a.RotationY = function (e) {
        var t = Math.cos(e),
          n = Math.sin(e);
        return a.create([
          [t, 0, n],
          [0, 1, 0],
          [-n, 0, t],
        ]);
      }),
      (a.RotationZ = function (e) {
        var t = Math.cos(e),
          n = Math.sin(e);
        return a.create([
          [t, -n, 0],
          [n, t, 0],
          [0, 0, 1],
        ]);
      }),
      (a.Random = function (e, t) {
        return a.Zero(e, t).map(function () {
          return Math.random();
        });
      }),
      (a.Zero = function (e, t) {
        var n,
          r,
          i,
          o = [],
          s = e;
        do {
          (o[(n = e - s)] = []), (r = t);
          do {
            (i = t - r), (o[n][i] = 0);
          } while (--r);
        } while (--s);
        return a.create(o);
      }),
      (s.prototype = {
        eql: function (e) {
          return this.isParallelTo(e) && this.contains(e.anchor);
        },
        dup: function () {
          return s.create(this.anchor, this.direction);
        },
        translate: function (e) {
          var t = e.elements || e;
          return s.create(
            [
              this.anchor.elements[0] + t[0],
              this.anchor.elements[1] + t[1],
              this.anchor.elements[2] + (t[2] || 0),
            ],
            this.direction
          );
        },
        isParallelTo: function (e) {
          if (e.normal) return e.isParallelTo(this);
          var t = this.direction.angleFrom(e.direction);
          return Math.abs(t) <= i || Math.abs(t - Math.PI) <= i;
        },
        distanceFrom: function (e) {
          if (e.normal) return e.distanceFrom(this);
          if (e.direction) {
            if (this.isParallelTo(e)) return this.distanceFrom(e.anchor);
            var t = this.direction.cross(e.direction).toUnitVector().elements,
              n = this.anchor.elements,
              r = e.anchor.elements;
            return Math.abs(
              (n[0] - r[0]) * t[0] + (n[1] - r[1]) * t[1] + (n[2] - r[2]) * t[2]
            );
          }
          var i = e.elements || e,
            o = ((n = this.anchor.elements), this.direction.elements),
            a = i[0] - n[0],
            s = i[1] - n[1],
            l = (i[2] || 0) - n[2],
            u = Math.sqrt(a * a + s * s + l * l);
          if (0 === u) return 0;
          var c = (a * o[0] + s * o[1] + l * o[2]) / u,
            f = 1 - c * c;
          return Math.abs(u * Math.sqrt(f < 0 ? 0 : f));
        },
        contains: function (e) {
          var t = this.distanceFrom(e);
          return null !== t && t <= i;
        },
        liesIn: function (e) {
          return e.contains(this);
        },
        intersects: function (e) {
          return e.normal
            ? e.intersects(this)
            : !this.isParallelTo(e) && this.distanceFrom(e) <= i;
        },
        intersectionWith: function (e) {
          if (e.normal) return e.intersectionWith(this);
          if (!this.intersects(e)) return null;
          var t = this.anchor.elements,
            n = this.direction.elements,
            r = e.anchor.elements,
            i = e.direction.elements,
            a = n[0],
            s = n[1],
            l = n[2],
            u = i[0],
            c = i[1],
            f = i[2],
            h = t[0] - r[0],
            d = t[1] - r[1],
            p = t[2] - r[2],
            m = u * u + c * c + f * f,
            g = a * u + s * c + l * f,
            v =
              (((-a * h - s * d - l * p) * m) / (a * a + s * s + l * l) +
                g * (u * h + c * d + f * p)) /
              (m - g * g);
          return o.create([t[0] + v * a, t[1] + v * s, t[2] + v * l]);
        },
        pointClosestTo: function (e) {
          if (e.direction) {
            if (this.intersects(e)) return this.intersectionWith(e);
            if (this.isParallelTo(e)) return null;
            var t = this.direction.elements,
              n = e.direction.elements,
              r = t[0],
              i = t[1],
              a = t[2],
              s = n[0],
              u = n[1],
              c = n[2],
              f = a * s - r * c,
              h = r * u - i * s,
              d = i * c - a * u,
              p = o.create([f * c - h * u, h * s - d * c, d * u - f * s]);
            return (m = l.create(e.anchor, p)).intersectionWith(this);
          }
          var m = e.elements || e;
          if (this.contains(m)) return o.create(m);
          var g = this.anchor.elements,
            v =
              ((r = (t = this.direction.elements)[0]),
              (i = t[1]),
              (a = t[2]),
              g[0]),
            y = g[1],
            S = g[2],
            w =
              ((f = r * (m[1] - y) - i * (m[0] - v)),
              (h = i * ((m[2] || 0) - S) - a * (m[1] - y)),
              (d = a * (m[0] - v) - r * ((m[2] || 0) - S)),
              o.create([i * f - a * d, a * h - r * f, r * d - i * h])),
            b = this.distanceFrom(m) / w.modulus();
          return o.create([
            m[0] + w.elements[0] * b,
            m[1] + w.elements[1] * b,
            (m[2] || 0) + w.elements[2] * b,
          ]);
        },
        rotate: function (e, t) {
          "undefined" == typeof t.direction && (t = s.create(t.to3D(), o.k));
          var n = a.Rotation(e, t.direction).elements,
            r = t.pointClosestTo(this.anchor).elements,
            i = this.anchor.elements,
            l = this.direction.elements,
            u = r[0],
            c = r[1],
            f = r[2],
            h = i[0] - u,
            d = i[1] - c,
            p = i[2] - f;
          return s.create(
            [
              u + n[0][0] * h + n[0][1] * d + n[0][2] * p,
              c + n[1][0] * h + n[1][1] * d + n[1][2] * p,
              f + n[2][0] * h + n[2][1] * d + n[2][2] * p,
            ],
            [
              n[0][0] * l[0] + n[0][1] * l[1] + n[0][2] * l[2],
              n[1][0] * l[0] + n[1][1] * l[1] + n[1][2] * l[2],
              n[2][0] * l[0] + n[2][1] * l[1] + n[2][2] * l[2],
            ]
          );
        },
        reflectionIn: function (e) {
          if (e.normal) {
            var t = this.anchor.elements,
              n = this.direction.elements,
              r = t[0],
              i = t[1],
              o = t[2],
              a = n[0],
              l = n[1],
              u = n[2],
              c = this.anchor.reflectionIn(e).elements,
              f = r + a,
              h = i + l,
              d = o + u,
              p = e.pointClosestTo([f, h, d]).elements,
              m = [
                p[0] + (p[0] - f) - c[0],
                p[1] + (p[1] - h) - c[1],
                p[2] + (p[2] - d) - c[2],
              ];
            return s.create(c, m);
          }
          if (e.direction) return this.rotate(Math.PI, e);
          var g = e.elements || e;
          return s.create(
            this.anchor.reflectionIn([g[0], g[1], g[2] || 0]),
            this.direction
          );
        },
        setVectors: function (e, t) {
          if (
            ((e = o.create(e)),
            (t = o.create(t)),
            2 == e.elements.length && e.elements.push(0),
            2 == t.elements.length && t.elements.push(0),
            e.elements.length > 3 || t.elements.length > 3)
          )
            return null;
          var n = t.modulus();
          return 0 === n
            ? null
            : ((this.anchor = e),
              (this.direction = o.create([
                t.elements[0] / n,
                t.elements[1] / n,
                t.elements[2] / n,
              ])),
              this);
        },
      }),
      (s.create = function (e, t) {
        return new s().setVectors(e, t);
      }),
      (s.X = s.create(o.Zero(3), o.i)),
      (s.Y = s.create(o.Zero(3), o.j)),
      (s.Z = s.create(o.Zero(3), o.k)),
      (l.prototype = {
        eql: function (e) {
          return this.contains(e.anchor) && this.isParallelTo(e);
        },
        dup: function () {
          return l.create(this.anchor, this.normal);
        },
        translate: function (e) {
          var t = e.elements || e;
          return l.create(
            [
              this.anchor.elements[0] + t[0],
              this.anchor.elements[1] + t[1],
              this.anchor.elements[2] + (t[2] || 0),
            ],
            this.normal
          );
        },
        isParallelTo: function (e) {
          var t;
          return e.normal
            ? ((t = this.normal.angleFrom(e.normal)),
              Math.abs(t) <= i || Math.abs(Math.PI - t) <= i)
            : e.direction
            ? this.normal.isPerpendicularTo(e.direction)
            : null;
        },
        isPerpendicularTo: function (e) {
          var t = this.normal.angleFrom(e.normal);
          return Math.abs(Math.PI / 2 - t) <= i;
        },
        distanceFrom: function (e) {
          if (this.intersects(e) || this.contains(e)) return 0;
          if (e.anchor) {
            var t = this.anchor.elements,
              n = e.anchor.elements,
              r = this.normal.elements;
            return Math.abs(
              (t[0] - n[0]) * r[0] + (t[1] - n[1]) * r[1] + (t[2] - n[2]) * r[2]
            );
          }
          var i = e.elements || e;
          (t = this.anchor.elements), (r = this.normal.elements);
          return Math.abs(
            (t[0] - i[0]) * r[0] +
              (t[1] - i[1]) * r[1] +
              (t[2] - (i[2] || 0)) * r[2]
          );
        },
        contains: function (e) {
          if (e.normal) return null;
          if (e.direction)
            return (
              this.contains(e.anchor) &&
              this.contains(e.anchor.add(e.direction))
            );
          var t = e.elements || e,
            n = this.anchor.elements,
            r = this.normal.elements;
          return (
            Math.abs(
              r[0] * (n[0] - t[0]) +
                r[1] * (n[1] - t[1]) +
                r[2] * (n[2] - (t[2] || 0))
            ) <= i
          );
        },
        intersects: function (e) {
          return "undefined" == typeof e.direction &&
            "undefined" == typeof e.normal
            ? null
            : !this.isParallelTo(e);
        },
        intersectionWith: function (e) {
          if (!this.intersects(e)) return null;
          if (e.direction) {
            var t = e.anchor.elements,
              n = e.direction.elements,
              r = this.anchor.elements,
              i =
                ((u = this.normal.elements)[0] * (r[0] - t[0]) +
                  u[1] * (r[1] - t[1]) +
                  u[2] * (r[2] - t[2])) /
                (u[0] * n[0] + u[1] * n[1] + u[2] * n[2]);
            return o.create([
              t[0] + n[0] * i,
              t[1] + n[1] * i,
              t[2] + n[2] * i,
            ]);
          }
          if (e.normal) {
            for (
              var l = this.normal.cross(e.normal).toUnitVector(),
                u = this.normal.elements,
                c = ((t = this.anchor.elements), e.normal.elements),
                f = e.anchor.elements,
                h = a.Zero(2, 2),
                d = 0;
              h.isSingular();

            )
              d++,
                (h = a.create([
                  [u[d % 3], u[(d + 1) % 3]],
                  [c[d % 3], c[(d + 1) % 3]],
                ]));
            for (
              var p = h.inverse().elements,
                m = u[0] * t[0] + u[1] * t[1] + u[2] * t[2],
                g = c[0] * f[0] + c[1] * f[1] + c[2] * f[2],
                v = [p[0][0] * m + p[0][1] * g, p[1][0] * m + p[1][1] * g],
                y = [],
                S = 1;
              S <= 3;
              S++
            )
              y.push(d == S ? 0 : v[(S + ((5 - d) % 3)) % 3]);
            return s.create(y, l);
          }
        },
        pointClosestTo: function (e) {
          var t = e.elements || e,
            n = this.anchor.elements,
            r = this.normal.elements,
            i =
              (n[0] - t[0]) * r[0] +
              (n[1] - t[1]) * r[1] +
              (n[2] - (t[2] || 0)) * r[2];
          return o.create([
            t[0] + r[0] * i,
            t[1] + r[1] * i,
            (t[2] || 0) + r[2] * i,
          ]);
        },
        rotate: function (e, t) {
          var n = a.Rotation(e, t.direction).elements,
            r = t.pointClosestTo(this.anchor).elements,
            i = this.anchor.elements,
            o = this.normal.elements,
            s = r[0],
            u = r[1],
            c = r[2],
            f = i[0] - s,
            h = i[1] - u,
            d = i[2] - c;
          return l.create(
            [
              s + n[0][0] * f + n[0][1] * h + n[0][2] * d,
              u + n[1][0] * f + n[1][1] * h + n[1][2] * d,
              c + n[2][0] * f + n[2][1] * h + n[2][2] * d,
            ],
            [
              n[0][0] * o[0] + n[0][1] * o[1] + n[0][2] * o[2],
              n[1][0] * o[0] + n[1][1] * o[1] + n[1][2] * o[2],
              n[2][0] * o[0] + n[2][1] * o[1] + n[2][2] * o[2],
            ]
          );
        },
        reflectionIn: function (e) {
          if (e.normal) {
            var t = this.anchor.elements,
              n = this.normal.elements,
              r = t[0],
              i = t[1],
              o = t[2],
              a = n[0],
              s = n[1],
              u = n[2],
              c = this.anchor.reflectionIn(e).elements,
              f = r + a,
              h = i + s,
              d = o + u,
              p = e.pointClosestTo([f, h, d]).elements,
              m = [
                p[0] + (p[0] - f) - c[0],
                p[1] + (p[1] - h) - c[1],
                p[2] + (p[2] - d) - c[2],
              ];
            return l.create(c, m);
          }
          if (e.direction) return this.rotate(Math.PI, e);
          var g = e.elements || e;
          return l.create(
            this.anchor.reflectionIn([g[0], g[1], g[2] || 0]),
            this.normal
          );
        },
        setVectors: function (e, t, n) {
          if (null === (e = (e = o.create(e)).to3D())) return null;
          if (null === (t = (t = o.create(t)).to3D())) return null;
          if ("undefined" == typeof n) n = null;
          else if (null === (n = (n = o.create(n)).to3D())) return null;
          var r,
            i,
            a = e.elements[0],
            s = e.elements[1],
            l = e.elements[2],
            u = t.elements[0],
            c = t.elements[1],
            f = t.elements[2];
          if (null !== n) {
            var h = n.elements[0],
              d = n.elements[1],
              p = n.elements[2];
            if (
              0 ===
              (i = (r = o.create([
                (c - s) * (p - l) - (f - l) * (d - s),
                (f - l) * (h - a) - (u - a) * (p - l),
                (u - a) * (d - s) - (c - s) * (h - a),
              ])).modulus())
            )
              return null;
            r = o.create([
              r.elements[0] / i,
              r.elements[1] / i,
              r.elements[2] / i,
            ]);
          } else {
            if (0 === (i = Math.sqrt(u * u + c * c + f * f))) return null;
            r = o.create([
              t.elements[0] / i,
              t.elements[1] / i,
              t.elements[2] / i,
            ]);
          }
          return (this.anchor = e), (this.normal = r), this;
        },
      }),
      (a.Translation = function (e) {
        var t;
        if (2 === e.elements.length)
          return (
            ((t = a.I(3)).elements[2][0] = e.elements[0]),
            (t.elements[2][1] = e.elements[1]),
            t
          );
        if (3 === e.elements.length)
          return (
            ((t = a.I(4)).elements[0][3] = e.elements[0]),
            (t.elements[1][3] = e.elements[1]),
            (t.elements[2][3] = e.elements[2]),
            t
          );
        throw "Invalid length for Translation";
      }),
      (a.prototype.flatten = function () {
        var e = [];
        if (0 === this.elements.length) return [];
        for (var t = 0; t < this.elements[0].length; t++)
          for (var n = 0; n < this.elements.length; n++)
            e.push(this.elements[n][t]);
        return e;
      }),
      (a.prototype.ensure4x4 = function () {
        var e;
        if (4 === this.elements.length && 4 === this.elements[0].length)
          return this;
        if (this.elements.length > 4 || this.elements[0].length > 4)
          return null;
        for (e = 0; e < this.elements.length; e++)
          for (var t = this.elements[e].length; t < 4; t++)
            e === t ? this.elements[e].push(1) : this.elements[e].push(0);
        for (e = this.elements.length; e < 4; e++)
          0 === e
            ? this.elements.push([1, 0, 0, 0])
            : 1 === e
            ? this.elements.push([0, 1, 0, 0])
            : 2 === e
            ? this.elements.push([0, 0, 1, 0])
            : 3 === e && this.elements.push([0, 0, 0, 1]);
        return this;
      }),
      (a.prototype.make3x3 = function () {
        return 4 !== this.elements.length || 4 !== this.elements[0].length
          ? null
          : a.create([
              [this.elements[0][0], this.elements[0][1], this.elements[0][2]],
              [this.elements[1][0], this.elements[1][1], this.elements[1][2]],
              [this.elements[2][0], this.elements[2][1], this.elements[2][2]],
            ]);
      }),
      (l.create = function (e, t, n) {
        return new l().setVectors(e, t, n);
      }),
      (l.XY = l.create(o.Zero(3), o.k)),
      (l.YZ = l.create(o.Zero(3), o.i)),
      (l.ZX = l.create(o.Zero(3), o.j)),
      (l.YX = l.XY),
      (l.ZY = l.YZ),
      (l.XZ = l.ZX);
    var u = o.create,
      c = a.create,
      f =
        (s.create,
        l.create,
        (function () {
          function e(e, t, n) {
            r.e.call(this, e, t, n);
          }
          return (
            (e.prototype = p(r.e, {
              fill: function (e, t) {
                var n = this.gl;
                n.bindTexture(n.TEXTURE_2D, this.texture),
                  t
                    ? n.texSubImage2D(
                        n.TEXTURE_2D,
                        0,
                        0,
                        0,
                        this.size.w,
                        this.size.h,
                        this.format,
                        n.UNSIGNED_BYTE,
                        e
                      )
                    : n.texImage2D(
                        n.TEXTURE_2D,
                        0,
                        this.format,
                        this.format,
                        n.UNSIGNED_BYTE,
                        e
                      );
              },
            })),
            e
          );
        })()),
      h = (function () {
        var e = r.c.createFromSource(
            "x-shader/x-vertex",
            m([
              "attribute vec3 aVertexPosition;",
              "attribute vec2 aTextureCoord;",
              "uniform mat4 uMVMatrix;",
              "uniform mat4 uPMatrix;",
              "varying highp vec2 vTextureCoord;",
              "void main(void) {",
              "  gl_Position = uPMatrix * uMVMatrix * vec4(aVertexPosition, 1.0);",
              "  vTextureCoord = aTextureCoord;",
              "}",
            ])
          ),
          t = r.c.createFromSource(
            "x-shader/x-fragment",
            m([
              "precision highp float;",
              "varying highp vec2 vTextureCoord;",
              "uniform sampler2D texture;",
              "void main(void) {",
              "  gl_FragColor = texture2D(texture, vTextureCoord);",
              "}",
            ])
          );
        function n(e, t, n) {
          (this.canvas = e),
            (this.size = t),
            (this.canvas.width = t.w),
            (this.canvas.height = t.h),
            this.onInitWebGL(),
            this.onInitShaders(),
            function () {
              var e = [1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1, 0],
                t = this.gl;
              (this.quadVPBuffer = t.createBuffer()),
                t.bindBuffer(t.ARRAY_BUFFER, this.quadVPBuffer),
                t.bufferData(
                  t.ARRAY_BUFFER,
                  new Float32Array(e),
                  t.STATIC_DRAW
                ),
                (this.quadVPBuffer.itemSize = 3),
                (this.quadVPBuffer.numItems = 4);
              (this.quadVTCBuffer = t.createBuffer()),
                t.bindBuffer(t.ARRAY_BUFFER, this.quadVTCBuffer),
                (e = [1, 0, 0, 0, 1, 1, 0, 1]),
                t.bufferData(
                  t.ARRAY_BUFFER,
                  new Float32Array(e),
                  t.STATIC_DRAW
                );
            }.call(this),
            n &&
              function () {
                var e = this.gl;
                (this.framebuffer = e.createFramebuffer()),
                  e.bindFramebuffer(e.FRAMEBUFFER, this.framebuffer),
                  (this.framebufferTexture = new r.e(
                    this.gl,
                    this.size,
                    e.RGBA
                  ));
                var t = e.createRenderbuffer();
                e.bindRenderbuffer(e.RENDERBUFFER, t),
                  e.renderbufferStorage(
                    e.RENDERBUFFER,
                    e.DEPTH_COMPONENT16,
                    this.size.w,
                    this.size.h
                  ),
                  e.framebufferTexture2D(
                    e.FRAMEBUFFER,
                    e.COLOR_ATTACHMENT0,
                    e.TEXTURE_2D,
                    this.framebufferTexture.texture,
                    0
                  ),
                  e.framebufferRenderbuffer(
                    e.FRAMEBUFFER,
                    e.DEPTH_ATTACHMENT,
                    e.RENDERBUFFER,
                    t
                  );
              }.call(this),
            this.onInitTextures(),
            function () {
              var e = this.gl;
              (this.perspectiveMatrix = (function (e, t, n, r) {
                var i = n * Math.tan((e * Math.PI) / 360),
                  o = -i;
                return (function (e, t, n, r, i, o) {
                  return c([
                    [(2 * i) / (t - e), 0, (t + e) / (t - e), 0],
                    [0, (2 * i) / (r - n), (r + n) / (r - n), 0],
                    [0, 0, -(o + i) / (o - i), (-2 * o * i) / (o - i)],
                    [0, 0, -1, 0],
                  ]);
                })(o * t, i * t, o, i, n, r);
              })(45, 1, 0.1, 100)),
                i.call(this),
                o.call(this, [0, 0, -2.415]),
                e.bindBuffer(e.ARRAY_BUFFER, this.quadVPBuffer),
                e.vertexAttribPointer(
                  this.vertexPositionAttribute,
                  3,
                  e.FLOAT,
                  !1,
                  0,
                  0
                ),
                e.bindBuffer(e.ARRAY_BUFFER, this.quadVTCBuffer),
                e.vertexAttribPointer(
                  this.textureCoordAttribute,
                  2,
                  e.FLOAT,
                  !1,
                  0,
                  0
                ),
                this.onInitSceneTextures(),
                s.call(this),
                this.framebuffer &&
                  e.bindFramebuffer(e.FRAMEBUFFER, this.framebuffer);
            }.call(this);
        }
        function i() {
          this.mvMatrix = a.I(4);
        }
        function o(e) {
          (function (e) {
            this.mvMatrix = this.mvMatrix.x(e);
          }).call(this, a.Translation(u([e[0], e[1], e[2]])).ensure4x4());
        }
        function s() {
          this.program.setMatrixUniform(
            "uPMatrix",
            new Float32Array(this.perspectiveMatrix.flatten())
          ),
            this.program.setMatrixUniform(
              "uMVMatrix",
              new Float32Array(this.mvMatrix.flatten())
            );
        }
        return (
          (n.prototype = {
            toString: function () {
              return "WebGLCanvas Size: " + this.size;
            },
            checkLastError: function (e) {
              var t = this.gl.getError();
              if (t !== this.gl.NO_ERROR) {
                var n = this.glNames[t];
                (n =
                  "undefined" !== typeof n
                    ? n + "(" + t + ")"
                    : "Unknown WebGL ENUM (0x" + value.toString(16) + ")"),
                  e
                    ? r.h.log("WebGL Error: %s, %s", e, n)
                    : r.h.log("WebGL Error: %s", n),
                  r.h.trace();
              }
            },
            onInitWebGL: function () {
              try {
                this.gl = this.canvas.getContext("webgl");
              } catch (e) {
                r.h.log("inInitWebGL error = " + e);
              }
              if (
                (this.gl ||
                  r.h.error(
                    "Unable to initialize WebGL. Your browser may not support it."
                  ),
                !this.glNames)
              )
                for (var e in ((this.glNames = {}), this.gl))
                  "number" === typeof this.gl[e] &&
                    (this.glNames[this.gl[e]] = e);
            },
            onInitShaders: function () {
              (this.program = new r.b(this.gl)),
                this.program.attach(new r.d(this.gl, e)),
                this.program.attach(new r.d(this.gl, t)),
                this.program.link(),
                this.program.use(),
                (this.vertexPositionAttribute =
                  this.program.getAttributeLocation("aVertexPosition")),
                this.gl.enableVertexAttribArray(this.vertexPositionAttribute),
                (this.textureCoordAttribute =
                  this.program.getAttributeLocation("aTextureCoord")),
                this.gl.enableVertexAttribArray(this.textureCoordAttribute);
            },
            onInitTextures: function () {
              var e = this.gl;
              e.viewport(0, 0, this.canvas.width, this.canvas.height),
                (this.texture = new r.e(e, this.size, e.RGBA));
            },
            onInitSceneTextures: function () {
              this.texture.bind(0, this.program, "texture");
            },
            drawScene: function () {
              this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
            },
            updateVertexArray: function (e) {
              this.zoomScene(e);
            },
            readPixels: function (e) {
              var t = this.gl;
              t.readPixels(
                0,
                0,
                this.size.w,
                this.size.h,
                t.RGBA,
                t.UNSIGNED_BYTE,
                e
              );
            },
            zoomScene: function (e) {
              i.call(this),
                o.call(this, [e[0], e[1], e[2]]),
                s.call(this),
                this.drawScene();
            },
            setViewport: function (e, t) {
              var n, i;
              r.h.log("toWidth=" + e + ",toHeight=" + t),
                this.gl.drawingBufferWidth < e ||
                this.gl.drawingBufferHeight < t
                  ? ((n = this.gl.drawingBufferWidth),
                    (i = this.gl.drawingBufferHeight),
                    (this.canvas.width = n),
                    (this.canvas.height = i))
                  : ((n = e), (i = t)),
                this.gl.viewport(0, 0, n, i);
            },
            clearCanvas: function () {
              this.gl.clearColor(0, 0, 0, 1),
                this.gl.clear(
                  this.gl.DEPTH_BUFFER_BIT | this.gl.COLOR_BUFFER_BIT
                );
            },
          }),
          n
        );
      })(),
      d =
        ((function () {
          function e(e, t) {
            h.call(this, e, t);
          }
          e.prototype = p(h, {
            drawCanvas: function (e) {
              this.texture.fill(e), this.drawScene();
            },
            onInitTextures: function () {
              var e = this.gl;
              this.setViewport(this.canvas.width, this.canvas.height),
                (this.texture = new f(e, this.size, e.RGBA));
            },
            initCanvas: function () {
              this.gl.clear(
                this.gl.DEPTH_BUFFER_BIT | this.gl.COLOR_BUFFER_BIT
              );
            },
          });
        })(),
        (function () {
          var e = r.c.createFromSource(
              "x-shader/x-vertex",
              m([
                "attribute vec3 aVertexPosition;",
                "attribute vec2 aTextureCoord;",
                "uniform mat4 uMVMatrix;",
                "uniform mat4 uPMatrix;",
                "varying highp vec2 vTextureCoord;",
                "void main(void) {",
                "  gl_Position = uPMatrix * uMVMatrix * vec4(aVertexPosition, 1.0);",
                "  vTextureCoord = aTextureCoord;",
                "}",
              ])
            ),
            t = r.c.createFromSource(
              "x-shader/x-fragment",
              m([
                "precision highp float;",
                "varying highp vec2 vTextureCoord;",
                "uniform sampler2D YTexture;",
                "uniform sampler2D UTexture;",
                "uniform sampler2D VTexture;",
                "const mat4 YUV2RGB = mat4",
                "(",
                " 1.16438, 0.00000, 1.59603, -.87079,",
                " 1.16438, -.39176, -.81297, .52959,",
                " 1.16438, 2.01723, 0, -1.08139,",
                " 0, 0, 0, 1",
                ");",
                "void main(void) {",
                " gl_FragColor = vec4( texture2D(YTexture,  vTextureCoord).x, texture2D(UTexture, vTextureCoord).x, texture2D(VTexture, vTextureCoord).x, 1) * YUV2RGB;",
                "}",
              ])
            );
          function n(e, t) {
            h.call(this, e, t);
          }
          return (
            (n.prototype = p(h, {
              onInitShaders: function () {
                (this.program = new r.b(this.gl)),
                  this.program.attach(new r.d(this.gl, e)),
                  this.program.attach(new r.d(this.gl, t)),
                  this.program.link(),
                  this.program.use(),
                  (this.vertexPositionAttribute =
                    this.program.getAttributeLocation("aVertexPosition")),
                  this.gl.enableVertexAttribArray(this.vertexPositionAttribute),
                  (this.textureCoordAttribute =
                    this.program.getAttributeLocation("aTextureCoord")),
                  this.gl.enableVertexAttribArray(this.textureCoordAttribute);
              },
              onInitTextures: function () {
                this.setViewport(this.size.w, this.size.h),
                  (this.YTexture = new r.e(this.gl, this.size)),
                  (this.UTexture = new r.e(this.gl, this.size.getHalfSize())),
                  (this.VTexture = new r.e(this.gl, this.size.getHalfSize()));
              },
              onInitSceneTextures: function () {
                this.YTexture.bind(0, this.program, "YTexture"),
                  this.UTexture.bind(1, this.program, "UTexture"),
                  this.VTexture.bind(2, this.program, "VTexture");
              },
              fillYUVTextures: function (e, t, n) {
                this.YTexture.fill(e),
                  this.UTexture.fill(t),
                  this.VTexture.fill(n),
                  this.drawScene();
              },
              drawCanvas: function (e, t, n) {
                this.YTexture.fill(e),
                  this.UTexture.fill(t),
                  this.VTexture.fill(n),
                  this.drawScene();
              },
              updateVertexArray: function (e) {
                this.zoomScene(e);
              },
              toString: function () {
                return "YUVCanvas Size: " + this.size;
              },
              initCanvas: function () {
                this.gl.clear(
                  this.gl.DEPTH_BUFFER_BIT | this.gl.COLOR_BUFFER_BIT
                );
              },
            })),
            n
          );
        })());
    function p(e, t) {
      for (
        var n = Object.create(e.prototype), r = Object.keys(t), i = 0;
        i < r.length;
        i++
      )
        n[r[i]] = t[r[i]];
      return n;
    }
    function m(e) {
      return e.join("\n");
    }
    var g = function (e, t, n) {
      var i,
        o = t,
        a = e,
        s = n,
        l = null,
        u = 0,
        c = 0,
        f = !1,
        h = null,
        p = 0,
        m = 0,
        g = !1,
        v = !1,
        y = 1,
        S = Object(r.f)(),
        w = S.bSupportMultiThread;
      S.browserType, S.errorCode, (i = w);
      var b = null,
        _ = !1,
        C = null,
        A = null,
        P = !1,
        T = function (e) {
          (function (e) {
            (this.buffer = e), (this.previous = null), (this.next = null);
          }).call(this, e);
        };
      function x() {
        var e = o || 25;
        function t() {
          (this.first = null), (this.size = 0);
        }
        return (
          (t.prototype = {
            enqueue: function (t) {
              this.size >= e && this.clear();
              var n = new T(t);
              if (null === this.first) this.first = n;
              else {
                for (var r = this.first; null !== r.next; ) r = r.next;
                r.next = n;
              }
              return (this.size += 1), n;
            },
            dequeue: function () {
              var e = null;
              return (
                null !== this.first &&
                  ((e = this.first),
                  (this.first = this.first.next),
                  (this.size -= 1)),
                e
              );
            },
            clear: function () {
              for (var e = null; null !== this.first; )
                (e = this.first),
                  (this.first = this.first.next),
                  (this.size -= 1),
                  (e.buffer = null),
                  (e = null);
              (this.size = 0), (this.first = null);
            },
          }),
          new t()
        );
      }
      function R() {
        (h = new x()), (f = !1);
      }
      var I = function (e) {
          return (
            "undefined" !== typeof l &&
            (l.drawCanvas(e.buffer.dataY, e.buffer.dataU, e.buffer.dataV),
            _ &&
              ((_ = !1),
              (function (e, t) {
                var n = C.width,
                  r = C.height,
                  i = document.createElement("canvas");
                (i.width = n), (i.height = r);
                for (var o = i.getContext("2d"), a = 0; a < e.length; a++)
                  o.drawImage(e[a], 0, 0, n, r);
                for (
                  var s = i.toDataURL(),
                    l = atob(s.substring("data:image/png;base64,".length)),
                    u = new Uint8Array(l.length),
                    c = 0,
                    f = l.length;
                  c < f;
                  ++c
                )
                  u[c] = l.charCodeAt(c);
                var h = new Blob([u.buffer], { type: "image/png" });
                (u = null), A && A(h), P || (D(h, t + ".png"), (h = null));
              })([C], b)),
            delete e.buffer,
            (e.buffer = null),
            (e.previous = null),
            (e.next = null),
            (e = null),
            !0)
          );
        },
        U = function e(t) {
          if (!0 === f) {
            if (0 === u || t - u < 200)
              return (
                0 === u && (u = t),
                void (
                  null !== h &&
                  (g ||
                    (null !== (n = h.dequeue()) &&
                      null !== n.buffer &&
                      null !== n.buffer.dataY &&
                      ((g = !0), I(n))),
                  window.requestAnimationFrame(e))
                )
              );
            if (s)
              return (
                null !== (n = h.dequeue()) &&
                  null !== n.buffer &&
                  null !== n.buffer.dataY &&
                  I(n),
                void window.requestAnimationFrame(e)
              );
            0 === c && (c = t);
            var n,
              r = t - c;
            r > m &&
              null !== (n = h.dequeue()) &&
              null !== n.buffer &&
              null !== n.buffer.dataY &&
              (v && (p = r - m),
              (m = n.buffer.nCostTime),
              (m -= p),
              I(n),
              (c = t),
              (v = !0)),
              window.requestAnimationFrame(e);
          }
        };
      function M(e, t) {
        function n(e, t) {
          (n.prototype.w = e), (n.prototype.h = t);
        }
        return (
          (n.prototype = {
            toString: function () {
              return "(" + n.prototype.w + ", " + n.prototype.h + ")";
            },
            getHalfSize: function () {
              return new M(n.prototype.w >>> 1, n.prototype.h >>> 1);
            },
            length: function () {
              return n.prototype.w * n.prototype.h;
            },
          }),
          new n(e, t)
        );
      }
      R.prototype = {
        draw: function (e, t, n, r) {
          if (!0 === f)
            if (i) l && l.drawCanvas(e, t, n);
            else if (null !== h)
              if (document.hidden && h.size >= 25) h.clear();
              else {
                var o = {};
                (o.dataY = e), (o.dataU = t), (o.dataV = n), 0 == r && (r = 25);
                var a = 1e3 / r,
                  u = h.size * a;
                s || (y = u > 500 ? 1.2 : u < 200 ? 0.8 : 1),
                  (o.nCostTime = 1e3 / r / y),
                  o.nCostTime < 20 && (o.nCostTime = 20),
                  h.enqueue(o);
              }
        },
        resize: function (e, t) {
          this.stopRendering(), null !== h && h.clear(), l && (l = null);
          var n = new M(e, t);
          (l = new d(a, n)), (n = null), this.startRendering();
        },
        initStartTime: function () {
          0 === u && this.startRendering();
        },
        startRendering: function () {
          0 === u && ((f = !0), i || window.requestAnimationFrame(U));
        },
        pause: function () {
          f = !1;
        },
        play: function () {
          f = !0;
        },
        stopRendering: function () {
          (f = !1), (u = 0);
        },
        capture: function (e, t, n, r) {
          (b = e), (_ = !0), (C = t), (A = n), (P = r);
        },
        setPlaySpeed: function (e) {
          y = e;
        },
        terminate: function () {
          (f = !1),
            (u = 0),
            null !== h && (h.clear(), (h = null)),
            l && l.clearCanvas(),
            (l = null);
        },
        getVideoBufferQueueSize: function () {
          return h.size;
        },
      };
      var D = (function (e) {
        var t = e.document,
          n = function () {
            return e.URL || e.webkitURL || e;
          },
          r = t.createElementNS("http://www.w3.org/1999/xhtml", "a"),
          i = "download" in r,
          o = /constructor/i.test(e.HTMLElement),
          a = /CriOS\/[\d]+/.test(navigator.userAgent),
          s = function (t) {
            (e.setImmediate || e.setTimeout)(function () {
              throw t;
            }, 0);
          },
          l = function (e) {
            setTimeout(function () {
              "string" === typeof e ? n().revokeObjectURL(e) : e.remove();
            }, 4e4);
          },
          u = function (e) {
            return /^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(
              e.type
            )
              ? new Blob([String.fromCharCode(65279), e], { type: e.type })
              : e;
          },
          c = function (t, c, f) {
            f || (t = u(t));
            var h,
              d = this,
              p = "application/octet-stream" === t.type,
              m = function () {
                !(function (e, t, n) {
                  for (var r = (t = [].concat(t)).length; r--; ) {
                    var i = e["on" + t[r]];
                    if ("function" === typeof i)
                      try {
                        i.call(e, n || e);
                      } catch (e) {
                        s(e);
                      }
                  }
                })(d, "writestart progress write writeend".split(" "));
              };
            if (((d.readyState = d.INIT), i))
              return (
                (h = n().createObjectURL(t)),
                void setTimeout(function () {
                  (r.href = h),
                    (r.download = c),
                    (function (e) {
                      var t = new MouseEvent("click");
                      e.dispatchEvent(t);
                    })(r),
                    m(),
                    l(h),
                    (d.readyState = d.DONE);
                })
              );
            !(function () {
              if ((a || (p && o)) && e.FileReader) {
                var r = new FileReader();
                return (
                  (r.onloadend = function () {
                    var t = a
                      ? r.result
                      : r.result.replace(
                          /^data:[^;]*;/,
                          "data:attachment/file;"
                        );
                    e.open(t, "_blank") || (e.location.href = t),
                      (t = void 0),
                      (d.readyState = d.DONE),
                      m();
                  }),
                  r.readAsDataURL(t),
                  void (d.readyState = d.INIT)
                );
              }
              h || (h = n().createObjectURL(t)),
                p
                  ? (e.location.href = h)
                  : e.open(h, "_blank") || (e.location.href = h),
                (d.readyState = d.DONE),
                m(),
                l(h);
            })();
          },
          f = c.prototype;
        return "undefined" !== typeof navigator && navigator.msSaveOrOpenBlob
          ? function (e, t, n) {
              return (
                (t = t || e.name || "download"),
                n || (e = u(e)),
                navigator.msSaveOrOpenBlob(e, t)
              );
            }
          : ((f.readyState = f.INIT = 0),
            (f.WRITING = 1),
            (f.DONE = 2),
            (f.error =
              f.onwritestart =
              f.onprogress =
              f.onwrite =
              f.onabort =
              f.onerror =
              f.onwriteend =
                null),
            function (e, t, n) {
              return new c(e, t || e.name || "download", n);
            });
      })(window);
      return new R();
    };
    var v = function () {
        var e = 200,
          t = null,
          n = null,
          i = null,
          o = 0,
          a = 0,
          s = !1,
          l = 0,
          u = 0,
          c = null,
          f = !1,
          h = new Float32Array(8e4),
          d = 0,
          p = null,
          m = 0;
        function g(n, r) {
          var o = r - l;
          if (
            ((o > e || o < 0) &&
              ((a = 0), (d = 0), (f = !0), null !== p && p.stop()),
            a - t.currentTime < 0 && (a = 0),
            (l = r),
            (h = (function (e, t, n) {
              var r = e;
              return (
                n + t.length >= r.length &&
                  (r = new Float32Array(r.length + 8e4)).set(r, 0),
                r.set(t, n),
                r
              );
            })(h, n, d)),
            (d += n.length),
            !f)
          ) {
            var s = 0;
            if (
              d / n.length > 1 &&
              (null !== c && (s = c * m), s >= d || null === c)
            )
              return void (d = 0);
            var u = null;
            (u = t.createBuffer(1, d - s, m))
              .getChannelData(0)
              .set(h.subarray(s, d)),
              (d = 0),
              ((p = t.createBufferSource()).buffer = u),
              p.connect(i),
              a || (a = t.currentTime + 0.1),
              p.start(a),
              (a += u.duration);
          }
        }
        function v() {}
        return (
          (v.prototype = {
            audioInit: function (e, o) {
              if (((a = 0), null !== t))
                r.h.info("Audio context already defined!");
              else
                try {
                  return (
                    (window.AudioContext =
                      window.AudioContext ||
                      window.webkitAudioContext ||
                      window.mozAudioContext ||
                      window.oAudioContext ||
                      window.msAudioContext),
                    ((t = new AudioContext()).onstatechange = function () {
                      "running" === t.state && (s = !0);
                    }),
                    (m = o),
                    (n = t.createGain()),
                    (i = t.createBiquadFilter()).connect(n),
                    (i.type = "lowpass"),
                    (i.frequency.value = 1500),
                    (i.gain.value = 40),
                    n.connect(t.destination),
                    this.setVolume(e),
                    !0
                  );
                } catch (e) {
                  return (
                    r.h.error(
                      "Web Audio API is not supported in this web browser! : " +
                        e
                    ),
                    !1
                  );
                }
            },
            play: function () {
              this.setVolume(o);
            },
            stop: function () {
              (o = 0), (n.gain.value = 0), (a = 0), (t = null);
            },
            bufferAudio: function (e, t) {
              s && g(e, 0);
            },
            setVolume: function (e) {
              o = e;
              var t = e / 1;
              t <= 0
                ? ((n.gain.value = 0), (a = 0))
                : (n.gain.value = t >= 1 ? 1 : t);
            },
            getVolume: function () {
              return o;
            },
            Mute: function (e) {
              if (e) (n.gain.value = 0), (a = 0);
              else {
                var t = o / 1;
                t <= 0
                  ? ((n.gain.value = 0), (a = 0))
                  : (n.gain.value = t >= 1 ? 1 : t);
              }
            },
            terminate: function () {
              "closed" !== t.state && ((a = 0), (s = !1), t.close());
            },
            setBufferingFlag: function (e, t) {
              "init" === t
                ? (u = e)
                : f &&
                  (0 === e || "undefined" === typeof e || null === e
                    ? (c = null)
                    : ((c = e - u), (u = 0)),
                  (f = !1));
            },
            getBufferingFlag: function () {
              return f;
            },
            setInitVideoTimeStamp: function (e) {
              u = e;
            },
            getInitVideoTimeStamp: function () {
              return u;
            },
            setSamplingRate: function (e) {
              m = e;
            },
          }),
          new v()
        );
      },
      y = function (e) {
        var t = [],
          n = {},
          i = e;
        function o() {
          for (var e in t)
            t[e] = [
              e.charCodeAt(0),
              e.charCodeAt(1),
              e.charCodeAt(2),
              e.charCodeAt(3),
            ];
          0,
            1 == i
              ? (n.FTYP = new Uint8Array([
                  105, 115, 111, 109, 0, 0, 0, 1, 105, 115, 111, 109, 97, 118,
                  99, 49,
                ]))
              : 2 == i &&
                (n.FTYP = new Uint8Array([
                  105, 115, 111, 109, 0, 0, 2, 0, 105, 115, 111, 109, 105, 115,
                  111, 50, 97, 118, 99, 49, 109, 112, 52, 49,
                ])),
            (n.STSD_PREFIX = new Uint8Array([0, 0, 0, 0, 0, 0, 0, 1])),
            (n.STTS = new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0])),
            (n.STSC = n.STCO = n.STTS),
            (n.STSZ = new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])),
            (n.HDLR_VIDEO = new Uint8Array([
              0, 0, 0, 0, 0, 0, 0, 0, 118, 105, 100, 101, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 86, 105, 100, 101, 111, 72, 97, 110, 100, 108, 101,
              114, 0,
            ])),
            (n.HDLR_AUDIO = new Uint8Array([
              0, 0, 0, 0, 0, 0, 0, 0, 115, 111, 117, 110, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 83, 111, 117, 110, 100, 72, 97, 110, 100, 108, 101,
              114, 0,
            ])),
            (n.DREF = new Uint8Array([
              0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 12, 117, 114, 108, 32, 0, 0, 0,
              1,
            ])),
            (n.SMHD = new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0])),
            (n.VMHD = new Uint8Array([0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0]));
        }
        t = {
          avc1: [],
          avcC: [],
          btrt: [],
          dinf: [],
          dref: [],
          esds: [],
          ftyp: [],
          hdlr: [],
          mdat: [],
          mdhd: [],
          mdia: [],
          mfhd: [],
          minf: [],
          moof: [],
          moov: [],
          mp4a: [],
          mvex: [],
          mvhd: [],
          sdtp: [],
          stbl: [],
          stco: [],
          stsc: [],
          stsd: [],
          stsz: [],
          stts: [],
          tfdt: [],
          tfhd: [],
          traf: [],
          trak: [],
          trun: [],
          trex: [],
          tkhd: [],
          vmhd: [],
          smhd: [],
          hev1: [],
          hvcC: [],
        };
        var a = function (e) {
            for (
              var t = 8, n = Array.prototype.slice.call(arguments, 1), r = 0;
              r < n.length;
              r++
            )
              t += n[r].byteLength;
            var i = new Uint8Array(t),
              o = 0;
            (i[o++] = (t >>> 24) & 255),
              (i[o++] = (t >>> 16) & 255),
              (i[o++] = (t >>> 8) & 255),
              (i[o++] = 255 & t),
              i.set(e, o),
              (o += 4);
            for (r = 0; r < n.length; r++)
              i.set(n[r], o), (o += n[r].byteLength);
            return i;
          },
          s = function (e) {
            return a(
              t.mp4a,
              new Uint8Array([
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                (65280 & e.channelcount) >> 8,
                255 & e.channelcount,
                (65280 & e.samplesize) >> 8,
                255 & e.samplesize,
                0,
                0,
                0,
                0,
                (65280 & e.samplerate) >> 8,
                255 & e.samplerate,
                0,
                0,
              ]),
              (function (e) {
                var n = e.config,
                  r = n.length,
                  i = new Uint8Array(
                    [
                      0,
                      0,
                      0,
                      0,
                      3,
                      23 + r,
                      0,
                      1,
                      0,
                      4,
                      15 + r,
                      64,
                      21,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      5,
                    ]
                      .concat([r])
                      .concat(n)
                      .concat([6, 1, 2])
                  );
                return a(t.esds, i);
              })(e)
            );
          },
          l = function (e) {
            return "audio" === e.type
              ? a(t.stsd, n.STSD_PREFIX, s(e))
              : a(
                  t.stsd,
                  n.STSD_PREFIX,
                  (function (e) {
                    var n = e.vps || [],
                      r = e.sps || [],
                      o = e.pps || [],
                      s = [],
                      l = [],
                      u = [],
                      c = 0;
                    for (c = 0; c < n.length; c++)
                      s.push((65280 & n[c].byteLength) >>> 8),
                        s.push(255 & n[c].byteLength),
                        (s = s.concat(Array.prototype.slice.call(n[c])));
                    for (c = 0; c < r.length; c++)
                      l.push((65280 & r[c].byteLength) >>> 8),
                        l.push(255 & r[c].byteLength),
                        (l = l.concat(Array.prototype.slice.call(r[c])));
                    for (c = 0; c < o.length; c++)
                      u.push((65280 & o[c].byteLength) >>> 8),
                        u.push(255 & o[c].byteLength),
                        (u = u.concat(Array.prototype.slice.call(o[c])));
                    return 1 == i
                      ? a(
                          t.avc1,
                          new Uint8Array([
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            1,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            (65280 & e.width) >> 8,
                            255 & e.width,
                            (65280 & e.height) >> 8,
                            255 & e.height,
                            0,
                            72,
                            0,
                            0,
                            0,
                            72,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            1,
                            19,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            24,
                            17,
                            17,
                          ]),
                          a(
                            t.avcC,
                            new Uint8Array(
                              [
                                1,
                                e.profileIdc,
                                e.profileCompatibility,
                                e.levelIdc,
                                255,
                              ]
                                .concat([r.length])
                                .concat(l)
                                .concat([o.length])
                                .concat(u)
                            )
                          )
                        )
                      : 2 == i
                      ? a(
                          t.hev1,
                          new Uint8Array([
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            1,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            (65280 & e.width) >> 8,
                            255 & e.width,
                            (65280 & e.height) >> 8,
                            255 & e.height,
                            0,
                            72,
                            0,
                            0,
                            0,
                            72,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            1,
                            19,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            24,
                            17,
                            17,
                          ]),
                          a(
                            t.hvcC,
                            new Uint8Array(
                              [
                                1,
                                e.general_profile_flag,
                                (4278190080 &
                                  e.general_profile_compatibility_flags) >>>
                                  24,
                                (16711680 &
                                  e.general_profile_compatibility_flags) >>>
                                  16,
                                (65280 &
                                  e.general_profile_compatibility_flags) >>>
                                  8,
                                255 & e.general_profile_compatibility_flags,
                                (0xff0000000000 &
                                  e.general_constraint_indicator_flags) >>>
                                  40,
                                (0xff00000000 &
                                  e.general_constraint_indicator_flags) >>>
                                  32,
                                (4278190080 &
                                  e.general_constraint_indicator_flags) >>>
                                  24,
                                (16711680 &
                                  e.general_constraint_indicator_flags) >>>
                                  16,
                                (65280 &
                                  e.general_constraint_indicator_flags) >>>
                                  8,
                                255 & e.general_constraint_indicator_flags,
                                e.general_level_idc,
                                240,
                                0,
                                252,
                                252 | e.chroma_format_idc,
                                248 | e.bitDepthLumaMinus8,
                                248 | e.bitDepthChromaMinus8,
                                0,
                                0,
                                e.rate_layers_nested_length,
                                3,
                              ]
                                .concat([32, 0, 1])
                                .concat(s)
                                .concat([33, 0, 1])
                                .concat(l)
                                .concat([34, 0, 1])
                                .concat(u)
                            )
                          )
                        )
                      : void 0;
                  })(e)
                );
          },
          u = function (e) {
            var r = null;
            return (
              (r = "audio" === e.type ? a(t.smhd, n.SMHD) : a(t.vmhd, n.VMHD)),
              a(
                t.minf,
                r,
                a(t.dinf, a(t.dref, n.DREF)),
                (function (e) {
                  return a(
                    t.stbl,
                    l(e),
                    a(t.stts, n.STTS),
                    a(t.stsc, n.STSC),
                    a(t.stsz, n.STSZ),
                    a(t.stco, n.STCO)
                  );
                })(e)
              )
            );
          },
          c = function (e) {
            return a(
              t.mdia,
              (function (e) {
                var n = e.timescale,
                  r = e.duration;
                return a(
                  t.mdhd,
                  new Uint8Array([
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    (n >>> 24) & 255,
                    (n >>> 16) & 255,
                    (n >>> 8) & 255,
                    255 & n,
                    (r >>> 24) & 255,
                    (r >>> 16) & 255,
                    (r >>> 8) & 255,
                    255 & r,
                    85,
                    196,
                    0,
                    0,
                  ])
                );
              })(e),
              (function (e) {
                var r = null;
                return (
                  (r = "audio" === e.type ? n.HDLR_AUDIO : n.HDLR_VIDEO),
                  a(t.hdlr, r)
                );
              })(e),
              u(e)
            );
          },
          f = function (e) {
            return a(
              t.trak,
              (function (e) {
                var n = e.id,
                  r = e.duration,
                  i = e.width,
                  o = e.height;
                return a(
                  t.tkhd,
                  new Uint8Array([
                    0,
                    0,
                    0,
                    7,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    (n >>> 24) & 255,
                    (n >>> 16) & 255,
                    (n >>> 8) & 255,
                    255 & n,
                    0,
                    0,
                    0,
                    0,
                    (r >>> 24) & 255,
                    (r >>> 16) & 255,
                    (r >>> 8) & 255,
                    255 & r,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    64,
                    0,
                    0,
                    0,
                    (i >>> 8) & 255,
                    255 & i,
                    0,
                    0,
                    (o >>> 8) & 255,
                    255 & o,
                    0,
                    0,
                  ])
                );
              })(e),
              c(e)
            );
          },
          h = function (e) {
            return a(
              t.mvex,
              (function (e) {
                var n = e.id,
                  r = new Uint8Array([
                    0,
                    0,
                    0,
                    0,
                    (n >>> 24) & 255,
                    (n >>> 16) & 255,
                    (n >>> 8) & 255,
                    255 & n,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    1,
                  ]);
                return a(t.trex, r);
              })(e)
            );
          },
          d = function (e) {
            var n,
              i,
              o =
                ((n = e.timescale),
                (i = e.duration),
                r.h.log("mvhd:  timescale: " + n + "  duration: " + i),
                a(
                  t.mvhd,
                  new Uint8Array([
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    (n >>> 24) & 255,
                    (n >>> 16) & 255,
                    (n >>> 8) & 255,
                    255 & n,
                    (i >>> 24) & 255,
                    (i >>> 16) & 255,
                    (i >>> 8) & 255,
                    255 & i,
                    0,
                    1,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    64,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    255,
                    255,
                    255,
                    255,
                  ])
                )),
              s = f(e),
              l = h(e);
            return a(t.moov, o, s, l);
          },
          p = function (e, n) {
            return "audio" === e.type
              ? audioTrun(e, n)
              : (function (e, n) {
                  var r,
                    i = null,
                    o = null,
                    s = 0,
                    l = n;
                  if (null === (r = e.samples || [])[0].frameDuration)
                    for (
                      l += 24 + 4 * r.length, i = trunHeader(r, l), s = 0;
                      s < r.length;
                      s++
                    )
                      (o = r[s]),
                        (i = i.concat([
                          (4278190080 & o.size) >>> 24,
                          (16711680 & o.size) >>> 16,
                          (65280 & o.size) >>> 8,
                          255 & o.size,
                        ]));
                  else
                    for (
                      i = (function (e, t) {
                        return [
                          0,
                          0,
                          3,
                          5,
                          (4278190080 & e.length) >>> 24,
                          (16711680 & e.length) >>> 16,
                          (65280 & e.length) >>> 8,
                          255 & e.length,
                          (4278190080 & t) >>> 24,
                          (16711680 & t) >>> 16,
                          (65280 & t) >>> 8,
                          255 & t,
                          0,
                          0,
                          0,
                          0,
                        ];
                      })(r, (l += 24 + 4 * r.length + 4 * r.length)),
                        s = 0;
                      s < r.length;
                      s++
                    )
                      (o = r[s]),
                        (i = i.concat([
                          (4278190080 & o.frameDuration) >>> 24,
                          (16711680 & o.frameDuration) >>> 16,
                          (65280 & o.frameDuration) >>> 8,
                          255 & o.frameDuration,
                          (4278190080 & o.size) >>> 24,
                          (16711680 & o.size) >>> 16,
                          (65280 & o.size) >>> 8,
                          255 & o.size,
                        ]));
                  return a(t.trun, new Uint8Array(i));
                })(e, n);
          },
          m = function (e, n) {
            return a(
              t.moof,
              (function (e) {
                var n = new Uint8Array([
                  0,
                  0,
                  0,
                  0,
                  (e >>> 24) & 255,
                  (e >>> 16) & 255,
                  (e >>> 8) & 255,
                  255 & e,
                ]);
                return a(t.mfhd, n);
              })(e),
              (function (e) {
                var n, r, i;
                return (
                  (n = a(t.tfhd, new Uint8Array([0, 2, 0, 0, 0, 0, 0, 1]))),
                  (r = a(
                    t.tfdt,
                    new Uint8Array([
                      0,
                      0,
                      0,
                      0,
                      (e.baseMediaDecodeTime >>> 24) & 255,
                      (e.baseMediaDecodeTime >>> 16) & 255,
                      (e.baseMediaDecodeTime >>> 8) & 255,
                      255 & e.baseMediaDecodeTime,
                    ])
                  )),
                  (i = p(e, 72)),
                  a(t.traf, n, r, i)
                );
              })(n)
            );
          };
        return (
          (o.prototype = {
            initSegment: function (e) {
              var i = a(t.ftyp, n.FTYP);
              r.h.log(e);
              var o = d(e),
                s = new Uint8Array(i.byteLength + o.byteLength);
              return s.set(i, 0), s.set(o, i.byteLength), s;
            },
            mediaSegment: function (e, n, r, i) {
              var o = m(e, n),
                s = (function (e) {
                  return a(t.mdat, e);
                })(r),
                l = null;
              return (
                (l = new Uint8Array(o.byteLength + s.byteLength)).set(o),
                l.set(s, o.byteLength),
                l
              );
            },
          }),
          new o()
        );
      };
    function S(e) {
      var t = 7,
        n = 7,
        r = 2,
        i = 3,
        o = 4,
        a = 5,
        s = 8,
        l = 16,
        u = 32,
        c = 255,
        f = 0,
        h = null,
        d = e;
      function p() {
        (f = 0), (h = new w());
      }
      function m(e, r) {
        var o = r,
          a = (f + o) >> i;
        return (o = (f + r) & t), (e[a] >> (n - (o & n))) & 1;
      }
      function g(e, t) {
        var n = f >> i,
          r = 8 * (n + 1) - f;
        if (r < 8)
          for (var o = 0; o < 3; o++) {
            var a = e[n + o];
            (a =
              0 == o
                ? (a >> r) << r
                : 2 == o
                ? (a & (255 >> (8 - r))) | (1 << r)
                : 0),
              e.set([a], n + o);
          }
        else e.set([0], n), e.set([1], n + 1);
      }
      function v(e, t) {
        return t <= 25 ? y(e, t) : (y(e, 16) << (t - 16)) | y(e, t - 16);
      }
      function y(e, t) {
        var n = 0;
        if (1 === t) n = m(e, 0);
        else for (var r = 0; r < t; r++) n = (n << 1) + m(e, r);
        return (f += t), n;
      }
      function S(e, t) {
        for (var n = 0, i = 0, o = t; f + o < 8 * e.length && !m(e, o++); ) n++;
        if (0 === n) return (f += 1), 0;
        i = 1 << n;
        for (var a = n - 1; a >= 0; a--, o++) i |= m(e, o) << a;
        return (f += n * r + 1), i - 1;
      }
      function b(e, t) {
        var n = S(e, t);
        return 1 & n ? (n + 1) / r : -n / r;
      }
      function _(e) {
        h.put("cpb_cnt_minus1", S(e, 0)),
          h.put("bit_rate_scale", y(e, o)),
          h.put("cpb_size_scale", y(e, o));
        for (
          var t = h.get("cpb_cnt_minus1"),
            n = new Array(t),
            r = new Array(t),
            i = new Array(t),
            s = 0;
          s <= t;
          s++
        )
          (n[s] = S(e, 0)), (r[s] = S(e, 0)), (i[s] = y(e, 1));
        h.put("bit_rate_value_minus1", n),
          h.put("cpb_size_value_minus1", r),
          h.put("cbr_flag", i),
          h.put("initial_cpb_removal_delay_length_minus1", y(e, a)),
          h.put("cpb_removal_delay_length_minus1", y(e, a)),
          h.put("dpb_output_delay_length_minus1", y(e, a)),
          h.put("time_offset_length", y(e, a));
      }
      return (
        (p.prototype = {
          parse: function (e) {
            if (((f = 0), h.clear(), 1 == d)) {
              h.put("forbidden_zero_bit", y(e, 1)),
                h.put("nal_ref_idc", y(e, r)),
                h.put("nal_unit_type", y(e, a)),
                h.put("profile_idc", y(e, s)),
                h.put("profile_compatibility", y(e, s)),
                h.put("level_idc", y(e, s)),
                h.put("seq_parameter_set_id", S(e, 0));
              var t = h.get("profile_idc");
              if (
                (100 === t ||
                  110 === t ||
                  122 === t ||
                  244 === t ||
                  44 === t ||
                  83 === t ||
                  86 === t ||
                  118 === t ||
                  128 === t ||
                  138 === t ||
                  139 === t ||
                  134 === t) &&
                (h.put("chroma_format_idc", S(e, 0)),
                h.get("chroma_format_idc") === i &&
                  h.put("separate_colour_plane_flag", y(e, 1)),
                h.put("bit_depth_luma_minus8", S(e, 0)),
                h.put("bit_depth_chroma_minus8", S(e, 0)),
                h.put("qpprime_y_zero_transform_bypass_flag", y(e, 1)),
                h.put("seq_scaling_matrix_present_flag", y(e, 1)),
                h.get("seq_scaling_matrix_present_flag"))
              ) {
                for (
                  var n = h.get("chroma_format_idc") !== i ? s : 12,
                    o = new Array(n),
                    p = 0;
                  p < n;
                  p++
                )
                  if (((o[p] = y(e, 1)), o[p]))
                    for (
                      var m = p < 6 ? l : 64, w = 8, C = 8, A = 0;
                      A < m;
                      A++
                    )
                      C && (C = (w + b(e, 0) + 256) % 256),
                        (w = 0 === C ? w : C);
                h.put("seq_scaling_list_present_flag", o);
              }
              if (
                (h.put("log2_max_frame_num_minus4", S(e, 0)),
                h.put("pic_order_cnt_type", S(e, 0)),
                0 === h.get("pic_order_cnt_type"))
              )
                h.put("log2_max_pic_order_cnt_lsb_minus4", S(e, 0));
              else if (1 === h.get("pic_order_cnt_type")) {
                h.put("delta_pic_order_always_zero_flag", y(e, 1)),
                  h.put("offset_for_non_ref_pic", b(e, 0)),
                  h.put("offset_for_top_to_bottom_field", b(e, 0)),
                  h.put("num_ref_frames_in_pic_order_cnt_cycle", S(e, 0));
                for (
                  var P = 0;
                  P < h.get("num_ref_frames_in_pic_order_cnt_cycle");
                  P++
                )
                  h.put("num_ref_frames_in_pic_order_cnt_cycle", b(e, 0));
              }
              h.put("num_ref_frames", S(e, 0)),
                h.put("gaps_in_frame_num_value_allowed_flag", y(e, 1)),
                h.put("pic_width_in_mbs_minus1", S(e, 0)),
                h.put("pic_height_in_map_units_minus1", S(e, 0)),
                h.put("frame_mbs_only_flag", y(e, 1)),
                0 === h.get("frame_mbs_only_flag") &&
                  h.put("mb_adaptive_frame_field_flag", y(e, 1)),
                h.put("direct_8x8_interence_flag", y(e, 1)),
                h.put("frame_cropping_flag", y(e, 1)),
                1 === h.get("frame_cropping_flag") &&
                  (h.put("frame_cropping_rect_left_offset", S(e, 0)),
                  h.put("frame_cropping_rect_right_offset", S(e, 0)),
                  h.put("frame_cropping_rect_top_offset", S(e, 0)),
                  h.put("frame_cropping_rect_bottom_offset", S(e, 0))),
                h.put("vui_parameters_present_flag", y(e, 1)),
                h.get("vui_parameters_present_flag") &&
                  (function (e) {
                    h.put("aspect_ratio_info_present_flag", y(e, 1)),
                      h.get("aspect_ratio_info_present_flag") &&
                        (h.put("aspect_ratio_idc", y(e, s)),
                        h.get("aspect_ratio_idc") === c &&
                          (g(e),
                          h.put("sar_width", y(e, l)),
                          g(e),
                          h.put("sar_height", y(e, l)))),
                      h.put("overscan_info_present_flag", y(e, 1)),
                      h.get("overscan_info_present_flag") &&
                        h.put("overscan_appropriate_flag", y(e, 1)),
                      h.put("video_signal_type_present_flag", y(e, 1)),
                      h.get("video_signal_type_present_flag") &&
                        (h.put("video_format", y(e, i)),
                        h.put("video_full_range_flag", y(e, 1)),
                        h.put("colour_description_present_flag", y(e, 1)),
                        h.get("colour_description_present_flag") &&
                          (h.put("colour_primaries", y(e, s)),
                          h.put("transfer_characteristics", y(e, s)),
                          h.put("matrix_coefficients", y(e, s)))),
                      h.put("chroma_loc_info_present_flag", y(e, 1)),
                      h.get("chroma_loc_info_present_flag") &&
                        (h.put("chroma_sample_loc_type_top_field", S(e, 0)),
                        h.put("chroma_sample_loc_type_bottom_field", S(e, 0))),
                      h.put("timing_info_present_flag", y(e, 1)),
                      h.get("timing_info_present_flag") &&
                        (h.put("num_units_in_tick", y(e, u)),
                        h.put("time_scale", y(e, u)),
                        h.put("fixed_frame_rate_flag", y(e, 1))),
                      h.put("nal_hrd_parameters_present_flag", y(e, 1)),
                      h.get("nal_hrd_parameters_present_flag") && _(e),
                      h.put("vcl_hrd_parameters_present_flag", y(e, 1)),
                      h.get("vcl_hrd_parameters_present_flag") && _(e),
                      (h.get("nal_hrd_parameters_present_flag") ||
                        h.get("vcl_hrd_parameters_present_flag")) &&
                        h.put("low_delay_hrd_flag", y(e, 1)),
                      h.put("pic_struct_present_flag", y(e, 1)),
                      h.put("bitstream_restriction_flag", y(e, 1)),
                      h.get("bitstream_restriction_flag") &&
                        (h.put(
                          "motion_vectors_over_pic_boundaries_flag",
                          y(e, 1)
                        ),
                        h.put("max_bytes_per_pic_denom", S(e, 0)),
                        h.put("max_bits_per_mb_denom", S(e, 0)));
                  })(e);
            } else if (2 == d) {
              var T = new ArrayBuffer(256),
                x = new Uint8Array(T);
              !(function (e, t, n, r) {
                for (var i = 0, o = 0; i + 2 < t && o + 2 < r; ++i)
                  0 == e[i] && 0 == e[i + 1] && 3 == e[i + 2]
                    ? ((n[o++] = e[i++]), (n[o++] = e[i++]))
                    : (n[o++] = e[i]);
                for (; i < t && o < r; ) n[o++] = e[i++];
              })(e, e.length, x, 256);
              var R = [],
                I = [];
              y(x, 4);
              var U = y(x, 3);
              for (
                h.put("temporalIdNested", y(x, 1)),
                  h.put("general_profile_space", y(x, 2)),
                  h.put("general_tier_flag", y(x, 1)),
                  h.put("general_profile_idc", y(x, 5)),
                  h.put("general_profile_compatibility_flags", v(x, 32)),
                  h.put(
                    "general_constraint_indicator_flags",
                    ((M = x),
                    (D = 48) <= 32 ? v(M, D) : (v(M, D - 32) << 32) | v(M, 32))
                  ),
                  h.put("general_level_idc", y(x, 8)),
                  p = 0;
                p < U && p < MAX_SUB_LAYERS;
                p++
              )
                (R[p] = y(x, 1)), (I[p] = y(x, 1));
              if (U > 0) for (p = U; p < 8; p++) y(x, 2);
              for (p = 0; p < U && p < MAX_SUB_LAYERS; p++) I[p] && y(x, 8);
              S(x, 0);
              h.put("chroma_format_idc", S(x, 0));
              S(x, 0), S(x, 0);
              y(x, 1),
                S(x, 0),
                S(x, 0),
                S(x, 0),
                S(x, 0),
                h.put("bitDepthLumaMinus8", S(x, 0) + 8),
                h.put("bitDepthChromaMinus8", S(x, 0) + 8),
                (T = null),
                (x = null);
            }
            var M, D;
            return !0;
          },
          getSizeInfo: function () {
            var e = 0,
              t = 0;
            0 === h.get("chroma_format_idc")
              ? (e = t = 0)
              : 1 === h.get("chroma_format_idc")
              ? (e = t = r)
              : h.get("chroma_format_idc") === r
              ? ((e = r), (t = 1))
              : h.get("chroma_format_idc") === i &&
                (0 === h.get("separate_colour_plane_flag")
                  ? (e = t = 1)
                  : 1 === h.get("separate_colour_plane_flag") && (e = t = 0));
            var n = h.get("pic_width_in_mbs_minus1") + 1,
              o = h.get("pic_height_in_map_units_minus1") + 1,
              a = (r - h.get("frame_mbs_only_flag")) * o,
              s = 0,
              u = 0,
              c = 0,
              f = 0;
            1 === h.get("frame_cropping_flag") &&
              ((s = h.get("frame_cropping_rect_left_offset")),
              (u = h.get("frame_cropping_rect_right_offset")),
              (c = h.get("frame_cropping_rect_top_offset")),
              (f = h.get("frame_cropping_rect_bottom_offset")));
            var d = n * l * (a * l);
            return {
              width: n * l - e * (s + u),
              height: a * l - t * (r - h.get("frame_mbs_only_flag")) * (c + f),
              decodeSize: d,
            };
          },
          getSpsValue: function (e) {
            return h.get(e);
          },
          getCodecInfo: function () {
            return (
              h.get("profile_idc").toString(l) +
              (h.get("profile_compatibility") < 15
                ? "0" + h.get("profile_compatibility").toString(l)
                : h.get("profile_compatibility").toString(l)) +
              h.get("level_idc").toString(l)
            );
          },
        }),
        new p()
      );
    }
    var w = function () {
      this.map = {};
    };
    w.prototype = {
      put: function (e, t) {
        this.map[e] = t;
      },
      get: function (e) {
        return this.map[e];
      },
      containsKey: function (e) {
        return e in this.map;
      },
      containsValue: function (e) {
        for (var t in this.map) if (this.map[t] === e) return !0;
        return !1;
      },
      isEmpty: function (e) {
        return 0 === this.size();
      },
      clear: function () {
        for (var e in this.map) delete this.map[e];
      },
      remove: function (e) {
        delete this.map[e];
      },
      keys: function () {
        var e = [];
        for (var t in this.map) e.push(t);
        return e;
      },
      values: function () {
        var e = [];
        for (var t in this.map) e.push(this.map[t]);
        return e;
      },
      size: function () {
        var e = 0;
        for (var t in this.map) e++;
        return e;
      },
    };
    var b = function () {
        var e = null,
          t = "",
          n = null,
          i = null,
          o = null,
          a = null,
          s = null,
          l = null,
          u = null,
          c = 1,
          f = { timestamp: 0, timestamp_usec: 0, timezone: 0 },
          h = { timestamp: 0, timestamp_usec: 0, timezone: 0 },
          d = null,
          p = !1,
          m = null,
          g = null,
          v = null,
          y = !1,
          S = !0,
          w = 0,
          b = !1,
          _ = [],
          C = 0.5,
          A = null,
          P = null,
          T = null,
          x = 0,
          R = 0,
          I = !1,
          U = null,
          M = "png",
          D = 1,
          E = Object(r.a)(),
          k = null,
          L = 0,
          O = 0,
          F = 0,
          N = 8,
          B = null,
          V = !1,
          z = !1,
          q = [],
          G = {},
          W = 25,
          Y = 0.5,
          H = !1,
          j = !1;
        function X() {}
        function Z() {
          J(), (j = !0);
        }
        function K() {
          var e = 0;
          if (null !== m)
            for (e = 0; e < m.length; e++)
              T.removeEventListener(m[e].type, m[e].function);
          if (null !== v)
            for (e = 0; e < v.length; e++)
              P.removeEventListener(v[e].type, v[e].function);
          if (null !== g)
            for (e = 0; e < g.length; e++)
              A.removeEventListener(g[e].type, g[e].function);
        }
        function J() {
          if (null === P || "ended" === P.readyState)
            return (
              (function (e) {
                (v = []).push({ type: "sourceopen", function: Z }),
                  v.push({ type: "error", function: ie });
                for (var t = 0; t < v.length; t++)
                  e.addEventListener(v[t].type, v[t].function);
              })((P = new MediaSource())),
              (A.src = window.URL.createObjectURL(P)),
              void r.h.log(
                "videoMediaSource::appendInitSegment new MediaSource()"
              )
            );
          if (
            (r.h.log("videoMediaSource::appendInitSegment start"),
            0 === P.sourceBuffers.length)
          ) {
            P.duration = 0;
            var i = null;
            if (
              (1 == n
                ? (i = 'video/mp4;codecs="avc1.' + t + '"')
                : 2 == n && (i = 'video/mp4; codecs="hvc1.1.6.L30.B0"'),
              null == i)
            )
              return;
            if (!MediaSource.isTypeSupported(i))
              return (
                r.h.log("not support" + i),
                B && B({ errorCode: 101 }),
                void (l && l("InitError"))
              );
            !(function (e) {
              (m = []).push({ type: "error", function: oe }),
                m.push({ type: "updateend", function: ne }),
                m.push({ type: "update", function: re });
              for (var t = 0; t < m.length; t++)
                e.addEventListener(m[t].type, m[t].function);
            })((T = P.addSourceBuffer(i)));
          }
          var o = e();
          null !== o
            ? (T.appendBuffer(o),
              r.h.log(
                "videoMediaSource::appendInitSegment end, codecInfo = " + t
              ))
            : P.endOfStream("network");
        }
        function Q() {
          A.paused && (o(), y || V || A.play());
        }
        function $() {
          A.paused || S || (r.h.log("pause"), A.pause());
        }
        function ee() {
          q.length &&
            (function (e) {
              if (
                (!H && j && ((H = !0), J()),
                null !== T &&
                  "closed" !== P.readyState &&
                  "ended" !== P.readyState)
              )
                try {
                  if (_.length > 0)
                    return (
                      r.h.count("1.segmentWaitDecode.length: " + _.length),
                      _.push(e),
                      void r.h.count("2.segmentWaitDecode.length: " + _.length)
                    );
                  T.updating
                    ? (r.h.log("updating.........."), _.push(e))
                    : (T.appendBuffer(e), V && (G.buffer = e));
                } catch (e) {
                  console.log(
                    "videoMediaSource::appendNextMediaSegment error >> initVideo"
                  ),
                    r.h.log(
                      "videoMediaSource::appendNextMediaSegment error >> initVideo"
                    ),
                    (_.length = 0),
                    B && B({ errorCode: 101 });
                }
            })(q.shift());
        }
        function te() {
          if (null !== P)
            try {
              if (
                T &&
                T.buffered.length > 0 &&
                ((function () {
                  var e = 1 * T.buffered.start(T.buffered.length - 1),
                    t = 1 * T.buffered.end(T.buffered.length - 1);
                  t - e > 60 && T.remove(e, t - 10);
                })(),
                (z && !V) ||
                  (A.duration > Y &&
                    ((A.currentTime = (A.duration - Y).toFixed(3)),
                    (Y += W < 10 ? 0.5 : 0.1))),
                A &&
                  A.duration - A.currentTime > N &&
                  (console.log(
                    "videoElement && videoElement.duration - videoElement.currentTime > MAX_DELAYTIME"
                  ),
                  B && B({ errorCode: 101 })),
                b && !p)
              ) {
                var e = 1 * T.buffered.start(T.buffered.length - 1),
                  t = 1 * T.buffered.end(T.buffered.length - 1);
                if (
                  (0 === A.currentTime ? t - e : t - A.currentTime) >=
                  C + 0.1
                ) {
                  if ((r.h.log("\u8df3\u79d2"), T.updating)) return;
                  var n = t - 0.1;
                  A.currentTime = n.toFixed(3);
                }
              }
            } catch (e) {
              r.h.log("sourceBuffer has been removed");
            }
        }
        function ne() {}
        function re() {
          _.length > 0 &&
            (r.h.count(
              "1. onSourceUpdate .segmentWaitDecode.length: " + _.length
            ),
            T.updating ||
              (r.h.count(
                "2. onSourceUpdate .appendBuffer: " +
                  _.length +
                  "  " +
                  _[0].length
              ),
              T.appendBuffer(_[0]),
              _.shift()));
        }
        function ie() {
          console.log("videoMediaSource::onSourceError"), l && l("SourceError");
        }
        function oe() {
          console.log("videoMediaSource::onSourceBufferErrormsg"),
            l && l("SourceBufferError");
        }
        function ae() {
          console.log("videoMediaSource::onError"),
            $(),
            B && B({ errorCode: 101 }),
            l && l("Error");
        }
        function se() {
          (y = !0), (S = !1), (z = !0), I || ((I = !0), s("PlayStart"));
        }
        function le() {
          (y = !1),
            (S = !0),
            r.h.log(
              "\u6682\u505c\u64ad\u653e----------------------------------------------"
            );
        }
        function ue() {
          var e = parseInt(P.duration, 10),
            t = parseInt(A.currentTime, 10),
            n = {
              timestamp: f.timestamp - c * (e - t + (1 !== c ? 1 : 0)),
              timestamp_usec: 0,
              timezone: f.timezone,
            };
          0 === t ||
            isNaN(e) ||
            (!p && Math.abs(e - t) > 4 && 1 === c) ||
            A.paused ||
            (null === d
              ? ((d = n), a(0, "currentTime"))
              : ((d.timestamp <= n.timestamp && c >= 1) ||
                  (d.timestamp > n.timestamp && c < 1)) &&
                ((d = n), ++w > 4 && a(n.timestamp, "currentTime")));
        }
        function ce() {
          null != T && (Q(), te());
        }
        function fe() {
          o();
        }
        function he() {
          Q();
        }
        function de() {
          if (
            (r.h.log("\u9700\u8981\u7f13\u51b2\u4e0b\u4e00\u5e27"),
            (b = !1),
            0 == R)
          )
            (x = Date.now()), R++;
          else {
            R++;
            var e = Date.now() - x;
            r.h.log("diffTime: " + e + "  Count: " + R),
              R >= 5 &&
                e < 6e4 &&
                C <= 1 &&
                ((C += 0.1), (R = 0), (x = 0), r.h.log("delay + 0.1 = " + C));
          }
        }
        function pe() {
          r.h.log("Can play !");
        }
        function me() {
          r.h.log("Can play without waiting"), (b = !0);
        }
        function ge() {
          r.h.log("loadedmetadata");
        }
        function ve(e, t, n, r, i) {
          for (
            var o = e.width, a = e.height, s = e.getContext("2d"), l = 0;
            l < t.length;
            l++
          )
            s.drawImage(t[l], 0, 0, o, a);
          for (
            var u = e.toDataURL(),
              c = atob(u.substring("data:image/png;base64,".length)),
              f = new Uint8Array(c.length),
              h = 0,
              d = c.length;
            h < d;
            ++h
          )
            f[h] = c.charCodeAt(h);
          var p = new Blob([f.buffer], { type: "image/png" });
          r && r(p), i || ye(p, n + ".png");
        }
        X.prototype = {
          init: function (e) {
            (u = Object(r.a)()),
              r.h.log("videoMediaSource::init browserType = " + u),
              ((A = e).autoplay = "safari" !== u),
              (A.controls = !1),
              (A.preload = "auto"),
              (function (e) {
                (g = []).push({ type: "durationchange", function: ce }),
                  g.push({ type: "playing", function: se }),
                  g.push({ type: "error", function: ae }),
                  g.push({ type: "pause", function: le }),
                  g.push({ type: "timeupdate", function: ue }),
                  g.push({ type: "resize", function: fe }),
                  g.push({ type: "seeked", function: he }),
                  g.push({ type: "waiting", function: de }),
                  g.push({ type: "canplaythrough", function: me }),
                  g.push({ type: "canplay", function: pe }),
                  g.push({ type: "loadedmetadata", function: ge });
                for (var t = 0; t < g.length; t++)
                  e.addEventListener(g[t].type, g[t].function);
              })(A),
              J();
          },
          setInitSegmentFunc: function (t) {
            e = t;
          },
          getVideoElement: function () {
            return A;
          },
          setCodecInfo: function (e) {
            t = e;
          },
          setMediaSegment: function (e) {
            q.push(e), V || ee();
          },
          capture: function (e, t, n, r) {
            U && clearInterval(U);
            var i = document.createElement("canvas");
            (i.width = A.videoWidth), (i.height = A.videoHeight);
            var o = t.ivsCanvasArr;
            b || "edge" === E
              ? (i.getContext("2d").drawImage(A, 0, 0, i.width, i.height),
                ve(i, o, e, n, r))
              : (U = setInterval(function () {
                  b &&
                    (i.getContext("2d").drawImage(A, 0, 0, i.width, i.height),
                    ve(i, o, e, n, r),
                    clearInterval(U));
                }, 200));
          },
          getCapture: function (e, t, n) {
            U && clearInterval(U),
              (D = n || 1),
              (M = "png"),
              ("jpg" !== t && "jpeg" !== t) || (M = "jpeg");
            var r = document.createElement("canvas"),
              i = null;
            return (
              (r.width = A.videoWidth),
              (r.height = A.videoHeight),
              b || "edge" === E
                ? (r.getContext("2d").drawImage(A, 0, 0, r.width, r.height),
                  (i = r.toDataURL("image/" + M, D)))
                : b &&
                  (r.getContext("2d").drawImage(A, 0, 0, r.width, r.height),
                  (i = r.toDataURL("image/" + M, D))),
              i
            );
          },
          setInitSegment: function () {
            J();
          },
          setTimeStamp: function (e, t) {
            i = e;
          },
          setVideoSizeCallback: function (e) {
            o = e;
          },
          setAudioStartCallback: function (e) {
            a = e;
          },
          setMseErrorCallback: function (e) {
            l = e;
          },
          getPlaybackTimeStamp: function () {
            return i;
          },
          setSpeedPlay: function (e) {
            c = e;
          },
          setvideoTimeStamp: function (e) {
            var t = Math.abs(f.timestamp - e.timestamp) > 3;
            h.timestamp,
              !0 === t &&
                ((w = 0),
                a((h = e).timestamp, "init"),
                0 !== f.timestamp && p && (A.currentTime = P.duration - 0.1),
                (d = null)),
              (f = e);
          },
          pause: function () {
            (V = !0), $();
          },
          play: function () {
            V = !1;
          },
          setPlaybackFlag: function (e) {
            p = e;
          },
          setTimeStampInit: function () {
            (d = null), (h = { timestamp: 0, timestamp_usec: 0, timezone: 0 });
          },
          close: function () {
            K(), $();
          },
          setBeginDrawCallback: function (e) {
            s = e;
          },
          setErrorCallback: function (e) {
            B = e;
          },
          terminate: function () {
            null !== A &&
              (K(),
              "open" === P.readyState &&
                (T && P.removeSourceBuffer(T), P.endOfStream()),
              (T = null),
              (P = null),
              (A = null),
              U && (clearInterval(U), (U = null)),
              k && (clearInterval(k), (k = null)),
              (F = 0),
              (O = 0),
              (L = 0),
              (H = !1),
              (j = !1));
          },
          getDuration: function () {
            return A.duration - A.currentTime;
          },
          setFPS: function (e) {
            e && (W = e);
          },
          setRtspOver: function () {
            A.duration.toFixed(4) - 0 === A.currentTime.toFixed(4) - 0 ||
              ((L = parseInt(A.currentTime)),
              (O = parseInt(A.duration)),
              (k = setInterval(function () {
                L === parseInt(A.currentTime) && O === parseInt(A.duration)
                  ? F++ > 10 && (k && clearInterval(k), (k = null))
                  : parseInt(A.currentTime) >= parseInt(A.duration)
                  ? (k && clearInterval(k), (k = null))
                  : ((L = parseInt(A.currentTime)),
                    (O = parseInt(A.duration)),
                    (F = 0));
              }, 150)));
          },
          getVideoBufferQueueSize: function () {
            return q.length;
          },
          playNextFrame: function () {
            ee();
          },
          getCurFrameInfo: function () {
            return (
              (G.src = (function () {
                var e = document.createElement("canvas");
                return (
                  (e.width = A.videoWidth),
                  (e.height = A.videoHeight),
                  e.getContext("2d").drawImage(A, 0, 0, e.width, e.height),
                  e.toDataURL()
                );
              })()),
              G
            );
          },
          setDecodeType: function (e) {
            n = e;
          },
        };
        var ye = (function (e) {
          var t = e.document,
            n = function () {
              return e.URL || e.webkitURL || e;
            },
            r = t.createElementNS("http://www.w3.org/1999/xhtml", "a"),
            i = "download" in r,
            o = /constructor/i.test(e.HTMLElement),
            a = /CriOS\/[\d]+/.test(navigator.userAgent),
            s = function (t) {
              (e.setImmediate || e.setTimeout)(function () {
                throw t;
              }, 0);
            },
            l = function (e) {
              setTimeout(function () {
                "string" === typeof e ? n().revokeObjectURL(e) : e.remove();
              }, 4e4);
            },
            u = function (e) {
              return /^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(
                e.type
              )
                ? new Blob([String.fromCharCode(65279), e], { type: e.type })
                : e;
            },
            c = function (t, c, f) {
              f || (t = u(t));
              var h,
                d = this,
                p = "application/octet-stream" === t.type,
                m = function () {
                  !(function (e, t, n) {
                    for (var r = (t = [].concat(t)).length; r--; ) {
                      var i = e["on" + t[r]];
                      if ("function" === typeof i)
                        try {
                          i.call(e, n || e);
                        } catch (e) {
                          s(e);
                        }
                    }
                  })(d, "writestart progress write writeend".split(" "));
                };
              if (((d.readyState = d.INIT), i))
                return (
                  (h = n().createObjectURL(t)),
                  void setTimeout(function () {
                    (r.href = h),
                      (r.download = c),
                      (function (e) {
                        var t = new MouseEvent("click");
                        e.dispatchEvent(t);
                      })(r),
                      m(),
                      l(h),
                      (d.readyState = d.DONE);
                  })
                );
              !(function () {
                if ((a || (p && o)) && e.FileReader) {
                  var r = new FileReader();
                  return (
                    (r.onloadend = function () {
                      var t = a
                        ? r.result
                        : r.result.replace(
                            /^data:[^;]*;/,
                            "data:attachment/file;"
                          );
                      e.open(t, "_blank") || (e.location.href = t),
                        (t = void 0),
                        (d.readyState = d.DONE),
                        m();
                    }),
                    r.readAsDataURL(t),
                    void (d.readyState = d.INIT)
                  );
                }
                h || (h = n().createObjectURL(t)),
                  p
                    ? (e.location.href = h)
                    : e.open(h, "_blank") || (e.location.href = h),
                  (d.readyState = d.DONE),
                  m(),
                  l(h);
              })();
            },
            f = c.prototype;
          return "undefined" !== typeof navigator && navigator.msSaveOrOpenBlob
            ? function (e, t, n) {
                return (
                  (t = t || e.name || "download"),
                  n || (e = u(e)),
                  navigator.msSaveOrOpenBlob(e, t)
                );
              }
            : ((f.readyState = f.INIT = 0),
              (f.WRITING = 1),
              (f.DONE = 2),
              (f.error =
                f.onwritestart =
                f.onprogress =
                f.onwrite =
                f.onabort =
                f.onerror =
                f.onwriteend =
                  null),
              function (e, t, n) {
                return new c(e, t || e.name || "download", n);
              });
        })(window);
        return new X();
      },
      _ = function () {
        var e = null,
          t = null,
          n = null,
          i = null,
          o = !1,
          a = null,
          s = { audio: !0, video: !1 },
          l = null;
        function u() {}
        return (
          (u.prototype = {
            init: function () {
              if (void 0 === e || null === e)
                try {
                  (window.AudioContext =
                    window.AudioContext ||
                    window.webkitAudioContext ||
                    window.mozAudioContext ||
                    window.oAudioContext ||
                    window.msAudioContext),
                    ((e = new AudioContext()).onstatechange = function () {
                      r.h.info("Audio Context State changed :: " + e.state);
                    });
                } catch (e) {
                  return void r.h.error(
                    "Web Audio API is not supported in this web browser! : " + e
                  );
                }
            },
            initAudioOut: function () {
              if (
                ((null !== t && null !== n) ||
                  ((t = e.createGain()),
                  ((n = e.createScriptProcessor(4096, 1, 1)).onaudioprocess =
                    function (e) {
                      if (null !== a) {
                        var t = e.inputBuffer.getChannelData(0);
                        null !== l && !0 === o && l(t);
                      }
                    }),
                  t.connect(n),
                  n.connect(e.destination),
                  (i = e.sampleRate),
                  (t.gain.value = 1)),
                void 0 === navigator.mediaDevices &&
                  (navigator.mediaDevices = {}),
                void 0 === navigator.mediaDevices.getUserMedia &&
                  (navigator.mediaDevices.getUserMedia = function (e, t, n) {
                    var r =
                      navigator.getUserMedia ||
                      navigator.webkitGetUserMedia ||
                      navigator.mozGetUserMedia ||
                      navigator.msGetUserMedia;
                    return r
                      ? new Promise(function (t, n) {
                          r.call(navigator, e, t, n);
                        })
                      : (n(),
                        Promise.reject(
                          new Error(
                            "getUserMedia is not implemented in this browser"
                          )
                        ));
                  }),
                navigator.mediaDevices.getUserMedia)
              )
                return (
                  navigator.mediaDevices
                    .getUserMedia(s)
                    .then(function (n) {
                      (a = n), e.createMediaStreamSource(n).connect(t);
                    })
                    .catch(function (e) {
                      r.h.error(e);
                    }),
                  (o = !0),
                  i
                );
              r.h.error(
                "Cannot open local media stream! :: navigator.mediaDevices.getUserMedia is not defined!"
              );
            },
            controlVolumnOut: function (e) {
              var n = (e / 20) * 2;
              t.gain.value = n <= 0 ? 0 : n >= 10 ? 10 : n;
            },
            stopAudioOut: function () {
              if (null !== a && o)
                try {
                  for (
                    var e = a.getAudioTracks(), t = 0, n = e.length;
                    t < n;
                    t++
                  )
                    e[t].stop();
                  (o = !1), (a = null);
                } catch (e) {
                  r.h.log(e);
                }
            },
            terminate: function () {
              this.stopAudioOut(), e.close(), (t = null), (n = null);
            },
            setSendAudioTalkBufferCallback: function (e) {
              l = e;
            },
          }),
          new u()
        );
      },
      C = n(1),
      A = n.n(C);
    var P = function (e, t) {
      var n = null,
        i = null,
        o = null;
      function a() {}
      function s(e) {
        var t = { type: "getRtpData", data: e };
        n.postMessage(t);
      }
      function l(e) {
        var t = e.data;
        switch (t.type) {
          case "rtpData":
            o(t.data);
        }
      }
      return (
        (a.prototype = {
          setSendAudioTalkCallback: function (e) {
            o = e;
          },
          talkInit: function (e, t) {
            var o = {
              type: "sdpInfo",
              data: {
                sdpInfo: e,
                aacCodecInfo: t,
                decodeMode: "canvas",
                mp4Codec:
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : null,
              },
            };
            try {
              (window.AudioContext =
                window.AudioContext ||
                window.webkitAudioContext ||
                window.mozAudioContext ||
                window.oAudioContext ||
                window.msAudioContext),
                ((n = new A.a()).onmessage = l),
                null === i &&
                  ((i = new _()).init(), i.setSendAudioTalkBufferCallback(s)),
                n.postMessage(o),
                (o = { type: "sampleRate", data: i.initAudioOut() }),
                n.postMessage(o);
            } catch (e) {
              return void r.h.error(
                "Web Audio API is not supported in this web browser! : " + e
              );
            }
          },
          stop: function () {
            i && (i.terminate(), (i = null)), n && (n.terminate(), (n = null));
          },
        }),
        new a()
      );
    };
    var T = function () {
      var e = null,
        t = null,
        n = -100,
        r = "#0000FF";
      function i() {}
      function o(e, t, n) {
        return (t.x - e.x) * (n.y - e.y) - (n.x - e.x) * (t.y - e.y);
      }
      function a(e, t) {
        for (var n = 0, r = 0; r < e.length - 1; r++)
          e[r].y <= t.y
            ? e[r + 1].y > t.y && o(e[r], e[r + 1], t) > 0 && ++n
            : e[r + 1].y <= t.y && o(e[r], e[r + 1], t) < 0 && --n;
        return 0 != n;
      }
      return (
        (i.prototype = {
          Init: function (n) {
            t = (e = n).getContext("2d");
          },
          SetCurrentColor: function (e) {
            r = e;
          },
          drawLine: function (e, n) {
            (e.x == n.x && e.y == n.y) ||
              (t.beginPath(),
              (t.lineWidth = 4),
              (t.strokeStyle = r),
              t.moveTo(e.x, e.y),
              t.lineTo(n.x, n.y),
              t.stroke(),
              t.setLineDash([]),
              t.closePath());
          },
          drawPolyLine: function (e, n) {
            if (!(n < 2)) {
              t.beginPath(), (t.lineWidth = 4), (t.strokeStyle = r);
              for (var i = 0; i < n; i++)
                0 === i ? t.moveTo(e[i].x, e[i].y) : t.lineTo(e[i].x, e[i].y);
              t.stroke(), t.setLineDash([0, 0]), t.closePath();
            }
          },
          drawArrow: function (e, t) {
            var n,
              r = {},
              i = {};
            (e.x == t.x && e.y == t.y) ||
              (t.x < e.x
                ? ((n = Math.atan((e.y - t.y) / (e.x - t.x))),
                  (r.x = t.x + 20 * Math.cos(n - Math.PI / 6)),
                  (r.y = t.y + 20 * Math.sin(n - Math.PI / 6)),
                  (i.x = t.x + 20 * Math.cos(n + Math.PI / 6)),
                  (i.y = t.y + 20 * Math.sin(n + Math.PI / 6)))
                : t.x > e.x
                ? ((n = Math.atan((e.y - t.y) / (e.x - t.x))),
                  (r.x = t.x - 20 * Math.cos(n - Math.PI / 6)),
                  (r.y = t.y - 20 * Math.sin(n - Math.PI / 6)),
                  (i.x = t.x - 20 * Math.cos(n + Math.PI / 6)),
                  (i.y = t.y - 20 * Math.sin(n + Math.PI / 6)))
                : ((n = Math.atan((e.y - t.y) / (e.x - t.x))),
                  (r.x = t.x + 20 * Math.cos(n - Math.PI / 6)),
                  (r.y = t.y + 20 * Math.sin(n - Math.PI / 6)),
                  (i.x = t.x + 20 * Math.cos(n + Math.PI / 6)),
                  (i.y = t.y + 20 * Math.sin(n + Math.PI / 6))),
              this.drawLine(t, r),
              this.drawLine(t, i));
          },
          drawTrackLine: function (e, t) {
            if (1 === t || 3 === t) {
              var n = e.x,
                r = e.y,
                i = e.xSize,
                o = e.ySize,
                a = new Array(4),
                s = new Array(8);
              (a[0].x = n - i),
                (a[0].y = r - o),
                (a[1].x = n + i),
                (a[1].y = r - o),
                (a[2].x = n + i),
                (a[2].y = r + o),
                (a[3].x = n - i),
                (a[3].y = r + o),
                (s[0].x = a[0].x + i / 2),
                (s[0].y = a[0].y),
                (s[1].x = a[1].x - i / 2),
                (s[1].y = a[1].y),
                (s[2].x = a[2].x - i / 2),
                (s[2].y = a[2].y),
                (s[3].x = a[3].x + i / 2),
                (s[3].y = a[3].y),
                (s[4].x = a[0].x),
                (s[4].y = a[0].y + o / 2),
                (s[5].x = a[1].x),
                (s[5].y = a[1].y + o / 2),
                (s[6].x = a[2].x),
                (s[6].y = a[2].y - o / 2),
                (s[7].x = a[3].x),
                (s[7].y = a[3].y - o / 2);
              for (var l = 0; l < 4; l++)
                this.drawLine(a[l], s[l]), this.drawLine(a[l], s[l + 4]);
              (a = null), (s = null);
            } else {
              var u = new Array(5);
              for (l = 0; l < 5; l++) u[l] = {};
              (u[0].x = e.x - e.xSize),
                (u[0].y = e.y - e.ySize),
                (u[1].x = e.x + e.xSize),
                (u[1].y = e.y - e.ySize),
                (u[2].x = e.x + e.xSize),
                (u[2].y = e.y + e.ySize),
                (u[3].x = e.x - e.xSize),
                (u[3].y = e.y + e.ySize),
                (u[4].x = e.x - e.xSize),
                (u[4].y = e.y - e.ySize),
                this.drawPolyLine(u, 5),
                (u = null);
            }
            if (3 === t) {
              (n = e.x), (r = e.y);
              var c = 0;
              (c = (i = e.xSize) > (o = e.ySize) ? o : i),
                ((u = new Array(8))[0].x = n - i),
                (u[0].y = r),
                (u[1].x = n - i - c),
                (u[1].y = r),
                (u[2].x = n),
                (u[2].y = r + o),
                (u[3].x = n),
                (u[3].y = r + o + c),
                (u[4].x = n + i),
                (u[4].y = r),
                (u[5].x = n + i + c),
                (u[5].y = r),
                (u[6].x = n),
                (u[6].y = r - o),
                (u[7].x = n),
                (u[7].y = r - o - c),
                this.drawPolyLine(u, 2),
                this.drawPolyLine(u + 2, 2),
                this.drawPolyLine(u + 4, 2),
                this.drawPolyLine(u + 6, 2),
                (u = null);
            }
          },
          drawDirection: function (e, t, n, r) {
            var i,
              o = {},
              a = {},
              s = (e.x + t.x) / 2,
              l = (e.y + t.y) / 2;
            if (e.x != t.x)
              if ((i = (e.y - t.y) / (e.x - t.x)) > 0) {
                var u = Math.atan(i);
                (o.x = s + r * Math.sin(u)),
                  (o.y = l - r * Math.cos(u)),
                  (a.x = s - r * Math.sin(u)),
                  (a.y = l + r * Math.cos(u));
              } else
                i < 0
                  ? ((u = Math.atan(-i)),
                    (o.x = s - r * Math.sin(u)),
                    (o.y = l - r * Math.cos(u)),
                    (a.x = s + r * Math.sin(u)),
                    (a.y = l + r * Math.cos(u)))
                  : ((o.x = s),
                    (a.x = o.x),
                    (o.y = l - r),
                    (a.y = o.y + 2 * r));
            else (o.x = e.x - r), (a.x = e.x + r), (o.y = l), (a.y = o.y);
            this.drawLine(o, a),
              e.x == t.x
                ? t.y < e.y
                  ? (1 != n && this.drawArrow(o, a),
                    0 != n && this.drawArrow(a, o))
                  : (1 != n && this.drawArrow(a, o),
                    0 != n && this.drawArrow(o, a))
                : e.x < t.x
                ? (1 != n && this.drawArrow(o, a),
                  0 != n && this.drawArrow(a, o))
                : (1 != n && this.drawArrow(a, o),
                  0 != n && this.drawArrow(o, a));
          },
          drawRegionDirection: function (e, t, n, r) {
            var i = {},
              o = {},
              s = 0,
              l = {},
              u = {};
            if (null !== e && 0 !== t) {
              if (
                ((i.x = e[0].x),
                (i.y = e[0].y),
                (o.x = e[1].x),
                (o.y = e[1].y),
                i.x != o.x
                  ? (s = (i.y - o.y) / (i.x - o.x)) > 0
                    ? ((l.x = (i.x + o.x) / 2 + r * Math.sin(Math.atan(s))),
                      (l.y = (i.y + o.y) / 2 - r * Math.cos(Math.atan(s))),
                      (u.x = (i.x + o.x) / 2 - r * Math.sin(Math.atan(s))),
                      (u.y = (i.y + o.y) / 2 + r * Math.cos(Math.atan(s))))
                    : s < 0
                    ? ((l.x = (i.x + o.x) / 2 - r * Math.sin(Math.atan(-s))),
                      (l.y = (i.y + o.y) / 2 - r * Math.cos(Math.atan(-s))),
                      (u.x = (i.x + o.x) / 2 + r * Math.sin(Math.atan(-s))),
                      (u.y = (i.y + o.y) / 2 + r * Math.cos(Math.atan(-s))))
                    : ((l.x = (i.x + o.x) / 2),
                      (u.x = l.x),
                      (l.y = (i.y + o.y) / 2 - r),
                      (u.y = l.y + 2 * r))
                  : ((l.x = i.x - r),
                    (u.x = i.x + r),
                    (l.y = (i.y + o.y) / 2),
                    (u.y = l.y)),
                this.drawLine(l, u),
                2 == n)
              )
                return this.drawArrow(l, u), void this.drawArrow(u, l);
              e[e.length] = e[0];
              var c = a(e, l),
                f = a(e, u);
              (0 == n && c) || (1 == n && f)
                ? this.drawArrow(u, l)
                : this.drawArrow(l, u);
            }
          },
          drawText: function (e, n, i) {
            n &&
              n.length &&
              n[0] &&
              void 0 !== n[0].x &&
              void 0 !== n[0].y &&
              (t.beginPath(),
              (t.font = "25px Tahoma"),
              (t.fillStyle = r),
              (t.textAlign = "left"),
              (t.textBaseline = "middle"),
              t.fillText(e, n[0].x, n[0].y),
              t.closePath());
          },
          getTextAngleCoordinates: function (e, t, n) {
            for (var r = {}, i = new Array(t), o = 0; o < t; o++)
              (i[o] = {}), (i[o].x = e[o].x), (i[o].y = -e[o].y);
            var a,
              s,
              l,
              u = 0,
              c = -1,
              f = 0,
              h = 0;
            if (0 == n) {
              for (u = 0; u < t - 1; u++)
                (a = i[u].x - i[u + 1].x),
                  (s = i[u].y - i[u + 1].y),
                  (l = Math.sqrt(a * a + s * s)) - h > 1e-4 &&
                    ((c = u), (h = l), (f = Math.atan2(s, a)));
              if (-1 == c || h < 0) return void (i = null);
            } else
              2 == nSideConfig &&
                ((a = i[0].x - i[1].x),
                (s = i[0].y - i[1].y),
                (l = Math.sqrt(a * a + s * s)),
                (c = 0),
                (f = Math.atan2(s, a)));
            var d = (180 * f) / Math.PI;
            return (
              (r =
                90 == d || -90 == d
                  ? i[c].y > i[c + 1].y
                    ? i[c]
                    : i[c + 1]
                  : i[c].x < i[c + 1].x
                  ? i[c]
                  : i[c + 1]),
              d >= -180 && d < -90
                ? (d += 180)
                : d >= 90 && d <= 180 && (d -= 180),
              (i = null),
              { pPointsDraw: r, nAngleDraw: d }
            );
          },
          drawHalfline: function (e, t) {
            var n = new Array(2);
            (n[0] = {}),
              (n[1] = {}),
              (n[0].x = e.x),
              (n[0].y = e.y),
              (n[1].x = (3 * e.x + t.x) / 4),
              (n[1].y = (3 * e.y + t.y) / 4),
              this.drawLine(n[0], n[1]),
              (n[0].x = (e.x + 3 * t.x) / 4),
              (n[0].y = (e.y + 3 * t.y) / 4),
              (n[1].x = t.x),
              (n[1].y = t.y),
              this.drawLine(n[0], n[1]);
          },
          drawBlock: function (e, t) {
            var n = new Array(5),
              r = 0;
            0 == t &&
              ((n[0] = {}),
              (n[1] = {}),
              (n[2] = {}),
              (n[3] = {}),
              (n[4] = {}),
              (n[0].x = e.left),
              (n[0].y = e.top),
              (n[1].x = e.right),
              (n[1].y = e.top),
              (n[2].x = e.right),
              (n[2].y = e.bottom),
              (n[3].x = e.left),
              (n[3].y = e.bottom),
              (n[4].x = e.left),
              (n[4].y = e.top),
              (r = 5)),
              this.drawPolyLine(n, r);
          },
          clearCanvasLayer: function () {
            t.clearRect(0, 0, e.width + 1, e.height + 1);
          },
          TestDraw: function () {
            n++;
            var e = t.createLinearGradient(n + 20, n + 20, n + 150, n + 150);
            e.addColorStop(0, "yellow"),
              e.addColorStop(1, "red"),
              (t.fillStyle = e),
              t.fillRect(n, n, 300, 300),
              t.stroke(),
              n > 1100 && (n = 0);
          },
          Stop: function () {
            (jsRuleBuf = null), (jsRuleInfo = null), (jsRuleDataView = null);
          },
        }),
        new i()
      );
    };
    function x(e) {
      var t, n, r, i, o, a;
      for (t = "", r = e.length, n = 0; n < r; )
        switch ((i = e[n++]) >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            t += String.fromCharCode(i);
            break;
          case 12:
          case 13:
            (o = e[n++]),
              (t += String.fromCharCode(((31 & i) << 6) | (63 & o)));
            break;
          case 14:
            (o = e[n++]),
              (a = e[n++]),
              (t += String.fromCharCode(
                ((15 & i) << 12) | ((63 & o) << 6) | ((63 & a) << 0)
              ));
        }
      return t;
    }
    var R = function () {
      var e = "#0000FF",
        t = "#FF0000",
        n = "#00CCFF",
        r = "#E1D800",
        i = "#24FF00",
        o = "#FF8000",
        a = "#FF80FF",
        s = "#8000FF",
        l = "#FF5700",
        u = "#FFFFFF",
        c = 287,
        f = 294,
        h = 601,
        d = 769,
        p = 770,
        m = 804,
        g = 16384,
        v = 573,
        y = 556,
        S = 299,
        w = 300,
        b = null,
        _ = null,
        C = null,
        A = null,
        P = null,
        R = null,
        I = null,
        U = null,
        M = null,
        D = null,
        E = 60,
        k = 50,
        L = new Array(32),
        O = 0,
        F = new Array(32),
        N = 0,
        B = {};
      function V() {}
      function z(e) {
        var t = "#00FF00";
        switch (e) {
          case 1:
            t = n;
            break;
          case 2:
          case 16:
            t = r;
            break;
          case 17:
            t = i;
            break;
          case 22:
            t = o;
            break;
          case 23:
            t = a;
            break;
          case 63:
            t = s;
        }
        return t;
      }
      return (
        (V.prototype = {
          Init: function (e) {
            (b = new T()).Init(e);
          },
          setIVSData: function (e, t, n, r) {
            switch (t) {
              case 4:
                !(function (e, t) {
                  Date.now();
                  C ||
                    ((_ = new ArrayBuffer(t)),
                    (C = new Uint8Array(_)),
                    (A = new DataView(_))),
                    C.set(Module.HEAPU8.subarray(e, e + t));
                  var n = 0;
                  (L[O] = {}),
                    (L[O].nRuleID = A.getUint32(0, !0)),
                    (L[O].nRuleType = A.getUint32(4, !0)),
                    (L[O].nRuleSubType = A.getUint32(8, !0)),
                    (L[O].nRuleNameLength = A.getUint32(12, !0));
                  var r = C.slice(16, 16 + L[O].nRuleNameLength);
                  (L[O].strRuleName = x(r)),
                    (L[O].nExcludeRegionNum = A.getUint32(144, !0)),
                    (L[O].nDirection = A.getInt32(148, !0)),
                    (L[O].nAlarmNum = A.getUint32(152)),
                    (L[O].bAlarm = A.getUint8(154)),
                    (n = 156);
                  for (
                    var i = A.getUint32(n, !0), o = new Array(i), a = 0;
                    a < i;
                    a++
                  )
                    (o[a] = {}),
                      (o[a].x = A.getUint32(n + 4 + 8 * a, !0)),
                      (o[a].y = A.getUint32(n + 8 + 8 * a, !0));
                  (L[O].DetectLinePoints = o), (n = n + 4 + 256);
                  for (
                    var s = A.getUint32(n, !0), l = new Array(s), a = 0;
                    a < s;
                    a++
                  )
                    (l[a] = {}),
                      (l[a].x = A.getUint32(n + 4 + 8 * a, !0)),
                      (l[a].y = A.getUint32(n + 8 + 8 * a, !0));
                  (L[O].RuleRegionPoints = l), (n = n + 4 + 256);
                  for (
                    var u = A.getUint32(n, !0), c = new Array(u), a = 0;
                    a < u;
                    a++
                  )
                    (c[a] = {}),
                      (c[a].x = A.getUint32(n + 4 + 8 * a, !0)),
                      (c[a].y = A.getUint32(n + 8 + 8 * a, !0));
                  L[O].DetectRegionPoints = c;
                  for (
                    var f = (n = n + 4 + 256),
                      h = new Array(L[O].nExcludeRegionNum),
                      a = 0;
                    a < L[O].nExcludeRegionNum;
                    a++
                  ) {
                    var d = A.getUint32(f, !0);
                    h[a] = new Array(d);
                    for (var p = 0; p < d; p++)
                      (h[a][p] = {}),
                        (h[a][p].x = A.getUint32(f + 4 + 8 * p, !0)),
                        (h[a][p].y = A.getUint32(f + 8 + 8 * p, !0));
                    f = f + 4 + 256;
                  }
                  (L[O].ExcludeRegionPoints = h), (n += 2600);
                  for (var m = new Array(2), a = 0; a < 2; a++)
                    (m[a] = {}),
                      (m[a].x = A.getUint32(n + 8 * a, !0)),
                      (m[a].y = A.getUint32(n + 4 + 8 * a, !0));
                  (L[O].DirectionPoints = m),
                    (n += 16),
                    (L[O].nIntelFlowLength = A.getUint32(n, !0));
                  var g = C.slice(n + 4, n + 4 + L[O].nIntelFlowLength);
                  (L[O].strIntelFlow = x(g)), (n = n + 4 + 256);
                  for (
                    var v = A.getUint32(n, !0), y = new Array(v), a = 0;
                    a < v;
                    a++
                  )
                    (y[a] = {}),
                      (y[a].x = A.getUint32(n + 4 + 8 * a, !0)),
                      (y[a].y = A.getUint32(n + 8 + 8 * a, !0));
                  (L[O].EscalatorLeftPoints = y), (n = n + 4 + 256);
                  for (
                    var S = A.getUint32(n, !0), w = new Array(S), a = 0;
                    a < S;
                    a++
                  )
                    (w[a] = {}),
                      (w[a].x = A.getUint32(n + 4 + 8 * a, !0)),
                      (w[a].y = A.getUint32(n + 8 + 8 * a, !0));
                  (L[O].EscalatorRightPoints = w),
                    (n = n + 4 + 256),
                    Date.now(),
                    O++;
                })(n, r);
                break;
              case 5:
                !(function (e, t) {
                  (F[N] = {}),
                    R ||
                      ((P = new ArrayBuffer(t)),
                      (R = new Uint8Array(P)),
                      (I = new DataView(P))),
                    R.set(Module.HEAPU8.subarray(e, e + t)),
                    (F[N].bAlarm = I.getUint8(0)),
                    (F[N].nObjType = I.getUint32(4, !0));
                  var n = {};
                  (n.x = I.getUint16(8, !0)),
                    (n.y = I.getUint16(10, !0)),
                    (n.xSize = I.getUint16(12, !0)),
                    (n.ySize = I.getUint16(14, !0)),
                    (F[N].ptIvsTrackPoint = n);
                  var r = {};
                  (r.x = I.getUint16(16, !0)),
                    (r.y = I.getUint16(18, !0)),
                    (r.xSize = I.getUint16(20, !0)),
                    (r.ySize = I.getUint16(22, !0)),
                    (F[N].ptSpecialShapePoint = r);
                  for (var i = new Array(2), o = 0; o < 2; o++)
                    (i[o] = {}),
                      (i[o].x = I.getUint32(24 + 8 * o, !0)),
                      (i[o].y = I.getUint32(28 + 8 * o, !0));
                  (F[N].ptTrackTextPoints = i),
                    (F[N].nTrackTextLength = I.getUint32(40, !0));
                  var a = R.slice(44, 44 + F[N].nTrackTextLength);
                  F[N].strTrackTextInfo = x(a);
                  var s = 300;
                  F[N].struAttribute80 = {};
                  var l = {};
                  (l.x = I.getUint16(s, !0)),
                    (l.y = I.getUint16(s + 2, !0)),
                    (l.xSize = I.getUint16(s + 4, !0)),
                    (l.ySize = I.getUint16(s + 6, !0)),
                    (F[N].struAttribute80.ptCarWindowPoint = l),
                    (s += 8),
                    (F[N].struAttribute84 = {}),
                    (F[N].struAttribute84.nPointsNum = I.getUint32(s, !0)),
                    (s += 4);
                  for (
                    var u = new Array(F[N].struAttribute84.nPointsNum), o = 0;
                    o < F[N].struAttribute84.nPointsNum;
                    o++
                  )
                    (u[o] = {}),
                      (u[o].x = I.getUint16(s + 8 * o, !0)),
                      (u[o].y = I.getUint16(s + 2 + 8 * o, !0)),
                      (u[o].xSize = I.getUint16(s + 4 + 8 * o, !0)),
                      (u[o].ySize = I.getUint16(s + 6 + 8 * o, !0));
                  (F[N].struAttribute84.ptIvsTrackPoints = u), (s += 256);
                  for (
                    var c = new Array(F[N].struAttribute84.nPointsNum), o = 0;
                    o < F[N].struAttribute84.nPointsNum;
                    o++
                  )
                    (c[o] = {}),
                      (c[o].x = I.getUint32(s + 8 * o, !0)),
                      (c[o].y = I.getUint32(s + 4 + 8 * o, !0));
                  (F[N].struAttribute84.ptTrackPoints0 = c), (s += 256);
                  for (
                    var f = new Array(F[N].struAttribute84.nPointsNum), o = 0;
                    o < F[N].struAttribute84.nPointsNum;
                    o++
                  )
                    (f[o] = {}),
                      (f[o].x = I.getUint32(s + 8 * o, !0)),
                      (f[o].y = I.getUint32(s + 4 + 8 * o, !0));
                  (F[N].struAttribute84.ptTrackPoints1 = f),
                    (s += 256),
                    (F[N].struAttribute8C = {}),
                    (F[N].struAttribute8C.nAccessoryNum = I.getUint8(s)),
                    (F[N].struAttribute8C.nTissueBoxNum = I.getUint8(s + 1)),
                    (F[N].struAttribute8C.nSunVisorCount = I.getUint8(s + 2)),
                    (F[N].struAttribute8C.nStandardCount = I.getUint8(s + 3)),
                    (s += 4);
                  for (
                    var h = new Array(F[N].struAttribute8C.nAccessoryNum),
                      o = 0;
                    o < F[N].struAttribute8C.nAccessoryNum;
                    o++
                  )
                    (h[o] = {}),
                      (h[o].x = I.getUint16(s + 8 * o, !0)),
                      (h[o].y = I.getUint16(s + 2 + 8 * o, !0)),
                      (h[o].xSize = I.getUint16(s + 4 + 8 * o, !0)),
                      (h[o].ySize = I.getUint16(s + 6 + 8 * o, !0));
                  (F[N].struAttribute8C.ptAccessoryPoints = h), (s += 256);
                  for (
                    var d = new Array(F[N].struAttribute8C.nTissueBoxNum),
                      o = 0;
                    o < F[N].struAttribute8C.nTissueBoxNum;
                    o++
                  )
                    (d[o] = {}),
                      (d[o].x = I.getUint16(s + 8 * o, !0)),
                      (d[o].y = I.getUint16(s + 2 + 8 * o, !0)),
                      (d[o].xSize = I.getUint16(s + 4 + 8 * o, !0)),
                      (d[o].ySize = I.getUint16(s + 6 + 8 * o, !0));
                  (F[N].struAttribute8C.ptTissueBoxPoints = d), (s += 256);
                  for (
                    var p = new Array(F[N].struAttribute8C.nSunVisorCount),
                      o = 0;
                    o < F[N].struAttribute8C.nSunVisorCount;
                    o++
                  )
                    (p[o] = {}),
                      (p[o].x = I.getUint16(s + 8 * o, !0)),
                      (p[o].y = I.getUint16(s + 2 + 8 * o, !0)),
                      (p[o].xSize = I.getUint16(s + 4 + 8 * o, !0)),
                      (p[o].ySize = I.getUint16(s + 6 + 8 * o, !0));
                  (F[N].struAttribute8C.ptSunVisorPoints = p), (s += 256);
                  for (
                    var m = new Array(F[N].struAttribute8C.nStandardCount),
                      o = 0;
                    o < F[N].struAttribute8C.nStandardCount;
                    o++
                  )
                    (m[o] = {}),
                      (m[o].x = I.getUint16(s + 8 * o, !0)),
                      (m[o].y = I.getUint16(s + 2 + 8 * o, !0)),
                      (m[o].xSize = I.getUint16(s + 4 + 8 * o, !0)),
                      (m[o].ySize = I.getUint16(s + 6 + 8 * o, !0));
                  (F[N].struAttribute8C.ptStandardPoints = m),
                    (s += 256),
                    (F[N].struAttribute8D = {}),
                    (F[N].struAttribute8D.nVirtualCoilNum = I.getUint32(s, !0)),
                    (s += 4),
                    (F[N].struAttribute8D.struVirtualCoil = new Array(
                      F[N].struAttribute8D.nVirtualCoilNum
                    ));
                  for (
                    var o = 0;
                    o < F[N].struAttribute8D.nVirtualCoilNum;
                    o++
                  ) {
                    (F[N].struAttribute8D.struVirtualCoil[o] = {}),
                      (F[N].struAttribute8D.struVirtualCoil[o].nFirstCoilNum =
                        I.getUint32(s, !0)),
                      (s += 4);
                    for (
                      var g = new Array(
                          F[N].struAttribute8D.struVirtualCoil[o].nFirstCoilNum
                        ),
                        v = 0;
                      v < F[N].struAttribute8D.struVirtualCoil[o].nFirstCoilNum;
                      v++
                    )
                      (g[v] = {}),
                        (g[v].x = I.getUint32(s + 8 * v, !0)),
                        (g[v].y = I.getUint32(s + 4 + 8 * v, !0));
                    (F[N].struAttribute8D.struVirtualCoil[o].ptFirstCoilPoints =
                      g),
                      (s += 64),
                      (F[N].struAttribute8D.struVirtualCoil[o].nSecondCoilNum =
                        I.getUint32(s, !0)),
                      (s += 4);
                    for (
                      var y = new Array(
                          F[N].struAttribute8D.struVirtualCoil[o].nSecondCoilNum
                        ),
                        v = 0;
                      v <
                      F[N].struAttribute8D.struVirtualCoil[o].nSecondCoilNum;
                      v++
                    )
                      (y[v] = {}),
                        (y[v].x = I.getUint32(s + 8 * v, !0)),
                        (y[v].y = I.getUint32(s + 4 + 8 * v, !0));
                    (F[N].struAttribute8D.struVirtualCoil[
                      o
                    ].ptSecondCoilPoints = y),
                      (s += 64);
                    for (var S = new Array(2), v = 0; v < 2; v++)
                      (S[v] = {}),
                        (S[v].x = I.getUint32(s + 8 * v, !0)),
                        (S[v].y = I.getUint32(s + 4 + 8 * v, !0));
                    (F[N].struAttribute8D.struVirtualCoil[o].ptRtailCoilPoints =
                      S),
                      (s += 16);
                    for (var w = new Array(2), v = 0; v < 2; v++)
                      (w[v] = {}),
                        (w[v].x = I.getUint32(s + 8 * v, !0)),
                        (w[v].y = I.getUint32(s + 4 + 8 * v, !0));
                    (F[N].struAttribute8D.struVirtualCoil[o].ptStrInfoPoints =
                      w),
                      (s += 16),
                      (F[N].struAttribute8D.struVirtualCoil[o].nStrInfoLength =
                        I.getUint32(s, !0));
                    var b = R.slice(
                      s + 4,
                      s +
                        4 +
                        F[N].struAttribute8D.struVirtualCoil[o].nStrInfoLength
                    );
                    (F[N].struAttribute8D.struVirtualCoil[o].strInfo = x(b)),
                      (s += 260);
                  }
                  F[N].struAttribute8D.nVirtualCoilNum < 5 &&
                    (s += 428 * (5 - F[N].struAttribute8D.nVirtualCoilNum)),
                    (F[N].struAttribute90 = {});
                  for (var _ = new Array(2), o = 0; o < 2; o++)
                    (_[o] = {}),
                      (_[o].x = I.getUint32(s + 8 * o, !0)),
                      (_[o].y = I.getUint32(s + 4 + 8 * o, !0));
                  (F[N].struAttribute90.ptTimePoints = _),
                    (s += 16),
                    (F[N].struAttribute90.nTimeInfoLength = I.getUint32(s, !0));
                  var C = R.slice(
                    s + 4,
                    s + 4 + F[N].struAttribute90.nTimeInfoLength
                  );
                  (F[N].struAttribute90.strTimeInfo = x(C)), (s += 260), N++;
                })(n, r);
                break;
              case 6:
                !(function (e, t) {
                  M ||
                    ((U = new ArrayBuffer(t)),
                    (M = new Uint8Array(U)),
                    (D = new DataView(U))),
                    M.set(Module.HEAPU8.subarray(e, e + t)),
                    (B.nBlockNum = D.getUint32(0, !0));
                  for (
                    var n = new Array(B.nBlockNum), r = 0;
                    r < B.nBlockNum;
                    r++
                  )
                    (n[r] = {}),
                      (n[r].left = D.getUint32(4 + 16 * r, !0)),
                      (n[r].top = D.getUint32(8 + 16 * r, !0)),
                      (n[r].right = D.getUint32(12 + 16 * r, !0)),
                      (n[r].bottom = D.getUint32(16 + 16 * r, !0));
                  B.arrBlockRect = n;
                })(n, r);
            }
          },
          Stop: function () {
            (_ = null),
              (C = null),
              (A = null),
              (P = null),
              (R = null),
              (I = null),
              (U = null),
              (M = null),
              (D = null),
              (L = null),
              (O = 0),
              (F = null),
              (N = 0),
              b.clearCanvasLayer();
          },
          drawIVSData: function () {
            b.clearCanvasLayer(),
              (function () {
                var n = e;
                b.SetCurrentColor(n);
                for (var r = 0; r < O; r++) {
                  L[r].bAlarm && ((n = t), b.SetCurrentColor(n));
                  var i = L[r].DetectLinePoints.length,
                    o = L[r].RuleRegionPoints.length,
                    a = L[r].DetectRegionPoints.length,
                    s = L[r].nRuleType;
                  switch (s) {
                    case c:
                      b.drawPolyLine(L[r].DetectLinePoints, i);
                      for (var _ = 1; _ < i; _++)
                        b.drawDirection(
                          L[r].DetectLinePoints[_ - 1],
                          L[r].DetectLinePoints[_],
                          L[r].nDirection,
                          E
                        );
                      b.drawPolyLine(L[r].RuleRegionPoints, o);
                      break;
                    case f:
                      0 != L[r].nAlarmNum &&
                        b.drawPolyLine(L[r].RuleRegionPoints, o);
                      break;
                    case h:
                      if (
                        (2 == i &&
                          (b.drawLine(
                            L[r].DetectLinePoints[0],
                            L[r].DetectLinePoints[1]
                          ),
                          b.drawArrow(
                            L[r].DetectLinePoints[0],
                            L[r].DetectLinePoints[1]
                          )),
                        L[r].nRuleRegionNum > 0)
                      )
                        for (var _ = 0; _ < o / 2; _++)
                          b.drawLine(
                            L[r].RuleRegionPoints[2 * _],
                            L[r].RuleRegionPoints[2 * _ + 1]
                          );
                      break;
                    case d:
                    case p:
                    case m:
                      2 == i && b.drawPolyLine(L[r].DetectLinePoints, i),
                        b.drawPolyLine(L[r].RuleRegionPoints, o);
                      break;
                    case g:
                      break;
                    case v:
                      b.drawPolyLine(
                        L[r].EscalatorLeftPoints,
                        L[r].EscalatorLeftPoints.length
                      ),
                        b.drawPolyLine(
                          L[r].EscalatorRightPoints,
                          L[r].EscalatorRightPoints.length
                        );
                      break;
                    default:
                      b.SetCurrentColor(n);
                      var C = !1;
                      i > 0 &&
                        (b.drawPolyLine(L[r].DetectLinePoints, i),
                        L[r].nDirection >= 0 &&
                          i > 1 &&
                          (b.drawDirection(
                            L[r].DetectLinePoints[0],
                            L[r].DetectLinePoints[1],
                            L[r].nDirection,
                            E
                          ),
                          (C = !0))),
                        o > 0 &&
                          ((L[r].RuleRegionPoints[o] =
                            L[r].RuleRegionPoints[0]),
                          b.drawPolyLine(L[r].RuleRegionPoints, o + 1),
                          L[r].nDirection >= 0 &&
                            o > 1 &&
                            (b.drawRegionDirection(
                              L[r].RuleRegionPoints,
                              o,
                              L[r].nDirection,
                              k
                            ),
                            (C = !0))),
                        C ||
                          (L[r].DirectionPoints[0].x ==
                            L[r].DirectionPoints[1].x &&
                            L[r].DirectionPoints[0].y ==
                              L[r].DirectionPoints[1].y) ||
                          (b.drawLine(
                            L[r].DirectionPoints[0],
                            L[r].DirectionPoints[1]
                          ),
                          b.drawArrow(
                            L[r].DirectionPoints[0],
                            L[r].DirectionPoints[1]
                          ));
                  }
                  a > 0 &&
                    (b.SetCurrentColor(l),
                    (L[r].DetectRegionPoints[a] = L[r].DetectRegionPoints[0]),
                    b.drawPolyLine(L[r].DetectRegionPoints, a + 1)),
                    b.SetCurrentColor(u);
                  for (var _ = 0; _ < L[r].nExcludeRegionNum; _++) {
                    var A = L[r].ExcludeRegionPoints[_].length;
                    (L[r].ExcludeRegionPoints[_][A] =
                      L[r].ExcludeRegionPoints[_][0]),
                      b.drawPolyLine(L[r].ExcludeRegionPoints[_], A + 1);
                  }
                  b.SetCurrentColor(n);
                  var P = {};
                  i > 0
                    ? (P = L[r].DetectLinePoints)
                    : o > 0 && (P = L[r].RuleRegionPoints),
                    L[r].nIntelFlowLength > 0 &&
                      b.drawText(L[r].strIntelFlow, P, P.length),
                    s != y &&
                      (s == h
                        ? b.drawText(
                            L[r].strRuleName,
                            L[r].RuleRegionPoints,
                            L[r].RuleRegionPoints.length
                          )
                        : s == S || s == w
                        ? b.drawText(
                            L[r].strRuleName,
                            L[r].RuleRegionPoints,
                            L[r].RuleRegionPoints.length
                          )
                        : s == d || s == p
                        ? ptRuleRegionCnt > 0 &&
                          b.drawText(
                            L[r].strRuleName,
                            L[r].RuleRegionPoints,
                            L[r].RuleRegionPoints.length
                          )
                        : b.drawText(L[r].strRuleName, P, P.length));
                }
                O = 0;
              })(),
              (function () {
                for (var e = 0; e < N; e++) {
                  var n = z(F[e].nObjType);
                  b.SetCurrentColor(n),
                    F[e].bAlarm && b.SetCurrentColor(t),
                    b.drawTrackLine(F[e].ptIvsTrackPoint, 0),
                    b.drawTrackLine(F[e].ptSpecialShapePoint, 0),
                    b.drawText(
                      F[e].strTrackTextInfo,
                      F[e].ptTrackTextPoints,
                      F[e].ptTrackTextPoints.length
                    ),
                    b.drawTrackLine(F[e].struAttribute80.ptCarWindowPoint, 0);
                  for (var r = 0; r < F[e].struAttribute84.nPointsNum; r++)
                    b.drawTrackLine(
                      F[e].struAttribute84.ptIvsTrackPoints[r],
                      0
                    );
                  for (var r = 0; r < F[e].struAttribute8C.nAccessoryNum; r++)
                    b.drawTrackLine(
                      F[e].struAttribute8C.ptAccessoryPoints[r],
                      0
                    );
                  for (var r = 0; r < F[e].struAttribute8C.nTissueBoxNum; r++)
                    b.drawTrackLine(
                      F[e].struAttribute8C.ptTissueBoxPoints[r],
                      0
                    );
                  for (var r = 0; r < F[e].struAttribute8C.nSunVisorCount; r++)
                    b.drawTrackLine(
                      F[e].struAttribute8C.ptSunVisorPoints[r],
                      0
                    );
                  for (var r = 0; r < F[e].struAttribute8C.nStandardCount; r++)
                    b.drawTrackLine(
                      F[e].struAttribute8C.ptStandardPoints[r],
                      0
                    );
                  for (
                    var r = 0;
                    r < F[e].struAttribute8D.nVirtualCoilNum;
                    r++
                  ) {
                    b.drawPolyLine(
                      F[e].struAttribute8D.struVirtualCoil[r].ptFirstCoilPoints,
                      F[e].struAttribute8D.struVirtualCoil[r].nFirstCoilNum
                    ),
                      b.drawPolyLine(
                        F[e].struAttribute8D.struVirtualCoil[r]
                          .ptSecondCoilPoints,
                        F[e].struAttribute8D.struVirtualCoil[r].nSecondCoilNum
                      ),
                      b.drawLine(
                        F[e].struAttribute8D.struVirtualCoil[r]
                          .ptRtailCoilPoints[0],
                        F[e].struAttribute8D.struVirtualCoil[r]
                          .ptRtailCoilPoints[1]
                      );
                    var i =
                      F[e].struAttribute8D.struVirtualCoil[r].strInfo.length;
                    b.drawText(
                      F[e].struAttribute8D.struVirtualCoil[r].strInfo,
                      F[e].struAttribute8D.struVirtualCoil[r].ptStrInfoPoints,
                      i
                    );
                  }
                  b.drawText(
                    F[e].struAttribute90.strTimeInfo,
                    F[e].struAttribute90.ptTimePoints,
                    F[e].struAttribute90.strTimeInfo.length
                  );
                }
                N = 0;
              })(),
              (function () {
                for (var e = B.nBlockNum, t = 0; t < e; t++)
                  b.drawBlock(B.arrBlockRect[t], 0);
              })();
          },
        }),
        new V()
      );
    };
    function I(e, t) {
      var n = Object.keys(e);
      if (Object.getOwnPropertySymbols) {
        var r = Object.getOwnPropertySymbols(e);
        t &&
          (r = r.filter(function (t) {
            return Object.getOwnPropertyDescriptor(e, t).enumerable;
          })),
          n.push.apply(n, r);
      }
      return n;
    }
    function U(e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = null != arguments[t] ? arguments[t] : {};
        t % 2
          ? I(Object(n), !0).forEach(function (t) {
              M(e, t, n[t]);
            })
          : Object.getOwnPropertyDescriptors
          ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n))
          : I(Object(n)).forEach(function (t) {
              Object.defineProperty(
                e,
                t,
                Object.getOwnPropertyDescriptor(n, t)
              );
            });
      }
      return e;
    }
    function M(e, t, n) {
      return (
        t in e
          ? Object.defineProperty(e, t, {
              value: n,
              enumerable: !0,
              configurable: !0,
              writable: !0,
            })
          : (e[t] = n),
        e
      );
    }
    function D(e, t) {
      return (
        (t = (t = t.toLowerCase())[0].toUpperCase() + t.substr(1)),
        Object.prototype.toString.call(e) === "[object " + t + "]"
      );
    }
    function E(e, t, n) {
      if (
        (void 0 === n && (n = 2),
        void 0 === t && (t = 0),
        (e = e.toString()).length >= n)
      )
        return e;
      var r = n - e.length;
      return new Array(r).fill(String(t)).join("") + e;
    }
    function k(e, t) {
      return void 0 !== e && e
        ? ((t = t || new Date()),
          (e = (e = (e = (e = (e = (e = e.replace(
            /y/gi,
            E(t.getFullYear()),
            0
          )).replace(/m/gi, E(t.getMonth() + 1), 0)).replace(
            /d/gi,
            E(t.getDate()),
            0
          )).replace(/h/gi, E(t.getHours()), 0)).replace(
            /i/gi,
            E(t.getMinutes()),
            0
          )).replace(/s/gi, E(t.getSeconds()), 0)))
        : "";
    }
    function L(e, t) {
      var n = (e = e || {}).nameFormat || ["ymd_his"];
      t = t || new Date();
      var r = "";
      if (D(n, "string")) n = [n, {}];
      else {
        if (!D(n, "array"))
          return void (function (e) {
            throw new Error(e);
          })("name format must be string or array");
        D(n[0], "string") || (n[0] = "ymd_his"),
          D(n[1], "object") || (n[1] = {});
      }
      var i = n[0].split(/\{(?:[^{}]+)\}/),
        o = n[1];
      n[0].replace(/\{([^{}]*)\}/g, function (e, t, n) {
        i.shift();
        (r += k()), (r += t in o ? o[t] : e);
      });
      var a = i.shift();
      return (r += k(a, t));
    }
    function O(e, t) {
      (this.name = e),
        (this.allowUpDateName = !0),
        (this.byteLength = 0),
        (this.options = t),
        (this.startTime = new Date().toLocaleString());
    }
    (O.prototype.setEndTime = function () {
      this.endTime = new Date().toLocaleString();
    }),
      (O.prototype.updateNameByStream = function (e, t) {
        if (this.allowUpDateName) {
          var n = new Uint8Array(t),
            r = ((n[19] << 24) + (n[18] << 16) + (n[17] << 8) + n[16]) >>> 0,
            i =
              "20" +
              (r >> 26) +
              "/" +
              ((r >> 22) & 15) +
              "/" +
              ((r >> 17) & 31) +
              " " +
              ((r >> 12) & 31) +
              ":" +
              ((r >> 6) & 63) +
              ":" +
              (63 & r);
          (this.name = L(e, new Date(i))),
            (this.allowUpDateName = !1),
            (n = null);
        }
        t = null;
      });
    var F = new (function () {
      var e = { count: 0, total: 0, group: [] },
        t = function () {};
      return (
        (t.prototype.add = function (t) {
          e.count++, (e.total += t.byteLength), e.group.push(t);
        }),
        (t.prototype.get = function (t) {
          return t in e ? e[t] : e;
        }),
        new t()
      );
    })();
    var N = function () {
        var e = 1048576,
          t = null,
          n = null,
          r = 0,
          i = void 0,
          o = null,
          a = 0,
          s = null;
        function l() {
          (this.onMessage = function () {}),
            (this.postMessage = function (e) {
              this.__onMessage(e);
            }),
            (this.__postMessage = function (e) {
              this.onMessage(e);
            });
        }
        return (
          (l.prototype.__onMessage = function (e) {
            var t = e;
            switch (t.type) {
              case "init":
                this.init(t.options);
                break;
              case "addBuffer":
                this.addBuffer(t);
                break;
              case "close":
                this.close();
                break;
              case "cancel":
                this.cancel();
            }
          }),
          (l.prototype.init = function (t) {
            (this.fullSize = t.fullSize || 1 / 0),
              (this.singleSize = t.singleSize + 20 * e || 520 * e),
              (i = "init"),
              (s = t.recordName),
              (this.limitOptions = Object.assign(
                { limitBy: "fullSize" },
                t.limitOptions
              )),
              (this.nameOptions = Object.assign(
                { namedBy: "date", nameFormat: ["ymd_his", {}] },
                t.nameOptions
              ));
          }),
          (l.prototype._malloc = function (e) {
            t && n && ((n = null), (t = null)),
              (t = new ArrayBuffer(e)),
              (n = new DataView(t));
            var r = this.nameOptions,
              i = "";
            if (null != s) i = s;
            else
              switch (this.nameOptions.namedBy.toLowerCase()) {
                case "date":
                  i = L(r);
                  break;
                default:
                  i = L();
              }
            o = new O(i);
          }),
          (l.prototype._initVideoMem = function () {
            !t && this.singleSize && this._malloc(this.singleSize);
          }),
          (l.prototype.appendVideoBuf = function (t, i, o, s) {
            var l = t.byteLength,
              u = 0;
            if ((u = 5 == a ? i + l : r + l) > this.singleSize - 20 * e)
              this.inNodePlace(), this.addBuffer({ buffer: t });
            else {
              if (5 == a) {
                for (var c = i; c < u; c++) n.setUint8(c, t[c - i]);
                u > r && (r = u);
              } else {
                for (c = r; c < u; c++) n.setUint8(c, t[c - r]);
                r = u;
              }
              this.__postMessage({
                type: "pendding",
                size: r,
                total: this.singleSize,
                time: t ? o.timeStamp : "",
                utcTime: t ? o.utcTimeStamp : "",
              });
            }
          }),
          (l.prototype.addBuffer = function (e) {
            if ("closed" !== i) {
              var t = e.buffer,
                n = e.offset,
                o = e.timeStamp,
                s = e.utcTimeStamp;
              (a = e.recordType), this._initVideoMem(), (i = "addBuffer");
              var l = t.length,
                u = 0;
              (u = 5 == a ? n + l : r + l),
                F.get("total") + u > this.fullSize
                  ? this.close()
                  : this.appendVideoBuf(t, n, {
                      timeStamp: o,
                      utcTimeStamp: s,
                    });
            }
          }),
          (l.prototype.inNodePlace = function () {
            if ("addBuffer" === i) {
              (i = "download"),
                o.updateNameByStream(this.nameOptions, t.slice(0, 20)),
                (o.byteLength = r),
                o.setEndTime(),
                F.add(o);
              var e = t.slice(0, r);
              if (
                (this.reset(),
                this.__postMessage({
                  type: "download",
                  data: U(U({}, o), {}, { buffer: e }),
                }),
                (e = null),
                "count" === this.limitOptions.limitBy)
              ) {
                var n = this.limitOptions.count;
                n && n === F.get("count") && this.close();
              }
            }
          }),
          (l.prototype.reset = function () {
            (r = 0), this._malloc(this.singleSize);
          }),
          (l.prototype.close = function () {
            this.inNodePlace(),
              "closed" !== i &&
                void 0 !== i &&
                ((i = "closed"),
                this.__postMessage({
                  type: "closed",
                  message: "record was closed",
                }),
                (t = null),
                (n = null));
          }),
          (l.prototype.cancel = function () {
            "closed" !== i &&
              void 0 !== i &&
              ((i = "closed"),
              this.__postMessage({
                type: "closed",
                message: "record was closed",
              }),
              (t = null),
              (n = null));
          }),
          new l()
        );
      },
      B = function () {
        var e = 0,
          t = null,
          n = null,
          i = null,
          o = null,
          a = null,
          s = null,
          l = null,
          u = null,
          c = null,
          f = 1,
          h = "",
          d = !1,
          p = null,
          m = 0,
          w = { id: 1, samples: null, baseMediaDecodeTime: 0 },
          _ = 0,
          C = null,
          A = 1,
          T = 0,
          x = 0,
          I = 0,
          U = 1,
          M = 0,
          D = null,
          E = { timestamp: 0, timestamp_usec: 0 },
          k = null,
          L = null,
          O = null,
          F = null,
          B = null,
          V = null,
          z = null,
          q = null,
          G = null,
          W = null,
          Y = null,
          H = "",
          j = !1,
          X = null,
          Z = null,
          K = 0,
          J = 0,
          Q = 0,
          $ = null,
          ee = null,
          te = null,
          ne = 0,
          re = null,
          ie = null,
          oe = null,
          ae = null,
          se = null,
          le = null,
          ue = null,
          ce = null,
          fe = null,
          he = null,
          de = null,
          pe = null,
          me = null,
          ge = 0,
          ve = 0,
          ye = null,
          Se = 0,
          we = 0,
          be = 0,
          _e = null,
          Ce = 0,
          Ae = !0,
          Pe = !1,
          Te = !1,
          xe = 0,
          Re = null,
          Ie = 0,
          Ue = !1,
          Me = 18,
          De = 20,
          Ee = !1,
          ke = !1;
        function Le() {}
        function Oe() {
          o.setDecodeType(Ce),
            o.setCodecInfo(h),
            o.setInitSegmentFunc(Be),
            o.setSpeedPlay(f),
            o.setFPS(m);
        }
        function Fe() {
          o.setVideoSizeCallback(Ve),
            o.setBeginDrawCallback(k),
            o.setErrorCallback(F),
            o.setAudioStartCallback(Ne),
            o.setMseErrorCallback(ze);
        }
        function Ne(e, t) {}
        function Be() {
          return p;
        }
        function Ve() {
          null !== O && O(!1);
        }
        function ze(e) {
          switch (e) {
            case "InitError":
              1 == Ce ? (Ae = !1) : (Pe = !1),
                Module._PLAY_SetSupportWebMSE(Ae, Pe);
              break;
            case "Error":
            case "SourceError":
            case "SourceBufferError":
              Te = !0;
          }
        }
        function qe() {
          o && (o.close(), o.terminate(), (o = null)),
            (p = null),
            (a = null),
            (s = null),
            (d = !1),
            (Ue = !1),
            (T = 0),
            (w = { id: 1, samples: null, baseMediaDecodeTime: 0 }),
            (_ = 0),
            (U = 1),
            (I = 0),
            (M = 0),
            (x = 0);
        }
        function Ge(e) {
          return e < 10 ? "0".concat(e) : e;
        }
        return (
          (Le.prototype = {
            Init: function (i, a, s, l) {
              (Pe = Object(r.g)()), Module._PLAY_SetSupportWebMSE(Ae, Pe);
              var u = Module._malloc(1),
                c = new Uint8Array(Module.HEAPU8.buffer, u, 1);
              Module._PLAY_GetFreePort(c.byteOffset),
                (e = c[0]),
                (c = null),
                Module._free(u),
                Module._PLAY_ViewResolutionChanged(
                  e,
                  parseInt(i.width),
                  parseInt(i.height),
                  0
                );
              var f = Module._PLAY_SetStreamOpenMode(e, 0);
              return (
                Module._PLAY_OpenStream(e, 0, 0, 10485760),
                (f = Module._PLAY_Play(e, 1)) &&
                  ((G = Module._malloc(5242880)),
                  (W = new Uint8Array(Module.HEAPU8.buffer, G, 5242880)),
                  (ye = new g(i, !0 === l ? 100 : 25, l)),
                  (t = a),
                  (n = s),
                  (Pe || Ae) && ((o = b()).init(t), Fe())),
                f
              );
            },
            GetPlayPort: function () {
              return e;
            },
            InputData: function (t, n) {
              var r = 0;
              return (
                W &&
                  (W.set(n),
                  (r = Module._PLAY_InputData(e, W.byteOffset, n.length))),
                r
              );
            },
            Pause: function (e) {
              return Module._PLAY_Pause(e);
            },
            SetPlaySpeed: function (t) {
              var n = Module._PLAY_SetPlaySpeed(e, t);
              return ye.setPlaySpeed(t), (f = t), n;
            },
            SetSecurityKey: function (t, n, r, i, o) {
              var a = Module._malloc(49),
                s = new Uint8Array(Module.HEAPU8.buffer),
                l = 0;
              if (1 == t)
                n.forEach(function (e, t) {
                  (s[(a + l) >> 0] = e), l++;
                });
              else if (2 == t) {
                var u = new Uint8Array(16);
                if (((s[(a + l) >> 0] = 1), l++, 0 == o)) {
                  for (var c = 0; c > 16; c++) u[c] = 0;
                  (o = 16), (i = u);
                }
                i.forEach(function (e, t) {
                  (s[(a + l) >> 0] = e), l++;
                }),
                  n.forEach(function (e, t) {
                    (s[a + l] = e), l++;
                  }),
                  (r = 1 + r + o),
                  (u = null);
              } else
                3 == t &&
                  n.split("").forEach(function (e, t) {
                    (s[(a + l) >> 0] = e.charCodeAt(0)), l++;
                  });
              var f = Module._PLAY_SetSecurityKey(e, a, r);
              return Module._free(a), f;
            },
            StartRecord: function (t) {
              return (
                (Re = new N()),
                (function (e, t) {
                  var n = parseInt(e) || 500;
                  Re.postMessage({
                    type: "init",
                    options: {
                      recordName: t,
                      singleSize: 1048576 * n,
                      nameOptions: { namedBy: "date", nameFormat: ["ymd_his"] },
                      limitOptions: { limitBy: "count", count: 10 },
                    },
                  }),
                    (Re.onMessage = function (e) {
                      switch (e.type) {
                        case "pendding":
                          z && z(e);
                          break;
                        case "download":
                          !(function (e, t) {
                            var n = new Blob([t]),
                              r = document.createElement("a");
                            (r.href = URL.createObjectURL(n)),
                              (r.download = e),
                              r.click(),
                              URL.revokeObjectURL(r.href),
                              (r = null),
                              (t = null);
                          })(e.data.name, e.data.buffer);
                          break;
                        case "closed":
                          z && z(e), (Re = null);
                      }
                    });
                })(500, t),
                (ne = 5),
                Module._PLAY_StartDataRecord(e, 0, 5)
              );
            },
            StopRecord: function () {
              var t = Module._PLAY_StopDataRecord(e);
              return Re.postMessage({ type: "close" }), (Re = null), t;
            },
            CancelRecord: function () {
              var t = Module._PLAY_StopDataRecord(e);
              return Re.postMessage({ type: "cancel" }), (Re = null), t;
            },
            OpenIVSDraw: function () {
              return (
                (_e = new R()).Init(n), Module._PLAY_RenderPrivateData(e, 1, 0)
              );
            },
            CloseIVSDraw: function () {
              var t = Module._PLAY_RenderPrivateData(e, 0, 0);
              return _e.Stop(), (_e = null), t;
            },
            Stop: function () {
              var t = Module._PLAY_Stop(e);
              return 0 == t
                ? t
                : ((t = Module._PLAY_CloseStream(e)),
                  (a = null),
                  (s = null),
                  o && (o.close(), o.terminate(), (o = null)),
                  (W = null),
                  Module._free(G),
                  ($ = null),
                  (ee = null),
                  (te = null),
                  (re = null),
                  (ie = null),
                  (oe = null),
                  (ae = null),
                  (se = null),
                  (le = null),
                  (ue = null),
                  (ce = null),
                  (fe = null),
                  (de = null),
                  (pe = null),
                  (me = 0),
                  he && (he.stop(), (he = null)),
                  ye &&
                    (ye && ye.stopRendering(),
                    ye && ye.terminate(),
                    (ye = null)),
                  (Se = 0),
                  (we = 0),
                  (be = 0),
                  (p = null),
                  (h = null),
                  (Te = !1),
                  (d = !1),
                  _e && (_e.Stop(), (_e = null)),
                  (Ie = 0),
                  (Ue = !1),
                  (xe = 0),
                  (ke = !1),
                  t);
            },
            FrameDataCallBack: function (e, n, r, i, g, P, R, L) {
              ie ||
                ((re = new ArrayBuffer(292)),
                (ie = new Uint8Array(re)),
                (oe = new DataView(re))),
                ie.set(Module.HEAPU8.subarray(P, P + 292));
              var O = oe.getInt32(0, !0),
                F = oe.getInt32(4, !0),
                N = oe.getInt32(56, !0),
                z = oe.getUint16(40, !0),
                G = Ge(oe.getUint16(42, !0)),
                W = Ge(oe.getUint16(46, !0)),
                $ = Ge(oe.getUint16(48, !0)),
                ee = Ge(oe.getUint16(50, !0)),
                te = Ge(oe.getUint16(52, !0)),
                ne = Date.UTC(z, G, W, $, ee, te) / 1e3,
                _e = new Date(
                  ""
                    .concat(z, "-")
                    .concat(G, "-")
                    .concat(W, " ")
                    .concat($, ":")
                    .concat(ee, ":")
                    .concat(te)
                ).getTime();
              if (((E.timestamp = ne), (E.timestamp_usec = 0), 1 == O)) {
                var Re = oe.getInt32(68, !0),
                  Le = oe.getInt32(72, !0),
                  Ne = oe.getUint32(8, !0);
                if (
                  ((E.timestamp = 8 == Le ? ne : Ne / 1e3),
                  (E.timestamp_usec = 0),
                  2 == Re || 4 == Re || 8 == Re
                    ? (Ce = 1)
                    : 12 == Re && (Ce = 2),
                  oe.getInt32(36, !0),
                  (K = oe.getInt32(12, !0)),
                  (J = oe.getInt32(16, !0)),
                  0 == K || 0 == J)
                )
                  return;
                if (
                  ((m = oe.getInt32(20, !0)),
                  Ie <= 5 && Ie++,
                  Me == N || De == N ? (Q = 1) : 0 == N && (Q = 0),
                  ((1 == Ce && 1 == Ae) || (2 == Ce && 1 == Pe)) &&
                    !Q &&
                    13 != Le)
                ) {
                  if (
                    ((K == Se && J == we && Re == be) ||
                      (0 != Se && (Te = !0), (Se = K), (we = J), (be = Re)),
                    Te && (qe(), (Te = !1)),
                    0 == Ue && 0 != N)
                  )
                    return;
                  (Z = new ArrayBuffer(g)),
                    (Y = new Uint8Array(Z)).set(
                      Module.HEAPU8.subarray(n, n + g)
                    ),
                    null == a && (a = new S(Ce)),
                    null == s && (s = new y(Ce)),
                    (function (e, t, n) {
                      for (var r = null, i = e.length, o = [], f = 0; f <= i; )
                        if (0 == e[f])
                          if (0 == e[f + 1])
                            if (1 == e[f + 2]) {
                              if ((o.push(f), (f += 3), 1 == Ce)) {
                                if (5 == (31 & e[f]) || 1 == (31 & e[f])) break;
                              } else if (
                                2 == Ce &&
                                (38 == (255 & e[f]) || 2 == (255 & e[f]))
                              )
                                break;
                            } else 0 == e[f + 2] ? f++ : (f += 3);
                          else f += 2;
                        else f += 1;
                      var g = 0;
                      if (1 == Ce) {
                        for (var f = 0; f < o.length; f++)
                          switch (
                            ((r = e.subarray(o[f] + 3, o[f + 1])),
                            31 & e[o[f] + 3])
                          ) {
                            case 1:
                            case 5:
                              (g = o[f] - 1), (D = e.subarray(g, e.length));
                              break;
                            case 7:
                              a.parse(r), (u = r);
                              break;
                            case 8:
                              c = r;
                          }
                        if (!d) {
                          d = !0;
                          var v = {
                            id: 1,
                            width: K,
                            height: J,
                            type: "video",
                            profileIdc: a.getSpsValue("profile_idc"),
                            profileCompatibility: 0,
                            levelIdc: a.getSpsValue("level_idc"),
                            sps: [u],
                            pps: [c],
                            timescale: 1e3,
                            fps: m,
                          };
                          (p = s.initSegment(v)), (h = a.getCodecInfo());
                        }
                      } else if (2 == Ce) {
                        for (var f = 0; f < o.length; f++)
                          switch (
                            ((r = e.subarray(o[f] + 3, o[f + 1] - 1)),
                            255 & e[o[f] + 3])
                          ) {
                            case 2:
                            case 38:
                              (g = o[f] - 1), (D = e.subarray(g, e.length));
                              break;
                            case 64:
                              l = r;
                              break;
                            case 66:
                              var y = e.subarray(o[f] + 5, o[f + 1] - 1);
                              a.parse(y), (u = r);
                              break;
                            case 68:
                              c = r;
                          }
                        if (!d) {
                          d = !0;
                          var S = a.getSpsValue("general_profile_space"),
                            w = a.getSpsValue("general_tier_flag"),
                            b = a.getSpsValue("general_profile_idc"),
                            _ = a.getSpsValue("temporalIdNested"),
                            v =
                              (a.getSpsValue(
                                "general_profile_compatibility_flags"
                              ),
                              a.getSpsValue(
                                "general_constraint_indicator_flags"
                              ),
                              {
                                id: 1,
                                width: K,
                                height: J,
                                type: "video",
                                general_profile_flag: (S << 6) | (w << 5) | b,
                                general_profile_compatibility_flags:
                                  a.getSpsValue(
                                    "general_profile_compatibility_flags"
                                  ),
                                general_constraint_indicator_flags:
                                  a.getSpsValue(
                                    "general_constraint_indicator_flags"
                                  ),
                                general_level_idc:
                                  a.getSpsValue("general_level_idc"),
                                chroma_format_idc:
                                  a.getSpsValue("chroma_format_idc"),
                                bitDepthLumaMinus8:
                                  a.getSpsValue("bitDepthLumaMinus8"),
                                bitDepthChromaMinus8: a.getSpsValue(
                                  "bitDepthChromaMinus8"
                                ),
                                rate_layers_nested_length: 11 | ((1 & _) << 2),
                                vps: [l],
                                sps: [u],
                                pps: [c],
                                timescale: 1e3,
                                fps: m,
                              });
                          p = s.initSegment(v);
                        }
                      }
                    })(Y),
                    0 == Ue &&
                      ((xe = 1),
                      null == o && (o = b()).init(t),
                      B((Be = { decodeMode: "video" })),
                      Fe(),
                      Oe(),
                      (Ue = !0)),
                    j && ((j = !1), o.capture(H, { ivsCanvasArr: [X] }, q, Ee)),
                    o.setvideoTimeStamp(E),
                    (function () {
                      if (null != D) {
                        var e = {
                          duration: Math.round((1 / m) * 1e3),
                          size: D.length,
                          frame_time_stamp: null,
                          frameDuration: null,
                        };
                        (e.frameDuration = e.duration),
                          null == w.samples && (w.samples = new Array(A)),
                          (w.samples[T++] = e),
                          (x += e.frameDuration),
                          (I += e.frameDuration);
                        var t = D.length - 4;
                        (D[0] = (4278190080 & t) >>> 24),
                          (D[1] = (16711680 & t) >>> 16),
                          (D[2] = (65280 & t) >>> 8),
                          (D[3] = 255 & t);
                        var n = new Uint8Array(D.length + _);
                        if (
                          (0 !== _ && n.set(C),
                          n.set(D, _),
                          (_ = (C = n).length),
                          T % A === 0 && 0 !== T)
                        ) {
                          if (
                            (null !== w.samples[0].frameDuration &&
                              ((w.baseMediaDecodeTime = 1 === U ? 0 : M),
                              (M = x)),
                            1 == f)
                          )
                            for (
                              var r = w.samples.length, i = I / A, a = 0;
                              a < r;
                              a++
                            )
                              w.samples[a].frameDuration = i;
                          I = 0;
                          var l = s.mediaSegment(
                            U,
                            w,
                            C,
                            w.baseMediaDecodeTime
                          );
                          U++,
                            (T = 0),
                            (C = null),
                            (_ = 0),
                            null !== o
                              ? o.setMediaSegment(l)
                              : !1 === d && Oe();
                        }
                      }
                    })(),
                    (Z = null),
                    (Y = null);
                } else {
                  if (0 == n || 0 == r || 0 == i) return;
                  0 != xe && (qe(), (Te = !0), (Se = 0), (we = 0));
                  var Be,
                    Ve = oe.getInt32(76, !0);
                  (K == Se && J == we) ||
                    ((xe = 0),
                    (Se = K),
                    (we = J),
                    ye.resize(K, J),
                    ((Be = { decodeMode: "canvas" }).width = K),
                    (Be.height = J),
                    [2, 4, 8].includes(Re)
                      ? (Be.encodeMode = "H264")
                      : 12 === Re && (Be.encodeMode = "H265"),
                    B(Be),
                    (ae = null),
                    (se = null),
                    (le = null),
                    (ue = null),
                    (ce = null),
                    (fe = null),
                    (ae = new ArrayBuffer(K * J)),
                    (ue = new Uint8Array(ae)),
                    (se = new ArrayBuffer((K * J) / 4)),
                    (ce = new Uint8Array(se)),
                    (le = new ArrayBuffer((K * J) / 4)),
                    (fe = new Uint8Array(le)));
                  var ze = 0;
                  for (ze = 0; ze < J; ze++)
                    ue.set(
                      Module.HEAPU8.subarray(n + ze * Ve, n + ze * Ve + K),
                      ze * K
                    );
                  for (ze = 0; ze < J / 2; ze++)
                    ce.set(
                      Module.HEAPU8.subarray(
                        r + (ze * Ve) / 2,
                        r + (ze * Ve) / 2 + K / 2
                      ),
                      (ze * K) / 2
                    );
                  for (ze = 0; ze < J / 2; ze++)
                    fe.set(
                      Module.HEAPU8.subarray(
                        i + (ze * Ve) / 2,
                        i + (ze * Ve) / 2 + K / 2
                      ),
                      (ze * K) / 2
                    );
                  ke || ((ke = !0), k("PlayStart")),
                    ye && ye.draw(ue, ce, fe, m),
                    j && ((j = !1), o.capture(H, { ivsCanvasArr: [X] }, q));
                }
                V({ timeStamp: _e, utcTimeStamp: ne });
              } else if (2 == O) {
                if (f > 1) return;
                if (Ie < 5 && !L) {
                  if (m > 5 || 0 == m) return;
                  if (Ie < 2) return;
                }
                if ((oe.getInt32(24, !0), oe.getInt32(80, !0) > 0)) return;
                var We = oe.getInt32(28, !0),
                  Ye = oe.getInt32(32, !0);
                oe.getInt32(84, !0),
                  me != g &&
                    ((me = g),
                    (de = null),
                    (pe = null),
                    (de = new ArrayBuffer(g)),
                    (pe = new Uint8Array(de))),
                  pe.set(Module.HEAPU8.subarray(n, n + g));
                for (
                  var He = new Int16Array(
                      pe.buffer,
                      pe.byteOffset,
                      pe.byteLength / Int16Array.BYTES_PER_ELEMENT
                    ),
                    je = new Float32Array(He.length),
                    Xe = 0;
                  Xe < He.length;
                  Xe++
                )
                  je[Xe] = He[Xe] / Math.pow(2, 15);
                (Ye == ge && We == ve) ||
                  ((ge = Ye),
                  (ve = We),
                  he && (he.stop(), (he = null)),
                  (he = new v()).setSamplingRate(Ye),
                  null !== he &&
                    (he.setInitVideoTimeStamp(0),
                    he.audioInit(R, Ye) || (he.stop(), (he = null)))),
                  he && he.bufferAudio(je, 0),
                  (He = null),
                  (je = null);
              }
            },
            DecryptionResultCallBack: function (e, t, n) {},
            IVSDataCallBack: function (e, t, n, r) {
              _e && _e.setIVSData(e, t, n, r);
            },
            DrawIVSDataCallBack: function (e) {
              _e && _e.drawIVSData();
            },
            RecordDataCallBack: function (e, t, n, r, i) {
              ee ||
                (($ = new ArrayBuffer(292)),
                (ee = new Uint8Array($)),
                (te = new DataView($))),
                ee.set(Module.HEAPU8.subarray(i, i + 292));
              var o = te.getInt32(0, !0);
              if ((te.getInt32(4, !0), te.getInt32(56, !0), 1 == o)) {
                te.getInt32(68, !0), te.getInt32(72, !0), te.getUint32(8, !0);
                var a = te.getUint16(40, !0),
                  s = Ge(te.getUint16(42, !0)),
                  l = Ge(te.getUint16(46, !0)),
                  u = Ge(te.getUint16(48, !0)),
                  c = Ge(te.getUint16(50, !0)),
                  f = Ge(te.getUint16(52, !0)),
                  h = Date.UTC(a, s, l, u, c, f) / 1e3,
                  d = new Date(
                    ""
                      .concat(a, "-")
                      .concat(s, "-")
                      .concat(l, " ")
                      .concat(u, ":")
                      .concat(c, ":")
                      .concat(f)
                  ).getTime();
              }
              var p = new ArrayBuffer(n),
                m = new Uint8Array(p);
              m.set(Module.HEAPU8.subarray(t, t + n)),
                Re.postMessage({
                  type: "addBuffer",
                  buffer: m,
                  offset: r,
                  timeStamp: d,
                  utcTimeStamp: h,
                  recordType: ne,
                }),
                (p = null),
                (m = null);
            },
            setCallback: function (e, t) {
              switch (e) {
                case "timeStamp":
                case "ResolutionChanged":
                  break;
                case "audioTalk":
                  L = t;
                  break;
                case "stepRequest":
                case "metaEvent":
                case "videoMode":
                  break;
                case "loadingBar":
                  O = t;
                  break;
                case "Error":
                  F = t;
                  break;
                case "PlayStart":
                  k = t;
                  break;
                case "DecodeStart":
                  B = t;
                  break;
                case "UpdateCanvas":
                  V = t;
                  break;
                case "FrameTypeChange":
                case "MSEResolutionChanged":
                case "audioChange":
                case "WorkerReady":
                  break;
                case "recordChanged":
                  z = t;
                  break;
                case "CaptureChanged":
                  q = t;
              }
            },
            capture: function (e, t, n) {
              (H = e), (j = !0), (X = t), (Ee = n), ye.capture(e, t, q, Ee);
            },
            mute: function (e) {
              he && he.Mute(e);
            },
            setVolume: function (e) {
              he && he.setVolume(e);
            },
            talkInit: function (e, t) {
              i || (i = new P()),
                i.talkInit(e, t),
                i.setSendAudioTalkCallback(L);
            },
            setPlaybackSpeed: function (e) {
              (f = e), ye.setPlaySpeed(f);
            },
            GetCurrentPlayTime: function () {
              return E.timestamp;
            },
          }),
          new Le()
        );
      };
    function V(e) {
      return (V =
        "function" === typeof Symbol && "symbol" === typeof Symbol.iterator
          ? function (e) {
              return typeof e;
            }
          : function (e) {
              return e &&
                "function" === typeof Symbol &&
                e.constructor === Symbol &&
                e !== Symbol.prototype
                ? "symbol"
                : typeof e;
            })(e);
    }
    if ("undefined" == typeof z) var z = {};
    z.MD5 = function (e) {
      function t(e) {
        var t = (e >>> 0).toString(16);
        return "00000000".substr(0, 8 - t.length) + t;
      }
      function n(e, t, n) {
        return (e & t) | (~e & n);
      }
      function r(e, t, n) {
        return (n & e) | (~n & t);
      }
      function i(e, t, n) {
        return e ^ t ^ n;
      }
      function o(e, t, n) {
        return t ^ (e | ~n);
      }
      function a(e, t) {
        return (e[t + 3] << 24) | (e[t + 2] << 16) | (e[t + 1] << 8) | e[t];
      }
      function s(e) {
        for (var t = [], n = 0; n < e.length; n++)
          if (e.charCodeAt(n) <= 127) t.push(e.charCodeAt(n));
          else
            for (
              var r = encodeURIComponent(e.charAt(n)).substr(1).split("%"),
                i = 0;
              i < r.length;
              i++
            )
              t.push(parseInt(r[i], 16));
        return t;
      }
      function l(e) {
        for (var t = new Array(e.length), n = 0; n < e.length; n++) t[n] = e[n];
        return t;
      }
      var u = null,
        c = null;
      function f(e, t) {
        return 4294967295 & (e + t);
      }
      return (
        "string" == typeof e
          ? (u = s(e))
          : e.constructor == Array
          ? 0 === e.length
            ? (u = e)
            : "string" == typeof e[0]
            ? (u = (function (e) {
                for (var t = [], n = 0; n < e.length; n++)
                  t = t.concat(s(e[n]));
                return t;
              })(e))
            : "number" == typeof e[0]
            ? (u = e)
            : (c = V(e[0]))
          : "undefined" != typeof ArrayBuffer
          ? e instanceof ArrayBuffer
            ? (u = l(new Uint8Array(e)))
            : e instanceof Uint8Array || e instanceof Int8Array
            ? (u = l(e))
            : e instanceof Uint32Array ||
              e instanceof Int32Array ||
              e instanceof Uint16Array ||
              e instanceof Int16Array ||
              e instanceof Float32Array ||
              e instanceof Float64Array
            ? (u = l(new Uint8Array(e.buffer)))
            : (c = V(e))
          : (c = V(e)),
        c && alert("MD5 type mismatch, cannot process " + c),
        (function () {
          function e(e, t, n, r) {
            var i,
              o,
              a = S;
            (S = y),
              (y = v),
              (v = f(
                v,
                (((i = f(g, f(e, f(t, n)))) << (o = r)) & 4294967295) |
                  (i >>> (32 - o))
              )),
              (g = a);
          }
          var s = u.length;
          u.push(128);
          var l = u.length % 64;
          if (l > 56) {
            for (var c = 0; c < 64 - l; c++) u.push(0);
            l = u.length % 64;
          }
          for (c = 0; c < 56 - l; c++) u.push(0);
          u = u.concat(
            (function (e) {
              for (var t = [], n = 0; n < 8; n++) t.push(255 & e), (e >>>= 8);
              return t;
            })(8 * s)
          );
          var h = 1732584193,
            d = 4023233417,
            p = 2562383102,
            m = 271733878,
            g = 0,
            v = 0,
            y = 0,
            S = 0;
          for (c = 0; c < u.length / 64; c++) {
            g = h;
            var w = 64 * c;
            e(n((v = d), (y = p), (S = m)), 3614090360, a(u, w), 7),
              e(n(v, y, S), 3905402710, a(u, w + 4), 12),
              e(n(v, y, S), 606105819, a(u, w + 8), 17),
              e(n(v, y, S), 3250441966, a(u, w + 12), 22),
              e(n(v, y, S), 4118548399, a(u, w + 16), 7),
              e(n(v, y, S), 1200080426, a(u, w + 20), 12),
              e(n(v, y, S), 2821735955, a(u, w + 24), 17),
              e(n(v, y, S), 4249261313, a(u, w + 28), 22),
              e(n(v, y, S), 1770035416, a(u, w + 32), 7),
              e(n(v, y, S), 2336552879, a(u, w + 36), 12),
              e(n(v, y, S), 4294925233, a(u, w + 40), 17),
              e(n(v, y, S), 2304563134, a(u, w + 44), 22),
              e(n(v, y, S), 1804603682, a(u, w + 48), 7),
              e(n(v, y, S), 4254626195, a(u, w + 52), 12),
              e(n(v, y, S), 2792965006, a(u, w + 56), 17),
              e(n(v, y, S), 1236535329, a(u, w + 60), 22),
              e(r(v, y, S), 4129170786, a(u, w + 4), 5),
              e(r(v, y, S), 3225465664, a(u, w + 24), 9),
              e(r(v, y, S), 643717713, a(u, w + 44), 14),
              e(r(v, y, S), 3921069994, a(u, w), 20),
              e(r(v, y, S), 3593408605, a(u, w + 20), 5),
              e(r(v, y, S), 38016083, a(u, w + 40), 9),
              e(r(v, y, S), 3634488961, a(u, w + 60), 14),
              e(r(v, y, S), 3889429448, a(u, w + 16), 20),
              e(r(v, y, S), 568446438, a(u, w + 36), 5),
              e(r(v, y, S), 3275163606, a(u, w + 56), 9),
              e(r(v, y, S), 4107603335, a(u, w + 12), 14),
              e(r(v, y, S), 1163531501, a(u, w + 32), 20),
              e(r(v, y, S), 2850285829, a(u, w + 52), 5),
              e(r(v, y, S), 4243563512, a(u, w + 8), 9),
              e(r(v, y, S), 1735328473, a(u, w + 28), 14),
              e(r(v, y, S), 2368359562, a(u, w + 48), 20),
              e(i(v, y, S), 4294588738, a(u, w + 20), 4),
              e(i(v, y, S), 2272392833, a(u, w + 32), 11),
              e(i(v, y, S), 1839030562, a(u, w + 44), 16),
              e(i(v, y, S), 4259657740, a(u, w + 56), 23),
              e(i(v, y, S), 2763975236, a(u, w + 4), 4),
              e(i(v, y, S), 1272893353, a(u, w + 16), 11),
              e(i(v, y, S), 4139469664, a(u, w + 28), 16),
              e(i(v, y, S), 3200236656, a(u, w + 40), 23),
              e(i(v, y, S), 681279174, a(u, w + 52), 4),
              e(i(v, y, S), 3936430074, a(u, w), 11),
              e(i(v, y, S), 3572445317, a(u, w + 12), 16),
              e(i(v, y, S), 76029189, a(u, w + 24), 23),
              e(i(v, y, S), 3654602809, a(u, w + 36), 4),
              e(i(v, y, S), 3873151461, a(u, w + 48), 11),
              e(i(v, y, S), 530742520, a(u, w + 60), 16),
              e(i(v, y, S), 3299628645, a(u, w + 8), 23),
              e(o(v, y, S), 4096336452, a(u, w), 6),
              e(o(v, y, S), 1126891415, a(u, w + 28), 10),
              e(o(v, y, S), 2878612391, a(u, w + 56), 15),
              e(o(v, y, S), 4237533241, a(u, w + 20), 21),
              e(o(v, y, S), 1700485571, a(u, w + 48), 6),
              e(o(v, y, S), 2399980690, a(u, w + 12), 10),
              e(o(v, y, S), 4293915773, a(u, w + 40), 15),
              e(o(v, y, S), 2240044497, a(u, w + 4), 21),
              e(o(v, y, S), 1873313359, a(u, w + 32), 6),
              e(o(v, y, S), 4264355552, a(u, w + 60), 10),
              e(o(v, y, S), 2734768916, a(u, w + 24), 15),
              e(o(v, y, S), 1309151649, a(u, w + 52), 21),
              e(o(v, y, S), 4149444226, a(u, w + 16), 6),
              e(o(v, y, S), 3174756917, a(u, w + 44), 10),
              e(o(v, y, S), 718787259, a(u, w + 8), 15),
              e(o(v, y, S), 3951481745, a(u, w + 36), 21),
              (h = f(h, g)),
              (d = f(d, v)),
              (p = f(p, y)),
              (m = f(m, S));
          }
          return (function (e, n, r, i) {
            for (var o = "", a = 0, s = 0, l = 3; l >= 0; l--)
              (a = 255 & (s = arguments[l])),
                (a <<= 8),
                (a |= 255 & (s >>>= 8)),
                (a <<= 8),
                (a |= 255 & (s >>>= 8)),
                (a <<= 8),
                (o += t((a |= s >>>= 8)));
            return o;
          })(m, p, d, h).toUpperCase();
        })()
      );
    };
    var q = function (e) {
        return z.MD5(e);
      },
      G = 0;
    function W(e) {
      return H(Y(X(e)));
    }
    function Y(e) {
      return K(J(Z(e), 8 * e.length));
    }
    function H(e) {
      for (
        var t, n = G ? "0123456789ABCDEF" : "0123456789abcdef", r = "", i = 0;
        i < e.length;
        i++
      )
        (t = e.charCodeAt(i)),
          (r += n.charAt((t >>> 4) & 15) + n.charAt(15 & t));
      return r;
    }
    function X(e) {
      for (var t, n, r = "", i = -1; ++i < e.length; )
        (t = e.charCodeAt(i)),
          (n = i + 1 < e.length ? e.charCodeAt(i + 1) : 0),
          55296 <= t &&
            t <= 56319 &&
            56320 <= n &&
            n <= 57343 &&
            ((t = 65536 + ((1023 & t) << 10) + (1023 & n)), i++),
          t <= 127
            ? (r += String.fromCharCode(t))
            : t <= 2047
            ? (r += String.fromCharCode(192 | ((t >>> 6) & 31), 128 | (63 & t)))
            : t <= 65535
            ? (r += String.fromCharCode(
                224 | ((t >>> 12) & 15),
                128 | ((t >>> 6) & 63),
                128 | (63 & t)
              ))
            : t <= 2097151 &&
              (r += String.fromCharCode(
                240 | ((t >>> 18) & 7),
                128 | ((t >>> 12) & 63),
                128 | ((t >>> 6) & 63),
                128 | (63 & t)
              ));
      return r;
    }
    function Z(e) {
      for (var t = Array(e.length >> 2), n = 0; n < t.length; n++) t[n] = 0;
      for (n = 0; n < 8 * e.length; n += 8)
        t[n >> 5] |= (255 & e.charCodeAt(n / 8)) << (24 - (n % 32));
      return t;
    }
    function K(e) {
      for (var t = "", n = 0; n < 32 * e.length; n += 8)
        t += String.fromCharCode((e[n >> 5] >>> (24 - (n % 32))) & 255);
      return t;
    }
    function J(e, t) {
      (e[t >> 5] |= 128 << (24 - (t % 32))),
        (e[15 + (((t + 64) >> 9) << 4)] = t);
      for (
        var n = Array(80),
          r = 1732584193,
          i = -271733879,
          o = -1732584194,
          a = 271733878,
          s = -1009589776,
          l = 0;
        l < e.length;
        l += 16
      ) {
        for (var u = r, c = i, f = o, h = a, d = s, p = 0; p < 80; p++) {
          n[p] =
            p < 16
              ? e[l + p]
              : te(n[p - 3] ^ n[p - 8] ^ n[p - 14] ^ n[p - 16], 1);
          var m = ee(ee(te(r, 5), Q(p, i, o, a)), ee(ee(s, n[p]), $(p)));
          (s = a), (a = o), (o = te(i, 30)), (i = r), (r = m);
        }
        (r = ee(r, u)),
          (i = ee(i, c)),
          (o = ee(o, f)),
          (a = ee(a, h)),
          (s = ee(s, d));
      }
      return Array(r, i, o, a, s);
    }
    function Q(e, t, n, r) {
      return e < 20
        ? (t & n) | (~t & r)
        : e < 40
        ? t ^ n ^ r
        : e < 60
        ? (t & n) | (t & r) | (n & r)
        : t ^ n ^ r;
    }
    function $(e) {
      return e < 20
        ? 1518500249
        : e < 40
        ? 1859775393
        : e < 60
        ? -1894007588
        : -899497514;
    }
    function ee(e, t) {
      var n = (65535 & e) + (65535 & t);
      return (((e >> 16) + (t >> 16) + (n >> 16)) << 16) | (65535 & n);
    }
    function te(e, t) {
      return (e << t) | (e >>> (32 - t));
    }
    var ne = W,
      re = function (e) {
        var t = e.wsURL,
          n = e.rtspURL,
          i = e.sourceId,
          o = null,
          a = e.isPlayback || !1,
          s = e.isTalkService || !1,
          l = e.isDownloadService,
          u = e.realm,
          c = 6,
          f = null,
          h = null,
          d = 0,
          p = 0,
          m = 'Authorization: WSSE profile="UsernameToken"',
          g = null,
          v = !1,
          y = e.playsdk,
          S = {
            OK: 200,
            UNAUTHORIZED: 401,
            NOTFOUND: 404,
            INVALID_RANGE: 457,
            NOTSERVICE: 503,
            DISCONNECT: 999,
          },
          w = 1e4,
          b = [],
          _ = 0,
          C = null,
          A = {},
          P = "UnAuthorized",
          T = null,
          x = "",
          R = null,
          I = {},
          U = null,
          M = {},
          D = { PLAY: 0, PAUSE: 1, KEEP_LIVE: 2, STOP: 3, SCALE: 4 },
          E = 0,
          k = null,
          L = "play";
        function O() {}
        function F() {
          G(B("TEARDOWN")),
            clearInterval(R),
            (R = null),
            null !== o && (o.onerror = null),
            null !== o &&
              o.readyState === WebSocket.OPEN &&
              (o.close(), (o = null)),
            y && y.Stop();
        }
        function N(e, t) {
          var n = e.split("#")[0];
          if (n) {
            var r = new RegExp("(^|&)" + t + "=([^&]*)(&|$)"),
              i = n.match(r);
            if (null !== i) return unescape(i[2]);
          }
          return null;
        }
        function B(e, o, l, c) {
          var f = "",
            h = "",
            d = q(M.username + ":" + u + ":" + M.password),
            p = t.split("://")[1].split("/")[0].split(":")[0] + ":8086";
          if (a)
            var v = N(i, "beginTime"),
              y = N(i, "endTime"),
              S = v ? v.replace(/-|:|\s/g, "_") : "",
              w = y ? y.replace(/-|:|\s/g, "_") : "";
          var b =
            i &&
            i.replace(
              /&beginTime=\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}&endTime=\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/,
              ""
            );
          switch (e) {
            case 0:
              var C = (function () {
                  for (var e = [], t = 0; t < 32; t++)
                    e.push(String.fromCharCode((256 * Math.random()) | 0));
                  return q(e.join(""));
                })(),
                A = (function () {
                  var e = new Date(),
                    t = e.getTimezoneOffset(),
                    n = e.getTime() + 60 * t * 1e3,
                    r = new Date(n),
                    i = r.getFullYear(),
                    o = r.getMonth() + 1,
                    a = r.getDate(),
                    s = r.getHours(),
                    l = r.getMinutes(),
                    u = r.getSeconds(),
                    c = function (e) {
                      return e < 10 ? "0" + e : e;
                    };
                  return (
                    i +
                    "-" +
                    c(o) +
                    "-" +
                    c(a) +
                    "T" +
                    c(s) +
                    ":" +
                    c(l) +
                    ":" +
                    c(u) +
                    "Z"
                  );
                })(),
                T = (function (e) {
                  for (
                    var t,
                      n,
                      r,
                      i,
                      o,
                      a,
                      s,
                      l =
                        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
                      u = "",
                      c = 0;
                    c < e.length;

                  )
                    (t = e[c++]),
                      (n = e[c++]),
                      (r = e[c++]),
                      (i = t >> 2),
                      (o = ((3 & t) << 4) | (n >> 4)),
                      (a = ((15 & n) << 2) | (r >> 6)),
                      (s = 63 & r),
                      isNaN(n) ? (a = s = 64) : isNaN(r) && (s = 64),
                      (u =
                        u +
                        l.charAt(i) +
                        l.charAt(o) +
                        l.charAt(a) +
                        l.charAt(s));
                  return u;
                })(
                  (function (e) {
                    var t,
                      n = e.trim(),
                      r =
                        "0x" === n.substr(0, 2).toLowerCase() ? n.substr(2) : n,
                      i = r.length;
                    if (i % 2 !== 0) return "";
                    for (var o = [], a = 0; a < i; a += 2)
                      (t = parseInt(r.substr(a, 2), 16)), o.push(t);
                    return o;
                  })(ne(C + A + d.toUpperCase()))
                );
              (f =
                "GET " +
                n +
                (a
                  ? "&starttime=".concat(S, "&endtime=").concat(w, "&")
                  : "&") +
                "method=" +
                e +
                "?sourceId=" +
                b +
                " HTTP/1.1\r\n"),
                (f += "Accept-Sdp: Private\r\n"),
                (f += g + "\r\n"),
                (f +=
                  "Connection: keep-alive\r\nCseq: " +
                  _ +
                  "\r\nHost: " +
                  p +
                  "\r\n"),
                c
                  ? (f += "Range: npt=" + c + "-\r\n")
                  : "ContinuePlay" !== P && (f += "Range: npt=0.000000-\r\n"),
                !0 === s &&
                  ((h += "v=0\r\n"),
                  (h += "o=- 2208989105 2208989105 IN IP4 0.0.0.0\r\n"),
                  (h += "s=Media Server\r\n"),
                  (h += "c=IN IP4 0.0.0.0\r\n"),
                  (h += "t=0 0\r\n"),
                  (h += "a=control:*\r\n"),
                  (h += "a=packetization-supported:DH\r\n"),
                  (h += "a=rtppayload-supported:DH\r\n"),
                  (h += "a=range:npt=now-\r\n"),
                  (h += "m=audio 0 RTP/AVP 8\r\n"),
                  (h += "a=control:trackID=5\r\n"),
                  (h += "a=rtpmap:8 PCMA/16000\r\n"),
                  (f +=
                    "Private-Length: " +
                    (h += "a=sendonly\r\n").length +
                    "\r\n"),
                  (f += "Private-Type: application/sdp\r\n")),
                (f +=
                  g === m
                    ? 'WSSE: UsernameToken Username="' +
                      M.username +
                      '", PasswordDigest="' +
                      T +
                      '", Nonce="' +
                      C +
                      '", Created="' +
                      A +
                      '"\r\n\r\n'
                    : "\r\n"),
                (f += h);
              break;
            case 1:
              (f =
                "GET " +
                n +
                (a
                  ? "&starttime=".concat(S, "&endtime=").concat(w, "&")
                  : "&") +
                "method=" +
                e +
                "?sourceId=" +
                b +
                " HTTP/1.1\r\n"),
                (f +=
                  "Connection: keep-alive\r\nCseq: " +
                  _ +
                  "\r\nHost: " +
                  p +
                  "\r\n\r\n");
              break;
            case 2:
            case 3:
              (f =
                "GET " +
                n +
                (a
                  ? "&starttime=".concat(S, "&endtime=").concat(w, "&")
                  : "&") +
                "method=" +
                e +
                "?sourceId=" +
                b +
                " HTTP/1.1\r\n"),
                (f +=
                  "Connection: keep-alive\r\nCseq: " +
                  _ +
                  "\r\nHost: " +
                  p +
                  "\r\n\r\n");
              break;
            case 4:
              (f =
                "GET " +
                n +
                (a
                  ? "&starttime=".concat(S, "&endtime=").concat(w, "&")
                  : "&") +
                "method=0?sourceId=" +
                b +
                " HTTP/1.1\r\n"),
                (f += c < 8 ? "Speed: " + c + "\r\n" : "Scale: " + c + "\r\n"),
                (f +=
                  "Connection: keep-alive\r\nCseq: " +
                  _ +
                  "\r\nHost: " +
                  p +
                  "\r\n\r\n");
          }
          return r.h.log(f), f;
        }
        function V(e) {
          r.h.log(e);
          var t,
            i = e.search("Cseq: ") + 5;
          if (
            ((_ = parseInt(e.slice(i, i + 10)) + 1),
            (t = (function (e) {
              var t = {},
                n = 0,
                r = 0,
                i = null,
                o = null;
              if (-1 !== e.search("Content-Type: application/sdp")) {
                var a = e.split("\r\n\r\n");
                o = a[0];
              } else o = e;
              var s = o.split("\r\n"),
                l = s[0].split(" ");
              if (
                (l.length > 2 &&
                  ((t.ResponseCode = parseInt(l[1])),
                  (t.ResponseMessage = l[2])),
                t.ResponseCode === S.OK)
              ) {
                for (n = 1; n < s.length; n++)
                  if ("Public" === (i = s[n].split(":"))[0])
                    t.MethodsSupported = i[1].split(",");
                  else if ("Cseq" === i[0]) t.Cseq = parseInt(i[1]);
                  else if ("Content-Type" === i[0])
                    (t.ContentType = i[1]),
                      -1 !== t.ContentType.search("application/sdp") &&
                        (t.SDPData = j(e));
                  else if ("Content-Length" === i[0])
                    t.ContentLength = parseInt(i[1]);
                  else if ("Content-Base" === i[0]) {
                    var u = s[n].search("Content-Base:");
                    -1 !== u && (t.ContentBase = s[n].substr(u + 13));
                  } else if ("Session" === i[0]) {
                    var c = i[1].split(";");
                    t.SessionID = parseInt(c[0]);
                  } else if ("Transport" === i[0]) {
                    var f = i[1].split(";");
                    for (r = 0; r < f.length; r++) {
                      var h = f[r].search("interleaved=");
                      if (-1 !== h) {
                        var d = f[r].substr(h + 12),
                          p = d.split("-");
                        p.length > 1 &&
                          ((t.RtpInterlevedID = parseInt(p[0])),
                          (t.RtcpInterlevedID = parseInt(p[1])));
                      }
                    }
                  } else if ("RTP-Info" === i[0]) {
                    i[1] = s[n].substr(9);
                    var m = i[1].split(",");
                    for (t.RTPInfoList = [], r = 0; r < m.length; r++) {
                      var g = m[r].split(";"),
                        v = {},
                        y = 0;
                      for (y = 0; y < g.length; y++) {
                        var w = g[y].search("url=");
                        -1 !== w && (v.URL = g[y].substr(w + 4)),
                          -1 !== (w = g[y].search("seq=")) &&
                            (v.Seq = parseInt(g[y].substr(w + 4)));
                      }
                      t.RTPInfoList.push(v);
                    }
                  }
              } else if (t.ResponseCode === S.UNAUTHORIZED)
                for (n = 1; n < s.length; n++)
                  if ("Cseq" === (i = s[n].split(":"))[0])
                    t.Cseq = parseInt(i[1]);
                  else if ("WWW-Authenticate" === i[0]) {
                    var b = i[1].split(",");
                    for (r = 0; r < b.length; r++) {
                      var _ = b[r].search("Digest realm=");
                      if (-1 !== _) {
                        var C = b[r].substr(_ + 13).split('"');
                        t.Realm = C[1];
                      }
                      if (-1 !== (_ = b[r].search("nonce="))) {
                        var A = b[r].substr(_ + 6).split('"');
                        t.Nonce = A[1];
                      }
                    }
                  }
              return t;
            })(e)).ResponseCode === S.UNAUTHORIZED && g === m)
          )
            !(function (e) {
              var t = M.username,
                r = M.passWord,
                i = { Method: null, Realm: null, Nonce: null, Uri: null },
                o = null;
              (i = {
                Method: P.toUpperCase(),
                Realm: e.Realm,
                Nonce: e.Nonce,
                Uri: n,
              }),
                (o = (function (e, t, n, r, i, o) {
                  var a = null,
                    s = null;
                  return (
                    (a = q(e + ":" + r + ":" + t).toLowerCase()),
                    (s = q(o + ":" + n).toLowerCase()),
                    q(a + ":" + i + ":" + s).toLowerCase()
                  );
                })(t, r, i.Uri, i.Realm, i.Nonce, i.Method)),
                (g =
                  'Authorization: Digest username="' +
                  t +
                  '", realm="' +
                  i.Realm +
                  '",'),
                (g +=
                  ' nonce="' +
                  i.Nonce +
                  '", uri="' +
                  i.Uri +
                  '", response="' +
                  o +
                  '"'),
                G(B(D.PLAY));
            })(t);
          else if (t.ResponseCode === S.OK)
            if ("UnAuthorized" === P) {
              (A = j(e)),
                "undefined" !== typeof t.ContentBase &&
                  (A.ContentBase = t.ContentBase);
              var a = 0;
              for (a = 0; a < A.Sessions.length; a += 1) {
                var u = {};
                "JPEG" === A.Sessions[a].CodecMime ||
                "H264" === A.Sessions[a].CodecMime ||
                "H265" === A.Sessions[a].CodecMime ||
                "H264-SVC" == A.Sessions[a].CodecMime ||
                "RAW" == A.Sessions[a].CodecMime
                  ? ((u.codecName = A.Sessions[a].CodecMime),
                    "H264-SVC" === A.Sessions[a].CodecMime &&
                      (u.codecName = "H264"),
                    ("H265" !== A.Sessions[a].CodecMime &&
                      "RAW" !== A.Sessions[a].CodecMime) ||
                      O.prototype.setLiveMode("canvas"),
                    (u.trackID = A.Sessions[a].ControlURL),
                    (u.ClockFreq = A.Sessions[a].ClockFreq),
                    (u.Port = parseInt(A.Sessions[a].Port)),
                    "undefined" !== typeof A.Sessions[a].Framerate &&
                      ((u.Framerate = parseInt(A.Sessions[a].Framerate)),
                      U(u.Framerate)),
                    b.push(u))
                  : "PCMU" === A.Sessions[a].CodecMime ||
                    -1 !== A.Sessions[a].CodecMime.search("G726-16") ||
                    -1 !== A.Sessions[a].CodecMime.search("G726-24") ||
                    -1 !== A.Sessions[a].CodecMime.search("G726-32") ||
                    -1 !== A.Sessions[a].CodecMime.search("G726-40") ||
                    "PCMA" === A.Sessions[a].CodecMime ||
                    -1 !== A.Sessions[a].CodecMime.search("G723.1") ||
                    -1 !== A.Sessions[a].CodecMime.search("G729") ||
                    -1 !== A.Sessions[a].CodecMime.search("MPA") ||
                    -1 !== A.Sessions[a].CodecMime.search("L16")
                  ? ("PCMU" === A.Sessions[a].CodecMime
                      ? (u.codecName = "G.711Mu")
                      : "G726-16" === A.Sessions[a].CodecMime
                      ? (u.codecName = "G.726-16")
                      : "G726-24" === A.Sessions[a].CodecMime
                      ? (u.codecName = "G.726-24")
                      : "G726-32" === A.Sessions[a].CodecMime
                      ? (u.codecName = "G.726-32")
                      : "G726-40" === A.Sessions[a].CodecMime
                      ? (u.codecName = "G.726-40")
                      : "PCMA" === A.Sessions[a].CodecMime
                      ? (u.codecName = "G.711A")
                      : "G723.1" === A.Sessions[a].CodecMime
                      ? (u.codecName = "G.723")
                      : "G729" === A.Sessions[a].CodecMime
                      ? (u.codecName = "G.729")
                      : "MPA" === A.Sessions[a].CodecMime
                      ? (u.codecName = "mpeg2")
                      : "L16" === A.Sessions[a].CodecMime &&
                        (u.codecName = "PCM"),
                    (u.trackID = A.Sessions[a].ControlURL),
                    (u.ClockFreq = A.Sessions[a].ClockFreq),
                    (u.Port = parseInt(A.Sessions[a].Port)),
                    (u.Bitrate = parseInt(A.Sessions[a].Bitrate)),
                    (u.TalkTransType = A.Sessions[a].TalkTransType),
                    b.push(u))
                  : "mpeg4-generic" === A.Sessions[a].CodecMime ||
                    "MPEG4-GENERIC" === A.Sessions[a].CodecMime
                  ? ((u.codecName = "mpeg4-generic"),
                    (u.trackID = A.Sessions[a].ControlURL),
                    (u.ClockFreq = A.Sessions[a].ClockFreq),
                    (u.Port = parseInt(A.Sessions[a].Port)),
                    (u.Bitrate = parseInt(A.Sessions[a].Bitrate)),
                    (u.TalkTransType = A.Sessions[a].TalkTransType),
                    b.push(u))
                  : "vnd.onvif.metadata" === A.Sessions[a].CodecMime
                  ? ((u.codecName = "MetaData"),
                    (u.trackID = A.Sessions[a].ControlURL),
                    (u.ClockFreq = A.Sessions[a].ClockFreq),
                    (u.Port = parseInt(A.Sessions[a].Port)),
                    b.push(u))
                  : "stream-assist-frame" === A.Sessions[a].CodecMime
                  ? ((u.codecName = "stream-assist-frame"),
                    (u.trackID = A.Sessions[a].ControlURL),
                    (u.ClockFreq = A.Sessions[a].ClockFreq),
                    (u.Port = parseInt(A.Sessions[a].Port)),
                    b.push(u))
                  : ("audio" === A.Sessions[a].Type && C({ errorCode: 201 }),
                    r.h.log(
                      "Unknown codec type:",
                      A.Sessions[a].CodecMime,
                      A.Sessions[a].ControlURL
                    ));
              }
              for (var c = 0; c < b.length; c++)
                b[c].RtpInterlevedID = 2 * (b[c].trackID.split("=")[1] - 0);
              s && (y.setCallback("audioTalk", z), y.talkInit(b, I)),
                (T = 0),
                clearInterval(R),
                (R = setInterval(function () {
                  return (
                    "GET_PARAMETER" === L ? E++ : (E = 0),
                    5 === E &&
                      ((o.fileOver = !0), l && ((l = !1), y.StopRecord(), F())),
                    G(B(D.KEEP_LIVE))
                  );
                }, w)),
                (P = "Playing");
            } else
              "ContinuePlay" === P
                ? (P = "Playing")
                : "Playing" === P || r.h.log("unknown rtsp state:" + P);
          else if (t.ResponseCode === S.NOTSERVICE) {
            if ("Setup" === P && -1 !== b[T].trackID.search("trackID=t"))
              return (
                (b[T].RtpInterlevedID = -1),
                (b[T].RtcpInterlevedID = -1),
                (T += 1),
                (s = !1),
                C({
                  errorCode: "504",
                  description: "Talk Service Unavilable",
                  place: "RtspClient.js",
                }),
                T < b.length
                  ? B("SETUP", b[T].trackID)
                  : ((P = "Play"), B("PLAY"))
              );
            C({ errorCode: "503", description: "Service Unavilable" });
          } else if (t.ResponseCode === S.NOTFOUND) {
            if ("Describe" === P || "Options" === P)
              return void C({ errorCode: 404, description: "rtsp not found" });
          } else if (t.ResponseCode === S.INVALID_RANGE)
            return (
              ("backup" !== x && "playback" !== x) ||
                C({ errorCode: "457", description: "Invalid range" }),
              void r.h.log("RTP disconnection detect!!!")
            );
        }
        function z(e) {
          !0 === s &&
            (function (e) {
              null !== o && o.readyState === WebSocket.OPEN
                ? o.send(e)
                : r.h.log("SendRtpData - Websocket does not exist");
            })(e);
        }
        function G(e) {
          void 0 != e &&
            null != e &&
            "" != e &&
            (null !== o && o.readyState === WebSocket.OPEN
              ? void 0 != e && o.send(W(e))
              : r.h.log("ws\u672a\u8fde\u63a5"));
        }
        function W(e) {
          for (
            var t = e.length, n = new Uint8Array(new ArrayBuffer(t)), r = 0;
            r < t;
            r++
          )
            n[r] = e.charCodeAt(r);
          return n;
        }
        function Y(e) {
          var t = new Uint8Array(),
            n = new Uint8Array(e.data);
          for ((t = new Uint8Array(n.length)).set(n, 0), d = t.length; d > 0; )
            if (36 !== t[0]) {
              var r = String.fromCharCode.apply(null, t),
                i = null;
              (-1 === r.indexOf("OffLine: File Over") &&
                -1 === r.indexOf("OffLine:File Over") &&
                -1 === r.indexOf("OffLine:Internal Error")) ||
                ((o.fileOver = !0), l && ((l = !1), y.StopRecord(), F())),
                -1 !== r.indexOf("OffLine:Internal Error") &&
                  C({ errorCode: 500, description: "Internal Error" }),
                -1 !== r.indexOf("OffLine:KmsUnavailable") &&
                  C({ errorCode: 203 }),
                !1 === v && -1 !== r.indexOf("200 OK")
                  ? ((i = r.lastIndexOf("\r\n")), (v = !0))
                  : (i = r.search("\r\n\r\n"));
              var a = r.search("HTTP");
              if (-1 === a) return void (t = new Uint8Array());
              if (-1 === i) return void (d = t.length);
              (f = t.subarray(a, i + c)), (t = t.subarray(i + c));
              var s = String.fromCharCode.apply(null, f);
              G(V(s)), (d = t.length);
            } else {
              if (
                ((L = "PLAY"),
                clearTimeout(k),
                (k = setTimeout(function () {
                  L = "GET_PARAMETER";
                }, 1e4)),
                (h = t.subarray(0, c)),
                !(
                  (p = (h[2] << 24) | (h[3] << 16) | (h[4] << 8) | h[5]) + c <=
                  t.length
                ))
              )
                return void (d = t.length);
              var u = t.subarray(c, p + c);
              H(h, u), (t = t.subarray(p + c)), (d = t.length);
            }
        }
        function H(e, t) {
          y.InputData(e, t);
        }
        function j(e) {
          var t = { Sessions: [] },
            n = (
              -1 !== e.search("Private-Type: application/sdp")
                ? e.split("\r\n\r\n")[1]
                : e
            ).split("\r\n"),
            r = 0,
            i = !1;
          for (r = 0; r < n.length; r++) {
            var o = n[r].split("=");
            if (o.length > 0)
              switch (o[0]) {
                case "a":
                  var a = o[1].split(":");
                  if (a.length > 1) {
                    if ("control" === a[0]) {
                      var l = n[r].search("control:");
                      !0 === i
                        ? -1 !== l &&
                          (t.Sessions[t.Sessions.length - 1].ControlURL = n[
                            r
                          ].substr(l + 8))
                        : -1 !== l && (t.BaseURL = n[r].substr(l + 8));
                    } else if ("rtpmap" === a[0]) {
                      var u = a[1].split(" ");
                      t.Sessions[t.Sessions.length - 1].PayloadType = u[0];
                      var c = u[1].split("/");
                      (t.Sessions[t.Sessions.length - 1].CodecMime = c[0]),
                        c.length > 1 &&
                          (t.Sessions[t.Sessions.length - 1].ClockFreq = c[1]);
                    } else if ("framesize" === a[0]) {
                      var f = a[1].split(" ");
                      if (f.length > 1) {
                        var h = f[1].split("-");
                        (t.Sessions[t.Sessions.length - 1].Width = h[0]),
                          (t.Sessions[t.Sessions.length - 1].Height = h[1]);
                      }
                    } else if ("framerate" === a[0])
                      t.Sessions[t.Sessions.length - 1].Framerate = a[1];
                    else if ("fmtp" === a[0]) {
                      var d = n[r].split(" ");
                      if (d.length < 2) continue;
                      for (var p = 1; p < d.length; p++) {
                        var m = d[p].split(";"),
                          g = 0;
                        for (g = 0; g < m.length; g++) {
                          var v = m[g].search("mode=");
                          if (
                            (-1 !== v &&
                              (t.Sessions[t.Sessions.length - 1].mode = m[
                                g
                              ].substr(v + 5)),
                            -1 !== (v = m[g].search("config=")) &&
                              ((t.Sessions[t.Sessions.length - 1].config = m[
                                g
                              ].substr(v + 7)),
                              (I.config =
                                t.Sessions[t.Sessions.length - 1].config),
                              (I.clockFreq =
                                t.Sessions[t.Sessions.length - 1].ClockFreq),
                              (I.bitrate =
                                t.Sessions[t.Sessions.length - 1].Bitrate)),
                            -1 !== (v = m[g].search("sprop-vps=")) &&
                              (t.Sessions[t.Sessions.length - 1].VPS = m[
                                g
                              ].substr(v + 10)),
                            -1 !== (v = m[g].search("sprop-sps=")) &&
                              (t.Sessions[t.Sessions.length - 1].SPS = m[
                                g
                              ].substr(v + 10)),
                            -1 !== (v = m[g].search("sprop-pps=")) &&
                              (t.Sessions[t.Sessions.length - 1].PPS = m[
                                g
                              ].substr(v + 10)),
                            -1 !== (v = m[g].search("sprop-parameter-sets=")))
                          ) {
                            var y = m[g].substr(v + 21).split(",");
                            y.length > 1 &&
                              ((t.Sessions[t.Sessions.length - 1].SPS = y[0]),
                              (t.Sessions[t.Sessions.length - 1].PPS = y[1]));
                          }
                        }
                      }
                    }
                  } else
                    1 === a.length &&
                      ("recvonly" === a[0]
                        ? (t.Sessions[t.Sessions.length - 1].TalkTransType =
                            "recvonly")
                        : "sendonly" === a[0] &&
                          (t.Sessions[t.Sessions.length - 1].TalkTransType =
                            "sendonly"));
                  break;
                case "m":
                  var S = o[1].split(" "),
                    w = {};
                  (w.Type = S[0]),
                    (w.Port = S[1]),
                    (w.Payload = S[3]),
                    t.Sessions.push(w),
                    (i = !0);
                  break;
                case "b":
                  if (!0 === i) {
                    var b = o[1].split(":");
                    t.Sessions[t.Sessions.length - 1].Bitrate = b[1];
                  }
              }
          }
          if (!0 === s)
            for (var _ = 0; _ < t.Sessions.length; _ += 1)
              ("1" !== t.Sessions[_].ControlURL.split("=")[1] &&
                "2" !== t.Sessions[_].ControlURL.split("=")[1]) ||
                t.Sessions.splice(_, 1);
          return t;
        }
        function G(e) {
          void 0 != e &&
            null != e &&
            "" != e &&
            (o && o.readyState === WebSocket.OPEN
              ? void 0 != e && o.send(W(e))
              : r.h.log("ws\u672a\u8fde\u63a5"));
        }
        return (
          (O.prototype = {
            connect: function () {
              o ||
                (console.log("---------start create websocket-----------"),
                ((o = new WebSocket(t)).binaryType = "arraybuffer"),
                (o.fileOver = !1),
                o.addEventListener("message", Y, !1),
                (o.onopen = function () {
                  (g = m), G(B(D.PLAY));
                }),
                (o.onerror = function (e) {
                  C({ errorCode: 202, description: "Open WebSocket Error" });
                }),
                (o.onclose = function (e) {
                  o &&
                    !o.fileOver &&
                    C({ errorCode: 202, description: "Open WebSocket Error" });
                }));
            },
            disconnect: function () {
              G(B(D.STOP)),
                clearInterval(R),
                (R = null),
                null !== o && (o.onerror = null),
                null !== o &&
                  o.readyState === WebSocket.OPEN &&
                  (o.close(), (o = null)),
                y && y.Stop();
            },
            controlPlayer: function (e) {
              var t = "";
              switch ((e.command, e.command)) {
                case "PLAY":
                  if (((P = "Play"), null != e.range)) {
                    t = B(D.PLAY, 0, 0, e.range);
                    break;
                  }
                  t = B(D.PLAY);
                  break;
                case "PAUSE":
                  if ("PAUSE" === P) break;
                  (P = "PAUSE"), (t = B(D.PAUSE));
                  break;
                case "SCALE":
                  (t = B(D.SCALE, 0, 0, e.data)), y && y.SetPlaySpeed(e.data);
                  break;
                case "TEARDOWN":
                  (t = B(D.STOP)), y && y.Stop();
                  break;
                case "audioPlay":
                case "volumn":
                case "audioSamplingRate":
                  y && y.setVolume(e.data);
                  break;
                default:
                  r.h.log("\u672a\u77e5\u6307\u4ee4: " + e.command);
              }
              "" != t && G(t);
            },
            setLiveMode: function (e) {},
            setPlayMode: function (e) {},
            setRTSPURL: function (e) {
              n = e;
            },
            setCallback: function (e, t) {
              "GetFrameRate" === e ? (U = t) : y.setCallback(e, t),
                "Error" == e && (C = t);
            },
            setUserInfo: function (e, t) {
              (M.username = e), (M.passWord = t);
            },
            capture: function (e, t) {
              y.capture(e, t);
            },
            setLessRate: function (e) {},
            setFrameData: function (e, t, n, r, i, o, a) {
              y.setFrameData(e, t, n, r, i, o, a);
            },
          }),
          new O()
        );
      },
      ie = function (e) {
        var t = e.wsURL,
          n = null,
          i = e.isTalkService,
          o = e.isDownloadService,
          a = 6,
          s = null,
          l = null,
          u = 0,
          c = 0,
          f = !1,
          h = !1,
          d = e.playsdk,
          p = {
            OK: 200,
            UNAUTHORIZED: 401,
            NOTFOUND: 404,
            INVALID_RANGE: 457,
            NOTSERVICE: 503,
            DISCONNECT: 999,
          },
          m = 4e4,
          g = 5,
          v = 1e3,
          y = "",
          S = [],
          w = 1,
          b = null,
          _ = {},
          C = "Options",
          A = null,
          P = null,
          T = "",
          x = null,
          R = null,
          I = !1,
          U = {},
          M = e.rtspURL,
          D = null,
          E = {},
          k = 0,
          L = null,
          O = "play";
        function F() {}
        function N() {
          G(B("TEARDOWN", null)),
            clearInterval(x),
            (x = null),
            null !== n && (n.onerror = null),
            null !== n &&
              n.readyState === WebSocket.OPEN &&
              (n.close(), (n = null), (P = null)),
            d && d.Stop();
        }
        function B(e, t, n, o) {
          var a = "";
          switch (e) {
            case "OPTIONS":
            case "TEARDOWN":
            case "GET_PARAMETER":
            case "SET_PARAMETERS":
              a = e + " " + M + " RTSP/1.0\r\nCSeq: " + w + "\r\n" + y + "\r\n";
              break;
            case "DESCRIBE":
              (a = e + " " + M + " RTSP/1.0\r\nCSeq: " + w),
                !0 === i &&
                  (a += "\r\nRequire: www.onvif.org/ver20/backchannel"),
                (a = a + "\r\n" + y + "\r\n");
              break;
            case "SETUP":
              r.h.log("trackID: " + t),
                (a =
                  e +
                  " " +
                  M +
                  "/trackID=" +
                  t +
                  " RTSP/1.0\r\nCSeq: " +
                  w +
                  "\r\n" +
                  y +
                  "Transport: DH/AVP/TCP;unicast;interleaved=" +
                  2 * t +
                  "-" +
                  (2 * t + 1) +
                  "\r\n"),
                (a += 0 != P ? "Session: " + P + "\r\n\r\n" : "\r\n");
              break;
            case "PLAY":
              (a =
                e +
                " " +
                M +
                " RTSP/1.0\r\nCSeq: " +
                w +
                "\r\nSession: " +
                P +
                "\r\n"),
                void 0 != o && 0 != o
                  ? ((a += "Range: npt=" + o + "-\r\n"), (a += y + "\r\n"))
                  : (a += y + "\r\n");
              break;
            case "PAUSE":
              a =
                e +
                " " +
                M +
                " RTSP/1.0\r\nCSeq: " +
                w +
                "\r\nSession: " +
                P +
                "\r\n\r\n";
              break;
            case "SCALE":
              (a =
                "PLAY " +
                M +
                " RTSP/1.0\r\nCSeq: " +
                w +
                "\r\nSession: " +
                P +
                "\r\n"),
                (a += o < 8 ? "Speed: " + o + "\r\n" : "Scale: " + o + "\r\n"),
                (a += y + "\r\n");
          }
          return r.h.log(a), a;
        }
        function V(e) {
          r.h.log(e);
          var t,
            a = e.search("CSeq: ") + 5;
          if (
            ((w = parseInt(e.slice(a, a + 10)) + 1),
            (t = (function (e) {
              var t = {},
                n = 0,
                r = 0,
                i = null,
                o = null;
              if (-1 !== e.search("Content-Type: application/sdp")) {
                var a = e.split("\r\n\r\n");
                o = a[0];
              } else o = e;
              var s = o.split("\r\n"),
                l = s[0].split(" ");
              if (
                (l.length > 2 &&
                  ((t.ResponseCode = parseInt(l[1])),
                  (t.ResponseMessage = l[2])),
                t.ResponseCode === p.OK)
              ) {
                for (n = 1; n < s.length; n++)
                  if ("Public" === (i = s[n].split(":"))[0])
                    t.MethodsSupported = i[1].split(",");
                  else if ("CSeq" === i[0]) t.CSeq = parseInt(i[1]);
                  else if ("Content-Type" === i[0])
                    (t.ContentType = i[1]),
                      -1 !== t.ContentType.search("application/sdp") &&
                        (t.SDPData = j(e));
                  else if ("Content-Length" === i[0])
                    t.ContentLength = parseInt(i[1]);
                  else if ("Content-Base" === i[0]) {
                    var u = s[n].search("Content-Base:");
                    -1 !== u && (t.ContentBase = s[n].substr(u + 13));
                  } else if ("Session" === i[0]) {
                    var c = i[1].split(";");
                    t.SessionID = parseInt(c[0]);
                  } else if ("Transport" === i[0]) {
                    var f = i[1].split(";");
                    for (r = 0; r < f.length; r++) {
                      var h = f[r].search("interleaved=");
                      if (-1 !== h) {
                        var d = f[r].substr(h + 12),
                          m = d.split("-");
                        m.length > 1 &&
                          ((t.RtpInterlevedID = parseInt(m[0])),
                          (t.RtcpInterlevedID = parseInt(m[1])));
                      }
                    }
                  } else if ("RTP-Info" === i[0]) {
                    i[1] = s[n].substr(9);
                    var g = i[1].split(",");
                    for (t.RTPInfoList = [], r = 0; r < g.length; r++) {
                      var v = g[r].split(";"),
                        y = {},
                        S = 0;
                      for (S = 0; S < v.length; S++) {
                        var w = v[S].search("url=");
                        -1 !== w && (y.URL = v[S].substr(w + 4)),
                          -1 !== (w = v[S].search("seq=")) &&
                            (y.Seq = parseInt(v[S].substr(w + 4)));
                      }
                      t.RTPInfoList.push(y);
                    }
                  }
              } else if (t.ResponseCode === p.UNAUTHORIZED)
                for (n = 1; n < s.length; n++)
                  if ("CSeq" === (i = s[n].split(":"))[0])
                    t.CSeq = parseInt(i[1]);
                  else if ("WWW-Authenticate" === i[0]) {
                    var b = i[1].split(",");
                    for (r = 0; r < b.length; r++) {
                      var _ = b[r].search("Digest realm=");
                      if (-1 !== _) {
                        var C = b[r].substr(_ + 13).split('"');
                        t.Realm = C[1];
                      }
                      if (-1 !== (_ = b[r].search("nonce="))) {
                        var A = b[r].substr(_ + 6).split('"');
                        t.Nonce = A[1];
                      }
                    }
                  }
              return t;
            })(e)).ResponseCode === p.UNAUTHORIZED && "" === y)
          )
            !(function (e) {
              var t = E.username,
                n = E.passWord,
                r = { Method: null, Realm: null, Nonce: null, Uri: null },
                i = null;
              (r = {
                Method: C.toUpperCase(),
                Realm: e.Realm,
                Nonce: e.Nonce,
                Uri: M,
              }),
                (i = (function (e, t, n, r, i, o) {
                  var a = null,
                    s = null;
                  return (
                    (a = q(e + ":" + r + ":" + t).toLowerCase()),
                    (s = q(o + ":" + n).toLowerCase()),
                    q(a + ":" + i + ":" + s).toLowerCase()
                  );
                })(t, n, r.Uri, r.Realm, r.Nonce, r.Method)),
                (y =
                  'Authorization: Digest username="' +
                  t +
                  '", realm="' +
                  r.Realm +
                  '",'),
                (y +=
                  ' nonce="' +
                  r.Nonce +
                  '", uri="' +
                  r.Uri +
                  '", response="' +
                  i +
                  '"'),
                (y += "\r\n"),
                G(B("OPTIONS", null));
            })(t);
          else if (t.ResponseCode === p.OK) {
            if ("Options" === C) return (C = "Describe"), B("DESCRIBE", null);
            if ("Describe" === C) {
              (_ = j(e)),
                "undefined" !== typeof t.ContentBase &&
                  (_.ContentBase = t.ContentBase);
              var s = 0;
              for (s = 0; s < _.Sessions.length; s += 1) {
                var l = {};
                "JPEG" === _.Sessions[s].CodecMime ||
                "H264" === _.Sessions[s].CodecMime ||
                "H265" === _.Sessions[s].CodecMime ||
                "H264-SVC" == _.Sessions[s].CodecMime
                  ? ((l.codecName = _.Sessions[s].CodecMime),
                    "H264-SVC" == _.Sessions[s].CodecMime &&
                      (l.codecName = "H264"),
                    "H265" == _.Sessions[s].CodecMime &&
                      F.prototype.setLiveMode("canvas"),
                    (l.trackID = _.Sessions[s].ControlURL),
                    (l.ClockFreq = _.Sessions[s].ClockFreq),
                    (l.Port = parseInt(_.Sessions[s].Port)),
                    "undefined" !== typeof _.Sessions[s].Framerate &&
                      ((l.Framerate = parseInt(_.Sessions[s].Framerate)),
                      D(l.Framerate)),
                    S.push(l))
                  : "PCMU" === _.Sessions[s].CodecMime ||
                    -1 !== _.Sessions[s].CodecMime.search("G726-16") ||
                    -1 !== _.Sessions[s].CodecMime.search("G726-24") ||
                    -1 !== _.Sessions[s].CodecMime.search("G726-32") ||
                    -1 !== _.Sessions[s].CodecMime.search("G726-40") ||
                    "PCMA" === _.Sessions[s].CodecMime
                  ? ("PCMU" === _.Sessions[s].CodecMime
                      ? (l.codecName = "G.711Mu")
                      : "G726-16" === _.Sessions[s].CodecMime
                      ? (l.codecName = "G.726-16")
                      : "G726-24" === _.Sessions[s].CodecMime
                      ? (l.codecName = "G.726-24")
                      : "G726-32" === _.Sessions[s].CodecMime
                      ? (l.codecName = "G.726-32")
                      : "G726-40" === _.Sessions[s].CodecMime
                      ? (l.codecName = "G.726-40")
                      : "PCMA" === _.Sessions[s].CodecMime &&
                        (l.codecName = "G.711A"),
                    (l.trackID = _.Sessions[s].ControlURL),
                    (l.ClockFreq = _.Sessions[s].ClockFreq),
                    (l.Port = parseInt(_.Sessions[s].Port)),
                    (l.Bitrate = parseInt(_.Sessions[s].Bitrate)),
                    (l.TalkTransType = _.Sessions[s].TalkTransType),
                    S.push(l))
                  : "mpeg4-generic" === _.Sessions[s].CodecMime ||
                    "MPEG4-GENERIC" === _.Sessions[s].CodecMime
                  ? ((l.codecName = "mpeg4-generic"),
                    (l.trackID = _.Sessions[s].ControlURL),
                    (l.ClockFreq = _.Sessions[s].ClockFreq),
                    (l.Port = parseInt(_.Sessions[s].Port)),
                    (l.Bitrate = parseInt(_.Sessions[s].Bitrate)),
                    (l.TalkTransType = _.Sessions[s].TalkTransType),
                    S.push(l))
                  : "vnd.onvif.metadata" === _.Sessions[s].CodecMime
                  ? ((l.codecName = "MetaData"),
                    (l.trackID = _.Sessions[s].ControlURL),
                    (l.ClockFreq = _.Sessions[s].ClockFreq),
                    (l.Port = parseInt(_.Sessions[s].Port)),
                    S.push(l))
                  : "stream-assist-frame" === _.Sessions[s].CodecMime
                  ? ((l.codecName = "stream-assist-frame"),
                    (l.trackID = _.Sessions[s].ControlURL),
                    (l.ClockFreq = _.Sessions[s].ClockFreq),
                    (l.Port = parseInt(_.Sessions[s].Port)),
                    S.push(l))
                  : ("audio" === _.Sessions[s].Type && b({ errorCode: 201 }),
                    r.h.log(
                      "Unknown codec type:",
                      _.Sessions[s].CodecMime,
                      _.Sessions[s].ControlURL
                    ));
              }
              return !1 === i
                ? void b({ errorCode: 404, description: "rtsp not found" })
                : ((A = 0),
                  (C = "Setup"),
                  r.h.log(S),
                  B("SETUP", S[A].trackID.split("=")[1] - 0));
            }
            if ("Setup" === C) {
              if (((P = t.SessionID), A < S.length))
                return (
                  (S[A].RtpInterlevedID = t.RtpInterlevedID),
                  (S[A].RtcpInterlevedID = t.RtcpInterlevedID),
                  (A += 1) !== S.length
                    ? B("SETUP", S[A].trackID.split("=")[1] - 0)
                    : (i && (d.setCallback("audioTalk", z), d.talkInit(S, U)),
                      (C = "Play"),
                      B("PLAY", null))
                );
              if (i)
                return (
                  d.setCallback("audioTalk", z),
                  d.talkInit(S, U),
                  (C = "Play"),
                  B("PLAY", null)
                );
              r.h.log("Unknown setup SDP index");
            } else
              "Play" === C
                ? ((P = t.SessionID),
                  clearInterval(x),
                  (x = setInterval(function () {
                    return (
                      "GET_PARAMETER" === O ? k++ : (k = 0),
                      k === g &&
                        ((n.fileOver = !0),
                        o && ((o = !1), d.StopRecord(), N())),
                      G(B("GET_PARAMETER", null))
                    );
                  }, m)),
                  (k = 0),
                  "live" === T &&
                    (R = setInterval(function () {
                      if (!I) {
                        if (k > g)
                          return (
                            clearInterval(R),
                            o && ((o = !1), d.StopRecord(), N()),
                            b({
                              errorCode: "999",
                              description: "no rtsp response",
                              place: "RtspClient.js",
                              channelId: channelId,
                            }),
                            void r.h.log("RTP disconnection detect!!!")
                          );
                        k++;
                      }
                      I = !1;
                    }, v)),
                  (C = "Playing"))
                : "Playing" === C || r.h.log("unknown rtsp state:" + C);
          } else if (t.ResponseCode === p.NOTSERVICE) {
            if ("Setup" === C && "sendonly" === S[A].TalkTransType)
              return (
                (S[A].RtpInterlevedID = -1),
                (S[A].RtcpInterlevedID = -1),
                (A += 1),
                (i = !1),
                b({
                  errorCode: "504",
                  description: "Talk Service Unavilable",
                  place: "RtspClient.js",
                }),
                A < S.length
                  ? B("SETUP", S[A].trackID)
                  : ((C = "Play"), B("PLAY", null))
              );
            b({ errorCode: "503", description: "Service Unavilable" });
          } else if (t.ResponseCode === p.NOTFOUND) {
            if ("Describe" === C || "Options" === C)
              return void b({ errorCode: 404, description: "rtsp not found" });
          } else if (t.ResponseCode === p.INVALID_RANGE)
            return (
              ("backup" !== T && "playback" !== T) ||
                b({ errorCode: "457", description: "Invalid range" }),
              void r.h.log("RTP disconnection detect!!!")
            );
        }
        function z(e) {
          !0 === i &&
            (function (e) {
              null !== n && n.readyState === WebSocket.OPEN
                ? n.send(e)
                : r.h.log("SendRtpData - Websocket does not exist");
            })(e);
        }
        function G(e) {
          void 0 != e &&
            null != e &&
            "" != e &&
            (null !== n && n.readyState === WebSocket.OPEN
              ? (!1 === h &&
                  -1 !== e.search("DESCRIBE") &&
                  ((f = !0), (h = !0)),
                void 0 != e && n.send(W(e)))
              : r.h.log("ws\u672a\u8fde\u63a5"));
        }
        function W(e) {
          for (
            var t = e.length, n = new Uint8Array(new ArrayBuffer(t)), r = 0;
            r < t;
            r++
          )
            n[r] = e.charCodeAt(r);
          return n;
        }
        function Y(e) {
          var t = new Uint8Array(),
            r = new Uint8Array(e.data);
          for ((t = new Uint8Array(r.length)).set(r, 0), u = t.length; u > 0; )
            if (36 !== t[0]) {
              var i = String.fromCharCode.apply(null, t),
                h = null;
              (-1 === i.indexOf("OffLine:File Over") &&
                -1 === i.indexOf("OffLine: File Over") &&
                -1 === i.indexOf("OffLine:Internal Error")) ||
                ((n.fileOver = !0), o && ((o = !1), d.StopRecord(), N())),
                -1 !== i.indexOf("OffLine:Internal Error") &&
                  b({ errorCode: 500, description: "Internal Error" }),
                -1 !== i.indexOf("OffLine:KmsUnavailable") &&
                  b({ errorCode: 203 }),
                !0 === f
                  ? ((h = i.lastIndexOf("\r\n")), (f = !1))
                  : (h = i.search("\r\n\r\n"));
              var p = i.search("RTSP");
              if (-1 === p) return void (t = new Uint8Array());
              if (-1 === h) return void (u = t.length);
              (s = t.subarray(p, h + a)),
                (t = t.subarray(h + a)),
                G(V(String.fromCharCode.apply(null, s))),
                (u = t.length);
            } else {
              if (
                ((O = "PLAY"),
                clearTimeout(L),
                (L = setTimeout(function () {
                  O = "GET_PARAMETER";
                }, 1e4)),
                (l = t.subarray(0, a)),
                !(
                  (c = (l[2] << 24) | (l[3] << 16) | (l[4] << 8) | l[5]) + a <=
                  t.length
                ))
              )
                return void (u = t.length);
              var m = t.subarray(a, c + a);
              H(l, m), (t = t.subarray(c + a)), (u = t.length);
            }
        }
        function H(e, t) {
          d.InputData(e, t), (I = !0);
        }
        function j(e) {
          var t = { Sessions: [] },
            n = (
              -1 !== e.search("Content-Type: application/sdp")
                ? e.split("\r\n\r\n")[1]
                : e
            ).split("\r\n"),
            r = 0,
            i = !1;
          for (r = 0; r < n.length; r++) {
            var o = n[r].split("=");
            if (o.length > 0)
              switch (o[0]) {
                case "a":
                  var a = o[1].split(":");
                  if (a.length > 1) {
                    if ("control" === a[0]) {
                      var s = n[r].search("control:");
                      !0 === i
                        ? -1 !== s &&
                          (t.Sessions[t.Sessions.length - 1].ControlURL = n[
                            r
                          ].substr(s + 8))
                        : -1 !== s && (t.BaseURL = n[r].substr(s + 8));
                    } else if ("rtpmap" === a[0]) {
                      var l = a[1].split(" ");
                      t.Sessions[t.Sessions.length - 1].PayloadType = l[0];
                      var u = l[1].split("/");
                      (t.Sessions[t.Sessions.length - 1].CodecMime = u[0]),
                        u.length > 1 &&
                          (t.Sessions[t.Sessions.length - 1].ClockFreq = u[1]);
                    } else if ("framesize" === a[0]) {
                      var c = a[1].split(" ");
                      if (c.length > 1) {
                        var f = c[1].split("-");
                        (t.Sessions[t.Sessions.length - 1].Width = f[0]),
                          (t.Sessions[t.Sessions.length - 1].Height = f[1]);
                      }
                    } else if ("framerate" === a[0])
                      t.Sessions[t.Sessions.length - 1].Framerate = a[1];
                    else if ("fmtp" === a[0]) {
                      var h = n[r].split(" ");
                      if (h.length < 2) continue;
                      for (var d = 1; d < h.length; d++) {
                        var p = h[d].split(";"),
                          m = 0;
                        for (m = 0; m < p.length; m++) {
                          var g = p[m].search("mode=");
                          if (
                            (-1 !== g &&
                              (t.Sessions[t.Sessions.length - 1].mode = p[
                                m
                              ].substr(g + 5)),
                            -1 !== (g = p[m].search("config=")) &&
                              ((t.Sessions[t.Sessions.length - 1].config = p[
                                m
                              ].substr(g + 7)),
                              (U.config =
                                t.Sessions[t.Sessions.length - 1].config),
                              (U.clockFreq =
                                t.Sessions[t.Sessions.length - 1].ClockFreq),
                              (U.bitrate =
                                t.Sessions[t.Sessions.length - 1].Bitrate)),
                            -1 !== (g = p[m].search("sprop-vps=")) &&
                              (t.Sessions[t.Sessions.length - 1].VPS = p[
                                m
                              ].substr(g + 10)),
                            -1 !== (g = p[m].search("sprop-sps=")) &&
                              (t.Sessions[t.Sessions.length - 1].SPS = p[
                                m
                              ].substr(g + 10)),
                            -1 !== (g = p[m].search("sprop-pps=")) &&
                              (t.Sessions[t.Sessions.length - 1].PPS = p[
                                m
                              ].substr(g + 10)),
                            -1 !== (g = p[m].search("sprop-parameter-sets=")))
                          ) {
                            var v = p[m].substr(g + 21).split(",");
                            v.length > 1 &&
                              ((t.Sessions[t.Sessions.length - 1].SPS = v[0]),
                              (t.Sessions[t.Sessions.length - 1].PPS = v[1]));
                          }
                        }
                      }
                    }
                  } else
                    1 === a.length &&
                      ("recvonly" === a[0]
                        ? (t.Sessions[t.Sessions.length - 1].TalkTransType =
                            "recvonly")
                        : "sendonly" === a[0] &&
                          (t.Sessions[t.Sessions.length - 1].TalkTransType =
                            "sendonly"));
                  break;
                case "m":
                  var y = o[1].split(" "),
                    S = {};
                  (S.Type = y[0]),
                    (S.Port = y[1]),
                    (S.Payload = y[3]),
                    t.Sessions.push(S),
                    (i = !0);
                  break;
                case "b":
                  if (!0 === i) {
                    var w = o[1].split(":");
                    t.Sessions[t.Sessions.length - 1].Bitrate = w[1];
                  }
              }
          }
          return t;
        }
        return (
          (F.prototype = {
            connect: function () {
              n ||
                (console.log("---------start create websocket-----------"),
                ((n = new WebSocket(t)).binaryType = "arraybuffer"),
                (n.fileOver = !1),
                n.addEventListener("message", Y, !1),
                (n.onopen = function () {
                  var e = W(
                    "OPTIONS " + M + " RTSP/1.0\r\nCSeq: " + w + "\r\n\r\n"
                  );
                  n.send(e);
                }),
                (n.onerror = function (e) {
                  b({ errorCode: 202, description: "Open WebSocket Error" });
                }),
                (n.onclose = function (e) {
                  n &&
                    !n.fileOver &&
                    b({ errorCode: 202, description: "Open WebSocket Error" });
                }));
            },
            disconnect: function () {
              G(B("TEARDOWN", null)),
                clearInterval(x),
                (x = null),
                null !== n && (n.onerror = null),
                null !== n &&
                  n.readyState === WebSocket.OPEN &&
                  (n.close(), (n = null), (P = null)),
                d && d.Stop();
            },
            controlPlayer: function (e) {
              var t = "";
              switch ((e.command, e.command)) {
                case "PLAY":
                  if (((C = "Play"), null != e.range)) {
                    t = B("PLAY", null, 0, e.range);
                    break;
                  }
                  t = B("PLAY", null);
                  break;
                case "PAUSE":
                  if ("PAUSE" === C) break;
                  (C = "PAUSE"), (t = B("PAUSE", null));
                  break;
                case "SCALE":
                  (t = B("SCALE", null, 0, e.data)),
                    d && d.SetPlaySpeed(e.data);
                  break;
                case "TEARDOWN":
                  (t = B("TEARDOWN", null)), d && d.Stop();
                  break;
                case "audioPlay":
                case "volumn":
                case "audioSamplingRate":
                  d && d.setVolume(e.data);
                  break;
                default:
                  r.h.log("\u672a\u77e5\u6307\u4ee4: " + e.command);
              }
              "" != t && G(t);
            },
            setLiveMode: function (e) {},
            setPlayMode: function (e) {},
            setRTSPURL: function (e) {
              M = e;
            },
            setCallback: function (e, t) {
              "GetFrameRate" === e ? (D = t) : d.setCallback(e, t),
                "Error" == e && (b = t);
            },
            setUserInfo: function (e, t) {
              (E.username = e), (E.passWord = t);
            },
            capture: function (e, t) {
              d.capture(e, t);
            },
            setLessRate: function (e) {},
            setFrameData: function (e, t, n, r, i, o, a) {
              d.setFrameData(e, t, n, r, i, o, a);
            },
          }),
          new F()
        );
      },
      oe = function (e, t, n) {
        var r = n.isPrivateProtocol,
          i = null,
          o = new B(),
          a = {
            wsURL: e,
            rtspURL: t,
            playsdk: o,
            RTSP_STATE: {
              OK: 200,
              UNAUTHORIZED: 401,
              NOTFOUND: 404,
              INVALID_RANGE: 457,
              NOTSERVICE: 503,
              DISCONNECT: 999,
            },
          },
          s = Object.assign(a, n);
        function l() {}
        return (
          (i = r ? new re(s) : new ie(s)),
          (l.prototype = {
            init: function (e, t, n, r) {
              o.Init(e, t, n, r) > 0 && o.GetPlayPort();
            },
            connect: function () {
              i.connect();
            },
            disconnect: function () {
              i.disconnect();
            },
            controlPlayer: function (e) {
              return i.controlPlayer(e);
            },
            setLiveMode: function (e) {},
            setPlayMode: function (e) {},
            setRTSPURL: function (e) {
              i.setRTSPURL(e);
            },
            setCallback: function (e, t) {
              i.setCallback(e, t);
            },
            setUserInfo: function (e, t) {
              i.setUserInfo(e, t);
            },
            capture: function (e, t) {
              o.capture(e, t);
            },
            getCapture: function (e, t) {
              o.capture(e, t, !0);
            },
            setLessRate: function (e) {},
            startRecord: function (e) {
              o.StartRecord(e);
            },
            stopRecord: function () {
              o.StopRecord();
            },
            cancelRecord: function () {
              o.CancelRecord();
            },
            openIVS: function () {
              o.OpenIVSDraw();
            },
            closeIVS: function () {
              o.CloseIVSDraw();
            },
            setFrameData: function (e, t, n, r, i, a, s, l) {
              o.FrameDataCallBack(e, t, n, r, i, a, s, l);
            },
            setDecryptionResult: function (e, t, n) {
              o.DecryptionResultCallBack(e, t, n);
            },
            setIVSData: function (e, t, n, r) {
              o.IVSDataCallBack(e, t, n, r);
            },
            drawIVSData: function (e) {
              o.DrawIVSDataCallBack(e);
            },
            setRecordData: function (e, t, n, r, i) {
              o.RecordDataCallBack(e, t, n, r, i);
            },
            getPlayPort: function () {
              return o.GetPlayPort();
            },
            pause: function () {
              return o.Pause("1");
            },
            start: function () {
              return o.Pause("0");
            },
          }),
          new l()
        );
      },
      ae = function (e) {
        (this.wsURL = e.wsURL),
          (this.rtspURL = e.rtspURL),
          (this.isPlayback = e.playback || !1),
          (this.isTalkService = e.isTalkService || !1),
          (this.isDownloadService = e.isDownloadService || !1),
          (this.isPrivateProtocol = e.isPrivateProtocol || !1),
          (this.playBackRange = e.range || !1),
          (this.realm = e.realm || ""),
          (this.sourceId = e.sourceId || ""),
          (this.ws = null),
          (this.decodeMode = e.decodeMode),
          (this.lessRateCanvas = e.lessRateCanvas || !1),
          (this.events = {
            ResolutionChanged: function () {},
            PlayStart: function () {},
            DecodeStart: function () {},
            UpdateCanvas: function () {},
            GetFrameRate: function () {},
            FrameTypeChange: function () {},
            Error: function () {},
            MSEResolutionChanged: function () {},
            audioChange: function () {},
            WorkerReady: function () {},
            IvsDraw: function () {},
            FileOver: function () {},
            Waiting: function () {},
          }),
          (this.username = "admin"),
          (this.password = "admin123");
      };
    ae.prototype = {
      init: function (e, t, n) {
        for (var r in ((this.ws = new oe(this.wsURL, this.rtspURL, {
          isPrivateProtocol: this.isPrivateProtocol,
          realm: this.realm,
          sourceId: this.sourceId,
          isPlayback: this.isPlayback,
        })),
        this.ws.init(e, t, n, this.isPlayback),
        this.ws.setLiveMode(this.decodeMode),
        this.ws.setUserInfo(this.username, this.password),
        this.ws.setPlayMode(this.isPlayback),
        this.ws.setLessRate(this.lessRateCanvas),
        this.events))
          this.ws.setCallback(r, this.events[r]);
        this.events = null;
      },
      connect: function () {
        this.ws.connect();
      },
      talk: function (e, t, n, r, i) {
        if ("on" === e) {
          for (var o in ((this.ws = new oe(this.wsURL, this.rtspURL, {
            isTalkService: this.isTalkService,
            isPrivateProtocol: this.isPrivateProtocol,
            realm: this.realm,
            sourceId: this.sourceId,
          })),
          i && this.ws.init(t, n, r, this.isPlayback),
          this.ws.setUserInfo(this.username, this.password),
          this.events))
            this.ws.setCallback(o, this.events[o]);
          this.ws.connect();
        } else this.ws.disconnect();
      },
      download: function (e, t, n, r) {
        for (var i in ((this.ws = new oe(this.wsURL, this.rtspURL, {
          isPrivateProtocol: this.isPrivateProtocol,
          realm: this.realm,
          sourceId: this.sourceId,
          isPlayback: this.isPlayback,
          isDownloadService: this.isDownloadService,
        })),
        this.ws.init(e, t, n, this.isPlayback),
        this.ws.setLiveMode(this.decodeMode),
        this.ws.setUserInfo(this.username, this.password),
        this.ws.setPlayMode(this.isPlayback),
        this.ws.setLessRate(this.lessRateCanvas),
        this.events))
          this.ws.setCallback(i, this.events[i]);
        (this.events = null), this.connect(), this.startRecord(r);
      },
      play: function () {
        this.controlPlayer("PLAY");
      },
      pause: function () {
        this.controlPlayer("PAUSE"), this.ws.pause();
      },
      continue: function () {
        this.controlPlayer("PLAY"), this.ws.start();
      },
      stop: function () {
        this.controlPlayer("TEARDOWN");
      },
      close: function () {
        this.ws.disconnect();
      },
      playByTime: function (e) {
        this.controlPlayer("PLAY", "video", e);
      },
      playFF: function (e) {
        this.controlPlayer("SCALE", e);
      },
      playRewind: function () {},
      audioPlay: function () {
        this.controlPlayer("audioPlay", "start");
      },
      audioStop: function () {
        this.controlPlayer("audioPlay", "stop");
      },
      setAudioSamplingRate: function (e) {
        this.controlPlayer("audioSamplingRate", e);
      },
      setAudioVolume: function (e) {
        this.controlPlayer("volumn", e);
      },
      controlPlayer: function (e, t, n) {
        var r;
        (r =
          "video" === t
            ? { command: e, range: n || 0 }
            : { command: e, data: t }),
          this.ws.controlPlayer(r);
      },
      setPlayMode: function (e) {
        this.ws.setLiveMode(e);
      },
      setPlayPath: function (e) {
        this.ws.setRTSPURL(e);
      },
      capture: function (e, t) {
        this.ws.capture(e, t);
      },
      getCapture: function (e, t) {
        this.ws.getCapture(e, t);
      },
      startRecord: function (e) {
        this.ws.startRecord(e || Date.now() + ".mp4");
      },
      stopRecord: function () {
        this.ws.stopRecord();
      },
      cancelRecord: function () {
        this.ws.cancelRecord();
      },
      openIVS: function () {
        this.ws.openIVS();
      },
      closeIVS: function () {
        this.ws.closeIVS();
      },
      setFrameData: function (e, t, n, r, i, o, a, s) {
        this.ws.setFrameData(e, t, n, r, i, o, a, s);
      },
      setDecryptionResult: function (e, t, n) {
        this.ws.setDecryptionResult(e, t, n);
      },
      setIVSData: function (e, t, n, r) {
        this.ws.setIVSData(e, t, n, r);
      },
      drawIVSData: function (e) {
        this.ws.drawIVSData(e);
      },
      setRecordData: function (e, t, n, r, i) {
        this.ws.setRecordData(e, t, n, r, i);
      },
      getPlayPort: function () {
        return this.ws.getPlayPort();
      },
      on: function (e, t) {
        this.events[e] = t;
      },
    };
    t.default = ae;
  },
]).default;
export default PlayerControl;
