<template>
  <div class="home-header-component">
    <div class="home-header">
        <a-menu mode="horizontal" class="page-home-menu" :openKeys="openKeys" :selectedKeys="selectedKeys" @openChange="openChange" @click="itemClick">
          <template v-for="(item,index) in menuListHS">
              <template v-if="item.meta.ismenu && authValidate(item) && item.path!=='/message'">
                <a-sub-menu v-if="hasMenuChildren(item) && authValidate(item)" :key="item.meta.id" class="header-nev-menu" 
                  :class="index==4?'header-nev-no-border':index==6?'header-nev-no-border header-nev-no-border-last':''">
                  <span slot="title">
                    <span class="menu-text">{{ item.name }}</span>
                  </span>
                  <template v-for="subItem of item.children || []">
                    <template v-if="subItem.meta.ismenu && authValidate(item)">
                      <a-sub-menu v-if="hasMenuChildren(subItem)" :key="subItem.meta.id">
                        <span slot="title">
                          <span>{{ subItem.name }}</span>
                        </span>
                        <template v-for="thirdItem of subItem.children || []">
                          <a-menu-item v-if="thirdItem.meta.ismenu && authValidate(thirdItem)" :key="thirdItem.meta.id">
                            <router-link :to="thirdItem.path">{{ thirdItem.name }}</router-link>
                          </a-menu-item>
                        </template>
                      </a-sub-menu>
                      <template v-else>
                        <a-menu-item v-if="authValidate(subItem)" :key="subItem.meta.id">
                          <router-link :to="subItem.path">
                            <span class="menu-text">{{ subItem.name }} </span>
                          </router-link>
                        </a-menu-item>
                      </template>
                    </template>
                  </template>
                </a-sub-menu>
                <template v-else>
                  <a-menu-item v-if="authValidate(item)" :key="item.meta.id" class="header-nev-menu">
                      <router-link  :to="item.path">
                        <span class="menu-text">{{ item.name }}</span>
                      </router-link>
                  </a-menu-item>
                </template>
              </template>
              <template v-if="item.meta.ismenu && authValidate(item) && item.path=='/message'">
                  <!-- <a-menu-item v-if="authValidate(item)" :key="item.meta.id" class="header-nev-menu header-nev-menu-message hint-header">
                    
                    <router-link :to="item.path">
                      <span class="menu-text">({{homeUnreadNumber}})</span>
                    </router-link>
                  </a-menu-item> -->
              </template>
          </template>
        </a-menu>
        <div class="user_login">
          <img src="../../images/icon-me.png" alt="" srcset="">
          <p>您好,融通安全监管</p>
          <p>/</p>
          <p  @click="logout()">退出登录</p>
        </div>
        <div class="now_date">{{dateTime}}</div>
    </div>
    
    
  </div>
</template>
<script>
import menuListHS from "@/router/_business_router_hs";
import {createNamespacedHelpers,mapGetters,mapActions as mapOtherActions,mapState as mapOtherState,} from "vuex";
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("Home/");
import request from '@/utils/request'

export default {
  name: "homeHeaderComponent",
  props: {},
  data() {
    return {
      headerName : "home",
      mapHeaderName : "architecture",
      headerTypeList : ['building'],
     
      menuListHS,
      activeIndex :"1",
      current : 'mail',
      openKeys: [],
      selectedKeys: [],
      dateTime : "",
      time : null,
      userinfo : {}
    };
  },
  created() {
    if (JSON.stringify(this.$route.meta) != "{}") {
      const { id, parentId } = this.$route.meta;
      if(typeof parentId !=="undefined"){
        this.selectedKeys = [parentId];
        localStorage.setItem("currentKey", this.selectedKeys);
      }
      
    }
  },
  mounted(){
    // console.log("menuListHS:",this.menuListHS)
     this.time = setInterval(this.getDate, 1000);
     this.userinfo = JSON.parse(localStorage.getItem("iot-fire-op-userinfo"));
  },
  computed: {
		...mapOtherState({
			authCodes: (store) => store.permission.authCodes
		}),
    ...mapState([
      "homeUnreadNumber"
    ]),
	},
  methods: {
    ...mapActions([
      "filterAroundPower",
    ]),
    getDate(){
        var date = new Date();
        var sign2 = ":";
        var year = date.getFullYear() // 年
        var month = date.getMonth() + 1; // 月
        var day = date.getDate(); // 日
        var hour = date.getHours(); // 时
        var minutes = date.getMinutes(); // 分
        var seconds = date.getSeconds() //秒
        var weekArr = [ '星期天','星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
        var week = weekArr[date.getDay()];
        // 给一位数的数据前面加 “0”
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (day >= 0 && day <= 9) {
          day = "0" + day;
        }
        if (hour >= 0 && hour <= 9) {
          hour = "0" + hour;
        }
        if (minutes >= 0 && minutes <= 9) {
          minutes = "0" + minutes;
        }
        if (seconds >= 0 && seconds <= 9) {
          seconds = "0" + seconds;
        }
        this.dateTime =  year + "年" + month + "月" + day + "日 " + week + " " +hour +sign2 + minutes +sign2 +seconds;

    },
    gotoIcc() {
      request({
        url: "/api-thing/v1/iccIntegration/getToken",
        method: "get",
      }).then((res) => {
        console.log(res)
        if (res.success) {
          window.open(`https://116.228.80.14:8091/?token=${ res.data.access_token }&refreshToken=${ res.data.refresh_token }#/home`)
        }
      })
    },
    gobaseMessage(){
      
    },
    hasMenuChildren(data) {
			if (!data.children) return;
			if (!data.children.length) return;

			let hasMenu = false;
			data.children.map(function(item) {
				if (item.meta && item.meta.ismenu) {
					hasMenu = true;
				}
			});

			return hasMenu;
        },
    authValidate(item) {
			return this.authCodes.includes(item.meta.code)
		},
    logout() {
      this.$store.dispatch('LogOut')
      .then(() => {
        localStorage.removeItem('isLogin');
        location.reload();
      })
    },
    openChange(v) {
      this.openKeys = v;
    },
    itemClick(v) {
      console.log(v.key);
      this.selectedKeys = [v.key];
      localStorage.setItem("currentKey", this.selectedKeys);
    },
  },
  watch: {
      $route(to,from) {
      setTimeout(() => {
        console.log("selectedKeys", this.selectedKeys);
        this.selectedKeys = [localStorage.getItem("currentKey")];
      }, 200);
    },
      
  },
  destroyed(){
    clearInterval(this.time)
  }
};
</script>
<style lang="less">
.home-seach{
      .ant-input-search{
        .ant-input,.ant-input:hover{
          color:#3685A8;
              border: 1px solid #2277A7;
        }
        .ant-input{
          -webkit-box-shadow:0px 0px 10px 1px #2277A7 inset;
          -moz-box-shadow:0px 0px  10px 1px #2277A7 inset;
          box-shadow:0px 0px  10px 1px #2277A7 inset;
        }
      }
      .anticon{
        color:#3685A8;
      }
      .ant-input-group-addon{
        background-color:rgba(255,255,255,0);
      }
      .ant-btn{
        border: 1px solid #3685A8;
        border-left : 0px;
        -webkit-box-shadow:0px 0px 20px 3px #3685A8 inset;
        -moz-box-shadow:0px 0px  20px 3px #3685A8 inset;
        box-shadow:0px 0px  20px 3px #3685A8 inset;
      }
    }
</style>
<style lang="less" scoped>
.home-dashboard{
    .home-header-component{
        z-index: 100;
      .now_date{
        position:absolute;
        top:10px;
        right: 25px;
        font-size: 16px;
      }


        .header-nev{
          display: flex;
          color: #fff;
          font-size:0.18rem;
          line-height: 0.3rem;
          margin-top: 0.6rem;
          margin-left: 0.3rem;
          .on-header-nev{
            position: relative;
            display: block;
            background: url('http://xiaofangyunfile.zxzx119.com/home-map-btn-seclet.png') no-repeat center;
            background-size:100%;
            background-position: 0rem 0.025rem;  
            
          }
          .nev{
            padding:0;
            cursor:pointer;
            margin-left: 0.3rem;
            position: relative;
            .nev-text{
              padding: 0rem 0.2rem;
            }
          }
          .nev::after{
            content :"|";
            color: #52CFFF;
            padding-left: 0.2rem;
            position: absolute;
            top: 0;
            right: -.2rem;
          }
          .nev:last-child::after,.nev:nth-child(5)::after{ 
            content :"";
          }
          .nev:nth-child(5){
            margin-right: 4.5rem;
          }
        }
        .hint-header{
          width: 0.7rem;
          font-size: 0.16rem;
          position:absolute;
          right: 0.2rem;
          top: 0.1rem;
          padding-left:0.3rem;
          background: url('http://xiaofangyunfile.zxzx119.com/home-map-bell.png') no-repeat center;
          background-size:0.2rem 0.2rem;
          background-position: 0rem 0rem;
          cursor: pointer;
          line-height:0.2rem;
          .menu-text{
            color: #FF0000;
          }
        }
        .user_login{
          position:absolute;
          display: flex;
          right : 0.3rem;
          top: 0.45rem;
          font-size: 0.16rem;
          height:0.5rem;
          line-height:0.5rem;
          align-items: center;
          cursor: pointer;
          >p{
            margin: 0;
          }
          
          >img{
            background: #fff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
          }
          >p:nth-child(2){
            margin: 0 0.1rem 0 0.1rem;
            width: 70px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          >p:nth-child(2),p:nth-child(3){
            color:#52CFFF;
            font-size: 0.14rem;
            margin: 0 0.1rem 0 0.1rem;
          }
        }
        .home-header{
        width: 100%;
        height:1rem;
        background: url('../../images/home-map-header-rt.png') no-repeat center;
        background-size:100% 1rem;
        background-position: 0rem 0rem;  
        top: 0;
        left: 0;
        position: fixed;
        z-index:100000;
        .home-totle{
          margin:0.1rem auto ;
          width: 100%;
          position:absolute;
          text-align:center;
          >p{
            color: #ffffff;
            margin-bottom: 0; bottom: 0;
          }
          >p:first-child{
            font-size:0.3rem;
          }
         
        }
    }
    }
  
  }
</style>