import request from '@/utils/request'
import { message } from 'ant-design-vue'
import { buildListParams } from '@/utils';

export const getTableList = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api-fire/v1/danger',
      method: 'get',
      params: buildListParams(params.searchForm, params.pagination),
    }).then(res => {
      if(res.success) {
        let result = res.result;
        commit('SET_TABLE_DATA', res.result.data)
        commit('SET_TABLE_PAGE_INFO', {
          pageNo: result.pageNo, 
          pageSize: result.pageSize, 
          total: Number(result.totalCount)})
        resolve(res.result.data);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const detail = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/danger/${params.id}`,
      method: 'get'
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}

export const create = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api-fire/v1/danger',
      method: 'post',
      data: params
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
export const edit = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-fire/v1/danger/${params.id}`,
      method: 'put',
      data: params
    }).then(res => {
      if(res.success) {
        resolve(res.result);
      } else {
        reject(res);
      }
    }).catch(e => {
      reject(e);
    })
  })
}
export const deleteData = ({ commit }, keys) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-fire/v1/danger`,
      method: 'delete',
      data: {ids: keys}
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const deleteSingleData = ({ commit }, id) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-fire/v1/danger/${id}`,
      method: 'delete',
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const updatePagination = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_TABLE_PAGE_INFO", params);
		reslove();
	});
};

//下发通知
export const sendMessage = ({ commit }, ids) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-fire/v1/danger/sendMessage`,
      method: 'post',
      data: ids
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

//隐患处理
export const handler = ({ commit }, params) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-fire/v1/danger/handler/${params.id}`,
      method: 'put',
      data: params.data
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      console.log(e)

      reject();
    })
  })
}

//处理整改
export const rectify = ({ commit }, params) => {
  return new Promise((resolve, reject) => {request({
      url: `/api-fire/v1/danger/rectify/${params.id}`,
      method: 'put',
      data: params.data
    }).then(res => {
      if(res.success) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(e => {
      reject();
    })
  })
}

export const updateSearchForm = ({commit}, params) => {
	return new Promise((reslove, reject) => {
		commit("SET_SEARCH_FORM", params);
		reslove();
	});
};

//向社会单位下发整改通知
export const sendText = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/api-uc/v1/uc/company/sendAllUserMessage/${params.orgId}`,
      method: 'post',
      params: {message:params.message},
    })
      .then((res) => {
        if (res.success) {
          let result = res.result?res.result:'';
          resolve(res.result);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
